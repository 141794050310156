import { Loader } from '@tackle-io/platform-ui';
import AceInvitationDetails from './AceInvitationDetails/AceInvitationDetails';
import { useAceInvitation } from 'packages/cosell/api/hooks/useAceInvitation';
import { useMemo } from 'react';
import { CoSellContextProvider } from '../../CoSellContextProvider';
import { ErrorBoundary } from 'components';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import { useCurrentUserVendorQuery } from 'generated/graphql';
import useAceInvitationEventsQuery from '../../hooks/useAceInvitationEventsQuery/useAceInvitationEventsQuery';

interface UnifiedInvitationDetailsContainerProps {
  invitationId?: string;
  children?: React.ReactNode;
}

const UnifiedInvitationDetails = ({
  match,
}: {
  match: { params: { [key: string]: string }; url: string };
}) => {
  const invitationId = useMemo(
    () => match.params.invitationId?.split('?')[0],
    [match.params.invitationId],
  );
  const { aceInvitationQuery } = useAceInvitation({ invitationId });
  const invitation = aceInvitationQuery?.data;
  const { invitationEvents, isInvitationEventsLoading, invitationEventsError } =
    useAceInvitationEventsQuery(invitation?.invitationId);

  const events = invitationEvents?.events || [];

  // this invitationError property from useQuery
  if (aceInvitationQuery.isError) {
    return <div>Error loading invitation</div>;
  }

  if (aceInvitationQuery.isLoading || isInvitationEventsLoading) {
    return <Loader />;
  }

  if (!invitation) {
    return <div>No invitation found</div>;
  }

  return (
    <UnifiedInvitationDetailsContainer invitationId={invitationId}>
      <AceInvitationDetails
        invitation={invitation}
        invitationEvents={events}
        invitationEventsError={invitationEventsError}
      />
    </UnifiedInvitationDetailsContainer>
  );
};

/** Wrapper for Tackle-UI only. */
export const UnifiedInvitationDetailsContainer = ({
  invitationId,
  children,
}: UnifiedInvitationDetailsContainerProps) => {
  const { data: currentUserData } = useCurrentUserVendorQuery({
    nextFetchPolicy: 'cache-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const vendorId = currentUserData?.currentUser?.vendor?.id;

  if (!vendorId) {
    return <div>Not Authenticated</div>;
  }

  return (
    <ErrorBoundary renderError={() => <ErrorPage title="Co-sell" />}>
      <CoSellContextProvider
        invitationId={invitationId}
        renderEnv="downstream"
        vendorId={vendorId}
      >
        {children}
      </CoSellContextProvider>
    </ErrorBoundary>
  );
};
export default UnifiedInvitationDetails;
