import { makeStyles } from 'vendor/material';

const useStyles = makeStyles({
  offerStepIndicator: {
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    display: 'inline-flex',
    flex: '1 1 auto',
    marginLeft: '.125rem',
    marginTop: '.125rem',
    width: '100%',
  },
});

export default useStyles;
