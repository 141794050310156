import { CreateAceOpportunityFormValues } from '../../components/UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';

import {
  LifeCycle,
  Marketing,
  CreateCustomer,
  Project,
  OpportunityRequestAccount,
  CreateAceOpportunityRequest,
  Contact,
} from '../../types/requests/AceOpportunityRequest';
import { getAwsSubmission } from './getAwsSubmission';

export const getAccount = (values: CreateAceOpportunityFormValues) => {
  const account: OpportunityRequestAccount = {
    address: getAddress(values),
    duns: values.duns,
    awsAccountId: values.awsAccountId,
    companyName: values.customerCompanyName,
    industry: values.industry,
    otherIndustry: values.industryOther,
    websiteUrl: values.customerWebsite,
  };
  return account;
};

export const getContacts = (
  values: CreateAceOpportunityFormValues,
): Contact[] => {
  const contact = {
    firstName: values.customerFirstName,
    lastName: values.customerLastName,
    email: values.customerEmail,
    phone: values.customerPhone ? `+${values.customerPhone}` : undefined,
    businessTitle: values.customerTitle,
  };
  return [contact];
};

export const getCustomer = (
  values: CreateAceOpportunityFormValues,
): CreateCustomer => {
  const customer: CreateCustomer = {
    account: getAccount(values),
    contacts: getContacts(values),
  };

  return customer;
};

export const getLifecycle = (values: CreateAceOpportunityFormValues) => {
  const targetCloseDate = values.targetCloseDate
    ? new Date(values.targetCloseDate)?.toISOString()
    : null;
  const lifeCycle: LifeCycle = {
    nextSteps: values.nextStep,
    targetCloseDate,
  };
  return lifeCycle;
};

export const getMarketing = (values: CreateAceOpportunityFormValues) => {
  const marketing: Marketing = {
    awsFundingUsed: values.isMarketingDevelopmentFunded,
    campaignName: values.campaignName,
    channels: values.marketingActivityChannel,
    source: values.marketingSource,
    useCases: values.marketingActivityUseCases,
  };
  return marketing;
};

export const getProject = (values: CreateAceOpportunityFormValues) => {
  const project: Project = {
    additionalComments: values.additionalComments,
    apnPrograms: values.apnPrograms,
    competitorName: values.competitiveTracking,
    customerBusinessProblem: values.customerBusinessProblem,
    customerUseCase: values.customerUseCase,
    deliveryModels: values.deliveryModels,
    /** form only handles 1 entry at the moment,
     *  TODO: update this to handle multiple entries if form supports it
     */
    expectedCustomerSpend: [
      {
        amount: String(values.expectedCustomerSpendAmount),
        currencyCode: values.expectedCustomerSpendCurrencyCode,
        frequency: values.expectedCustomerSpendFrequency,
        targetCompany: values.expectedCustomerSpendTargetCompany,
      },
    ],
    otherCompetitorNames: values.otherCompetitorNames,
    salesActivities: values.salesActivities,
    title: values.projectTitle,
    otherSolutionDescription: values.otherSolutionDescription,
  };
  return project;
};

// For Create, we initialize with just 1
export const getPartnerOpportunityTeam = (
  values: CreateAceOpportunityFormValues,
) => {
  const contactFormFields = [
    'primaryContactFirstName',
    'primaryContactLastName',
    'primaryContactEmail',
    'primaryContactPhone',
    'primaryContactTitle',
  ];
  if (contactFormFields.some((field) => values[field])) {
    return [
      {
        email: values.primaryContactEmail,
        firstName: values.primaryContactFirstName,
        lastName: values.primaryContactLastName,
        phone: values.primaryContactPhone
          ? `+${values.primaryContactPhone}`
          : null,
        businessTitle: values.primaryContactTitle,
      },
    ];
  } else {
    return null;
  }
};
export const getAddress = (values: CreateAceOpportunityFormValues) => {
  const address = {
    city: values.city,
    countryCode: values.countryCode,
    postalCode: values.postalCode,
    stateOrRegion: values.state,
    streetAddress: values.address,
  };
  return address;
};

export const convertAceFormValuesToCreateRequest = (
  values: CreateAceOpportunityFormValues,
): CreateAceOpportunityRequest => {
  return {
    customer: getCustomer(values),
    lifeCycle: getLifecycle(values),
    marketing: getMarketing(values),
    nationalSecurity: values.nationalSecurity,
    opportunityType: values.opportunityType,
    opportunityTeam: getPartnerOpportunityTeam(values),
    primaryNeedsFromAws: values.primaryNeedsFromAws,
    project: getProject(values),
    solutions: values.solutions,
    awsProducts: values.awsProducts,
    awsSubmission: getAwsSubmission(values.primaryNeedsFromAws),
  };
};
