import * as yup from '../../../../../../../../../../utils/yup-extended';

export enum FieldKey {
  Sku = 'sku',
  Description = 'description',
  Price = 'price',
}

export interface FormUsageDimension {
  [FieldKey.Sku]: string | null;
  [FieldKey.Description]: string | null;
  [FieldKey.Price]: number | null;
}

export const usageDimensionsSchema = yup.array<FormUsageDimension>().of(
  yup.object({
    [FieldKey.Sku]: yup.string().required().progress(true),
    [FieldKey.Description]: yup.string().nullable(),
    [FieldKey.Price]: yup.number().min(0).nullable(),
  }),
);
