import * as yup from 'utils/yup-extended';
import { EULAType } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { isWithProgress } from 'pages/PrivateOffers/pages/Next/generic/utils/schema/schemaTestsUtils';

export enum FieldKey {
  EulaType = 'eulaType',
  EulaDocumentUrns = 'eulaDocumentUrns',
}

export type FormValues = {
  [FieldKey.EulaType]: string;
  [FieldKey.EulaDocumentUrns]: string[];
};

const documentLengthErrorMessage =
  'A single document is required with a maximum of 5.';

const eulaTypeSchema = yup
  .string()
  .oneOf(Object.values(EULAType))
  .required()
  .progress(true);

const eulaDocumentUrnsSchema = yup.array<string>().when(`$`, {
  is: isWithProgress(
    FieldKey.EulaType,
    (dt: EULAType) => dt === EULAType.Custom,
  ),
  then: (schema: yup.ArraySchema<string>) =>
    schema
      .min(1, documentLengthErrorMessage)
      .max(5, documentLengthErrorMessage)
      .required()
      .progress(true),
  otherwise: (schema: yup.ArraySchema<string>) =>
    schema.max(5, 'Maximum of 5 documents allowed.'),
});

export const formSchema: yup.ObjectSchema<FormValues> = yup.object({
  [FieldKey.EulaType]: eulaTypeSchema,
  [FieldKey.EulaDocumentUrns]: eulaDocumentUrnsSchema,
});
