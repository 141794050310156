import { UnifiedOpportunityFormShell } from '../UnifiedOpportunityFormShell';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import AceOpportunityFormFields from './AceOpportunityFormFields';
import { convertAceFormValuesToCreateRequest } from 'packages/cosell/src/utilities/typeConverters/convertAceFormValuesToCreateRequest';
import { aceOpportunityFormValidationSchema } from './aceOpportunityFormValidationSchema';
import {
  CreateAceOpportunityFormValues,
  UpdateAceOpportunityFormValues,
} from './AceOpportunityFormValues';
import { convertOpportunityResponseToAceFormValues } from 'packages/cosell/src/utilities/typeConverters/convertOpportunityResponseToAceFormValues';
import {
  convertAceFormValuesToUpdateRequest,
  getSoftwareRevenue,
} from 'packages/cosell/src/utilities/typeConverters/convertAceFormValuesToUpdateRequest';
import {
  getEditPageSubmitTackleOperationId,
  TackleOperationId,
} from 'packages/cosell/api/utils';
import { Loader } from '@tackle-io/platform-ui';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import { removeUndefinedOrEmptyObjectProperties } from 'packages/cosell/src/utilities/typeConverters/utils';
import { useAceVendorConfig } from 'packages/cosell/api/hooks/useAceVendorConfig';
import { useAcePickListQuery } from 'packages/cosell/api/hooks/useAcePickList';
import useOpportunityEventsQuery from 'packages/cosell/src/hooks/useOpportunityEventsQuery/useOpportunityEventsQuery';
import { AceCoSellErrorMessagesBanner } from 'packages/cosell/src/pages/AceCoSellErrorMessagesBanner';
import { convertRawRequestToAceFormValues } from 'packages/cosell/src/utilities/typeConverters/convertRawRequestToAceFormValues';

interface EditAceOpportunityFormProps {
  opportunityId: string;
  onCancel: () => void;
  onUpdateSuccess: (opportunityId: string) => void;
}

/**
 *
 * TODO:
 *
 *   The edit form can then be prefilled with the original payload values
 */
const EditAceOpportunityForm = ({
  opportunityId,
  onCancel,
  onUpdateSuccess,
}: EditAceOpportunityFormProps) => {
  const acePickListQuery = useAcePickListQuery();

  const {
    isSaasDocumentationRequiredToLaunch,
    isError: isVendorConfigError,
    isLoading: isVendorConfigLoading,
  } = useAceVendorConfig();
  const { aceOpportunityQuery, updateAceOpportunity } = useAceOpportunity({
    opportunityId,
  });

  const opportunity = aceOpportunityQuery?.data;
  const lastAceRequestHasErrors = opportunity?.metadata?.hasCloudErrors;

  const { aceLatestRawRequest } = useOpportunityEventsQuery(opportunityId);

  const handleSubmitToCloud = async (
    values: UpdateAceOpportunityFormValues,
  ) => {
    const requiredSaasDocumentationToLaunchParams =
      opportunity?.isCreatedInCloud &&
      isSaasDocumentationRequiredToLaunch({
        deliveryModels: opportunity.project?.deliveryModels ?? [],
      })
        ? { softwareRevenue: getSoftwareRevenue(values) }
        : null;

    const body = {
      ...convertAceFormValuesToUpdateRequest(values),
      ...requiredSaasDocumentationToLaunchParams,
    };
    const trimmedBody = removeUndefinedOrEmptyObjectProperties(body);
    const tackleOperationId =
      'lifeCycle' in opportunity
        ? getEditPageSubmitTackleOperationId(
            opportunity?.lifeCycle?.reviewStatus,
          )
        : TackleOperationId.UPDATE_START_ENGAGEMENT;

    return updateAceOpportunity.mutateAsync(
      {
        requestBody: trimmedBody,
        tackleOperationId,
      },
      {
        onSuccess(data) {
          if (data?.id) {
            onUpdateSuccess(data.id);
          }
        },
      },
    );
  };

  const handleSaveAceOpportunityAsDraft = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    const requestBody = convertAceFormValuesToCreateRequest(values);
    return updateAceOpportunity.mutateAsync(
      {
        requestBody,
        tackleOperationId: TackleOperationId.UPDATE_OPPORTUNITY,
      },
      {
        onSuccess(data) {
          if (data?.id) {
            onUpdateSuccess(data.id);
          }
        },
      },
    );
  };

  if (
    aceOpportunityQuery.isLoading ||
    updateAceOpportunity.isLoading ||
    isVendorConfigLoading ||
    acePickListQuery.isLoading
  ) {
    return <Loader />;
  }

  /** We only merge the rawRequest form data with the existing response form data if there was an error in the last request.
   * If the last request has no errors, the opportunity response data already contains the most up-to-date information.
   */
  const mergedFormValues = lastAceRequestHasErrors
    ? {
        ...(opportunity.isCreatedInCloud
          ? convertOpportunityResponseToAceFormValues(opportunity)
          : null),
        ...convertRawRequestToAceFormValues(aceLatestRawRequest), // !!!Make sure the raw request form value is after the opportunity form value
      }
    : opportunity?.isCreatedInCloud
    ? convertOpportunityResponseToAceFormValues(opportunity)
    : null;

  /** an opportunity that has been created in the cloud.
   *  we do not want to pass in an incomplete (pendingCreation) opportunity
   * TODO: update the opportunity type in UnifiedOpportunityFormShell to accept a null opportunity
   * since should not set this to undefined.
   * undefined is used to satisfy typescript
   */

  const flattenedAceFormValues = !opportunity?.isCreatedInCloud
    ? convertRawRequestToAceFormValues(aceLatestRawRequest)
    : mergedFormValues;

  if (isVendorConfigError || acePickListQuery.isError || !opportunity) {
    return <div>Required data failed to load, please refresh</div>;
  }

  const cloudCreatedOpportunity = opportunity.isCreatedInCloud
    ? opportunity
    : undefined;

  return (
    <>
      <UnifiedOpportunityFormShell
        onCancel={onCancel}
        opportunityId={opportunityId}
        opportunity={cloudCreatedOpportunity}
        onSubmit={handleSubmitToCloud}
        /** strict mode type error here that needs to be addressed */
        onSaveAsDraft={handleSaveAceOpportunityAsDraft}
        initialValues={flattenedAceFormValues}
        provider={DisplayCloudType.AWS}
        validationSchema={aceOpportunityFormValidationSchema}
      >
        {opportunity.metadata?.hasCloudErrors && (
          <div style={{ paddingTop: '1rem' }}>
            <AceCoSellErrorMessagesBanner
              opportunityId={opportunityId}
              title="Co-sell operation failed"
            />
          </div>
        )}

        <AceOpportunityFormFields
          reviewStatus={
            cloudCreatedOpportunity?.lifeCycle?.reviewStatus ?? null
          }
          isCreatedInCloud={opportunity.isCreatedInCloud}
        />
      </UnifiedOpportunityFormShell>
    </>
  );
};

export default EditAceOpportunityForm;
