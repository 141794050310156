import { makeStyles } from 'vendor/material';
import { fontWeightMediumLarge } from 'utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gap: theme.spacing(2),
    borderBottom: `1px solid #EBECF0`,
    paddingBottom: theme.spacing(2),
  },
  toggleButton: {
    height: '28px',
    borderRadius: '4px',
    backgroundColor: theme.palette.NEUTRAL020,
    display: 'flex',
    alignItems: 'center',
    fontWeight: fontWeightMediumLarge,
  },
  chevronOpen: {
    transform: 'rotate(180deg)',
  },
  toggleIcon: {
    fontSize: '1rem',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));

export default useStyles;
