import React from 'react';
import { FieldArray } from 'formik';
import { Box, Grid } from 'vendor/material';
import { Button } from '@tackle-io/platform-ui';
import { FieldKey, FormBuyer } from './formSchema';
import OfferBuyer from './OfferBuyer';

const newBuyer: FormBuyer = { name: '', email: '', title: '' };

const OfferBuyers = () => (
  <Grid container spacing={2}>
    <FieldArray name={FieldKey.Buyers}>
      {({ form, push, remove }) => (
        <>
          {form.values.buyers.map((_, index: number) => (
            <OfferBuyer
              key={`offer-buyer-${index}`}
              index={index}
              onRemove={remove}
            />
          ))}
          <Box mt={1}>
            <Button
              appearance="primary"
              variant="text"
              onClick={() => {
                push(newBuyer);
              }}
            >
              Add buyer
            </Button>
          </Box>
        </>
      )}
    </FieldArray>
  </Grid>
);

export default OfferBuyers;
