import { AceInvitationCloudStatusEnum } from 'packages/cosell/src/types/enums';
import {
  AceInvitationEventResponse,
  AceInvitationStatusEnum,
} from '../../types/AceInvitation.type';
/**
 * Check if the invitation has any async operations pending,
 * events that have been kicked off, but the backend does not monitor.
 *
 */

export function getPendingRequestOperationId({
  events,
  status,
}: {
  events: Pick<AceInvitationEventResponse, 'operation' | 'cloudRaw'>[];
  status: AceInvitationCloudStatusEnum;
}) {
  if (
    status === AceInvitationCloudStatusEnum.ACCEPTED ||
    status === AceInvitationCloudStatusEnum.REJECTED
  ) {
    return null;
  }

  // Map operationId to status which completes the cycle of an invitation
  const eventOperationStatusMap: {
    acceptInvitation: AceInvitationStatusEnum;
    rejectInvitation: AceInvitationStatusEnum;
  } = {
    rejectInvitation: AceInvitationStatusEnum.REJECTED,
    acceptInvitation: AceInvitationStatusEnum.ACCEPTED,
  };

  for (let op in eventOperationStatusMap) {
    const operationStatus =
      eventOperationStatusMap[op as 'acceptInvitation' | 'rejectInvitation'];

    /**
     * Only checking the last X events (most recent)
     */
    const operationEventIndex = events.findIndex((x) => x.operation === op);
    if (!operationStatus || operationEventIndex === -1) {
      /** if no events linked to operation are found, it's not pending
       *  move on to next operation
       */
      continue;
    }
    /** if cloudRaw['cloudRaw']['Status'] matches expected status, we are no longer pending */
    /** Look at only latest events until the operation event index */
    const latestEventsBeforeOperation = events.slice(0, operationEventIndex);
    const hasSuccessfullyProcessedOperation = latestEventsBeforeOperation.some(
      (event) => event['cloudRaw']['Status'] === operationStatus,
    );
    if (!hasSuccessfullyProcessedOperation) {
      // Returns rejectInvitation or acceptInvitation
      return op as 'acceptInvitation' | 'rejectInvitation';
    }
  }

  return null;
}
