import EditForm from './EditForm/EditForm';
import { useHistory } from 'react-router-dom';
import { initialFormValuesForNewOffer } from './EditForm/initialFormValues';
import { FormValues } from './EditForm/formSchema';
import { formValuesToPrivateOffer } from './EditForm/formValuesToPrivateOffer';
import React, { useContext } from 'react';
import {
  OfferType,
  PrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import AwsEditOfferContextProvider from 'pages/PrivateOffers/pages/Next/aws/edit/AwsEditOfferContext/AwsEditOfferContextProvider';
import {
  toPrivateOfferAfterSubmit,
  toPrivateOfferOnCancel,
} from 'pages/PrivateOffers/pages/Next/generic/utils/page/editOfferPageCallbackUtils';
import { Cloud } from 'utils/cloudTypes';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContextProvider/apiContext';
import { mergeInitialValuesWithSalesforceDataMapping } from './EditForm/mergeInitialValuesWithSalesforceDataMapping';
import SalesforceDataMappingContextProvider from 'pages/PrivateOffers/pages/Next/generic/SalesforceDataMappingContext/SalesforceDataMappingContextProvider';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import SalesforceDataMappingContext from '../../generic/SalesforceDataMappingContext/salesforceDataMappingContext';
import OfferPageContext from '../../generic/OfferPageContext/offerPageContext';
import BuyerAgreementsContextProvider from './BuyerAgreementsContext/BuyerAgreementsContextProvider';
import { toPrivateOfferOnAmend } from './utils/page/editOfferPageCallbackUtils';
import { OfferRunFrom } from '../../generic/OfferPageContext/offerRunFrom';

interface NewAwsPrivateOfferPageProps {
  offerType: OfferType;
}

const NewAwsPrivateOfferPage: React.FunctionComponent<
  NewAwsPrivateOfferPageProps
> = ({ offerType }) => {
  const {
    api: { createOffer },
  } = useContext(ApiContext);

  const { user, productsByProductId, afterSubmit } =
    useContext(OfferPageContext);

  const { opportunityId, salesforceDataMapping } = useContext(
    SalesforceDataMappingContext,
  );

  const userId = user?.email || '';

  const initialValues = initialFormValuesForNewOffer(
    offerType,
    userId,
    opportunityId,
  );

  const mergedInitialValues = mergeInitialValuesWithSalesforceDataMapping(
    initialValues,
    salesforceDataMapping?.privateOffer,
    productsByProductId,
  );

  const onSubmit = async (
    formValues: FormValues,
    createInMarketplace: boolean,
  ) => {
    const offer = formValuesToPrivateOffer(formValues);
    const createdOffer = await createOffer(offer, createInMarketplace);

    if (createdOffer) {
      afterSubmit(createdOffer);
    }
  };

  return (
    <EditForm
      dataMappingErrors={salesforceDataMapping?.errors}
      initialValues={mergedInitialValues}
      onSubmit={onSubmit}
    />
  );
};

type NewAwsPrivateOfferPageWithDataProvidersProps =
  NewAwsPrivateOfferPageProps & {
    accessTokenProvider?: () => Promise<string>;
    mode: OfferPageMode;
    runFrom: OfferRunFrom;
    afterSubmit?: (offer: PrivateOffer) => void;
    onAmend?: (offerId: string, offer: PrivateOffer | null) => void;
    onCancel?: (offer: PrivateOffer | null) => void;
    opportunityId?: string;
    mappingId?: string;
  };

const NewAwsPrivateOfferPageWithDataProviders: React.FunctionComponent<
  NewAwsPrivateOfferPageWithDataProvidersProps
> = ({
  accessTokenProvider,
  mode,
  runFrom,
  afterSubmit,
  onAmend,
  onCancel,
  offerType,
  opportunityId,
  mappingId,
}) => (
  <AwsEditOfferContextProvider
    accessTokenProvider={accessTokenProvider}
    mode={mode}
    runFrom={runFrom}
    afterSubmit={afterSubmit}
    onAmend={onAmend}
    onCancel={onCancel}
  >
    <SalesforceDataMappingContextProvider
      opportunityId={opportunityId}
      mappingId={mappingId}
    >
      <BuyerAgreementsContextProvider>
        <NewAwsPrivateOfferPage offerType={offerType} />
      </BuyerAgreementsContextProvider>
    </SalesforceDataMappingContextProvider>
  </AwsEditOfferContextProvider>
);

export const NewAwsPrivateOfferCanvasPage: React.FunctionComponent<
  Omit<NewAwsPrivateOfferPageWithDataProvidersProps, 'runFrom'>
> = (props) => (
  <NewAwsPrivateOfferPageWithDataProviders
    {...props}
    runFrom={OfferRunFrom.Canvas}
  />
);

const offerTypeParam = 'offerType';
const modeParam = 'mode';
const opportunityIdParam = 'opportunityId';
const mappingIdParam = 'mappingId';

const getOfferType = (offerTypeParam: string): OfferType => {
  const matchingOfferType = Object.values(OfferType).find(
    (ot: OfferType) => ot === offerTypeParam,
  );

  return matchingOfferType || OfferType.Direct;
};

const getOfferMode = (modeParam: string): OfferPageMode => {
  const matchingMode = Object.values(OfferPageMode).find(
    (m: OfferPageMode) => m === modeParam,
  );

  return matchingMode || OfferPageMode.New;
};

const NewAwsPrivateOfferPlatformPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { search } = history.location;
  const searchParams = new URLSearchParams(search);
  const offerType = getOfferType(searchParams.get(offerTypeParam));
  const mode = getOfferMode(searchParams.get(modeParam));
  const opportunityId = searchParams.get(opportunityIdParam);
  const mappingId = searchParams.get(mappingIdParam);
  const afterSubmit = toPrivateOfferAfterSubmit(history, Cloud.Aws);
  const onAmend = toPrivateOfferOnAmend(history);
  const onCancel = toPrivateOfferOnCancel(history);

  return (
    <NewAwsPrivateOfferPageWithDataProviders
      offerType={offerType}
      mode={mode}
      runFrom={OfferRunFrom.Platform}
      opportunityId={opportunityId}
      mappingId={mappingId}
      afterSubmit={afterSubmit}
      onAmend={onAmend}
      onCancel={onCancel}
    />
  );
};

export default NewAwsPrivateOfferPlatformPage;
