import { Box } from 'vendor/material';

//HELPERS
import {
  ToastDanger,
  ToastLoading,
  ToastSuccess,
} from 'hooks/useToast/toast.templates';

//ASSETS
import useStyles from 'hooks/useToast/useToast.styles';
import { Slide } from 'react-toastify';
import { ThumbUp } from 'mdi-material-ui';
import { CloseIcon } from './assets';

export const WAITING_ON_AWS = 'waitingOnAWS';
export const DRAFT = 'draft';
export const DELETE_DRAFT = 'deleteMessege';
export const CLOUD_REVIEW = 'cloudReview';
export const INBOUND_OPP = 'inboundOutboundOpp';
export const INBOUND_ACCEPT_TO_VIEW_DETAILS = 'acceptInboundOutboundOpp';
export const DECLINE_INBOUND_OPP = 'declineInboundOutboundOpp';
export const INVITATION_ACCEPTANCE_SUBMITTED = 'invitationAcceptanceSubmitted';
export const INVITATION_REJECTION_SUBMITTED = 'invitationRejectionSubmitted';
export const ACCEPT_INVITATION = 'acceptInvitation';
export const REJECT_INVITATION = 'rejectInvitation';

const ToastDelete = () => {
  const classes = useStyles();
  return (
    <div className={classes.toastContainer}>
      <Box pl={2}>
        <span>Draft Successfully Deleted</span>
      </Box>
    </div>
  );
};

const ToastInboundOutBound = () => {
  const classes = useStyles();
  return (
    <div className={classes.toastContainer}>
      <Box pl={2}>
        <span>Accept this opportunity to see all of the details from AWS.</span>
      </Box>
    </div>
  );
};

const ToastAcceptInboundOutbound = () => {
  const classes = useStyles();
  return (
    <div className={classes.toastContainer}>
      <ThumbUp />
      <Box pl={2}>
        <span>Opportunity accepted</span>
      </Box>
    </div>
  );
};

const ToastDeclineInboundOutbound = () => {
  const classes = useStyles();
  return (
    <div className={classes.toastContainer}>
      <Box pl={2}>
        <span>Opportunity declined</span>
      </Box>
    </div>
  );
};

const ToastWaitingOnAWS = () => {
  return (
    <ToastSuccess
      message={`You have successfully submitted your opportunity to the cloud`}
    />
  );
};

const ToastAcceptanceSubmitted = () => {
  return <ToastSuccess message={`Acceptance submitted!`} />;
};

const ToastRejectionSubmitted = () => {
  const classes = useStyles();
  return (
    <div className={classes.toastContainer}>
      <CloseIcon />
      <Box pl={2}>
        <span>Rejection submitted</span>
      </Box>
    </div>
  );
};

const ToastDraft = () => {
  return <ToastLoading message={`Your Draft has been successfully created!`} />;
};

const ToastCloudReview = () => {
  return (
    <ToastSuccess
      message={`You have successfully submitted your opportunity to the cloud service. It is now pending the cloud's review`}
    />
  );
};

export const TOASTS = {
  [DELETE_DRAFT]: {
    message: <ToastDelete />,
    options: {
      render: <ToastDelete />,
      autoClose: true,
      closeButton: false,
      closeOnClick: false,
      draggable: false,
      transition: Slide,
      position: 'top-center',
      type: 'neutral',
      toastId: 'delete-draft-toast',
    },
  },
  [DRAFT]: {
    message: <ToastDraft />,
    options: {
      render: <ToastDraft />,
      autoClose: true,
      closeButton: false,
      closeOnClick: false,
      draggable: false,
      transition: Slide,
      position: 'top-center',
      type: 'info',
      toastId: 'draft-toast',
    },
  },
  [WAITING_ON_AWS]: {
    message: <ToastWaitingOnAWS />,
    options: {
      render: <ToastWaitingOnAWS />,
      autoClose: true,
      closeButton: false,
      closeOnClick: false,
      draggable: false,
      transition: Slide,
      position: 'top-center',
      type: 'success',
      toastId: 'success-toast',
    },
  },
  [ACCEPT_INVITATION]: {
    message: 'Invitation accepted!',
    options: {
      autoClose: true,
      closeButton: false,
      closeOnClick: false,
      draggable: false,
      transition: Slide,
      position: 'top-right',
      type: 'success',
      toastId: 'success-accept-invitation-toast',
    },
  },
  [REJECT_INVITATION]: {
    message: 'Invitation rejected',
    options: {
      autoClose: true,
      closeButton: false,
      closeOnClick: false,
      draggable: false,
      transition: Slide,
      position: 'top-right',
      type: 'warning',
      toastId: 'success-reject-invitation-toast',
    },
  },
  [INVITATION_REJECTION_SUBMITTED]: {
    message: <ToastRejectionSubmitted />,
    options: {
      render: <ToastRejectionSubmitted />,
      autoClose: true,
      closeButton: false,
      closeOnClick: false,
      draggable: false,
      transition: Slide,
      position: 'top-right',
      type: 'warning',
      toastId: 'success-reject-invitation-toast',
    },
  },
  [INVITATION_ACCEPTANCE_SUBMITTED]: {
    message: <ToastAcceptanceSubmitted />,
    options: {
      render: <ToastAcceptanceSubmitted />,
      autoClose: true,
      closeButton: false,
      closeOnClick: false,
      draggable: false,
      transition: Slide,
      position: 'top-right',
      type: 'success',
      toastId: 'success-acceptance-submitted-toast',
    },
  },
  [CLOUD_REVIEW]: {
    message: <ToastCloudReview />,
    options: {
      render: <ToastCloudReview />,
      autoClose: true,
      closeButton: false,
      closeOnClick: false,
      draggable: false,
      transition: Slide,
      position: 'top-center',
      type: 'success',
      toastId: 'cloud-review-toast',
    },
  },
  [INBOUND_OPP]: {
    message: <ToastInboundOutBound />,
    options: {
      render: <ToastInboundOutBound />,
      autoClose: true,
      closeButton: true,
      closeOnClick: true,
      draggable: false,
      transition: Slide,
      position: 'top-center',
      type: 'info',
      toastId: 'inbound-opp-toast',
    },
  },
  [INBOUND_ACCEPT_TO_VIEW_DETAILS]: {
    message: <ToastAcceptInboundOutbound />,
    options: {
      render: <ToastAcceptInboundOutbound />,
      autoClose: true,
      closeButton: true,
      closeOnClick: true,
      draggable: false,
      transition: Slide,
      position: 'top-center',
      type: 'success',
      toastId: 'accept-inbound-opp-toast',
    },
  },
  [DECLINE_INBOUND_OPP]: {
    message: <ToastDeclineInboundOutbound />,
    options: {
      render: <ToastDeclineInboundOutbound />,
      autoClose: true,
      closeButton: true,
      closeOnClick: true,
      draggable: false,
      transition: Slide,
      position: 'top-center',
      type: 'neutral',
      toastId: 'decline-inbound-opp-toast',
    },
  },
  errorToast: (message: string) => ({
    message: <ToastDanger message={message} />,
    options: {
      render: <ToastDanger message={message} />,
      autoClose: false,
      closeButton: false,
      closeOnClick: true,
      draggable: false,
      transition: Slide,
      position: 'top-center',
      type: 'error',
    },
  }),
};
