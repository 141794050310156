import { Button } from '@tackle-io/platform-ui';
import TackleCoSellAnimatedHandshake from 'components/AnimatedSvgs/TackleCoSellAnimatedHandshake';
import { Grid, makeStyles, Typography } from 'vendor/material';
import { LightbulbOnOutline } from 'mdi-material-ui';
import useCoSellSfCanvasActions from 'packages/salesforce-canvas/cosell/src/hooks/useCoSellSfCanvasActions';
import { DisplayCloudType } from 'pages/CoSell/types/enums';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '16px',
    height: '100%',
    background: 'linear-gradient(to bottom, #FFFFFF99, #DEF0F399)',
  },
  expertTip: {
    fontSize: '16px',
    fontWeight: 600,
    textAlign: 'center',
    '& svg': {
      fill: theme.palette.YELLOW500,
      fontSize: '1.7em',
      verticalAlign: 'text-bottom',
    },
  },
  button: {
    backgroundColor: theme.palette.common.white,
    whiteSpace: 'nowrap',
  },
}));

const CoSellCallToAction = () => {
  const { handleCoSellAction } = useCoSellSfCanvasActions();

  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item xs container>
        <Grid
          item
          xs
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item>
            <TackleCoSellAnimatedHandshake />
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center">
              Co-sell with your cloud partners to unlock revenue
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              align="center"
              className={classes.expertTip}
            >
              <LightbulbOnOutline fontSize="inherit" /> Tackle expert tip
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align="center">
              Co-sell increases win rates by 27% and deal size by 80%
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs>
          <Button
            fullWidth
            variant="outlined"
            appearance="primary"
            className={classes.button}
            onClick={() =>
              handleCoSellAction({
                id: 'create-cosell',
                cloud: DisplayCloudType.AWS,
              })
            }
          >
            Co-sell with AWS
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            /** not implemented yet */
            disabled
            fullWidth
            variant="outlined"
            appearance="primary"
            className={classes.button}
            onClick={() =>
              handleCoSellAction({
                id: 'create-cosell',
                cloud: DisplayCloudType.MICROSOFT,
              })
            }
          >
            Co-sell with Microsoft
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CoSellCallToAction;
