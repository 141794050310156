import * as yup from '../../../../../../utils/yup-extended';

export enum FieldKey {
  ProductRef = 'productRef',
}

export interface FormValues {
  [FieldKey.ProductRef]: string | null;
}

export const formSchema = yup.object<FormValues>({
  [FieldKey.ProductRef]: yup.string().nullable().required().progress(true),
});
