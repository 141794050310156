import Lottie, { LottieComponentProps } from 'lottie-react';
import animationData from './tackle-cosell-handshake.json';

const TackleCoSellAnimatedHandshake = (
  props: Omit<LottieComponentProps, 'animationData'>,
) => (
  <Lottie
    animationData={animationData}
    loop={false}
    style={{ width: 120 }}
    {...props}
  />
);

export default TackleCoSellAnimatedHandshake;
