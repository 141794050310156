import React, { useContext } from 'react';
import ViewOfferPage from 'pages/PrivateOffers/pages/Next/generic/ViewOfferPage/ViewOfferPage';
import BasicInformationSection from 'pages/PrivateOffers/pages/Next/aws/view/OfferView/BasicInformationSection';
import AdditionalDetailsSection from './OfferView/AdditionalDetailsSection';
import { Box } from 'vendor/material';
import OfferDetailsSection from './OfferView/OfferDetailsSection';
import EndUserLicenseAgreementSection from './OfferView/EndUserLicenseAgreementSection';
import ProductAndPricingSection from './OfferView/ProductAndPricingSection';
import SidePanel from 'pages/PrivateOffers/pages/Next/aws/view/OfferView/SidePanel';
import { OfferType, PrivateOffer } from '../../generic/api/types/PrivateOffer';
import { History } from 'history';
import ResellerAgreementSection from 'pages/PrivateOffers/pages/Next/aws/view/OfferView/ResellerAgreementSection';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import AwsPrivateOfferPlatformPage, {
  AwsPrivateOfferCanvasPage,
  AwsPrivateOfferCanvasPageProps,
} from 'pages/PrivateOffers/pages/Next/aws/shared/AwsPrivateOfferPage';
import OfferContext from '../../generic/OfferContext/offerContext';
import AmendmentContextProvider from 'pages/PrivateOffers/pages/Next/aws/edit/AmendmentContext/AmendmentContextProvider';

const ViewAwsPrivateOfferPage: React.FunctionComponent = () => {
  const { offer } = useContext(OfferContext);

  return (
    <ViewOfferPage offer={offer} sidePanel={<SidePanel />}>
      <>
        <Box mb={2}>
          <BasicInformationSection />
        </Box>
        <Box mb={2}>
          <ProductAndPricingSection />
        </Box>
        <Box mb={2}>
          <AdditionalDetailsSection />
        </Box>
        <Box mb={2}>
          <EndUserLicenseAgreementSection />
        </Box>
        {offer.offerType === OfferType.PartnerResale && (
          <Box mb={2}>
            <ResellerAgreementSection />
          </Box>
        )}
        <Box mb={2}>
          <OfferDetailsSection />
        </Box>
      </>
    </ViewOfferPage>
  );
};

interface AmendmentProviderWrappedPageProps {
  children: React.ReactElement;
}

const AmendmentProviderWrappedPage: React.FunctionComponent<
  AmendmentProviderWrappedPageProps
> = ({ children }) => {
  const { offer } = useContext(OfferContext);

  return (
    <AmendmentContextProvider offerId={offer?.sourceOfferId}>
      {children}
    </AmendmentContextProvider>
  );
};

const includeVendorContext = false;

export const ViewAwsPrivateOfferCanvasPage: React.FunctionComponent<
  Omit<AwsPrivateOfferCanvasPageProps, 'mode' | 'children'>
> = (props) => (
  <AwsPrivateOfferCanvasPage
    {...props}
    includeVendorContext={false}
    mode={OfferPageMode.View}
  >
    <AmendmentProviderWrappedPage>
      <ViewAwsPrivateOfferPage />
    </AmendmentProviderWrappedPage>
  </AwsPrivateOfferCanvasPage>
);

const platformOnEditProducer = (history: History) => (offer: PrivateOffer) => {
  history.push({
    pathname: `/private-offers/aws/${offer.poId}/edit`,
  });
};

const ViewAwsPrivateOfferPlatformPage: React.FunctionComponent = () => (
  <AwsPrivateOfferPlatformPage
    includeVendorContext={includeVendorContext}
    mode={OfferPageMode.View}
    onEditProducer={platformOnEditProducer}
  >
    <AmendmentProviderWrappedPage>
      <ViewAwsPrivateOfferPage />
    </AmendmentProviderWrappedPage>
  </AwsPrivateOfferPlatformPage>
);

export default ViewAwsPrivateOfferPlatformPage;
