import React from 'react';
import { Divider, Grid, useMediaQuery, useTheme } from 'vendor/material';
import InfoItem, {
  FULL_GRID_SIZE,
  HALF_GRID_SIZE,
} from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
// import { DetailsSectionCard, InfoItem } from 'packages/cosell/src/components';
import { PersonIcon } from 'packages/cosell/assets';
import { Contact } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { coSellAceOpportunityFormFieldsDataId } from 'packages/cosell/src/utilities/intercomEnums';
interface AceContactDetailsProps {
  contact: Contact;
}
const CHARACTER_COUNT = 25;

const AceContactDetails: React.FC<AceContactDetailsProps> = ({ contact }) => {
  const theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down(960));

  return (
    <DetailsSectionCard
      title="Contact details"
      icon={<PersonIcon />}
      subTitle="Provide the details of the end customer associated with the opportunity."
    >
      <Grid container spacing={2}>
        <InfoItem
          itemTitle="Customer first name"
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_FIRST_NAME}
          value={contact?.firstName}
        />
        {screenMobile && <Divider style={{ width: '100%' }} />}
        <InfoItem
          itemTitle="Customer last name"
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_LAST_NAME}
          value={contact?.lastName}
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="Customer title"
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_TITLE}
          value={contact?.businessTitle}
        />
        {screenMobile && <Divider style={{ width: '100%' }} />}
        <InfoItem
          itemTitle="Customer email"
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_EMAIL}
          value={contact?.email}
          gridSize={
            (contact?.email?.length ?? 0) > CHARACTER_COUNT
              ? FULL_GRID_SIZE
              : HALF_GRID_SIZE
          }
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="Customer phone"
          value={contact?.phone}
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_PHONE}
          gridSize={FULL_GRID_SIZE}
        />
        <Divider style={{ width: '100%' }} />
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceContactDetails;
