import React from 'react';
import { Button, Modal } from '@tackle-io/platform-ui';
import { Typography, Box } from 'vendor/material';

interface AdminInstructionsModalProps {
  open: boolean;
  onClose: () => void;
}

const AdminInstructionsModal: React.FC<AdminInstructionsModalProps> = ({
  open,
  onClose,
}) => {
  return (
    <Modal
      title="Admin Instructions"
      open={open}
      width="small"
      showCloseButton
      onClose={onClose}
    >
      <Box mb={2}>
        <Typography variant="body2">
          Here are the instructions for the admin.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default AdminInstructionsModal;
