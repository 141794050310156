import classNames from 'classnames';
import {
  AceOpportunityEventOperation,
  AceOpportunityEventOperationEnum,
} from '../../types/responses/AceOpportunityEventResponse';
import { AnimationSpinner } from '../AnimationSpinner/AnimationSpinner';
import Button from '../Button/Button';
import { makeStyles } from 'vendor/material';
import { useMemo } from 'react';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    opacity: '1!important',
    justifyContent: 'flex-start',
  },
  primaryDisabledButton: {
    backgroundColor: '#0052CC !important',
  },
  destructiveDisabledButton: {
    backgroundColor: '#DE350B !important',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  textContent: {
    marginLeft: '4px',
  },
}));

export const PollingStatusButton = ({
  pendingOperation,
}: {
  pendingOperation?: AceOpportunityEventOperation;
}) => {
  const classes = useStyles();
  const pollingStatusText = useMemo(() => {
    // TODO: Display for Accept/Reject invitation
    switch (pendingOperation) {
      case AceOpportunityEventOperationEnum.UPDATE_OPPORTUNITY:
        return 'Submitting...';
      case AceOpportunityEventOperationEnum.START_ENGAGEMENT:
        return 'Submitting...';
      case AceOpportunityEventOperationEnum.LAUNCH_OPPORTUNITY:
        return 'Launching...';
      case AceOpportunityEventOperationEnum.CLOSE_LOST_OPPORTUNITY:
        return 'Closing lost...';
      default:
        return 'Submitting...';
    }
  }, [pendingOperation]);

  return (
    <Button
      variant="contained"
      disabled
      className={classNames(
        classes.buttonContainer,
        pendingOperation ===
          AceOpportunityEventOperationEnum.CLOSE_LOST_OPPORTUNITY
          ? classes.destructiveDisabledButton
          : classes.primaryDisabledButton,
      )}
    >
      <div className={classes.labelContainer}>
        <AnimationSpinner height={16} width={16} />
        <span className={classes.textContent}>{pollingStatusText}</span>
      </div>
    </Button>
  );
};
