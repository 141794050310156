import { PrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { History } from 'history';
import { Cloud } from 'utils/cloudTypes';

export const toPrivateOfferAfterSubmit =
  (history: History, cloud: Cloud) => (offer: PrivateOffer) => {
    history.push({
      pathname: `/private-offers/${cloud}/${offer.poId}`,
    });
  };

export const toPrivateOfferOnCancel =
  (history: History) => (offer: PrivateOffer | null) => {
    history.push({
      pathname: offer
        ? `/private-offers/${offer.marketplace}/${offer.poId}`
        : '/private-offers',
    });
  };

export const toPrivateOfferOnOpenOffer = (offer: PrivateOffer) => {
  window.open(`/private-offers/aws/${offer.poId}`);
};
