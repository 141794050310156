import {
  Activity,
  ActivitySlug,
  PrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { Optional } from 'utils/optional/optional';
import { getUtcDateTimeFromSerializedIsoDateTime } from 'pages/PrivateOffers/pages/Next/generic/utils/date/dateUtils';

export const getLastOfferProgressActivitySlug = (
  offer: PrivateOffer | null,
): ActivitySlug => {
  const { offerId, activities = [] } = offer || {};

  if (activities.length === 0) {
    return ActivitySlug.VendorCreatedOffer;
  }

  const offerActivitySlugs = new Set(activities.map((a) => a.slug));

  const activitySlugOrder = [
    ActivitySlug.VendorCancelledMarketplaceOfferSuccess,
    ActivitySlug.BuyerAcceptedOffer,
    ActivitySlug.BuyerViewedOffer,
    ActivitySlug.BuyerOpenedPurchaseInstructions,
    ActivitySlug.VendorSentPurchaseInstructions,
    ActivitySlug.VendorCreatedMarketplaceOfferSuccess,
    !offerId && ActivitySlug.VendorCreatedOfferInSalesforce,
    !offerId && ActivitySlug.VendorCreatedOffer,
    !offerId && ActivitySlug.VendorDraftedOffer,
    ActivitySlug.VendorCreatedOffer,
  ].filter((v) => !!v) as ActivitySlug[];

  return (
    activitySlugOrder.find((s) => offerActivitySlugs.has(s)) ||
    ActivitySlug.VendorDraftedOffer
  );
};

export const byCreatedAtDescending = (a: Activity, b: Activity): number => {
  const createdAtA = getUtcDateTimeFromSerializedIsoDateTime(a.createdAt);
  const createdAtB = getUtcDateTimeFromSerializedIsoDateTime(b.createdAt);

  return createdAtA < createdAtB ? 1 : -1;
};

const byCreatedAtAscending = (a: Activity, b: Activity): number => {
  const createdAtA = getUtcDateTimeFromSerializedIsoDateTime(a.createdAt);
  const createdAtB = getUtcDateTimeFromSerializedIsoDateTime(b.createdAt);

  return createdAtA < createdAtB ? -1 : 1;
};

export const getLatestOfferActivitySlug = (
  offer: PrivateOffer | null,
): ActivitySlug => {
  const { activities = [] } = offer || {};
  const sortedActivities = [...activities].sort(byCreatedAtAscending);

  return (
    sortedActivities.find(
      (a) => a.slug === ActivitySlug.VendorCancelledMarketplaceOfferSuccess,
    )?.slug ||
    sortedActivities.at(activities.length - 1)?.slug ||
    ActivitySlug.VendorDraftedOffer
  );
};

export const getMarketplaceErrors = (activities: Activity[]): string[] =>
  Optional.ofNullable(activities)
    .filter((a) => a.length > 0)
    .map((a) => a.at(a.length - 1))
    .filter((a) => a.metadata?.errors?.length > 0)
    .map((a) => a.metadata?.errors?.map((e: { message: string }) => e.message))
    .orElse([]);
