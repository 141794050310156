import { FormSections, formSectionToTitle } from '../formSections';
import { CalendarClock } from 'mdi-material-ui';
import React from 'react';
import { Banner, Card } from '@tackle-io/platform-ui';
import { FieldKey } from './formSchema';
import { FastField, useFormikContext } from 'formik';
import DateSelector from '../../../../generic/DateSelector/DateSelector';
import { DataId } from '../../../../generic/analytics';
import { getFormattedError } from '../../../../generic/utils/field/fieldUtils';
import { startOfTodayAsUtcDatetime } from '../../../../generic/utils/date/dateUtils';
import { Box } from 'vendor/material';
import { FormValues } from '../formSchema';
import { OfferType } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';

const fieldKeyToLabel: { [fk: string]: string } = {
  [FieldKey.OfferAcceptanceDeadline]: 'Offer acceptance deadline',
};

const OfferValidityFormSection = () => {
  const {
    values: { offerType },
  } = useFormikContext<FormValues>();

  const bannerTitle =
    offerType === OfferType.PartnerResale
      ? 'For partner offers, the offer expiration date must be on or before the first payment date when using a payment schedule.'
      : 'The offer acceptance deadline must be before the second payment date when using a payment schedule.';

  return (
    <div id={FormSections.OfferValidityPeriod}>
      <Card
        title={formSectionToTitle[FormSections.OfferValidityPeriod]}
        subtitle="Set an acceptance deadline for your offer."
        icon={<CalendarClock />}
      >
        <FastField name={FieldKey.OfferAcceptanceDeadline}>
          {({ field, meta }) => (
            <Box>
              <Box mb={2}>
                <Banner borderPosition="top" title={bannerTitle} />
              </Box>
              <Box>
                <DateSelector
                  {...field}
                  label={`${
                    fieldKeyToLabel[FieldKey.OfferAcceptanceDeadline]
                  } *`}
                  dataId={DataId.OfferAcceptanceDeadlineField}
                  minDate={startOfTodayAsUtcDatetime().toISO()}
                  error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                />
              </Box>
            </Box>
          )}
        </FastField>
      </Card>
    </div>
  );
};

export default OfferValidityFormSection;
