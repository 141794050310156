import React from 'react';
import { Box, Grid, Typography, GridSize } from 'vendor/material';
import useStyles from './InfoItem.styles';
import { isNull, isUndefined } from 'lodash';

export const HALF_GRID_SIZE = 6;
export const FULL_GRID_SIZE = 12;

const InfoItem: React.FC<{
  className?: string;
  itemTitle: string;
  value: any;
  gridSize?: GridSize;
  flipContent?: boolean;
  dataId?: string;
}> = ({
  className,
  itemTitle,
  value,
  gridSize = HALF_GRID_SIZE,
  flipContent,
  dataId,
}) => {
  const classes = useStyles();

  function getDisplayValue(value: any) {
    if (isNull(value) || isUndefined(value) || value === '') {
      return '-';
    }
    return value;
  }

  return (
    <Grid
      className={className}
      item
      xs={12}
      md={gridSize}
      style={{ padding: '16px 0 0 0' }}
    >
      {flipContent ? (
        <Box mb={2}>
          <Typography component="p" className={classes.infoValue}>
            {getDisplayValue(value)}
          </Typography>
          <Typography
            component="h5"
            className={classes.infoTitle}
            data-id={dataId}
          >
            {itemTitle}
          </Typography>
        </Box>
      ) : (
        <Box mb={2}>
          <Typography
            component="h5"
            className={classes.infoTitle}
            data-id={dataId}
          >
            {itemTitle}
          </Typography>

          <Typography component="p" className={classes.infoValue}>
            {getDisplayValue(value)}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default InfoItem;
