import React from 'react';
import classNames from 'classnames';
import { Check } from 'mdi-material-ui';
import useStyles from './OfferProgressStep.styles';

interface OfferProgressStepProps {
  dataId?: string;
  step: number;
  title: string;
  priorStepCompleted: boolean;
  stepCompleted: boolean;
  nextStepCompleted: boolean;
  offerIsCancelled: boolean;
  lastStep?: boolean;
}

const OfferProgressStep: React.FC<OfferProgressStepProps> = ({
  dataId,
  step,
  title,
  priorStepCompleted,
  stepCompleted,
  nextStepCompleted,
  offerIsCancelled,
  lastStep = false,
}) => {
  const classes = useStyles();
  const firstStep = step === 0;
  const active = priorStepCompleted && !stepCompleted && !nextStepCompleted;
  const finished = stepCompleted && lastStep;
  const canceledCompleted = offerIsCancelled && stepCompleted;
  const canceledActive = offerIsCancelled && active;

  const indicatorLineClassName = classNames(classes.indicatorLine, {
    [classes.indicatorLineFirst]: firstStep,
    [classes.indicatorLineLast]: lastStep,
    [classes.indicatorLineCompletedBlue]: stepCompleted,
    [classes.indicatorLineSelectedBlue]: active,
    [classes.indicatorLineAllCompleted]: finished,
    [classes.indicatorLineCompletedCancelled]: canceledCompleted,
    [classes.indicatorLineActiveCancelled]: canceledActive,
  });

  const iconCircleClassName = classNames(classes.iconCircle, {
    [classes.iconCircleSelected]: active,
    [classes.iconCircleSelectedBlue]: active,
    [classes.iconCircleCompletedBlue]: stepCompleted,
    [classes.iconCircleAllStepsCompleted]: finished,
    [classes.iconCircleCompletedCancelled]: canceledCompleted,
    [classes.iconCircleActiveCancelled]: canceledActive,
  });

  const icon = stepCompleted ? (
    <Check htmlColor="#fff" style={{ fontSize: 18 }} />
  ) : active ? null : (
    step + 1
  );

  return (
    <div className={classes.stepIndicator} data-id={dataId}>
      <div className={indicatorLineClassName}>
        <span className={classes.indicatorPoint}>
          <span className={iconCircleClassName}>{icon}</span>
        </span>
      </div>
      <div className={classes.indicatorItem}>
        {active && offerIsCancelled ? 'Canceled' : title}
      </div>
    </div>
  );
};

export default OfferProgressStep;
