import { decorate, observable, action, computed } from 'mobx';
import { assign, isEmpty } from 'lodash';

import api from '../utils/api';

// TODO review usage when implementing Vendor pages
class Vendor {
  id = null;

  name = null;

  metadata = [];

  applications = [];

  configuration = {};

  feature_flags = {};

  tkl_customer_data = {};

  onboarding = {};

  loading = false;

  error = null;

  constructor(props) {
    props.id = props.vendorid;
    props.metadata = props.metadata_fields;
    delete props.vendorid;
    delete props.metadata_fields;

    assign(this, props);
  }

  setLoading = (loading) => {
    this.loading = loading;
  };

  setError = (error) => {
    this.error = error;
  };

  sync = (props) => {
    props.id = props.vendorid;
    props.metadata = props.metadata_fields;
    delete props.vendorid;
    delete props.metadata_fields;

    assign(this, props);
  };

  update = (json) => {
    this.setLoading(true);

    return new Promise((resolve, reject) => {
      api
        .put(`vendor/${this.id}`, {
          json,
        })
        .json()
        .then(({ vendor }) => {
          this.sync(vendor);
          this.setLoading(false);
          resolve(vendor);
        })
        .catch((error) => {
          this.setError(error);
          this.setLoading(false);
          reject(error);
        });
    });
  };

  getUsers() {
    return api.get('vendor/users').json();
  }

  inviteUsers(emails) {
    // TODO catch & handle error
    return api
      .post('vendor/users', {
        json: { emails },
      })
      .json();
  }

  manualMeter(json) {
    return api
      .post('vendor/manual-meter', {
        json,
      })
      .json();
  }

  get hasSnsConfigured() {
    if (isEmpty(this.configuration)) return false;
    if (isEmpty(this.configuration.vendor_events)) return false;

    const { url = '' } = this.configuration.vendor_events;
    if (isEmpty(url)) return false;

    return true;
  }

  get hasAwsConfigured() {
    if (isEmpty(this.configuration)) return false;
    if (isEmpty(this.configuration.aws)) return false;

    const { role_arn = '' } = this.configuration.aws.assume_role;
    if (isEmpty(role_arn)) return false;

    return true;
  }
}

decorate(Vendor, {
  id: observable,
  name: observable,
  metadata: observable,
  applications: observable,
  configuration: observable,
  feature_flags: observable,
  tkl_customer_data: observable,
  onboarding: observable,
  loading: observable,
  error: observable,
  setLoading: observable,
  setError: observable,
  sync: action,
  update: action,
  getUsers: action,
  inviteUsers: action,
  manualMeter: action,
  hasSnsConfigured: computed,
  hasAwsConfigured: computed,
});

export default Vendor;
