import { UnifiedOpportunityFormShell } from '../UnifiedOpportunityFormShell';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import AceOpportunityFormFields from './AceOpportunityFormFields';
import { convertAceFormValuesToCreateRequest } from 'packages/cosell/src/utilities/typeConverters/convertAceFormValuesToCreateRequest';
import { aceOpportunityFormValidationSchema } from './aceOpportunityFormValidationSchema';
import { CreateAceOpportunityFormValues } from './AceOpportunityFormValues';
import { TackleOperationId } from 'packages/cosell/api/utils';
// import { getMockInitialValues } from 'packages/cosell/api/mockHelpers';
import { Loader } from '@tackle-io/platform-ui';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import {
  COSELL_CREATE_DEFAULT_VALUES,
  COSELL_PATH,
} from 'packages/cosell/src/utilities/constants/CoSellConstants';
import { useAceVendorConfig } from 'packages/cosell/api/hooks/useAceVendorConfig';
import { useAcePickListQuery } from 'packages/cosell/api/hooks/useAcePickList';
import { CreationPending } from './CreationPending';
import { useState } from 'react';
import { AceCoSellErrorMessagesBanner } from 'packages/cosell/src/pages/AceCoSellErrorMessagesBanner';
import invariant from 'tiny-invariant';
import { useHistory } from 'react-router-dom';
import { useCoSellContext } from 'packages/cosell/src/CoSellContextProvider';

interface CreateAceOpportunityFormProps {
  /** banners to be passed through to top of form */
  banners?: React.ReactNode;
  /** salesforce opportunity id
   * only added if creating an opportunity from an existing salesforce opportunity
   * TODO: update type so crmId is required if renderEnv is 'sf_canvas'
   */
  crmId?: string;
  onCancel: () => void;
  onCreated?: (opportunityId: string) => void;
  initialValues?: Partial<CreateAceOpportunityFormValues>;
}

/**
 *
 * TODO:
 * - if creation failed, the tackle-id is still created and should be "updated" (edit) with instead of creating a new one.
 *   The edit form can then be prefilled with the original payload values
 */
const CreateAceOpportunityForm = ({
  banners: externalBanners,
  crmId,
  onCancel,
  onCreated,
  initialValues,
}: CreateAceOpportunityFormProps) => {
  const history = useHistory();
  const [creationPendingOpportunity, setCreationPendingOpportunity] = useState<{
    tackleId: string | null;
    hasAceError: boolean;
  }>({
    tackleId: null,
    hasAceError: false,
  });

  const acePickListQuery = useAcePickListQuery();
  const { isError: isVendorConfigError, isLoading: isVendorConfigLoading } =
    useAceVendorConfig();
  const { createAceOpportunity } = useAceOpportunity({});

  // const mockInitialValues = getMockInitialValues();

  const { renderEnv } = useCoSellContext();

  const handleSubmitToCloud = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    const createAndSubmitReqBody = convertAceFormValuesToCreateRequest(values);
    if (crmId) {
      createAndSubmitReqBody['crmId'] = crmId;
    }

    const response = await createAceOpportunity.mutateAsync({
      requestBody: createAndSubmitReqBody,
      tackleOperationId: TackleOperationId.CREATE_OPPORTUNITY,
    });

    setCreationPendingOpportunity({
      tackleId: response.id,
      hasAceError: false,
    });
    return response;
  };

  const handleSaveDraft = async (values: CreateAceOpportunityFormValues) => {
    const requestBody = convertAceFormValuesToCreateRequest(values);

    if (crmId) {
      requestBody['crmId'] = crmId;
    }

    const response = await createAceOpportunity.mutateAsync({
      requestBody,
      tackleOperationId: TackleOperationId.CREATE_DRAFT_OPPORTUNITY,
    });
    setCreationPendingOpportunity({
      tackleId: response.id,
      hasAceError: false,
    });
    return response;
  };

  const handleCreationError = ({
    aceError,
    error,
  }: {
    aceError: boolean;
    error: unknown;
  }) => {
    if (aceError) {
      setCreationPendingOpportunity((prev) => {
        return {
          ...prev,
          hasAceError: true,
        };
      });

      if (renderEnv === 'sf_canvas') {
        history.push(
          `/cosell/opportunity/aws/${creationPendingOpportunity.tackleId}/edit`,
        );
      } else {
        history.push(
          `${COSELL_PATH}/opportunity/aws/${creationPendingOpportunity.tackleId}/edit`,
        );
      }
    }

    // TODO: remove when complete with SF integration
    console.error(JSON.stringify(error));
  };

  if (isVendorConfigLoading || acePickListQuery.isLoading) {
    return <Loader />;
  }

  if (isVendorConfigError || acePickListQuery.isError) {
    return <div>Required data failed to load, please refresh</div>;
  }

  if (
    creationPendingOpportunity.tackleId &&
    creationPendingOpportunity.hasAceError === false
  ) {
    invariant(
      typeof onCreated === 'function',
      'onCreated callback is required in CreateAceOpportunityForm',
    );

    return (
      <CreationPending
        onError={handleCreationError}
        onSuccess={onCreated}
        pendingAceTackleId={creationPendingOpportunity.tackleId}
      />
    );
  }

  const banners = (
    <>
      {externalBanners}
      {creationPendingOpportunity.tackleId &&
      creationPendingOpportunity.hasAceError ? (
        <AceCoSellErrorMessagesBanner
          opportunityId={creationPendingOpportunity.tackleId}
          title="Co-sell creation failed"
        />
      ) : null}
    </>
  );

  return (
    <>
      <UnifiedOpportunityFormShell
        banners={banners}
        onCancel={onCancel}
        onSubmit={handleSubmitToCloud}
        onSaveAsDraft={handleSaveDraft}
        initialValues={{
          // ...mockInitialValues,
          ...COSELL_CREATE_DEFAULT_VALUES,
          ...(initialValues ?? null),
        }}
        provider={DisplayCloudType.AWS}
        validationSchema={aceOpportunityFormValidationSchema}
      >
        <AceOpportunityFormFields reviewStatus={null} />
      </UnifiedOpportunityFormShell>
    </>
  );
};

export default CreateAceOpportunityForm;
