import { useAuthService } from '@tackle-io/tackle-auth-tools';
import { useEffect, useMemo, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAuth } from 'vendor/auth0';
import { Banner, Link, Loader } from '@tackle-io/platform-ui';
import { Typography } from 'vendor/material';
import { Page } from 'components';
import TackleLogo from 'images/tackle-logo';
import { translateErrorToHumanReadable } from 'hooks/useErrorNotification';
import Email from 'utils/constants';

import { useStyle } from './index.styles';
import { useReturnTo } from 'boot/hooks/useReturnTo';

type Error = {
  error: string;
  error_description: string;
};

const AuthCallback = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth();
  const authService = useAuthService();
  const { search } = useLocation();
  const [returnTo] = useReturnTo();

  const classes = useStyle();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const [registering, setRegistering] = useState(true);
  const [error, setError] = useState<Error>({
    error: queryParams.get('error'),
    error_description: queryParams.get('error_description'),
  });

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then((token) => {
          return authService.register(token);
        })
        .then(([_, shouldRefresh]) => {
          return shouldRefresh
            ? getAccessTokenSilently({ ignoreCache: true })
            : null;
        })
        .then(() => {
          setRegistering(false);
        })
        .catch((error) => {
          console.error('getAccessTokenSilently resulted in an error: ', error);
          setError({
            error: 'registration_error',
            error_description:
              'User registration failed. Please return to login.',
          });
        });
    }
  }, [isAuthenticated, getAccessTokenSilently, authService]);

  if (error.error) {
    return (
      <Page size="sm" className={classes.page}>
        <TackleLogo className={classes.logo} />
        <Banner
          type="danger"
          borderPosition="top"
          title="Login Error"
          body={
            <>
              <Typography>
                {translateErrorToHumanReadable(
                  error.error,
                  error.error_description,
                )}
              </Typography>
              <Link to="/" style={{ float: 'right' }}>
                Return to login
              </Link>
            </>
          }
        />
        <Typography
          component="h2"
          variant="body2"
          className={classes.subheader}
        >
          If this problem persists, please{' '}
          <Link href={`mailto:${Email.Support}`}>contact support</Link>.
        </Typography>
      </Page>
    );
  }

  if (!registering) {
    return <Redirect exact to={returnTo || '/'} />;
  }

  // no error occurred and user is still registering == loading state.
  return <Loader />;
};

export default AuthCallback;
