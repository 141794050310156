import {
  AceOpportunityEventOperationEnum,
  AceOpportunityEventResponse,
} from 'packages/cosell/src/types/responses/AceOpportunityEventResponse';

const isCloudUpdateEventComplete = ({
  event,
  cloudUpdateEvent,
}: {
  event: AceOpportunityEventSubset;
  cloudUpdateEvent: AceOpportunityEventSubset;
}) => {
  const cloudUpdateEventReviewStatus =
    cloudUpdateEvent.cloudRaw?.['LifeCycle']?.['ReviewStatus'];
  const cloudUpdateEventStage =
    cloudUpdateEvent.cloudRaw?.['LifeCycle']?.['Stage'];

  switch (event.operation) {
    case AceOpportunityEventOperationEnum.UPDATE_OPPORTUNITY:
      // When polling for Approved stage for Update opportunity, we need to wait for Approved status
      // Or successfully updated a launched opportunity
      const isPendingApprovedStatus = event?.cloudRequests?.some(
        (cloudRequest) =>
          cloudRequest?.request?.['LifeCycle']?.['ReviewStatus'] === 'Approved',
      );
      if (
        isPendingApprovedStatus &&
        cloudUpdateEventReviewStatus === 'Approved'
      ) {
        return true;
      } else if (isPendingApprovedStatus) {
        return false;
      }

      if (cloudUpdateEventReviewStatus === 'Pending Submission') {
        // Successfully updated draft opportunity
        return true;
      } else if (cloudUpdateEventReviewStatus === 'Submitted') {
        // Successfully submitted draft opportunity to cloud
        return true;
      } else if (cloudUpdateEventReviewStatus === 'Approved') {
        // Successfully update approved opportunity
        return true;
      }
      return false;
    case AceOpportunityEventOperationEnum.START_ENGAGEMENT:
      if (cloudUpdateEventReviewStatus === 'Submitted') {
        // Successfully submitted draft opportunity to cloud
        return true;
      }
      return false;
    case AceOpportunityEventOperationEnum.LAUNCH_OPPORTUNITY:
      // Successfully launched opportunity
      return cloudUpdateEventStage === 'Launched';
    case AceOpportunityEventOperationEnum.CLOSE_LOST_OPPORTUNITY:
      // Successfully closed lost opportunity
      return cloudUpdateEventStage === 'Closed Lost';
    default:
      return false;
  }
};

const isNextEventFailed = (
  event: AceOpportunityEventSubset,
  nextEvents: AceOpportunityEventSubset[],
) =>
  event.cloudResponses?.some((response) => response.success === false) &&
  nextEvents?.some(
    (event) =>
      event.operation === AceOpportunityEventOperationEnum.CLOUD_UPDATE_EVENT,
  );

const isNextEventSuccess = ({
  event,
  nextEvents,
}: {
  event: AceOpportunityEventSubset;
  nextEvents: AceOpportunityEventSubset[];
}) =>
  event.cloudResponses?.some((response) => response.success === true) &&
  nextEvents?.some(
    (nextEvent) =>
      nextEvent.operation ===
        AceOpportunityEventOperationEnum.CLOUD_UPDATE_EVENT &&
      isCloudUpdateEventComplete({
        event,
        cloudUpdateEvent: nextEvent,
      }),
  );

const isOperationCompleted = ({
  event,
  nextEvents,
}: {
  event: AceOpportunityEventSubset;
  nextEvents: AceOpportunityEventSubset[];
}) => {
  switch (event?.operation) {
    case AceOpportunityEventOperationEnum.CREATE_OPPORTUNITY:
      return 'cloudResponses' in event;
    case AceOpportunityEventOperationEnum.UPDATE_OPPORTUNITY:
      if (
        isNextEventFailed(event, nextEvents) ||
        isNextEventSuccess({ event, nextEvents })
      ) {
        return true;
      }
      return false;
    case AceOpportunityEventOperationEnum.START_ENGAGEMENT:
      if (
        isNextEventFailed(event, nextEvents) ||
        isNextEventSuccess({ event, nextEvents })
      ) {
        return true;
      }
      return false;
    case AceOpportunityEventOperationEnum.LAUNCH_OPPORTUNITY:
      if (
        isNextEventFailed(event, nextEvents) ||
        isNextEventSuccess({ event, nextEvents })
      ) {
        return true;
      }
      return false;
    case AceOpportunityEventOperationEnum.CLOSE_LOST_OPPORTUNITY:
      if (
        isNextEventFailed(event, nextEvents) ||
        isNextEventSuccess({ event, nextEvents })
      ) {
        return true;
      }
      return false;
    default:
      return true;
  }
};

export type AceOpportunityEventSubset = Pick<
  AceOpportunityEventResponse,
  'operation' | 'cloudResponses' | 'cloudRaw' | 'cloudRequests'
>;

/**
 * Check if the opportunity has any async actions pending,
 * events that have been kicked off, but the backend does not monitor.
 *
 */
export function getPendingOperation(events: AceOpportunityEventSubset[]) {
  const pendingEvent = events?.find(
    (event, index) =>
      !isOperationCompleted({
        event,
        nextEvents: index !== 0 ? events.slice(0, index) : [],
      }),
  );
  return pendingEvent?.operation;
}
