import { useQuery } from '@tanstack/react-query';
import { listMappings } from '../requests/listMappings';
import { Cloud, Domain, Operation } from '../../utils/constants';

export const LIST_MAPPINGS_QUERY_LIMIT = 25;

/**
 * Query for getting a list of mappings
 */
export function useListMappingsQuery({
  cloud,
  domain,
  operation,
  isPublished,
  isDefault,
  limit = LIST_MAPPINGS_QUERY_LIMIT,
  next = '',
  enabled = true,
}: {
  cloud: Cloud;
  domain: Domain;
  operation: Operation;
  isPublished?: boolean;
  isDefault?: boolean;
  limit?: number;
  next?: string;
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: [
      'list-mappings',
      cloud,
      domain,
      operation,
      isPublished,
      isDefault,
      limit,
      next,
    ],
    queryFn: () =>
      listMappings({
        cloud,
        domain,
        operation,
        isPublished,
        isDefault,
        limit,
        next,
      }),
    select: (data) => ({
      mappings: data?.mappings,
    }),
    staleTime: Infinity,
    enabled,
  });
}
