import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  activeFiltersContainer: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  filterTag: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.25),
    cursor: 'pointer',
    fontSize: theme.spacing(1.5),
  },
}));

export default useStyles;
