import React from 'react';
import { Grid, GridSize } from 'vendor/material';
import { fontWeightMediumLarge } from 'utils/fontWeightConstants';

interface SalesforceCoSellWidgetDetailTableItemProps {
  title: string;
  width?: GridSize;
  children?: React.ReactNode;
}

export const SalesforceCoSellWidgetDetailTableItem = (
  props: SalesforceCoSellWidgetDetailTableItemProps,
) => {
  const { title, width = 6, children } = props;

  return (
    <Grid item xs={width}>
      <span style={{ fontWeight: fontWeightMediumLarge }}>{title}</span> <br />
      {children || '-'}
    </Grid>
  );
};
