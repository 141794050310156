import { Banner } from '@tackle-io/platform-ui';
import { Domain } from 'pages/Settings/Account/components/FieldMapper/utils/constants';

const contentMap = {
  [Domain.CO_SELL]: {
    message:
      'Field mapping to your Salesforce fields has not been configured. Contact your admin to request field mapping to pre-fill your co-sells and save a ton of time.',
    title: 'Co-sell fields could not be populated from the opportunity.',
  },
  [Domain.OFFER]: {
    message:
      'Field mapping to your Salesforce fields has not been configured. Contact your admin to request field mapping to pre-fill your offers and save a ton of time.',
    title: 'Offer fields could not be populated from the opportunity.',
  },
} as const;

/** inform user their Salesforce field mapping has not been configured  */
export const DataMapperNotConfiguredBanner = ({
  domain,
}: {
  domain: keyof typeof contentMap;
}) => {
  return (
    <Banner
      borderPosition="top"
      body={contentMap[domain].message}
      isCollapsible
      title={contentMap[domain].title}
      type="warning"
    />
  );
};
