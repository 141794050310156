import { FieldKey, FormValues } from './formSchema';
import {
  PrivateOffer,
  ResellerAgreementType,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';

export const initialFormValuesForNewOffer: FormValues = {
  [FieldKey.ResellerAgreementType]: ResellerAgreementType.NoneSelected,
  [FieldKey.ResellerAgreementDocumentUrns]: [],
};

export const initialFormValuesForExistingOffer = (
  offer: PrivateOffer,
): FormValues => {
  const { partnerOffer } = offer;
  const resellerAgreement = partnerOffer?.resellerAgreement;
  const resellerAgreementDocumentUrns = resellerAgreement?.documentUrns || [];

  const resellerAgreementType =
    resellerAgreement?.type || ResellerAgreementType.NoneSelected;

  return {
    ...initialFormValuesForNewOffer,
    [FieldKey.ResellerAgreementType]: resellerAgreementType,
    [FieldKey.ResellerAgreementDocumentUrns]: resellerAgreementDocumentUrns,
  };
};

export const initialFormValuesForCloneOffer = (
  offer: PrivateOffer,
): FormValues => initialFormValuesForExistingOffer(offer);

export const initialFormValuesForAmendOffer = (
  offer: PrivateOffer,
): FormValues => initialFormValuesForExistingOffer(offer);
