import { ActivitySlug } from '../api/types/PrivateOffer';

export const ACTIVITY_SLUG_TO_ACTION_DESCRIPTION = {
  [ActivitySlug.VendorCreatedOfferInSalesforce]: 'Created offer in Salesforce',
  [ActivitySlug.VendorCreatedOffer]: 'Created draft offer',
  [ActivitySlug.VendorClonedOffer]: 'Cloned offer',
  [ActivitySlug.VendorSentPurchaseInstructions]: 'Sent purchase instructions',
  [ActivitySlug.VendorReSentPurchaseInstructions]:
    'Resent purchase instructions',
  [ActivitySlug.BuyerOpenedPurchaseInstructions]: 'Opened instructions',
  [ActivitySlug.BuyerViewedOffer]: 'Viewed offer',
  [ActivitySlug.BuyerAcceptedOffer]: 'Accepted offer',
  [ActivitySlug.VendorCreatedMarketplaceOfferFailed]:
    'Marketplace offer failed to create',
  [ActivitySlug.VendorCreatedMarketplaceOfferPending]:
    'Marketplace offer pending',
  [ActivitySlug.VendorCreatedMarketplaceOfferSuccess]:
    'Offer created in marketplace',
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationPending]:
    'Offer expiration update pending',
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationSuccess]:
    'Offer expiration updated',
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationFailed]:
    'Offer expiration failed to update',
  [ActivitySlug.VendorCancelledMarketplaceOfferPending]:
    'Offer cancellation pending',
  [ActivitySlug.VendorCancelledMarketplaceOfferSuccess]: 'Offer cancelled',
  [ActivitySlug.VendorCancelledMarketplaceOfferFailed]:
    'Offer cancellation failed',
} as Record<ActivitySlug, string>;
