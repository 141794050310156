import React, { useContext } from 'react';
import { ClipboardText } from 'mdi-material-ui';
import { Grid } from 'vendor/material';
import { Card } from '@tackle-io/platform-ui';
import LabelAndValue from './LabelAndValue';
import OfferSection from 'pages/PrivateOffers/pages/Next/aws/view/OfferView/OfferSection';
import SectionDivider from './SectionDivider';
import { toFinite } from 'lodash';
import {
  additionalFieldKeysToOmit,
  registrationFieldKeysToOmit,
} from 'pages/PrivateOffers/pages/Next/generic/utils/offer/additionalFieldUtils';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';

const AdditionalDetailsSection: React.FunctionComponent = () => {
  const { offer } = useContext(OfferContext);
  const { preRegistrationDetails, pricing, offerMetadata } = offer;

  const hasRegistrationDetailsToDisplay =
    Object.keys(preRegistrationDetails).filter(
      (k) => !registrationFieldKeysToOmit.has(k),
    ).length > 0;

  return (
    <Card
      title="Additional details"
      icon={<ClipboardText />}
      bodyStyle={{ backgroundColor: '#FAFBFC' }}
    >
      {hasRegistrationDetailsToDisplay && (
        <>
          <OfferSection title="Registration details">
            <Grid container spacing={2}>
              {Object.entries(preRegistrationDetails)
                .filter(([key]) => !registrationFieldKeysToOmit.has(key))
                .map(([key, value]) => (
                  <Grid item md={4} sm={6} xs={12} key={key}>
                    <LabelAndValue label={key} value={value} />
                  </Grid>
                ))}
            </Grid>
          </OfferSection>
          <SectionDivider />
        </>
      )}
      <OfferSection title="Additional fields">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <LabelAndValue
              label="Marketplace fee"
              value={`${toFinite(pricing.marketplaceFee).toFixed(2)}%`}
            />
          </Grid>
          {Object.entries(offerMetadata)
            .filter(([key]) => !additionalFieldKeysToOmit.has(key))
            .map(([key, value]) => (
              <Grid item md={4} sm={6} xs={12} key={key}>
                <LabelAndValue label={key} value={value} />
              </Grid>
            ))}
        </Grid>
      </OfferSection>
    </Card>
  );
};

export default AdditionalDetailsSection;
