import { decorate, observable, action, computed } from 'mobx';
import { DateTime } from 'luxon';
import fp from 'lodash/fp';
import { filter, find } from 'lodash-es';

import Store from './Store';
import api from 'utils/api';
import { AWSOrder, GCPOrder, AZUREOrder, RedhatOrder } from '../models';

interface OrdersByCloud {
  aws: AWSOrder[];
  gcp: GCPOrder[];
  azure: AZUREOrder[];
  redhat: RedhatOrder[];
}

type AnyOrder = AWSOrder | GCPOrder | AZUREOrder | RedhatOrder;

/**
 * TODO: CAS has been deprecated as of Aug 2024. This store will need to be updated.
 * Order possibilities:
 * 1. CAS only - listing has not been configured in Tackle or is an AMI
 * 2. CAS w/ Tackle listing data - listing has been enabled, but not reg.
 * 3. CAS w/ Tackle order data - Tackle order registration enabled for listing
 * 4. Tackle only - CAS not enabled for vendor
 */
class OrdersStore extends Store {
  get orders(): AnyOrder[] {
    return super.data;
  }

  set orders(orders) {
    super.data = orders;
  }

  load = () => {
    super.loading = true;

    api
      .get('orders')
      .json()
      .then(
        ({ aws = [], azure = [], gcp = [], redhat = [] }: OrdersByCloud) => {
          aws = aws.map((o) => new AWSOrder(o));
          azure = azure.map((o) => new AZUREOrder(o));
          gcp = gcp.map((o) => new GCPOrder(o));
          redhat = redhat.map((o) => new RedhatOrder(o));
          this.orders = [...aws, ...azure, ...gcp, ...redhat];
          this.loading = false;
        },
      )
      .catch((error) => {
        super.error = error;
        super.loading = false;
      });
  };

  getOrder = (productid, id) =>
    find(this.orders, { productid, customerid: id });

  getActive = () => {
    const now = DateTime.utc();
    return this.orders.filter((o) => o.expiration > now);
  };

  getInactive = () => {
    const now = DateTime.utc();
    return this.orders.filter(
      (o) => o.expiration.invalid || o.expiration <= now,
    );
  };

  findByProduct(pid) {
    return filter(this.orders, { productid: pid });
  }

  find(productid, customerid): AnyOrder {
    return fp.flow(
      fp.filter({ productid }),
      fp.find<any>({ customerid }),
    )(this.orders);
  }
}

decorate(OrdersStore, {
  loading: observable,
  orders: computed,
  load: action,
  findByProduct: action,
  find: action,
});

export default OrdersStore;

const ordersStore = new OrdersStore();
export { ordersStore };
