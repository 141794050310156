import React, { ReactNode, createContext, useContext } from 'react';
import { gql } from 'graphql-tag';
import {
  ProspectScoreCrmAccountQuery,
  useProspectScoreCrmAccountQuery,
} from 'generated/graphql';

export const GET_CRM_ACCOUNT = gql`
  query ProspectScoreCrmAccount($vendorId: String!, $crmId: String!) {
    prospectScoreCrmAccount(vendor_id: $vendorId, crm_id: $crmId) {
      prospect_id
      domain
      score_aws
      score_azure
      score_gcp
      visibility
      user_entered_domain
      company_name
      region
      revenue_band
      primary_industry
    }
  }
`;

export const SUBMIT_SCORE_OR_MAKE_VISIBLE = gql`
  mutation ProspectSubmitScoreOrMakeVisible(
    $vendorId: String!
    $crmId: String!
    $domain: String
  ) {
    prospectSubmitScoreOrMakeVisible(
      vendorId: $vendorId
      crmId: $crmId
      domain: $domain
    ) {
      prospect_id
      batch_id
      extra
      extra_columns_order
      domain
      score_aws
      score_azure
      score_gcp
      visibility
      crm_id
      user_entered_domain
      company_name
      region
      revenue_band
      primary_industry
    }
  }
`;

type ProspectCrmAccountDataContextValue = {
  loading: boolean;
  error?: Error;
  vendorId: string;
  crmId: string;
  website: string;
  token: string;
  crmAccountScore?: ProspectScoreCrmAccountQuery['prospectScoreCrmAccount'];
};

const ProspectCrmAccountDataContext = createContext<
  ProspectCrmAccountDataContextValue | undefined
>(undefined);

type ProspectCrmAccountDataProviderProps = {
  children: ReactNode;
  vendorId: string;
  crmId: string;
  website: string;
  token: string;
};

export const ProspectAccountDataProvider: React.FC<
  ProspectCrmAccountDataProviderProps
> = ({ children, vendorId, crmId, token, website }) => {
  const { loading, error, data } = useProspectScoreCrmAccountQuery({
    variables: { vendorId, crmId },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });
  return (
    <ProspectCrmAccountDataContext.Provider
      value={{
        loading,
        error,
        crmAccountScore: data?.prospectScoreCrmAccount,
        vendorId,
        crmId,
        website,
        token,
      }}
    >
      {children}
    </ProspectCrmAccountDataContext.Provider>
  );
};

export const useProspectScoreCrmAccountData =
  (): ProspectCrmAccountDataContextValue => {
    const context = useContext(ProspectCrmAccountDataContext);
    if (!context) {
      throw new Error(
        'useProspectScoreCrmAccountData must be used within a ProspectAccountDataProvider',
      );
    }
    return context;
  };
