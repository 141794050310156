import TackleProspectZeroStateAnimation from 'components/AnimatedSvgs/TackleProspectZeroStateAnimatedSvg';
import { Grid, makeStyles, Typography } from 'vendor/material';
import { LightbulbOnOutline, Loading } from 'mdi-material-ui';
import { Button } from '@tackle-io/platform-ui';
import {
  ProspectScoreCrmAccount,
  useProspectSubmitScoreOrMakeVisibleMutation,
} from 'generated/graphql';
import { useProspectScoreCrmAccountData } from './ProspectAccountDataProvider';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    background: 'linear-gradient(to bottom, #FFFFFF99, #DEF0F399)',
  },
  expertTip: {
    fontSize: '16px',
    fontWeight: 600,
    textAlign: 'center',
    '& svg': {
      fill: theme.palette.YELLOW500,
      fontSize: '1.7em',
      verticalAlign: 'text-bottom',
    },
  },
  loadingIcon: {
    animation: '$spin 1s linear infinite',
  },
  button: {
    backgroundColor: theme.palette.common.white,
    whiteSpace: 'nowrap',
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    textWrap: 'balance',
  },
  svgContent: {
    flex: 1,
    alignSelf: 'center',
  },
  title: {
    fontSize: '32px',
    marginBlockEnd: '1rem',
  },
}));

const isOutOfScanLimit = (error: any): boolean => {
  return (
    error.graphQLErrors &&
    error.graphQLErrors[0] &&
    error.graphQLErrors[0].extensions.response.status === 403 &&
    error.graphQLErrors[0].extensions.response.body.max_entitlements_count !==
      undefined
  );
};

interface ProspectDetailsAndScoringProps {
  setStoredCrmAccountScore: (score: ProspectScoreCrmAccount) => void;
  setLimitUseState: (limitUse: boolean) => void;
}

const ProspectCallToAction: React.FC<ProspectDetailsAndScoringProps> = ({
  setStoredCrmAccountScore,
  setLimitUseState,
}) => {
  const classes = useStyles();
  const { vendorId, crmId, website, token } = useProspectScoreCrmAccountData();
  const [
    submitProspectScoreOrMakeVisible,
    {
      loading: submitOrUpdateProspectLoading,
      data: submitOrUpdateProspectData,
      error: submitOrUpdateProspectError,
    },
  ] = useProspectSubmitScoreOrMakeVisibleMutation({
    onCompleted: (data) => {
      setStoredCrmAccountScore(
        data.prospectSubmitScoreOrMakeVisible as ProspectScoreCrmAccount,
      );
    },
    onError: (error) => {
      if (isOutOfScanLimit(error)) {
        setLimitUseState(true);
      }
    },
  });

  const getScoreOrMakeVisible = () => {
    submitProspectScoreOrMakeVisible({
      variables: {
        vendorId,
        crmId,
        domain: website,
      },
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });
  };
  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item xs container>
        <Grid
          item
          xs
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item>
            <TackleProspectZeroStateAnimation />
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center">
              Find out which Cloud Marketplace is most likely to drive revenue
              for this account
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              align="center"
              className={classes.expertTip}
            >
              <LightbulbOnOutline fontSize="inherit" /> Tackle expert tip
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align="center">
              Sellers who use Tackle Prospect are twice as likely to transact on
              Cloud Marketplaces.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs>
          <Button
            disabled={submitOrUpdateProspectLoading || !website}
            fullWidth
            variant="outlined"
            startIcon={
              submitOrUpdateProspectLoading ? (
                <Loading className={classes.loadingIcon} />
              ) : undefined
            }
            appearance={
              !!submitOrUpdateProspectError
                ? 'destructive'
                : submitOrUpdateProspectData
                ? 'success'
                : 'primary'
            }
            className={classes.button}
            onClick={getScoreOrMakeVisible}
          >
            Score account
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProspectCallToAction;
