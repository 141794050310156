import React from 'react';
import { Tag } from '../Tag';
import {
  AceOpportunityReviewStatusEnum,
  TackleOpportunityStatusEnum,
  TackleOnlyOpportunityStatusEnum,
  TagColorEnum,
} from '../../types/enums';

const statusToColorMapping: {
  [key in TackleOpportunityStatusEnum]: TagColorEnum;
} = {
  [AceOpportunityReviewStatusEnum.SUBMITTED]: TagColorEnum.STANDARD,
  [AceOpportunityReviewStatusEnum.IN_REVIEW]: TagColorEnum.STANDARD,
  [AceOpportunityReviewStatusEnum.APPROVED]: TagColorEnum.STANDARD,
  [AceOpportunityReviewStatusEnum.PENDING_SUBMISSION]: TagColorEnum.STANDARD,
  [AceOpportunityReviewStatusEnum.REJECTED]: TagColorEnum.RED,
  [AceOpportunityReviewStatusEnum.ACTION_REQUIRED]: TagColorEnum.YELLOW,
  [TackleOnlyOpportunityStatusEnum.SUBMISSION_ERROR]: TagColorEnum.RED,
};

interface AwsCoSellStatusTagProps {
  status: TackleOpportunityStatusEnum;
}

export const AwsCoSellStatusTag: React.FC<AwsCoSellStatusTagProps> = (
  props,
) => {
  return <Tag color={statusToColorMapping[props.status]}>{props.status}</Tag>;
};
