export enum AceOpportunityReviewStatusEnum {
  PENDING_SUBMISSION = 'Pending Submission',
  SUBMITTED = 'Submitted',
  IN_REVIEW = 'In review',
  ACTION_REQUIRED = 'Action Required',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export enum TackleOnlyOpportunityStatusEnum {
  SUBMISSION_ERROR = 'Submission Error',
}

export type TackleOpportunityStatusEnum =
  | AceOpportunityReviewStatusEnum
  | TackleOnlyOpportunityStatusEnum;
