import ky, { AfterResponseHook } from 'ky';
import { authStore } from 'stores/Auth';

const handleUnauthorizedResponse: AfterResponseHook = (
  _,
  __,
  response: Response,
): Response => {
  if (response.status === 401) {
    authStore.logout();
    return response;
  }

  return response;
};

const handler: ProxyHandler<typeof ky> = {
  get: (target: typeof ky, p: string | symbol, _) => (input, options) =>
    target[p].apply(this, [encodeURI(input), options]),
};

const createApiClient = (
  prefixUrl: string,
  accessTokenProvider: () => Promise<string>,
) => {
  const kyApi = ky.extend({
    prefixUrl,
    timeout: 60000,
    hooks: {
      afterResponse: [handleUnauthorizedResponse],
      beforeRequest: [
        async (request) => {
          const token = await accessTokenProvider();

          request.headers.set('Authorization', `Bearer ${token}`);

          return request;
        },
      ],
    },
  });

  return new Proxy<typeof ky>(kyApi, handler);
};

export const getLegacyAuthToken = async () => authStore.getToken();

export type AccessTokenProvider = () => Promise<string>;

const createApi = (
  prefixUrl: string,
  accessTokenProvider?: AccessTokenProvider,
) => createApiClient(prefixUrl, accessTokenProvider || getLegacyAuthToken);

export const offersV2Api = (accessTokenProvider?: AccessTokenProvider) =>
  createApi(process.env.REACT_APP_OFFERS_API_URL, accessTokenProvider);

export const authApi = (accessTokenProvider?: AccessTokenProvider) =>
  createApi(process.env.REACT_APP_TKL_AUTH_SERVICE_URL, accessTokenProvider);

export const salesforceDataMapperApi = (
  accessTokenProvider?: AccessTokenProvider,
) => createApi(process.env.REACT_APP_DATA_MAPPER_API_URL, accessTokenProvider);
