import { useQuery, useQueryClient } from '@tanstack/react-query';
import coSellClient from '../coSellClient';
import { useMutation } from '@tanstack/react-query';
import { TackleOperationId } from '../utils';
import {
  CreateAceOpportunityRequest,
  UpdateAceOpportunityRequest,
} from 'packages/cosell/src/types/requests/AceOpportunityRequest';
import { ACE_OPPORTUNITY_EVENTS_QUERY_KEY } from 'packages/cosell/src/hooks/useOpportunityEventsQuery/useOpportunityEventsQuery';

export const ACE_OPPORTUNITY_QUERY_KEY = 'co-sell-aws-opportunity' as const;

export const useAceOpportunity = ({
  opportunityId,
}: {
  opportunityId?: string;
}) => {
  const queryClient = useQueryClient();

  const aceOpportunityQuery = useQuery({
    queryKey: [ACE_OPPORTUNITY_QUERY_KEY, opportunityId],
    queryFn: () => coSellClient.getOpportunityByIdV3(opportunityId!),
    enabled: !!opportunityId,
  });

  const createAceOpportunity = useMutation({
    mutationFn: async ({
      requestBody,
      tackleOperationId,
    }: {
      requestBody: CreateAceOpportunityRequest;
      tackleOperationId: TackleOperationId;
    }) => await coSellClient.createOpportunity(requestBody, tackleOperationId),
  });

  /**TODO: refactor implementation to pass in opportunityId */
  const updateAceOpportunity = useMutation({
    mutationFn: async ({
      requestBody,
      tackleOperationId,
    }: {
      requestBody: UpdateAceOpportunityRequest;
      tackleOperationId: TackleOperationId;
    }) =>
      await coSellClient.updateOpportunity(
        requestBody,
        /** opportunity has to exist to get to this point */
        opportunityId!,
        tackleOperationId,
      ),

    onSuccess: () => {
      queryClient.invalidateQueries([ACE_OPPORTUNITY_QUERY_KEY, opportunityId]);
      queryClient.invalidateQueries([
        ACE_OPPORTUNITY_EVENTS_QUERY_KEY,
        opportunityId,
      ]);
    },
  });

  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));
  const testEnvironmentAcceptOrRejectOpportunity = useMutation({
    mutationFn: async ({
      opportunityId,
      tackleOperationId,
    }: {
      opportunityId: string;
      tackleOperationId: TackleOperationId;
    }) =>
      coSellClient.acceptOrRejectOpportunity({
        opportunityId,
        tackleOperationId,
      }),
    onSuccess: async () => {
      // hack for the mock service to make sure the opportunity is updated before refetching
      await sleep(5000);
      return aceOpportunityQuery.refetch();
    },
  });

  return {
    aceOpportunityQuery,
    createAceOpportunity,
    updateAceOpportunity,
    testEnvironmentAcceptOrRejectOpportunity,
  };
};
