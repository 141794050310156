import React from 'react';
import { Box, makeStyles, Typography } from 'vendor/material/index';

interface OfferSectionProps {
  title: string;
  children: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
}));

const OfferSection: React.FunctionComponent<OfferSectionProps> = ({
  title,
  children,
}) => {
  const classes = useStyles();

  return (
    <Box mb={2}>
      <Box mb={2}>
        <Box mb={1}>
          <Typography className={classes.sectionTitle}>{title}</Typography>
        </Box>
      </Box>
      {children}
    </Box>
  );
};

export default OfferSection;
