import {
  SolutionsOfferedToggleEnum,
  type UpdateAceOpportunityFormValues,
} from '../../components/UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import { RawRequestWithOperation } from '../../hooks/useOpportunityEventsQuery/extractLatestRawRequestFromEvents';
import { SoftwareRevenue } from '../../types/AceOpportunity.type';
import { AcePartnerNeedType } from '../../types/enums';
import { MarketingSourceEnum } from '../../types/enums/MarketingSourceEnum';
import {
  CreateAceOpportunityRequest,
  Contact,
  LifeCycle,
  Marketing,
  CreateCustomer,
  Project,
  OpportunityRequestAccount,
  OpportunityRequestAddress,
  LaunchAceOpportunityRequest,
  StartEngagementRequest,
  UpdateAceOpportunityBaseRequest,
  CloseLostOpportunityRequest,
} from '../../types/requests/AceOpportunityRequest';

type LaunchAceFormValues = Pick<
  UpdateAceOpportunityFormValues,
  | 'deliveryModel'
  | 'customerSoftwareValue'
  | 'currencyCode'
  | 'effectiveDate'
  | 'expirationDate'
>;

type CloseLostFormValues = Pick<
  UpdateAceOpportunityFormValues,
  'closedLostReason' | 'additionalComments'
>;

type StartEngagementFormValues = Pick<
  UpdateAceOpportunityFormValues,
  'involvementType' | 'visibility'
>;

type CommonFormValues = Omit<
  UpdateAceOpportunityFormValues,
  | 'stage'
  | 'closedLostReason'
  | 'reviewComments'
  | 'marketplaceOfferId'
  | 'deliveryModel'
  | 'effectiveDate'
  | 'expirationDate'
  | 'currencyCode'
  | 'customerSoftwareValue'
>;

type ProjectDetailsFormValues = Pick<
  UpdateAceOpportunityFormValues,
  | 'additionalComments'
  | 'apnPrograms'
  | 'competitiveTracking'
  | 'customerBusinessProblem'
  | 'customerUseCase'
  | 'deliveryModels'
  | 'expectedCustomerSpendAmount'
  | 'expectedCustomerSpendCurrencyCode'
  | 'expectedCustomerSpendFrequency'
  | 'expectedCustomerSpendTargetCompany'
  | 'otherCompetitorNames'
  | 'salesActivities'
  | 'projectTitle'
  | 'otherSolutionDescription'
>;

export const getPartnerNeedType = (
  primaryNeedsFromAws: string[] | undefined,
): AcePartnerNeedType =>
  primaryNeedsFromAws && primaryNeedsFromAws.length > 0
    ? AcePartnerNeedType.CO_SELL_WITH_AWS
    : AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP;

const getAddressValues = (address: OpportunityRequestAddress) => ({
  city: address?.city ?? undefined,
  countryCode: address?.countryCode ?? undefined,
  postalCode: address?.postalCode ?? undefined,
  state: address?.stateOrRegion ?? undefined,
  address: address?.streetAddress ?? undefined,
});

const getAccountValues = (account: OpportunityRequestAccount) => ({
  duns: account?.duns ?? undefined,
  awsAccountId: account?.awsAccountId ?? undefined,
  customerCompanyName: account?.companyName ?? undefined,
  industry: account?.industry ?? undefined,
  industryOther: account?.otherIndustry ?? undefined,
  customerWebsite: account?.websiteUrl ?? undefined,
  ...getAddressValues(account?.address),
});

const getContactValues = (contact: Contact) => ({
  customerFirstName: contact?.firstName ?? undefined,
  customerLastName: contact?.lastName ?? undefined,
  customerEmail: contact?.email ?? undefined,
  customerPhone: contact?.phone ? contact?.phone.replace('+', '') : undefined,
  customerTitle: contact?.businessTitle ?? undefined,
});

const getCustomerDetails = (customer: CreateCustomer | undefined) => {
  const emptyCustomerFormFields = {
    customerCompanyName: undefined,
    customerWebsite: undefined,
    industry: undefined,
    countryCode: undefined,
    state: undefined,
    postalCode: undefined,
  };

  if (!customer) {
    return emptyCustomerFormFields;
  }
  const customerAccount = customer?.account
    ? getAccountValues(customer?.account)
    : emptyCustomerFormFields;
  const customerContact = customer?.contacts
    ? getContactValues(customer?.contacts[0])
    : {};
  return {
    ...customerAccount,
    ...customerContact,
  };
};

const getLifeCycleDetails = (lifeCycle: LifeCycle) => ({
  nextStep: lifeCycle?.nextSteps,
  targetCloseDate: lifeCycle?.targetCloseDate ?? '',
});

const getMarketingDetails = (marketing: Marketing) => ({
  isMarketingDevelopmentFunded: marketing?.awsFundingUsed,
  campaignName: marketing?.campaignName ?? undefined,
  marketingActivityChannel: marketing?.channels ?? undefined,
  marketingSource:
    (marketing?.source as MarketingSourceEnum) || MarketingSourceEnum.NONE,
  marketingActivityUseCases: marketing?.useCases ?? undefined,
});

const getProjectDetails = (project: Project): ProjectDetailsFormValues => ({
  additionalComments: project?.additionalComments ?? undefined,
  apnPrograms: project?.apnPrograms ?? undefined,
  competitiveTracking: project?.competitorName ?? undefined,
  customerBusinessProblem: project?.customerBusinessProblem,
  customerUseCase: project?.customerUseCase,
  deliveryModels: project?.deliveryModels,
  expectedCustomerSpendAmount:
    project?.expectedCustomerSpend?.[0]?.amount ?? null,
  expectedCustomerSpendCurrencyCode:
    project?.expectedCustomerSpend?.[0]?.currencyCode ?? null,
  expectedCustomerSpendFrequency:
    project?.expectedCustomerSpend?.[0]?.frequency ?? null,
  expectedCustomerSpendTargetCompany:
    project?.expectedCustomerSpend?.[0]?.targetCompany ?? null,
  otherCompetitorNames: project?.otherCompetitorNames ?? undefined,
  salesActivities: project?.salesActivities ?? undefined,
  projectTitle: project?.title,
  otherSolutionDescription: project?.otherSolutionDescription,
});

const getPartnerOpportunityTeamDetails = (team: Contact[]) => {
  if (!team || team.length === 0) {
    return {};
  }
  const primaryContact = team[0];
  if (!primaryContact) {
    return {};
  }
  return {
    primaryContactEmail: primaryContact?.email ?? undefined,
    primaryContactFirstName: primaryContact?.firstName ?? undefined,
    primaryContactLastName: primaryContact?.lastName ?? undefined,
    primaryContactPhone: primaryContact?.phone
      ? primaryContact?.phone.replace('+', '')
      : undefined,
    primaryContactTitle: primaryContact?.businessTitle ?? undefined,
  };
};

const getSolutionsOfferedToggleOption = (
  solutions: string[] | undefined,
): SolutionsOfferedToggleEnum => {
  if (!solutions || solutions.length === 0) {
    return SolutionsOfferedToggleEnum.OTHER_SOLUTION;
  }
  return SolutionsOfferedToggleEnum.EXISTING_SOLUTIONS;
};

const getSoftwareRevenueDetails = (
  softwareRevenue: SoftwareRevenue | null,
) => ({
  deliveryModel: softwareRevenue?.deliveryModel,
  customerSoftwareValue: softwareRevenue?.value?.amount
    ? Number(softwareRevenue?.value?.amount)
    : undefined,
  currencyCode: softwareRevenue?.value?.currencyCode,
  effectiveDate: softwareRevenue?.effectiveDate ?? undefined,
  expirationDate: softwareRevenue?.expirationDate ?? undefined,
});

const getCommonDetails = (
  request: CreateAceOpportunityRequest,
): CommonFormValues => ({
  ...getCustomerDetails(request?.customer),
  ...getProjectDetails(request?.project),
  ...getLifeCycleDetails(request?.lifeCycle),
  ...getMarketingDetails(request?.marketing),
  ...getPartnerOpportunityTeamDetails(request?.opportunityTeam),
  nationalSecurity: request?.nationalSecurity,
  partnerNeedType: request?.primaryNeedsFromAws
    ? getPartnerNeedType(request?.primaryNeedsFromAws)
    : undefined,
  opportunityType: request?.opportunityType,
  primaryNeedsFromAws: request?.primaryNeedsFromAws ?? [],
  awsProducts: request?.awsProducts ?? [],
  solutions: request?.solutions,
  solutionsOfferedToggle: getSolutionsOfferedToggleOption(request?.solutions),
  otherSolutionDescription: request?.project?.otherSolutionDescription,
});

export const convertRawRequestToAceFormValues = (
  trimmedRequestEvent: RawRequestWithOperation | undefined,
):
  | UpdateAceOpportunityFormValues
  | LaunchAceFormValues
  | StartEngagementFormValues
  | CloseLostFormValues => {
  if (!trimmedRequestEvent) {
    return {} as UpdateAceOpportunityFormValues;
  }
  const { operation, rawRequest } = trimmedRequestEvent;

  switch (operation) {
    case 'closeLostOpportunity': {
      const request = rawRequest as CloseLostOpportunityRequest;
      return {
        closedLostReason: request.lifeCycle?.closedLostReason ?? undefined,
        additionalComments: request.project?.additionalComments ?? undefined,
      };
    }
    case 'launchOpportunity': {
      const request = rawRequest as LaunchAceOpportunityRequest;
      return {
        ...getSoftwareRevenueDetails(request.softwareRevenue),
      };
    }
    case 'startEngagement': {
      const request = rawRequest as StartEngagementRequest;
      return {
        involvementType: request.awsSubmission?.involvementType,
        visibility: request.awsSubmission?.visibility ?? undefined,
      };
    }
    case 'updateOpportunity': {
      const request = rawRequest as UpdateAceOpportunityBaseRequest;
      return {
        ...getCommonDetails(request),
        involvementType: request.awsSubmission?.involvementType,
        visibility: request.awsSubmission?.visibility ?? undefined,
      };
    }
    case 'createOpportunity': {
      const request = rawRequest as CreateAceOpportunityRequest;
      return {
        ...getCommonDetails(request),
      };
    }
    default:
      return {} as UpdateAceOpportunityFormValues;
  }
};
