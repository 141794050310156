import { gql } from '@apollo/client';
import { usePlatformRbacProviderQuery } from 'generated/graphql';
import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import { useContext, createContext } from 'react';

type Area =
  | 'cosell'
  | 'integrations'
  | 'listings'
  | 'metering'
  | 'offers'
  | 'payments'
  | 'prospect'
  | 'settings';

export type Permission =
  /** admin */
  | '*'
  /** an operation like CreateOffer or could be * to indicate all operations */
  | `${Area}:${string}`;

type AreaPermissionAll = `${Area}:*`;
export const AREA_PERMISSION_COSELL: AreaPermissionAll = 'cosell:*';
export const AREA_PERMISSION_INTEGRATIONS: AreaPermissionAll = 'integrations:*';
export const AREA_PERMISSION_LISTINGS: AreaPermissionAll = 'listings:*';
export const AREA_PERMISSION_METERING: AreaPermissionAll = 'metering:*';
export const AREA_PERMISSION_OFFERS: AreaPermissionAll = 'offers:*';
export const AREA_PERMISSION_PAYMENTS: AreaPermissionAll = 'payments:*';
export const AREA_PERMISSION_PROSPECT: AreaPermissionAll = 'prospect:*';
export const AREA_PERMISSION_SETTINGS: AreaPermissionAll = 'settings:*';
export const AREA_PERMISSION_ALL = '*';

const cache = new WeakMap();
const userPermissionsToPermissionsByArea = (
  userPermissions: Permission[] | null | undefined,
): Record<Area, { [operation: string]: true }> => {
  if (!userPermissions)
    return {} as Record<Area, { [operation: string]: true }>;
  if (cache.has(userPermissions)) return cache.get(userPermissions);
  return (userPermissions as Permission[]).reduce((acc, permission) => {
    if (permission === '*') {
      // special case of the user permissions being simply '*'
      acc['*'] = { '*': true }; // all areas and operations are allowed
      return acc;
    }
    const [area, operation] = permission.split(':') as [Area, string];
    if (acc[area]) {
      acc[area][operation] = true;
    } else {
      acc[area] = { [operation]: true };
    }
    return acc;
  }, {} as Record<Area, { [operation: string]: true }>);
};

function checkPermission(
  permissionsByArea,
  requiredPermission: Permission,
): boolean {
  // special case where use MUST have full access
  if (requiredPermission === '*') return Boolean(permissionsByArea['*']?.['*']);
  const [area, operation] = requiredPermission.split(':') as [Area, string];
  // no permissions in this area
  if (!permissionsByArea[area]) return false;
  // the user has all operations for this area
  if (permissionsByArea[area]['*']) return true;
  // return if the user has the specific operation in the area
  return Boolean(permissionsByArea?.[area]?.[operation]);
}

function hasPermission(
  userPermissions: Permission[],
  requiredPermission: Permission,
): boolean {
  const permissionsByArea = userPermissionsToPermissionsByArea(userPermissions);
  // special case where user can do anything
  if (permissionsByArea['*']?.['*']) return true;
  return checkPermission(permissionsByArea, requiredPermission);
}

function hasAllPermissions(
  userPermissions: Permission[],
  requiredPermissions: Permission[],
): boolean {
  if (requiredPermissions.length === 0) return false;
  const permissionsByArea = userPermissionsToPermissionsByArea(userPermissions);
  // special case where user can do anything
  if (permissionsByArea['*']?.['*']) return true;
  return requiredPermissions.every((requiredPermission) =>
    checkPermission(permissionsByArea, requiredPermission),
  );
}

function hasAnyPermissionInArea(
  userPermissions: Permission[],
  area: Area,
): boolean {
  const permissionsByArea = userPermissionsToPermissionsByArea(userPermissions);
  // special case where user can do anything
  if (permissionsByArea['*']?.['*']) return true;
  if (permissionsByArea[area]) return true;
  return false;
}

const RBAC_CONTEXT = createContext<{
  loading: boolean;
  userPermissions: Permission[];
  hasPermission: (permission: Permission) => boolean;
  hasAllPermissions: (permissions: Permission[]) => boolean;
  hasAnyPermissionInArea: (area: Area) => boolean;
}>({
  loading: false,
  userPermissions: [],
  hasPermission: () => false,
  hasAllPermissions: () => false,
  hasAnyPermissionInArea: () => false,
});

export const useRbac = () => useContext(RBAC_CONTEXT);

gql`
  query PlatformRbacProvider {
    currentUser {
      id
      role_details {
        id
        permissions
      }
    }
  }
`;

export const PlatformRbacProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data, loading } = usePlatformRbacProviderQuery();
  const userPermissions = (data?.currentUser?.role_details?.permissions ||
    []) as Permission[];
  return (
    <RBAC_CONTEXT.Provider
      value={{
        loading,
        userPermissions,
        hasPermission: (permission) =>
          hasPermission(userPermissions, permission),
        hasAllPermissions: (permissions) =>
          hasAllPermissions(userPermissions, permissions),
        hasAnyPermissionInArea: (area) =>
          hasAnyPermissionInArea(userPermissions, area),
      }}
    >
      {children}
    </RBAC_CONTEXT.Provider>
  );
};

export const CanvasRbacProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const canvasSession = useCanvasSession();
  const userPermissions = (canvasSession?.permissions || []) as Permission[];
  return (
    <RBAC_CONTEXT.Provider
      value={{
        loading: canvasSession.isLoadingCanvasSession,
        userPermissions,
        hasPermission: (permission) =>
          hasPermission(userPermissions, permission),
        hasAllPermissions: (permissions) =>
          hasAllPermissions(userPermissions, permissions),
        hasAnyPermissionInArea: (area) =>
          hasAnyPermissionInArea(userPermissions, area),
      }}
    >
      {children}
    </RBAC_CONTEXT.Provider>
  );
};

export const TestRbacProvider = ({
  children,
  userPermissions,
}: {
  children: React.ReactNode;
  userPermissions: Permission[];
}) => {
  return (
    <RBAC_CONTEXT.Provider
      value={{
        loading: false,
        userPermissions,
        hasPermission: (permission) =>
          hasPermission(userPermissions, permission),
        hasAllPermissions: (permissions) =>
          hasAllPermissions(userPermissions, permissions),
        hasAnyPermissionInArea: (area) =>
          hasAnyPermissionInArea(userPermissions, area),
      }}
    >
      {children}
    </RBAC_CONTEXT.Provider>
  );
};

export const FOR_TESTING_ONLY = {
  hasPermission,
  hasAllPermissions,
  hasAnyPermissionInArea,
  userPermissionsToPermissionsByArea,
};
