import React from 'react';
import { Divider, Grid, useMediaQuery, useTheme } from 'vendor/material';
import InfoItem, {
  FULL_GRID_SIZE,
  HALF_GRID_SIZE,
} from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
// import { DetailsSectionCard, InfoItem } from 'packages/cosell/src/components';
import { PersonIcon } from 'packages/cosell/assets';
import { Contact } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { coSellAceOpportunityFormFieldsDataId } from 'packages/cosell/src/utilities/intercomEnums';

const CHARACTER_COUNT = 25;
interface AcePartnerDetailsProps {
  partnerContact: Contact;
}

const AcePartnerDetails: React.FC<AcePartnerDetailsProps> = ({
  partnerContact,
}) => {
  const theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down(960));

  return (
    <DetailsSectionCard
      title="Partner details"
      icon={<PersonIcon />}
      subTitle="Provide the details of the sales contact at your company who will work this opportunity."
    >
      <Grid container spacing={2}>
        <InfoItem
          itemTitle="Primary contact first name"
          value={partnerContact?.firstName}
          dataId={
            coSellAceOpportunityFormFieldsDataId.PRIMARY_CONTACT_FIRST_NAME
          }
        />
        {screenMobile && <Divider style={{ width: '100%' }} />}
        <InfoItem
          itemTitle="Primary contact last name"
          value={partnerContact?.lastName}
          dataId={
            coSellAceOpportunityFormFieldsDataId.PRIMARY_CONTACT_LAST_NAME
          }
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="Primary contact title"
          value={partnerContact?.businessTitle}
          dataId={coSellAceOpportunityFormFieldsDataId.PRIMARY_CONTACT_TITLE}
          gridSize={
            (partnerContact?.email?.length ?? 0) > CHARACTER_COUNT
              ? FULL_GRID_SIZE
              : HALF_GRID_SIZE
          }
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="Primary contact email"
          value={partnerContact?.email}
          dataId={coSellAceOpportunityFormFieldsDataId.PRIMARY_CONTACT_EMAIL}
          gridSize={
            (partnerContact?.email?.length ?? 0) > CHARACTER_COUNT
              ? FULL_GRID_SIZE
              : HALF_GRID_SIZE
          }
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="Primary contact phone"
          value={partnerContact?.phone}
          dataId={coSellAceOpportunityFormFieldsDataId.PRIMARY_CONTACT_PHONE}
          gridSize={FULL_GRID_SIZE}
        />
        <Divider style={{ width: '100%' }} />
      </Grid>
    </DetailsSectionCard>
  );
};

export default AcePartnerDetails;
