import {
  getUtcDateTimeFromSerializedIsoDateTime,
  startOfTodayAsUtcDatetime,
} from 'pages/PrivateOffers/pages/Next/generic/utils/date/dateUtils';
import { DateTime } from 'luxon';
import { Schedule } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';

const schedulesAfterToday = (
  todayDateTime: DateTime,
  schedules: Schedule[],
): Schedule[] =>
  schedules.filter(({ invoiceDate }) => {
    const dateTime = getUtcDateTimeFromSerializedIsoDateTime(invoiceDate);
    return dateTime.isValid && dateTime > todayDateTime;
  });

export const getSchedulesAfterToday = (schedules: Schedule[]): Schedule[] => {
  const todayDateTime = startOfTodayAsUtcDatetime();

  return schedulesAfterToday(todayDateTime, schedules);
};
