import { Box, Divider, Grid, makeStyles } from 'vendor/material';
import AWSOfferListViewItem from './AWSOfferListViewItem';
import StartAWSOffer from './StartAWSOffer';
import { useContext, useEffect, useState } from 'react';
import { Button } from '@tackle-io/platform-ui';
import {
  Marketplace,
  PrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { Product } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContextProvider/apiContext';
import OfferSubmissionErrorsBanner from 'pages/PrivateOffers/pages/Next/generic/OfferSubmissionErrorsBanner/OfferSubmissionErrorsBanner';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  contents: {
    overflow: 'auto',
  },
}));

const AWSOffersForOpportunity = ({
  awsOffers,
}: {
  awsOffers: PrivateOffer[];
}) => {
  const classes = useStyles();
  const {
    api: { getProducts },
    offerSubmissionError,
    setOfferSubmissionError,
  } = useContext(ApiContext);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    getProducts(Marketplace.Aws).then(setProducts);
  }, [getProducts]);

  const [startingNewAWSOffer, setStartingNewAWSOffer] = useState(false);
  if (startingNewAWSOffer) {
    return <StartAWSOffer onCancel={() => setStartingNewAWSOffer(false)} />;
  }

  return (
    <Box height="100%" mt={1}>
      <Grid
        container
        direction="column"
        className={classes.container}
        spacing={2}
      >
        <Grid item xs className={classes.contents}>
          {offerSubmissionError && (
            <Grid item>
              <OfferSubmissionErrorsBanner
                offerSubmissionError={offerSubmissionError}
                setOfferSubmissionError={setOfferSubmissionError}
              />
            </Grid>
          )}
          <Grid container direction="column" wrap="nowrap" spacing={2}>
            {awsOffers.map((awsOffer) => {
              return (
                <Grid item key={awsOffer.poId}>
                  <AWSOfferListViewItem
                    awsOffer={awsOffer}
                    products={products}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Button
            fullWidth
            variant="outlined"
            appearance="primary"
            onClick={() => setStartingNewAWSOffer(true)}
          >
            Add AWS offer
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AWSOffersForOpportunity;
