import React from 'react';
import OfferProgressStep from 'pages/PrivateOffers/pages/Next/generic/OfferProgressBar/OfferProgressStep';
import useStyles from './OfferProgressBar.styles';
import {
  ActivitySlug,
  OfferType,
  PrivateOffer,
} from '../api/types/PrivateOffer';

interface OfferProgressBarProps {
  offer: PrivateOffer | null;
}

const PartnerOfferProgressBar: React.FC<OfferProgressBarProps> = ({
  offer,
}) => {
  const classes = useStyles();
  const offerActivities = offer?.activities ?? [];
  const activitySlugs = new Set(offerActivities.map((a) => a.slug));

  const createdInMarketplace = activitySlugs.has(
    ActivitySlug.VendorCreatedMarketplaceOfferSuccess,
  );

  const offerAccepted = activitySlugs.has(ActivitySlug.BuyerAcceptedOffer);
  const offerCanceled = !!offer?.cancelledAt;

  return (
    <div
      className={classes.offerStepIndicator}
      data-id="new-offer-step-progress-indicator"
    >
      <>
        <OfferProgressStep
          step={0}
          title="Offer created in marketplace"
          priorStepCompleted={true}
          stepCompleted={createdInMarketplace}
          nextStepCompleted={offerAccepted}
          offerIsCancelled={offerCanceled}
        />
        <OfferProgressStep
          step={1}
          title="Partner created offer in marketplace"
          priorStepCompleted={createdInMarketplace}
          stepCompleted={offerAccepted}
          nextStepCompleted={offerAccepted}
          offerIsCancelled={offerCanceled}
        />
        <OfferProgressStep
          step={2}
          title="Offer accepted"
          priorStepCompleted={offerAccepted && createdInMarketplace}
          stepCompleted={offerAccepted}
          nextStepCompleted={false}
          offerIsCancelled={offerCanceled}
          lastStep
        />
      </>
    </div>
  );
};

const DirectOfferProgressBar: React.FC<OfferProgressBarProps> = ({ offer }) => {
  const classes = useStyles();
  const offerActivities = offer?.activities ?? [];
  const activitySlugs = new Set(offerActivities.map((a) => a.slug));

  const createdInMarketplace = activitySlugs.has(
    ActivitySlug.VendorCreatedMarketplaceOfferSuccess,
  );

  const offerEmailSent = activitySlugs.has(
    ActivitySlug.VendorSentPurchaseInstructions,
  );

  const offerEmailRead = activitySlugs.has(
    ActivitySlug.BuyerOpenedPurchaseInstructions,
  );

  const offerViewed = activitySlugs.has(ActivitySlug.BuyerViewedOffer);
  const offerAccepted = activitySlugs.has(ActivitySlug.BuyerAcceptedOffer);
  const offerCanceled = !!offer?.cancelledAt;

  return (
    <div
      className={classes.offerStepIndicator}
      data-id="new-offer-step-progress-indicator"
    >
      <>
        <OfferProgressStep
          step={0}
          title="Offer created in marketplace"
          priorStepCompleted={true}
          stepCompleted={createdInMarketplace}
          nextStepCompleted={offerEmailSent}
          offerIsCancelled={offerCanceled}
        />
        <OfferProgressStep
          step={1}
          title="Offer email sent"
          priorStepCompleted={createdInMarketplace}
          stepCompleted={offerEmailSent}
          nextStepCompleted={offerEmailRead}
          offerIsCancelled={offerCanceled}
        />
        <OfferProgressStep
          step={2}
          title="Offer email read"
          priorStepCompleted={offerEmailSent}
          stepCompleted={offerEmailRead}
          nextStepCompleted={offerViewed}
          offerIsCancelled={offerCanceled}
        />
        <OfferProgressStep
          step={3}
          title="Offer viewed"
          priorStepCompleted={offerEmailRead}
          stepCompleted={offerViewed}
          nextStepCompleted={offerAccepted}
          offerIsCancelled={offerCanceled}
        />
        <OfferProgressStep
          step={4}
          title="Offer accepted"
          priorStepCompleted={offerViewed}
          stepCompleted={offerAccepted}
          nextStepCompleted={false}
          offerIsCancelled={offerCanceled}
          lastStep
        />
      </>
    </div>
  );
};

const OfferProgressBar: React.FunctionComponent<
  { offerType: OfferType } & OfferProgressBarProps
> = ({ offerType, offer }) => {
  const ProgressBarComponentForOfferType =
    offerType === OfferType.PartnerResale
      ? PartnerOfferProgressBar
      : DirectOfferProgressBar;

  return <ProgressBarComponentForOfferType offer={offer} />;
};

export default OfferProgressBar;
