import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import coSellClient from 'packages/cosell/api/coSellClient';
import { extractLatestAceOpportunityEventErrors } from './extractLatestAceOpportunityEventErrors';
import { extractLatestRawRequestFromEvents } from './extractLatestRawRequestFromEvents';
import { getPendingOperation } from './getPendingOperation';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';

export const POLLING_MS = 3000;
const STALE_TIME_MS = 30000;

export const ACE_OPPORTUNITY_EVENTS_QUERY_KEY =
  'ace-opportunity-events' as const;
/**
 * Fetches opportunity events for a given opportunity ID
 * @param opportunityId - the opportunity ID
 * @returns opportunityEvents - the opportunity events
 * @returns opportunityEventsError - the opportunity events error
 * @returns isOpportunityEventsLoading - whether the opportunity events are loading
 * @returns opportunityErrors - the errors from the latest opportunity event with errors
 */
const useOpportunityEventsQuery = (opportunityId: string) => {
  const [enablePolling, setEnablePolling] = useState<boolean>(false);
  const { aceOpportunityQuery } = useAceOpportunity({
    opportunityId,
  });

  const aceOpportunityEventsQuery = useQuery({
    queryKey: [ACE_OPPORTUNITY_EVENTS_QUERY_KEY, opportunityId],
    queryFn: () => coSellClient.getOpportunityEvents(opportunityId),
    enabled: !!opportunityId,
    // adjust this to a value that makes sense for this data
    staleTime: STALE_TIME_MS,
    refetchInterval: enablePolling ? POLLING_MS : 0,
  });

  const {
    data: opportunityEvents,
    error: opportunityEventsError,
    isLoading: isOpportunityEventsLoading,
  } = aceOpportunityEventsQuery;

  const pendingOperation = getPendingOperation(opportunityEvents?.events);

  useEffect(() => {
    let timeoutId = null;
    if (!!pendingOperation) {
      setEnablePolling(!!pendingOperation);
    } else {
      if (enablePolling && !pendingOperation) {
        // Events are no longer pending, so we should get the latest opportunity details
        aceOpportunityQuery.refetch();
        timeoutId = setTimeout(() => {
          // Keep showing the polling button for 2 seconds after until opportunity has refreshed
          // so button actions don't appear
          // TODO: Temporary solution until we can detect an updated status from the pendingOperation
          setEnablePolling(false);
        }, 2000);
      }
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [pendingOperation, aceOpportunityQuery, enablePolling]);

  /** provide enough data to describe error clearly and associate to event */
  const aceOpportunityErrors = useMemo(() => {
    const events = opportunityEvents?.events ?? [];
    if (!events.length) return [];

    return extractLatestAceOpportunityEventErrors({
      events,
    });
  }, [opportunityEvents]);

  const aceLatestRawRequest = useMemo(() => {
    const events = opportunityEvents?.events ?? [];
    if (!events.length) return undefined;

    return extractLatestRawRequestFromEvents({
      events,
    });
  }, [opportunityEvents]);

  return {
    pendingOperation,
    opportunityEvents,
    opportunityEventsError,
    isOpportunityEventsLoading,
    aceOpportunityErrors,
    aceLatestRawRequest,
  };
};

export default useOpportunityEventsQuery;
