import { useCallback, useContext, useEffect, useState } from 'react';
import OffersCallToAction from './OffersCallToAction';
import { useCanvasSession } from '../src/useCanvasSession';
import { Box } from 'vendor/material';
import { Loader } from '@tackle-io/platform-ui';
import ListOfOffersForOpportunity from './ListOfOffersForOpportunity';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContextProvider/apiContext';
import { OfferAPIKey } from 'pages/PrivateOffers/pages/Next/generic/ApiContextProvider/offerAPIKey';
import { PrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { ApiContextProvider } from 'pages/PrivateOffers/pages/Next/generic/ApiContextProvider/ApiContextProvider';

const OffersTabContent = () => {
  const {
    api: { getOffersForOpportunity },
    isLoading,
  } = useContext(ApiContext);

  const session = useCanvasSession();
  const loading = isLoading(OfferAPIKey.OffersForOpportunity);
  const [offers, setOffers] = useState<PrivateOffer[] | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!session.record?.Id) return;
    (async () => {
      try {
        const offersForOpportunity = await getOffersForOpportunity(
          session.record.Id,
        );
        setOffers(offersForOpportunity);
      } catch (error) {
        setError(error);
      }
    })();

    return () => {
      setOffers(null);
    };
  }, [getOffersForOpportunity, session?.record?.Id]);

  return (
    <Box overflow="auto" height="100%" position="relative">
      {loading && <Loader />}
      {error && <div>Error: {error.message}</div>}
      {!loading && (!offers || offers?.length === 0) && <OffersCallToAction />}
      {offers?.length > 0 && (
        <ListOfOffersForOpportunity
          opportunityId={session?.record?.Id}
          offers={offers}
        />
      )}
    </Box>
  );
};

const OffersTabContentWithProvider = () => {
  const { canvasSession } = useCanvasSession();
  const accessTokenProvider = useCallback(
    async () => canvasSession.access_token,
    [canvasSession.access_token],
  );
  return (
    <ApiContextProvider accessTokenProvider={accessTokenProvider}>
      <OffersTabContent />
    </ApiContextProvider>
  );
};

export default OffersTabContentWithProvider;
