import { makeStyles } from 'vendor/material';
import { Box } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    overflow: 'hidden',
  },
  footer: {
    flexShrink: 0,
    '&:empty': {
      display: 'none',
    },
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
}));

/**
 * A component for which renders a footer at the bottom of the container
 * if the WidgetContainer.Footer component is used and children are provided.
 */
function WidgetContainer({ children }: { children: React.ReactNode }) {
  const { wrapper } = useStyles();
  return <div className={wrapper}>{children}</div>;
}

function WidgetContent({ children }: { children: React.ReactNode }) {
  const { content } = useStyles();
  return <div className={content}>{children}</div>;
}
function Footer({ children }: { children?: React.ReactNode }) {
  const { footer } = useStyles();
  return <Box className={footer}>{children}</Box>;
}

WidgetContainer.Content = WidgetContent;
WidgetContainer.Footer = Footer;
WidgetContainer.displayName = 'WidgetContainer';

export { WidgetContainer };
