import { useProspectScoreCrmAccountData } from './ProspectAccountDataProvider';
import { Loader } from '@tackle-io/platform-ui';
import ProspectCallToAction from './ProspectCallToAction';
import ProspectDetailsAndScoring from './ProspectDetailsAndScoring';
import { ProspectScoreCrmAccount } from 'generated/graphql';
import { useEffect, useState } from 'react';
import { useScanLimitData } from './ScanLimitDataProvider';
import ScanLimitReachedBanner from './ScanLimitReachedBanner';
import WebsiteRequiredBanner from './WebsiteRequiredBanner';

export const ProspectComponentSelector: React.FC = () => {
  const {
    loading: loadingAccount,
    crmAccountScore,
    website,
  } = useProspectScoreCrmAccountData();
  const [storedCrmAccountScore, setStoredCrmAccountScore] = useState<
    ProspectScoreCrmAccount | undefined
  >(undefined);

  useEffect(() => {
    if (crmAccountScore) {
      setStoredCrmAccountScore(crmAccountScore);
    }
  }, [crmAccountScore, setStoredCrmAccountScore]);
  const { loading: loadingScanLimit, limitUse } = useScanLimitData();
  const [limitUseState, setLimitUseState] = useState<boolean>(limitUse);

  useEffect(() => {
    setLimitUseState(limitUse);
  }, [limitUse]);

  if (!website) {
    return <WebsiteRequiredBanner />;
  }

  if (loadingAccount || loadingScanLimit) return <Loader />;

  if (
    !storedCrmAccountScore ||
    storedCrmAccountScore.visibility !== 'visible'
  ) {
    if (limitUseState) return <ScanLimitReachedBanner />;
    return (
      <ProspectCallToAction
        setStoredCrmAccountScore={setStoredCrmAccountScore}
        setLimitUseState={setLimitUseState}
      />
    );
  }
  return <ProspectDetailsAndScoring crmAccountScore={storedCrmAccountScore} />;
};
