import { useCanvasSession } from '../src/useCanvasSession';
import { NewAwsPrivateOfferCanvasPage } from 'pages/PrivateOffers/pages/Next/aws/edit/NewAwsPrivateOfferPage';
import { ViewAwsPrivateOfferCanvasPage } from 'pages/PrivateOffers/pages/Next/aws/view/ViewAwsPrivateOfferPage';
import { EditAwsPrivateOfferCanvasPage } from 'pages/PrivateOffers/pages/Next/aws/edit/EditAwsPrivateOfferPage';
import { CloneAwsPrivateOfferCanvasPage } from 'pages/PrivateOffers/pages/Next/aws/edit/CloneAwsPrivateOfferPage';
import { OffersCanvasAppContentPayload } from '../src/lib/salesforceCanvasSession';
import useOffersCanvasActions from './useOffersCanvasActions';

const OffersCanvasApp = () => {
  const { canvasSession, payload } =
    useCanvasSession<OffersCanvasAppContentPayload>();
  const { navigate } = useOffersCanvasActions();
  const accessTokenProvider = async () => canvasSession.access_token;

  if (payload.action === 'create') {
    return (
      <NewAwsPrivateOfferCanvasPage
        accessTokenProvider={accessTokenProvider}
        offerType={payload.offerType}
        afterSubmit={() => navigate({ action: 'go-back-to-opportunity-root' })}
        onCancel={() => navigate({ action: 'go-back-to-opportunity-root' })}
        opportunityId={
          payload.record.objectName === 'Opportunity' ? payload.record.Id : null
        }
        mode={payload.newOrAbo}
      />
    );
  }
  if (payload.action === 'view') {
    return (
      <ViewAwsPrivateOfferCanvasPage
        poId={payload.poId}
        accessTokenProvider={accessTokenProvider}
        afterSubmit={() => navigate({ action: 'go-back-to-opportunity-root' })}
        onCancel={() => navigate({ action: 'go-back-to-opportunity-root' })}
        onEdit={() =>
          // this is not working... I think maybe salesforce does not like trying to navigate back to the same page with different params
          navigate({
            action: 'edit',
            cloud: 'aws',
            poId: payload.poId,
            domain: 'offers',
          })
        }
      />
    );
  }
  if (payload.action === 'edit') {
    return (
      <EditAwsPrivateOfferCanvasPage
        poId={payload.poId}
        accessTokenProvider={accessTokenProvider}
        afterSubmit={() => navigate({ action: 'go-back-to-opportunity-root' })}
        onCancel={() => navigate({ action: 'go-back-to-opportunity-root' })}
      />
    );
  }
  if (payload.action === 'clone') {
    return (
      <CloneAwsPrivateOfferCanvasPage
        poId={payload.poId}
        accessTokenProvider={accessTokenProvider}
        afterSubmit={() => navigate({ action: 'go-back-to-opportunity-root' })}
        onCancel={() => navigate({ action: 'go-back-to-opportunity-root' })}
      />
    );
  }
};

export default OffersCanvasApp;
