import { DateTime } from 'luxon';
import { isNumber } from 'lodash';

export const getSerializedUtcIsoDateTimeFromDate = (date: Date): string => {
  const dateTime = DateTime.utc(
    date.getFullYear(),
    date.getMonth() + 1, // JS Date months are 0 indexed. Luxon DateTime months are 1 indexed.
    date.getDate(),
  );

  return dateTime.toISO();
};

export const getUtcDateTimeFromSerializedIsoDateTime = (
  serializedIsoDateTime: string,
): DateTime => {
  const dateTime = DateTime.fromISO(serializedIsoDateTime);

  return dateTime.toUTC();
};

export const getDateFromSerializedUtcIsoDateTime = (
  serializedIsoDateTime: string | null,
): Date | null => {
  if (!serializedIsoDateTime) {
    return null;
  }

  const dateTime = getUtcDateTimeFromSerializedIsoDateTime(
    serializedIsoDateTime,
  );
  const month = dateTime.month - 1;

  return new Date(dateTime.year, month, dateTime.day);
};

const ShortDateFormat = 'L/d/yyyy';

export const formatDateFieldValue = (
  serializedIsoDateTime: string | null,
): string | null => {
  if (!serializedIsoDateTime) {
    return null;
  }

  const dateTime = getUtcDateTimeFromSerializedIsoDateTime(
    serializedIsoDateTime,
  );

  return dateTime.toFormat(ShortDateFormat);
};

const ZeroPaddedDateFormat = 'MM/dd/yyyy';

export const formatDateFieldValueZeroPadded = (
  serializedIsoDateTime: string | null,
): string | null => {
  if (!serializedIsoDateTime) {
    return null;
  }

  const dateTime = getUtcDateTimeFromSerializedIsoDateTime(
    serializedIsoDateTime,
  );

  return dateTime.toFormat(ZeroPaddedDateFormat);
};

const IsoDateFormat = 'yyyy-MM-dd';

export const formatIsoDateTimeAsIsoDate = (
  serializedIsoDateTime: string | null,
): string | null => {
  if (!serializedIsoDateTime) {
    return null;
  }

  const dateTime = getUtcDateTimeFromSerializedIsoDateTime(
    serializedIsoDateTime,
  );

  return dateTime.toFormat(IsoDateFormat);
};

export const startOfTodayAsUtcDatetime = () => {
  const now = DateTime.now();

  return DateTime.utc(now.year, now.month, now.day);
};

export const startOfLastDayOfMonthAsUtcDateTime = (): DateTime => {
  return DateTime.utc().endOf('month').startOf('day');
};

export const getMonthsDifferenceFromDateTimes = (
  from: DateTime,
  to: DateTime,
): number => {
  const difference = to.diff(from, ['months']).toObject();

  if (difference?.months <= 0 || !isNumber(difference?.months)) {
    return 0;
  }

  if (difference?.months < 0.5) {
    return 1;
  }

  return Math.round(difference?.months);
};
