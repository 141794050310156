import { awsCoSellToFormFieldEnumMap } from 'packages/cosell/src/utilities/typeConverters/awsCoSellToFormFieldEnumMap';

/** a union of all data fields in list */
type AwsCoSellDataField = keyof typeof awsCoSellToFormFieldEnumMap;

/**
 * returns the local form field name or label
 * for a given AWS field name
 * if not in map, returns the original name
 */
export function getFormFieldByAwsCoSellFieldName({
  key,
  name,
}: {
  key: 'label' | 'name';
  name: string;
}) {
  if (!(name in awsCoSellToFormFieldEnumMap)) {
    return name;
  }

  return awsCoSellToFormFieldEnumMap[name as AwsCoSellDataField]?.[key];
}
