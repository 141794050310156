import { useCurrentUserVendorQuery } from 'generated/graphql';
import { VendorEnvironmentEnum } from 'utils/constants';
import { useAuth } from 'vendor/auth0';

function useIsTestEnvironment(): boolean {
  const { isAuthenticated } = useAuth();
  const { data } = useCurrentUserVendorQuery({
    skip: !isAuthenticated,
  });
  return (
    data?.currentUser?.vendor?.vendor_type === VendorEnvironmentEnum.SANDBOX ||
    data?.currentUser?.vendor?.vendor_type === VendorEnvironmentEnum.DEMO
  );
}

export default useIsTestEnvironment;
