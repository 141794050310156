import { FieldKey, FormValues } from './formSchema';
import { PrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { Product } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import { registrationFieldKeysToOmit } from 'pages/PrivateOffers/pages/Next/generic/utils/offer/additionalFieldUtils';

export const initialFormValuesForNewOffer: FormValues = {
  [FieldKey.RegistrationPageFields]: [],
};

export const initialFormValuesForExistingOffer = (
  offer: PrivateOffer,
  product: Product | null,
): FormValues => {
  if (!product) {
    return initialFormValuesForNewOffer;
  }
  const response = product.registrationPageFields
    .filter((field) => !registrationFieldKeysToOmit.has(field.displayTitle))
    .map((field) => {
      const match = Object.keys(offer.preRegistrationDetails).find(
        (key) => key === field.displayTitle,
      );
      return {
        [FieldKey.Title]: field.title,
        [FieldKey.DisplayTitle]: field.displayTitle,
        [FieldKey.DataType]: field.dataType,
        [FieldKey.Enabled]: true,
        [FieldKey.TackleRequired]: field.tackleRequired,
        [FieldKey.FieldValue]: match ? offer.preRegistrationDetails[match] : '',
      };
    });
  return { [FieldKey.RegistrationPageFields]: response };
};

export const initialFormValuesForCloneOffer = (
  offer: PrivateOffer,
  product: Product | null,
): FormValues => {
  return { ...initialFormValuesForExistingOffer(offer, product) };
};

export const initialFormValuesForAmendOffer = (
  offer: PrivateOffer,
  product: Product | null,
): FormValues => {
  return { ...initialFormValuesForExistingOffer(offer, product) };
};
