import { ClipboardText } from 'mdi-material-ui';
import OfferFormSection, {
  sectionHeaderStyle,
} from '../../../../generic/OfferFormSection/OfferFormSection';
import { Box, Typography } from 'vendor/material';
import { Card, RadioGroup, TextField } from '@tackle-io/platform-ui';
import OfferBuyers from '../../../../generic/OfferBuyers/OfferBuyers';
import React, { useContext } from 'react';
import { FastField, useField, useFormikContext } from 'formik';
import { FormSections, formSectionToTitle } from '../formSections';
import { FieldKey, RenewalType } from './formSchema';
import { RadioValueType } from '@tackle-io/platform-ui/src/components/RadioGroup/RadioGroup';
import {
  createEventWrappingOnChangeHandler,
  getFormattedError,
} from '../../../../generic/utils/field/fieldUtils';
import { DataId } from '../../../../generic/analytics';
import { FormValues } from '../formSchema';
import { OfferType } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import BuyerAgreementsLookup from './BuyerAgreementsLookup/BuyerAgreementsLookup';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import BuyerAgreementsSidePanel from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/BasicInformationFormSection/BuyerAgreementsLookup/BuyerAgreementsSidePanel';
import BuyerAgreementsContext from 'pages/PrivateOffers/pages/Next/aws/edit/BuyerAgreementsContext/BuyerAgreementsContext';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';

const fieldKeyToLabel: { [fk: string]: string } = {
  [FieldKey.PartnerName]: 'Partner name',
  [FieldKey.PartnerAccountNumber]: 'Partner AWS account number',
  [FieldKey.BuyerAccountNumber]: 'Buyer AWS account number',
  [FieldKey.CompanyName]: 'Company name',
  [FieldKey.OfferName]: 'Offer name',
  [FieldKey.OfferDescription]: 'Offer description',
};

const renewalFieldOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

const renewalTypeFieldOptions = [
  {
    label: 'Existing customer moving to AWS marketplace',
    value: RenewalType.NewAwsMarketplaceCustomer,
  },
  {
    label: 'Existing customer on AWS marketplace',
    value: RenewalType.ExistingAwsMarketplaceCustomer,
  },
];

const BasicInformationFormSection = () => {
  const [renewalField] = useField(FieldKey.Renewal);
  const { mode } = useContext(OfferPageContext);
  const { offerForMode: offer, offerIsAmendment } = useContext(OfferContext);
  const createdInMarketplaceAt = offer?.createdInMarketplaceAt;
  const isAmendmentMode = mode === OfferPageMode.Amend || offerIsAmendment;

  const {
    agreementsForBuyer,
    agreementsSidePanelOpen,
    setAgreementsSidePanelOpen,
  } = useContext(BuyerAgreementsContext);

  const {
    values: { offerType },
  } = useFormikContext<FormValues>();

  return (
    <div id={FormSections.BasicInformation}>
      <Card
        title={formSectionToTitle[FormSections.BasicInformation]}
        subtitle="Add key details for your offer."
        icon={<ClipboardText />}
      >
        {offerType === OfferType.PartnerResale && (
          <OfferFormSection title="Partner details">
            <Box mb={2}>
              <FastField name={FieldKey.PartnerName}>
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    data-id={DataId.PartnerNameField}
                    label={`${fieldKeyToLabel[FieldKey.PartnerName]}`}
                    error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                    disabled={!!createdInMarketplaceAt}
                  />
                )}
              </FastField>
            </Box>
            <Box mb={2}>
              <FastField name={FieldKey.PartnerAccountNumber}>
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    data-id={DataId.PartnerAccountNumberField}
                    label={`${
                      fieldKeyToLabel[FieldKey.PartnerAccountNumber]
                    } *`}
                    error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                    disabled={!!createdInMarketplaceAt}
                    required
                  />
                )}
              </FastField>
            </Box>
          </OfferFormSection>
        )}
        {offerType === OfferType.Direct && (
          <OfferFormSection title="Billing account">
            <Box mb={2}>
              <FastField name={FieldKey.BuyerAccountNumber}>
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    data-id={DataId.BuyerBillingAccountField}
                    label={`${fieldKeyToLabel[FieldKey.BuyerAccountNumber]} *`}
                    error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                    disabled={!!createdInMarketplaceAt || isAmendmentMode}
                    required
                  />
                )}
              </FastField>
            </Box>
            <Box>
              <BuyerAgreementsLookup />
            </Box>
          </OfferFormSection>
        )}
        <OfferFormSection title="Buyer details">
          {offerType === OfferType.PartnerResale && (
            <Box mb={2}>
              <FastField name={FieldKey.BuyerAccountNumber}>
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    data-id={DataId.BuyerBillingAccountField}
                    label={`${fieldKeyToLabel[FieldKey.BuyerAccountNumber]} *`}
                    error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                    disabled={!!createdInMarketplaceAt}
                    required
                  />
                )}
              </FastField>
            </Box>
          )}
          <Box mb={2}>
            <FastField name={FieldKey.CompanyName}>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  data-id={DataId.CompanyNameField}
                  label={`${fieldKeyToLabel[FieldKey.CompanyName]} *`}
                  error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                  required
                />
              )}
            </FastField>
          </Box>
          {offerType === OfferType.Direct && (
            <Box mb={2}>
              <OfferBuyers />
            </Box>
          )}
        </OfferFormSection>
        <OfferFormSection title="Offer details">
          <Box mb={2}>
            <FastField name={FieldKey.OfferName}>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  data-id={DataId.OfferNameField}
                  label={fieldKeyToLabel[FieldKey.OfferName]}
                  placeholder="Example: CompanyName_Quote#"
                  helperText="Use general descriptions. Avoid personally identifiable information. If no name is provided, Tackle will generate a name for you."
                  maxLength={150}
                  error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                  disabled={!!createdInMarketplaceAt}
                />
              )}
            </FastField>
          </Box>
          <Box>
            <FastField name={FieldKey.OfferDescription}>
              {({ field, meta }) => {
                const offerDescriptionHelperText =
                  offerType === OfferType.PartnerResale
                    ? 'Will be visible to your partner. Special characters are not allowed.'
                    : 'Not visible to your buyer.';

                return (
                  <TextField
                    {...field}
                    data-id={DataId.OfferDescriptionField}
                    label={fieldKeyToLabel[FieldKey.OfferDescription]}
                    helperText={offerDescriptionHelperText}
                    multiline
                    rows={6}
                    maxLength={255}
                    error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                    disabled={!!createdInMarketplaceAt}
                  />
                );
              }}
            </FastField>
          </Box>
        </OfferFormSection>
        <OfferFormSection title="Renewal">
          <Box>
            <Box mb={2}>
              <Typography>
                Is this a renewal of a paid subscription for an existing
                customer purchasing the same product?
              </Typography>
            </Box>
            <Box>
              <FastField name={FieldKey.Renewal}>
                {({ field, form }) => {
                  const onChange = (cv: RadioValueType) => {
                    const newValue = cv === 'true';

                    const renewalType = newValue
                      ? isAmendmentMode
                        ? RenewalType.ExistingAwsMarketplaceCustomer
                        : RenewalType.NewAwsMarketplaceCustomer
                      : null;

                    form.setFieldValue(field.name, newValue);
                    form.setFieldValue(FieldKey.RenewalType, renewalType);
                  };

                  return (
                    <RadioGroup
                      {...field}
                      data-id={DataId.RenewalField}
                      direction="vertical"
                      options={renewalFieldOptions}
                      value={String(field.value)}
                      onChange={onChange}
                      disabled={!!createdInMarketplaceAt}
                    />
                  );
                }}
              </FastField>
            </Box>
            {renewalField.value && !isAmendmentMode && (
              <Box mt={2}>
                <Box mb={2}>
                  <Typography style={sectionHeaderStyle}>
                    Renewal type
                  </Typography>
                </Box>
                <FastField name={FieldKey.RenewalType}>
                  {({ field }) => (
                    <RadioGroup
                      {...field}
                      data-id={DataId.RenewalTypeField}
                      direction="vertical"
                      options={renewalTypeFieldOptions}
                      onChange={createEventWrappingOnChangeHandler<string>(
                        field.name,
                        field.onChange,
                      )}
                      disabled={!!createdInMarketplaceAt}
                    />
                  )}
                </FastField>
              </Box>
            )}
          </Box>
        </OfferFormSection>
      </Card>
      {agreementsSidePanelOpen && (
        <BuyerAgreementsSidePanel
          agreementsForBuyer={agreementsForBuyer}
          onBuyerAgreementsSidePanelClosed={() => {
            setAgreementsSidePanelOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default BasicInformationFormSection;
