import * as yup from 'utils/yup-extended';
import { FormBuyer } from 'pages/PrivateOffers/pages/Next/generic/OfferBuyers/formSchema';

export enum FieldKey {
  UsersToNotify = 'usersToNotify',
}

export interface FormUserToNotify {
  email: string;
}

const userToNotifySchema = yup.object<FormUserToNotify>({
  email: yup.string().required(),
});

export interface FormValues {
  [FieldKey.UsersToNotify]: FormUserToNotify[];
}

export const formSchema = yup.object<FormValues>({
  [FieldKey.UsersToNotify]: yup.array<FormBuyer>().of(userToNotifySchema),
});
