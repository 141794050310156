import { Location } from 'history';

export const SEARCH_KEY = 'q';
export const SORT_KEY = 'sort';
export const PAGE_KEY = 'page';
export const PAGE_SIZE_KEY = 'pageSize';
export const FROM_KEY = 'from';
export const TAB_KEY = 'tab';
export const SOURCE_KEY = 'source';

type SearchParamNumberKeys = 'page' | 'pageSize' | 'from' | 'source';
export const PAGE_LIMIT = 10;

export const CoSellLandingPageTabs = {
  OPPORTUNITIES: 'opportunities',
  INVITATIONS: 'invitations',
};

export enum OpportunityColumnKey {
  PROJECT_NAME = 'project_name',
  CUSTOMER = 'customer',
  COSELL_STATUS = 'cosell_status',
  COSELL_STAGE = 'cosell_stage',
  DATE_CREATED = 'date_created',
  SYNC_STATUS = 'sync_status',
}

export interface OpportunitySortState {
  sortKey: OpportunityColumnKey;
  orderAscending: boolean;
}

export enum InvitationColumnKey {
  PROJECT_NAME = 'project_name',
  CUSTOMER = 'customer',
  INVITATION_STATUS = 'invitation_status',
  DATE_RECEIVED = 'date_received',
  EXPIRATION_DATE = 'expiration_date',
  SYNC_STATUS = 'sync_status',
}

export interface InvitationSortState {
  sortKey: InvitationColumnKey;
  orderAscending: boolean;
}

export enum Operation {
  FILTER = 'FILTER',
  SORT = 'SORT',
  SEARCH = 'SEARCH',
  TAB = 'TAB',
}

interface Filter {
  value: string | boolean;
  operation: Operation;
  key?: string | OpportunityColumnKey;
  type?: string;
  label?: string;
}

/**
 * Helper function to get the existing URL params and update the search param
 * Then returns the new URL path
 */
export const getUrlParamsPath = ({
  filter,
  location,
}: {
  filter: Filter;
  location: Location;
}): string => {
  const searchParams = new URLSearchParams(location.search);
  //   TODO: Filter operations
  switch (filter.operation) {
    case Operation.SEARCH:
      searchParams.delete(SEARCH_KEY);
      if (filter?.value) {
        searchParams.append(SEARCH_KEY, encodeURIComponent(filter.value));
        searchParams.delete(PAGE_KEY);
        searchParams.delete(FROM_KEY);
      }
      break;
    case Operation.SORT:
      // Reset sort to default
      searchParams.delete(SORT_KEY);

      break;

    case Operation.TAB:
      searchParams.delete(FROM_KEY);
      searchParams.delete(PAGE_KEY);
      searchParams.delete(TAB_KEY);
      searchParams.delete(SOURCE_KEY);
      searchParams.delete(SEARCH_KEY);
      if (filter.value === CoSellLandingPageTabs.INVITATIONS) {
        searchParams.set(TAB_KEY, filter.value as string);
      }

      break;
    case Operation.FILTER:
      break;
  }

  return `${location.pathname}?${searchParams.toString()}`;
};

const SearchParamNumberDefaults = {
  page: 0,
  pageSize: PAGE_LIMIT,
  from: 0,
};

export const getSearchParamNumberValue = ({
  searchParams,
  key,
}: {
  searchParams: URLSearchParams;
  key: SearchParamNumberKeys;
}): number => {
  const searchParamValue = searchParams.get(key);

  let num = parseFloat(searchParamValue);

  if (!isNaN(num) && num > 0) {
    return num;
  } else {
    return SearchParamNumberDefaults[key];
  }
};
