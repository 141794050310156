import { PrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { Box, Grid } from 'vendor/material';

const GCPOffersForOpportunity = ({
  gcpOffers,
}: {
  gcpOffers: PrivateOffer[];
}) => {
  return (
    <Box height="100%">
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid item>
          <span>Google not yet implemented</span>
        </Grid>
        <Grid item>
          <pre>{JSON.stringify(gcpOffers, null, 2)}</pre>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GCPOffersForOpportunity;
