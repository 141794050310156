import {
  BuyerMapping,
  SalesforceDataMappingResponseJSON,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/SalesforceDataMappingResponseJSON';
import { SalesforceDataMapping } from 'pages/PrivateOffers/pages/Next/generic/api/types/SalesforceDataMapping';
import {
  Buyer,
  Marketplace,
  OfferType,
  PrivateOffer,
} from '../types/PrivateOffer';
import { Optional } from 'utils/optional/optional';

const valueOrNullIfError = <T>(value: T): T | null =>
  value === 'ERROR' ? null : value;

const mapToBuyers = (buyers: BuyerMapping[]): Buyer[] =>
  buyers.map((b) => ({
    fullName: b.full_name,
    emailAddress: b.email_address,
    title: b.title,
  }));

export const salesforceDataMappingJSONToSalesforceDataMapping = (
  salesforceDataMappingResponseJSON: SalesforceDataMappingResponseJSON,
): SalesforceDataMapping => {
  const {
    errors,
    mappedFields: { private_offer },
  } = salesforceDataMappingResponseJSON;

  const privateOffer: PrivateOffer = Optional.ofNullable(private_offer)
    .map((offer) => {
      const buyers = Optional.ofNullable(offer.buyers)
        .map(valueOrNullIfError)
        .map(mapToBuyers)
        .orElse([]);

      return {
        buyerBillingAccountRef: valueOrNullIfError(
          offer.buyer_billing_account_ref,
        ),
        buyerCompanyName: valueOrNullIfError(offer.buyer_company_name),
        buyers,
        marketplace: valueOrNullIfError(offer.marketplace as Marketplace),
        offerExpirationAt: valueOrNullIfError(offer.offer_expiration_at),
        offerMetadata: valueOrNullIfError(offer.offer_metadata),
        offerType: valueOrNullIfError(offer.offer_type as OfferType),
        preRegistrationDetails: valueOrNullIfError(
          offer.pre_registration_details,
        ),
        productId: valueOrNullIfError(offer.productid),
        salesforceId: valueOrNullIfError(offer.salesforce_id),
      };
    })
    .orElse(null);

  return {
    errors: errors ?? {},
    privateOffer,
  };
};
