import React, { useContext, useState } from 'react';
import { Autocomplete, Grid, IconButton, makeStyles } from 'vendor/material';
import { Delete } from 'mdi-material-ui';
import { useTextFieldStyles } from '@tackle-io/platform-ui';
import classNames from 'classnames';
import {
  DimensionNameOption,
  getAvailableDimensionNameOptions,
  getFilteredOptions,
  getOnBlur,
  getOnChange,
  getOptionLabel,
  getRenderInput,
  getRenderOption,
} from './utils/utils';
import { currencySymbolByCurrencyCode } from 'utils/currency';
import { FastField, Field, FormikProps } from 'formik';
import TextField from '../../../../../../../../../../components/FieldsPricing/TextField/TextField';
import { FormValues } from '../../../formSchema';
import { getFormattedError } from '../../../../../../generic/utils/field/fieldUtils';
import { FieldKey as ProductAndPricingFieldKey } from '../../formTypes';
import { FieldKey, FormDimension } from './formTypes';
import {
  DataId,
  OffersProductArea,
  PageLocation,
} from '../../../../../../generic/analytics';
import { ampli } from 'utils/analytics/ampli/index';
import {
  Dimension,
  Marketplace,
  PaymentModel,
  Pricing,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';

interface DimensionProps {
  index: number;
  onReplace: (i: number, d: FormDimension) => void;
  onRemove: (i: number) => void;
  form: FormikProps<FormValues>;
  marketplacePricing: Pricing;
}

export const useStyles = makeStyles((theme) => ({
  fieldKey: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  fieldValue: {
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
  },
  fieldAction: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
    display: 'flex',
    alignItems: 'flex-end',
  },
  buttonIcon: {
    color: theme.palette.NEUTRAL500,
  },
  deleteButton: {
    alignItems: 'flex-start',
    marginTop: theme.spacing(1.75),
  },
  dimensionNameTitle: {
    fontWeight: 500,
  },
  dimensionNameApiNameSubtitle: {
    color: theme.palette.NEUTRAL300,
  },
  createDimensionOption: {
    margin: '-6px -16px',
    padding: '6px 16px 6px 13px',
    borderLeft: '0.20rem solid',
    borderLeftColor: theme.palette.BLUE400,
  },
}));

const emptyDimensionNameOption: DimensionNameOption = {
  name: '',
  lowerCasedName: '',
  apiName: '',
};

const toDimensionNameOption = (d: Dimension): DimensionNameOption => ({
  name: d.name,
  lowerCasedName: d.name.toLowerCase(),
  apiName: d.apiName,
});

const DimensionRow: React.FC<DimensionProps> = ({
  index,
  onReplace,
  onRemove,
  form,
  marketplacePricing,
}) => {
  const classes = useStyles();
  const { offerForMode: offer } = useContext(OfferContext);

  const createdInMarketplaceAt = offer?.createdInMarketplaceAt;
  const textFieldStyles = useTextFieldStyles();
  const {
    values: { paymentModel, currencyCode, dimensions },
  } = form;
  const showPrice = paymentModel === PaymentModel.PerProduct;
  const currencySymbol = currencySymbolByCurrencyCode[currencyCode];
  const formDimensionAtIndex = dimensions.at(index);
  const dimensionPath = `${ProductAndPricingFieldKey.Dimensions}[${index}]`;
  const nameFieldKey = `${dimensionPath}.${FieldKey.Name}`;
  const apiNameFieldKey = `${dimensionPath}.${FieldKey.APIName}`;
  const priceFieldKey = `${dimensionPath}.${FieldKey.Price}`;
  const quantityFieldKey = `${dimensionPath}.${FieldKey.Quantity}`;

  const fieldKeyToLabel: { [fk: string]: string } = {
    [nameFieldKey]: 'Name',
    [apiNameFieldKey]: 'API name',
    [priceFieldKey]: 'Price',
    [quantityFieldKey]: 'Quantity',
  };

  const [selectedDimensionNameOption, setSelectedDimensionNameOption] =
    useState<DimensionNameOption>({
      name: formDimensionAtIndex.name ?? '',
      lowerCasedName: formDimensionAtIndex?.name.toLowerCase() ?? '',
      apiName: formDimensionAtIndex.apiName ?? '',
    });

  const [dimensionNameInputValue, setDimensionNameInputValue] = useState(
    selectedDimensionNameOption.name,
  );

  const marketplaceDimensions =
    (marketplacePricing?.dimensions as Dimension[]) ?? [];

  const marketplaceDimensionNameOptions = marketplaceDimensions.map(
    toDimensionNameOption,
  );

  const formDimensionIsMarketplaceDimension =
    formDimensionAtIndex &&
    !!marketplaceDimensionNameOptions.find(
      ({ name, apiName }) =>
        name === formDimensionAtIndex.name &&
        apiName === formDimensionAtIndex.apiName,
    );

  const availableDimensionNameOptions = getAvailableDimensionNameOptions(
    marketplaceDimensionNameOptions,
    dimensions,
    index,
  );

  const onDimensionNameChanged: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setDimensionNameInputValue(value);
  };

  const isNewDimension =
    marketplacePricing &&
    dimensionNameInputValue &&
    !formDimensionIsMarketplaceDimension;

  const onDeleteDimensionClicked = () => {
    ampli.buttonClicked(
      {
        button_product_area: OffersProductArea,
        button_location: PageLocation.OfferEditPage,
        button_name: DataId.DeleteDimensionButton,
      },
      { extra: { marketplace: Marketplace.Aws } },
    );

    onRemove(index);
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        md={showPrice ? 4 : 6}
        sm={11}
        xs={11}
        className={classes.fieldKey}
      >
        <Field name={nameFieldKey}>
          {({ field, meta }) => (
            <Autocomplete
              {...field}
              data-id={`${DataId.DimensionNameField}-${index}`}
              value={selectedDimensionNameOption ?? emptyDimensionNameOption}
              inputValue={dimensionNameInputValue}
              options={availableDimensionNameOptions}
              getOptionLabel={getOptionLabel}
              filterOptions={getFilteredOptions(
                dimensionNameInputValue,
                dimensions,
              )}
              renderOption={getRenderOption(
                marketplaceDimensionNameOptions,
                classes,
              )}
              renderInput={getRenderInput(
                textFieldStyles,
                onDimensionNameChanged,
                isNewDimension,
                field.name,
                field.onBlur,
                getFormattedError(field.name, fieldKeyToLabel, meta),
              )}
              onChange={getOnChange(
                formDimensionAtIndex,
                index,
                onReplace,
                setSelectedDimensionNameOption,
                setDimensionNameInputValue,
              )}
              onBlur={getOnBlur(
                selectedDimensionNameOption,
                dimensionNameInputValue,
                index,
                onReplace,
                setSelectedDimensionNameOption,
                setDimensionNameInputValue,
              )}
              forcePopupIcon={availableDimensionNameOptions.length > 0}
              size="small"
              freeSolo
              openOnFocus
              selectOnFocus
              handleHomeEndKeys
              disablePortal
              disableClearable
              disabled={!!createdInMarketplaceAt}
            />
          )}
        </Field>
      </Grid>
      <Grid item md={3} sm={12} xs={12} className={classes.fieldValue}>
        <FastField name={apiNameFieldKey}>
          {({ field, meta }) => (
            <TextField
              {...field}
              data-id={`${DataId.DimensionApiNameField}-${index}`}
              label={fieldKeyToLabel[field.name]}
              readOnly={formDimensionIsMarketplaceDimension}
              error={getFormattedError(field.name, fieldKeyToLabel, meta)}
              disabled={!!createdInMarketplaceAt}
            />
          )}
        </FastField>
      </Grid>
      {showPrice && (
        <Grid item md={2} sm={12} xs={12} className={classes.fieldValue}>
          <FastField name={priceFieldKey}>
            {({ field, meta }) => (
              <TextField
                {...field}
                data-id={`${DataId.DimensionPriceField}-${index}`}
                label={fieldKeyToLabel[field.name]}
                type="number"
                mode="numberformat"
                numberPrefix={currencySymbol}
                fixedDecimalScale
                decimalScale={2}
                error={getFormattedError(field.name, fieldKeyToLabel, meta)}
                disabled={!!createdInMarketplaceAt}
              />
            )}
          </FastField>
        </Grid>
      )}
      <Grid item md={2} sm={12} xs={12} className={classes.fieldValue}>
        <FastField name={quantityFieldKey}>
          {({ field, meta }) => (
            <TextField
              {...field}
              data-id={`${DataId.DimensionQuantityField}-${index}`}
              label={fieldKeyToLabel[field.name]}
              type="number"
              error={getFormattedError(field.name, fieldKeyToLabel, meta)}
              disabled={!!createdInMarketplaceAt}
            />
          )}
        </FastField>
      </Grid>
      <Grid
        item
        md={1}
        sm={1}
        xs={1}
        className={classNames(classes.fieldAction, classes.deleteButton)}
      >
        <IconButton
          data-id={`${DataId.DeleteDimensionButton}-${index}`}
          onClick={onDeleteDimensionClicked}
          disabled={!!createdInMarketplaceAt}
        >
          <Delete className={classes.buttonIcon} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default DimensionRow;
