import * as yup from '../../../../../../../../utils/yup-extended';

export enum FieldKey {
  OfferAcceptanceDeadline = 'offerAcceptanceDeadline',
}

export type FormValues = {
  [FieldKey.OfferAcceptanceDeadline]: string | null;
};

export const formSchema: yup.ObjectSchema<FormValues> = yup.object({
  [FieldKey.OfferAcceptanceDeadline]: yup
    .string()
    .nullable()
    .required()
    .progress(true),
});
