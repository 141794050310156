import { useState } from 'react';
import { Grid, makeStyles, Tab, Tabs } from 'vendor/material';
import TackleLogo from 'images/tackle-logo';
import { Radar, BrightnessPercent } from 'mdi-material-ui';
import { ReactComponent as Handshake } from '@material-symbols/svg-400/rounded/handshake-fill.svg';
import OffersTabContent from 'packages/salesforce-canvas/offers/OffersTabContent';
import TackleWidgetCoSellView from 'packages/salesforce-canvas/cosell/src/components/TackleWidgetCoSellView';
import ProspectTabContent from 'packages/salesforce-canvas/prospect/ProspectTabContent';
import { useCanvasSession } from '../useCanvasSession';

const useTackleSidebarWidgetForSalesforceStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    backgroundColor: theme.palette.NEUTRAL000,
  },
  tackleAppBar: {
    background: theme.palette.TEAL900,
    height: '4rem',
    display: 'flex',
    alignItems: 'center',
    paddingInline: '1rem',
  },
  cosellIcon: {
    width: '16px',
    height: '16px',
  },
  tabsContainer: {
    backgroundColor: theme.palette.NEUTRAL030,
  },
  tabElement: {
    borderBottom: '1px solid #C1C7D0',
    '&[aria-selected=true]': {
      backgroundColor: theme.palette.NEUTRAL000,
      borderColor: 'transparent',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  tabIndicator: {
    opacity: 0,
  },
  labelLockup: {
    width: 'auto',
    textTransform: 'capitalize',
  },
}));

const TackleSidebarWidgetForSalesforce = () => {
  const classes = useTackleSidebarWidgetForSalesforceStyles();
  const { payload } = useCanvasSession();
  const [selectedTab, setSelectedTab] = useState<typeof payload['domain']>(
    payload?.domain || 'cosell',
  );

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item>
        <div className={classes.tackleAppBar}>
          <TackleLogo theme="light" width="104px" />
        </div>
      </Grid>
      <Grid item>
        <Tabs
          value={selectedTab}
          onChange={(e, val) => setSelectedTab(val)}
          variant="fullWidth"
          className={classes.tabsContainer}
          TabIndicatorProps={{
            className: classes.tabIndicator,
          }}
        >
          <Tab
            value="prospect"
            className={classes.tabElement}
            label={
              <Grid className={classes.labelLockup} container spacing={1}>
                <Grid item className={classes.icon}>
                  <Radar fontSize="inherit" />
                </Grid>
                <Grid item xs>
                  Prospect
                </Grid>
              </Grid>
            }
          />
          <Tab
            value="cosell"
            className={classes.tabElement}
            label={
              <Grid className={classes.labelLockup} container spacing={1}>
                <Grid item className={classes.icon}>
                  <Handshake
                    fontSize="inherit"
                    className={classes.cosellIcon}
                  />
                </Grid>
                <Grid item xs>
                  Co-Sell
                </Grid>
              </Grid>
            }
          />
          <Tab
            value="offers"
            className={classes.tabElement}
            label={
              <Grid className={classes.labelLockup} container spacing={1}>
                <Grid item className={classes.icon}>
                  <BrightnessPercent fontSize="inherit" />
                </Grid>
                <Grid item xs>
                  Offers
                </Grid>
              </Grid>
            }
          />
        </Tabs>
      </Grid>
      <Grid
        item
        xs
        style={{
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {selectedTab === 'prospect' && <ProspectTabContent />}
        {selectedTab === 'cosell' && <TackleWidgetCoSellView />}
        {selectedTab === 'offers' && <OffersTabContent />}
      </Grid>
    </Grid>
  );
};

export default TackleSidebarWidgetForSalesforce;
