import { AceOpportunityReviewStatusEnum } from 'packages/cosell/src/types/enums/AceOpportunityReviewStatusEnum';
import { AceOpportunityStageEnum } from 'packages/cosell/src/types/enums/AceOpportunityStageEnum';
import { SourceEnum } from 'packages/cosell/src/types/enums/SourceEnum';
import { coSellApi } from '../tackleApiClient';
import { OpportunityTypeEnum } from 'packages/cosell/src/types/enums/OpportunityTypeEnum';

/**
 * TODO: update enum/union types for cloudStatus, cloudSalesStage
 * and any other fields it applies to
 */
type TackleOpportunityListResponse = {
  opportunities:
    | null
    | {
        /** ISO 8601 format
         * might not exist if has not been created in cloud yet
         */
        cloudCreated?: string;
        /** ISO 8601 format
         * might not exist if has not been created in cloud yet
         */
        cloudLastModified?: string;
        cloudProviderId?: string;
        cloudStatus?: AceOpportunityReviewStatusEnum;
        cloudSalesStage?: AceOpportunityStageEnum;
        companyName: string;
        created: string;
        crmId: string;
        hasCloudErrors: boolean;
        /** tackle co-sell opportunity id */
        id: string;
        /** ISO 8601 format */
        lastModified: string;
        opportunityType: OpportunityTypeEnum;
        source: SourceEnum;
        /** ISO 8601 format */
        targetCloseDate: string;
        title: string;
        pendingRequestIds?: string[];
      }[];
  currentPageCount: number;
  isNextPage: boolean;
  total: number;
};

export const getTackleOpportunityListByCrmId = async ({
  crmId,
}: {
  crmId: string;
}): Promise<TackleOpportunityListResponse> => {
  return coSellApi
    .get(`opportunities?crmId=${crmId}&sortField=cloudCreated&sortOrder=desc`)
    .json();
};
