import { DateTime } from 'luxon';
import { Pricing } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { getUtcDateTimeFromSerializedIsoDateTime } from '../date/dateUtils';

export const getUsageDuration = (pricing: Pricing, acceptedAtDate): number => {
  if (!!acceptedAtDate) {
    const serviceEndDateTime = getUtcDateTimeFromSerializedIsoDateTime(
      pricing.serviceEndAt,
    );
    const acceptedAtDateTime =
      getUtcDateTimeFromSerializedIsoDateTime(acceptedAtDate);
    return Math.ceil(
      acceptedAtDateTime.diff(serviceEndDateTime, ['days']).toObject()?.days,
    );
  } else {
    const serviceEndDateTime = getUtcDateTimeFromSerializedIsoDateTime(
      pricing.serviceEndAt,
    );
    const currentTime = DateTime.utc();
    return Math.ceil(
      serviceEndDateTime.diff(currentTime, ['days']).toObject()?.days,
    );
  }
};
