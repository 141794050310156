export enum FormSections {
  BasicInformation = 'basicInformation',
  ProductAndPricing = 'productAndPricing',
  EndUserLicenseAgreement = 'endUserLicenseAgreement',
  ResellerAgreement = 'resellerAgreement',
  RegistrationDetails = 'registrationDetails',
  AdditionalFields = 'additionalFields',
  OfferValidityPeriod = 'offerValidityPeriod',
  NotifyUsers = 'notifyUsers',
  LinkOffer = 'linkOffer',
}

export const formSectionToTitle = {
  [FormSections.BasicInformation]: 'Basic information',
  [FormSections.ProductAndPricing]: 'Product and pricing',
  [FormSections.EndUserLicenseAgreement]: 'End user license agreement',
  [FormSections.ResellerAgreement]: 'Reseller agreement (optional)',
  [FormSections.RegistrationDetails]: 'Registration details',
  [FormSections.AdditionalFields]: 'Additional fields',
  [FormSections.OfferValidityPeriod]: 'Offer validity period',
  [FormSections.NotifyUsers]: 'Notify users',
  [FormSections.LinkOffer]: 'Link offer',
};
