export enum OfferAPIKey {
  Silent = 'silent',
  User = 'user',
  Users = 'users',
  Products = 'products',
  Offer = 'offer',
  OffersForOpportunity = 'offersForOpportunity',
  AgreementOffer = 'agreementOffer',
  CancelOffer = 'cancelOffer',
  SendBuyerInstructions = 'sendBuyerInstructions',
  ArchiveOffer = 'archiveOffer',
  VendorCurrencies = 'vendorCurrencies',
  MarketplacePricing = 'marketplacePricing',
  Document = 'document',
  SalesforceDataMapping = 'dataMapping',
  ActiveAgreements = 'activeAgreements',
}
