import React, { useState } from 'react';
import useStyles from './RightRailButtonSection.styles';
import { Box } from 'vendor/material';
import { useCanUseAwsFeatures } from 'packages/cosell/src/hooks/useCanUseAwsFeatures/useCanUseAwsFeatures';
import { Button } from '@tackle-io/platform-ui';
import { coSellDetailsPageDataId } from 'packages/cosell/src/utilities/intercomEnums';
import { AceInvitationCloudStatusEnum } from 'packages/cosell/src/types/enums';
import RightRailRejectModal from './RightRailRejectModal';
import { useAceInvitation } from 'packages/cosell/api/hooks/useAceInvitation';
import useToast from 'hooks/useToast/useToast';
import {
  TOASTS,
  INVITATION_ACCEPTANCE_SUBMITTED,
  INVITATION_REJECTION_SUBMITTED,
} from 'packages/cosell/src/components';
import { useCoSellContext } from 'packages/cosell/src/CoSellContextProvider';
import { COSELL_PATH } from 'packages/cosell/src/utilities/constants';
import { useHistory } from 'react-router-dom';
import { PollingStatusButton } from 'packages/cosell/src/components/RightRailSubSection/PollingStatusButton';

interface RightRailButtonSectionProps {
  status: AceInvitationCloudStatusEnum;
  customerCompanyName: string;
  hasPendingRequest: boolean;
  setPendingOperationId: React.Dispatch<
    'acceptInvitation' | 'rejectInvitation' | null
  >;
}
const RightRailButtonSection: React.FC<RightRailButtonSectionProps> = ({
  status,
  customerCompanyName,
  hasPendingRequest,
  setPendingOperationId,
}) => {
  const classes = useStyles();
  const variantByStatus = 'contained';
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const { invitationId, renderEnv } = useCoSellContext();
  const [isProcessingOperation, setIsProcessingOperation] =
    useState<boolean>(hasPendingRequest);
  const { acceptInvitation } = useAceInvitation({
    invitationId,
    enablePolling: isProcessingOperation,
  });

  const { canUse } = useCanUseAwsFeatures();
  const { toaster } = useToast();
  const history = useHistory();
  const isDownstream = renderEnv === 'downstream';
  const isPendingStatus = status === AceInvitationCloudStatusEnum.PENDING;
  const encodedCustomerCompanyName = encodeURIComponent(customerCompanyName);

  const handleAcceptInvitation = async () => {
    if (invitationId) {
      await acceptInvitation.mutateAsync(
        {
          invitationId,
        },
        {
          onSuccess: () => {
            toaster(TOASTS[INVITATION_ACCEPTANCE_SUBMITTED]);
            setPendingOperationId('acceptInvitation');
            setIsProcessingOperation(true);
          },
        },
      );
    }
  };

  const handleRejectInvitation = () => {
    toaster(TOASTS[INVITATION_REJECTION_SUBMITTED]);
    setRejectModalOpen(false);

    setPendingOperationId('rejectInvitation');

    setIsProcessingOperation(true);
  };

  return (
    <Box className={classes.root}>
      {isProcessingOperation && isPendingStatus && <PollingStatusButton />}
      {status === AceInvitationCloudStatusEnum.ACCEPTED && isDownstream && (
        <Button
          disabled={!canUse.invitations.view}
          variant={variantByStatus}
          data-id={coSellDetailsPageDataId.VIEW_OPPORTUNITY}
          appearance="primary"
          onClick={() => {
            // Filter searched results by customer company name & originated from Invitation
            // Since we're unable to view the opportunity details from invitation details
            history.push(
              `${COSELL_PATH}?source=Inbound&q=${encodedCustomerCompanyName}`,
            );
          }}
        >
          View co-sell
        </Button>
      )}

      {isPendingStatus && !isProcessingOperation && (
        <Box className={classes.actionButton}>
          <Button
            disabled={!canUse.invitations.close}
            variant="contained"
            data-id={coSellDetailsPageDataId.ACCEPT_INVITATION}
            appearance="primary"
            onClick={handleAcceptInvitation}
          >
            Accept
          </Button>
          <Button
            disabled={!canUse.invitations.close}
            variant="contained"
            data-id={coSellDetailsPageDataId.REJECT_INVITATION}
            appearance="destructive"
            onClick={() => setRejectModalOpen(true)}
          >
            Reject
          </Button>
        </Box>
      )}
      {rejectModalOpen && (
        <RightRailRejectModal
          handleRejectInvitation={handleRejectInvitation}
          setRejectModalOpen={setRejectModalOpen}
        />
      )}
    </Box>
  );
};

export default RightRailButtonSection;
