import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  Popover,
  PopoverOrigin,
} from 'vendor/material';
import {
  Button,
  Calendar,
  Dropdown,
  useTextFieldStyles,
} from '@tackle-io/platform-ui';
import { Calendar as CalendarIcon } from 'mdi-material-ui';
import React, { useState } from 'react';
import {
  formatDateFieldValue,
  getDateFromSerializedUtcIsoDateTime,
} from '../utils/date/dateUtils';
import { createDateSelectorOnChangeHandler } from './utils/utils';
import { FieldChangeEvent } from '../utils/field/fieldChangeEventUtils';
import { createEventNameInjectingOnBlurHandler } from 'pages/PrivateOffers/pages/Next/generic/utils/field/fieldUtils';

interface DateSelectorProps {
  name: string;
  label: string;
  value: string | null;
  onChange: (e: FieldChangeEvent<string>) => void;
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void;
  dataId?: string;
  helperText?: string;
  placeholder?: string;
  minDate?: string | null;
  maxDate?: string | null;
  error?: string | null;
  disabled?: boolean;
  showClearDateFooter?: boolean;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
  },
  calendarContent: {
    marginBottom: theme.spacing(1.5),
  },
  dateSelectFormControl: {
    '& button': {
      paddingTop: '9px',
      paddingBottom: '9px',
      '&.selected': {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
  },
  calendarFooter: {
    padding: theme.spacing(1.5, 0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.NEUTRAL040}`,
  },
}));

const popoverAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const popoverTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

const DateSelector: React.FunctionComponent<DateSelectorProps> = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  dataId,
  helperText = '',
  placeholder = '',
  minDate = null,
  maxDate = null,
  error = null,
  disabled = false,
  showClearDateFooter = false,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const inputClasses = useTextFieldStyles();
  const popoverOpen = !!anchorEl;
  const popoverId = popoverOpen ? 'date-popover' : undefined;
  const popoverClasses = { paper: classes.paper };

  const onCalendarOpened = (event: React.MouseEvent): void => {
    setAnchorEl(event.currentTarget);
  };

  const onCalendarClosed = (): void => {
    setAnchorEl(null);
  };

  const onClearDate = (): void => {
    const event: FieldChangeEvent<string> = {
      type: 'change',
      target: { name, value: null },
    };

    onChange(event);
  };

  return (
    <FormControl
      error={!!error}
      fullWidth
      className={classes.dateSelectFormControl}
    >
      <InputLabel className={inputClasses.inputLabel}>{label}</InputLabel>
      <div data-id={dataId}>
        <Dropdown
          value={formatDateFieldValue(value) || ''}
          onClick={onCalendarOpened}
          placeholder={placeholder}
          startIcon={<CalendarIcon />}
          disabled={disabled}
          fullWidth
          hideEndIcon
        />
      </div>
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={onCalendarClosed}
        classes={popoverClasses}
        anchorOrigin={popoverAnchorOrigin}
        transformOrigin={popoverTransformOrigin}
        onBlur={createEventNameInjectingOnBlurHandler(name, onBlur)}
      >
        <div className={classes.calendarContent}>
          <Calendar
            onChange={createDateSelectorOnChangeHandler(
              name,
              onChange,
              setAnchorEl,
            )}
            value={getDateFromSerializedUtcIsoDateTime(value)}
            minDate={getDateFromSerializedUtcIsoDateTime(minDate)}
            maxDate={getDateFromSerializedUtcIsoDateTime(maxDate)}
          />
        </div>
        {showClearDateFooter && value && (
          <div className={classes.calendarFooter}>
            <Button
              variant="outlined"
              appearance="secondary"
              onClick={onClearDate}
            >
              Clear Date
            </Button>
          </div>
        )}
      </Popover>
      <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
    </FormControl>
  );
};

export default DateSelector;
