import { Optional } from 'utils/optional/optional';
import { Document } from '../types/Document';
import { DocumentJSON } from '../types/DocumentJSON';

export const documentJSONToDocument = (
  document: DocumentJSON | null,
): Document =>
  Optional.ofNullable(document)
    .map((d) => ({
      documentId: d.document_id,
      documentUrn: d.document_urn,
      filename: d.filename,
    }))
    .orElse(null);
