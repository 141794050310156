import { useEffect, useRef, useState } from 'react';

interface UsePollingHookProps {
  isPolling: boolean;
}

interface UsePollingProps<T> {
  startPollingPredicate: () => boolean;
  pollingFn: () => Promise<T>;
  stopPollingPredicate: (polledItem: T) => boolean;
  afterPollingComplete: (polledItem: T) => void;
  pollingInterval: number;
}

const usePolling = <T>({
  startPollingPredicate,
  pollingFn,
  stopPollingPredicate,
  afterPollingComplete,
  pollingInterval,
}: UsePollingProps<T>): UsePollingHookProps => {
  const [isPolling, setIsPolling] = useState(false);
  const [polledItem, setPolledItem] = useState<T>(null);
  const intervalId = useRef(null);

  useEffect(() => {
    if (!startPollingPredicate()) {
      return;
    }

    const startPolling = () => {
      setIsPolling(true);

      intervalId.current = setInterval(async () => {
        const latestPolledItem = await pollingFn();
        setPolledItem(latestPolledItem);
      }, pollingInterval);
    };

    startPolling();

    return () => {
      clearInterval(intervalId.current);
    };
  }, [startPollingPredicate, pollingFn, stopPollingPredicate, pollingInterval]);

  useEffect(() => {
    if (stopPollingPredicate(polledItem)) {
      clearInterval(intervalId.current);
      afterPollingComplete(polledItem);
      setIsPolling(false);
    }
  }, [stopPollingPredicate, polledItem, afterPollingComplete]);

  return { isPolling };
};

export default usePolling;
