import { convertOpportunityResponseToAceFormValues } from 'packages/cosell/src/utilities/typeConverters/convertOpportunityResponseToAceFormValues';
import type { CosellAwsMappedFields } from 'packages/salesforce-canvas/api/requests/getMappedFields';

const dataMapperFieldErrorValue = 'ERROR';

/** create an initialValues object for the Ace create opportunity form
 *  from the data mapper response,
 *  returns an initial values object with fields that have a defined and valid value
 */
export const transformToAceInitialValues = (
  dataMapping: CosellAwsMappedFields,
) => {
  const aceFormValues = convertOpportunityResponseToAceFormValues(dataMapping);

  return Object.fromEntries(
    Object.entries(aceFormValues).filter(
      ([, value]) =>
        ![undefined, null, dataMapperFieldErrorValue].includes(value),
    ),
  );
};
