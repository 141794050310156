import { PrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { createContext } from 'react';
import { Product } from '../api/types/Product';

export interface OfferContextValues {
  offer: PrivateOffer | null;
  setOffer: (v: PrivateOffer | null) => void;
  product: Product | null;
  offerForMode: PrivateOffer | null;
  offerIsAmendment: boolean;
}

const OfferContext = createContext<OfferContextValues>({
  offer: null,
  setOffer: () => {},
  product: null,
  offerForMode: null,
  offerIsAmendment: false,
});

export default OfferContext;
