import { OfferType } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import { isWithProgress } from 'pages/PrivateOffers/pages/Next/generic/utils/schema/schemaTestsUtils';
import * as yup from 'utils/yup-extended';

export enum FieldKey {
  OfferId = 'offerId',
  SourceOfferId = 'sourceOfferId',
  isAmended = 'isAmended',
  SellingAuthorizationId = 'sellingAuthorizationId',
  OfferType = 'offerType',
}

export type FormValues = {
  [FieldKey.OfferId]: string | null;
  [FieldKey.SourceOfferId]: string | null;
  [FieldKey.isAmended]: boolean;
  [FieldKey.SellingAuthorizationId]: string | null;
};

export const formSchema = (mode: OfferPageMode): yup.ObjectSchema<FormValues> =>
  yup.object({
    [FieldKey.OfferId]: yup.string().when('$', {
      is: ({ values }: { values: FormValues }) =>
        values?.[FieldKey.OfferType] === OfferType.Direct &&
        mode === OfferPageMode.Associate,
      then: yup.string().required().progress(true),
      otherwise: yup.string(),
    }),
    [FieldKey.isAmended]: yup.boolean(),
    [FieldKey.SourceOfferId]: yup.string().when('$', {
      is: isWithProgress(FieldKey.isAmended, (isAmended) => !!isAmended),
      then: yup.string().required().progress(true),
      otherwise: yup.string(),
    }),
    [FieldKey.SellingAuthorizationId]: yup.string().when('$', {
      is: isWithProgress(
        FieldKey.OfferType,
        (ot) => ot === OfferType.PartnerResale,
      ),
      then: yup.string().progress(true),
      otherwise: yup.string(),
    }),
  });
