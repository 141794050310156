import { ValidationError } from 'yup';
import { FieldKey as ProductAndPricingFieldKey } from '../../formTypes';
import { FieldKey, FormDimension } from './formTypes';
import { Optional } from 'utils/optional/optional';
import {
  combineValidationErrors,
  hasValidationError,
} from '../../../../../../generic/utils/schema/schemaTestsUtils';

const toApiName = (d: FormDimension) => d.apiName;

const toDuplicateApiNameError = (
  apiName: string,
  index: number,
  array: string[],
): ValidationError | null => {
  const duplicateApiNameExists = array.some(
    (an, i) => apiName && an && apiName === an && index !== i,
  );

  return duplicateApiNameExists
    ? new ValidationError(
        'Api names must be unique',
        null,
        `${ProductAndPricingFieldKey.Dimensions}.${index}.${FieldKey.APIName}`,
      )
    : null;
};

export const duplicateApiNamesTest = (dimensions: FormDimension[]) => {
  const errors = Optional.ofNullable(dimensions)
    .map((d) =>
      d.map(toApiName).map(toDuplicateApiNameError).filter(hasValidationError),
    )
    .orElse([]);

  return (
    errors.length === 0 ||
    combineValidationErrors(errors, ProductAndPricingFieldKey.Dimensions)
  );
};
