import Lottie, { LottieComponentProps } from 'lottie-react';
import animationData from './Tackle_AgreementIcon_500x500.json';

const TackleAnimatedHandshake = (
  props: Omit<LottieComponentProps, 'animationData'>,
) => {
  return (
    <Lottie
      animationData={animationData}
      loop={false}
      style={{ width: 120 }}
      {...props}
    />
  );
};

export default TackleAnimatedHandshake;
