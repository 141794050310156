import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import usePrivateOfferApi from 'pages/PrivateOffers/pages/Next/generic/hooks/usePrivateOfferApi';
import { getLegacyAuthToken } from 'pages/PrivateOffers/pages/Next/generic/api/offersV2API';
import ApiContext, {
  ApiContextValues,
  OfferSubmissionError,
  SchemaValidationError,
} from 'pages/PrivateOffers/pages/Next/generic/ApiContextProvider/apiContext';
import { OfferAPIKey } from 'pages/PrivateOffers/pages/Next/generic/ApiContextProvider/offerAPIKey';

const initialState: { [k: string]: boolean } = {
  [OfferAPIKey.Silent]: false,
  [OfferAPIKey.User]: false,
  [OfferAPIKey.Users]: false,
  [OfferAPIKey.Products]: false,
  [OfferAPIKey.Offer]: false,
  [OfferAPIKey.OffersForOpportunity]: false,
  [OfferAPIKey.AgreementOffer]: false,
  [OfferAPIKey.VendorCurrencies]: false,
  [OfferAPIKey.MarketplacePricing]: false,
  [OfferAPIKey.Document]: false,
  [OfferAPIKey.SalesforceDataMapping]: false,
  [OfferAPIKey.ActiveAgreements]: false,
};

export interface ContextValueSetters {
  setOfferSubmissionError: Dispatch<
    SetStateAction<SchemaValidationError | null>
  >;
  setSubmitting: Dispatch<SetStateAction<{ [k: string]: boolean }>>;
  setLoading: Dispatch<SetStateAction<{ [k: string]: boolean }>>;
  setSynced: Dispatch<SetStateAction<{ [k: string]: boolean }>>;
}

interface ApiContextProviderProps {
  accessTokenProvider?: () => Promise<string>;
  children: React.ReactElement;
}

export const ApiContextProvider: React.FunctionComponent<
  ApiContextProviderProps
> = ({ accessTokenProvider, children }) => {
  const [offerSubmissionError, setOfferSubmissionError] =
    useState<OfferSubmissionError>(null);

  const [submitting, setSubmitting] = useState<{ [k: string]: boolean }>({
    [OfferAPIKey.Offer]: false,
    [OfferAPIKey.Document]: false,
  });

  const [synced, setSynced] = useState<{ [k: string]: boolean }>(initialState);

  const [loading, setLoading] =
    useState<{ [k: string]: boolean }>(initialState);

  const isSubmitting = useCallback(
    (k: OfferAPIKey) => submitting[k],
    [submitting],
  );

  const isLoading = useCallback((k: OfferAPIKey) => loading[k], [loading]);
  const hasSynced = useCallback((k: OfferAPIKey) => synced[k], [synced]);

  useEffect(() => {
    return () => {
      setOfferSubmissionError(null);
      setSubmitting({});
      setSynced({});
      setLoading({});
    };
  }, []);

  const apiTokenProvider = accessTokenProvider || getLegacyAuthToken;

  const api = usePrivateOfferApi(apiTokenProvider, {
    setOfferSubmissionError,
    setSubmitting,
    setSynced,
    setLoading,
  });

  const contextValues: ApiContextValues = {
    offerSubmissionError,
    setOfferSubmissionError,
    isSubmitting,
    isLoading,
    hasSynced,
    api,
  };

  return (
    <ApiContext.Provider value={contextValues}>{children}</ApiContext.Provider>
  );
};
