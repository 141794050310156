import { FieldOnChangeHandler } from '../../utils/field/fieldChangeEventUtils';
import { getSerializedUtcIsoDateTimeFromDate } from '../../utils/date/dateUtils';
import { createEventWrappingOnChangeHandler } from '../../utils/field/fieldUtils';

type SetAnchorHandler = (anchorEl: null) => void;

export const createDateSelectorOnChangeHandler =
  (
    name: string,
    onChange: FieldOnChangeHandler<string>,
    setAnchorEl: SetAnchorHandler,
  ) =>
  (date: Date | null) => {
    const value = date ? getSerializedUtcIsoDateTimeFromDate(date) : null;
    const eventWrappingOnChange = createEventWrappingOnChangeHandler(
      name,
      onChange,
    );

    eventWrappingOnChange(value);
    setAnchorEl(null);
  };
