import {
  addMethod,
  mixed,
  MixedSchema,
  number,
  NumberSchema,
  Schema,
  ValidationError,
} from 'yup';

export type FormProgress = {
  total: number;
  complete: number;
  statusByPath?: { [path: string]: 'incomplete' | 'invalid' | 'complete' };
};

type Context = {
  progress?: FormProgress;
  self?: boolean;
};

/**
 *
 * @param schema yup field schema
 * @param values field values
 */
export const getFormProgress = <TValues>(
  schema: Schema<TValues>,
  values: TValues,
): FormProgress => {
  const progress: FormProgress = { total: 0, complete: 0, statusByPath: {} };
  try {
    schema.validateSync(values, {
      context: { progress, values },
      abortEarly: false,
      stripUnknown: true,
    });
  } catch (err) {
    if (err instanceof ValidationError) {
      return progress;
    }
    throw err;
  }

  return progress;
};

/**
 *
 * @param value: the value of the form field input
 * @returns {boolean}
 */
const itHasAValue = (value): boolean => {
  const valueType = typeof value;

  if (valueType === 'object' && Array.isArray(value)) {
    return value.filter((item) => !!item).length !== 0;
  }

  switch (valueType) {
    case 'boolean':
      return true;
    case 'number':
      return true;
    default:
      break;
  }

  return Boolean(value);
};

/**
 * @param isRequired: Whether value that will be passed in is required
 * @returns {boolean}
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function countProgress<T, C>(isRequired = false) {
  let schema = this as MixedSchema<T | C>;
  if (!isRequired) {
    schema = schema.nullable();
  }

  return schema.test(
    'calculate-progress',
    'should never error',
    function test(value): true {
      const context = this.options.context as Context;

      if (!context.progress) {
        return true;
      }

      if (context.self) {
        // abort to avoid infinite recursion which happens when calling isValidSync in this function
        return true;
      }

      const hasValue = itHasAValue(value);
      const isValid = this.schema.isValidSync(value, {
        context: { self: true }, // flag to avoid infinite recursion
      });

      // abort if field is optional and is not filled out
      if (!isRequired && !hasValue) {
        return true;
      }

      if (isRequired && !hasValue) {
        context.progress.statusByPath[this.path] = 'incomplete';
      }

      if (!isValid) {
        context.progress.statusByPath[this.path] = 'invalid';
      }

      // required fields and optional fields that are filled out count toward total required fields
      if (isRequired || hasValue) {
        context.progress.total += 1;
      }

      // valid, filled out fields count toward complete count
      if (isValid && hasValue) {
        context.progress.complete += 1;
        context.progress.statusByPath[this.path] = 'complete';
      }

      return true;
    },
  );
}

declare module 'yup' {
  interface NumberSchema {
    /**
     * Extend Yup Number with custom functionality to prevent NaN values
     */
    transformNaNValue: () => NumberSchema;
  }
}

function preventNaNValue() {
  const number = this as NumberSchema;
  return number
    .transform((value, originalValue): number =>
      originalValue === '' ||
      Number.isNaN(value) ||
      typeof originalValue === 'string'
        ? null
        : value,
    )
    .nullable();
}

addMethod(number, 'transformNaNValue', preventNaNValue);

addMethod(mixed, 'progress', countProgress);

declare module 'yup' {
  interface Schema<T, C> {
    /**
     * Custom yup method for counting form progress for smart onboarding form fields
     * @param {boolean} isRequired - False: Field only counts for the progress if it has a valid value,
     * True: Field is always required for progress calculation.
     */
    progress: (isRequired: boolean) => Schema<T, C>;
  }
}

export type FormProgressQueryResult = {
  progress: null | FormProgress;
  loading: boolean;
  didError: boolean;
};

export const sumFormProgressQueryResult = (
  ...formProgressQueryResults: FormProgressQueryResult[]
): FormProgressQueryResult =>
  formProgressQueryResults.reduce<FormProgressQueryResult>(
    (acc, { progress, loading, didError }) => {
      // update progress
      acc.progress.total = acc.progress.total + progress.total;
      acc.progress.complete = acc.progress.complete + progress.complete;
      // update loading state
      acc.loading = acc.loading || loading;
      // update errors
      acc.didError = acc.didError || didError;
      return acc;
    },
    { progress: { total: 0, complete: 0 }, loading: false, didError: null },
  );

export const isComplete = (progress: FormProgress | FormProgressQueryResult) =>
  'total' in progress
    ? progress.total === progress.complete
    : progress.progress.total === progress.progress.complete;

export const percentComplete = (
  progress: FormProgress | FormProgressQueryResult,
) =>
  'total' in progress
    ? (progress.complete / progress.total) * 100
    : (progress.progress.complete / progress.progress.total) * 100;

export * from 'yup';
