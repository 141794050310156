import { makeStyles } from 'vendor/material';
import { fontWeightRegular, fontWeightBold } from 'utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  stepIndicator: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    flex: '1 1 0%',
  },
  indicatorLine: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    '&::before, &::after': {
      borderTop: `3px solid ${theme.palette.NEUTRAL040}`,
      display: 'inline-block',
      content: "''",
      flex: '1 1 100%',
      position: 'relative',
    },
  },
  indicatorLineFirst: {
    '&::before': {
      visibility: 'hidden',
    },
  },
  indicatorLineLast: {
    '&::after': {
      visibility: 'hidden',
    },
  },
  indicatorLineSelectedBlue: {
    '&::before': {
      borderColor: theme.palette.BLUE400,
    },
  },
  indicatorLineCompletedBlue: {
    '&::before, &::after': {
      borderColor: theme.palette.BLUE400,
    },
  },
  indicatorLineAllCompleted: {
    '&::before, &::after': {
      borderColor: '#00875A',
    },
  },
  indicatorLineCompletedCancelled: {
    '&::before, &::after': {
      borderColor: '#DFE1E6',
    },
  },
  indicatorLineActiveCancelled: {
    '&::before': {
      borderColor: '#DFE1E6',
    },
  },
  indicatorPoint: {
    flex: '0 0 auto',
    lineHeight: 0,
    padding: '.25rem 0',
    transition: 'opacity .15s ease-out',
  },
  iconCircle: {
    transform: 'rotate(0deg)',
    padding: '0em',
    borderRadius: '50%',
    borderColor: theme.palette.NEUTRAL040,
    borderStyle: 'solid',
    borderWidth: '3px',
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: fontWeightBold,
    color: theme.palette.NEUTRAL050,
  },
  iconCircleSelected: {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.background.paper,
    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.12)',
    '&::before': {
      content: "''",
      borderRadius: '50%',
      position: 'absolute',
      width: theme.typography.pxToRem(12),
      height: theme.typography.pxToRem(12),
    },
  },
  iconCircleSelectedBlue: {
    '&::before': {
      backgroundColor: theme.palette.BLUE400,
    },
  },
  iconCircleSelectedPurple: {
    '&::before': {
      backgroundColor: theme.palette.PURPLE400,
    },
  },
  indicatorItem: {
    paddingTop: theme.spacing(1.2),
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'center',
    wordWrap: 'break-word',
    maxWidth: 114,
  },
  iconCircleCompletedBlue: {
    backgroundColor: theme.palette.BLUE400,
    borderColor: theme.palette.BLUE400,
  },
  iconCircleCompletedPurple: {
    backgroundColor: theme.palette.PURPLE400,
    borderColor: theme.palette.PURPLE400,
  },
  iconCircleAllStepsCompleted: {
    backgroundColor: '#00875A',
    borderColor: '#00875A',
  },
  iconCircleCompletedCancelled: {
    backgroundColor: '#DFE1E6',
    borderColor: '#DFE1E6',
  },
  iconCircleActiveCancelled: {
    '&::before': {
      backgroundColor: '#DFE1E6',
    },
  },
}));

export default useStyles;
