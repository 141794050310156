import { useCanvasSession } from '../src/useCanvasSession';
import { ProspectAccountDataProvider } from './ProspectAccountDataProvider';
import ProspectComingSoon from './ProspectComingSoon';
import { ProspectComponentSelector } from './ProspectComponentSelector';
import { ScanLimitDataProvider } from './ScanLimitDataProvider';

/** main entry point for Prospect from CanvasApp */
const ProspectTabContent = () => {
  const session = useCanvasSession();
  const vendorId = session.vendorId;
  const accountId = session.canvasSession.context.parameters.account?.Id;
  const website = session.canvasSession.context.parameters.account?.Website;
  const token = session.canvasSession.access_token;

  if (!accountId) {
    return <ProspectComingSoon />;
  }
  return (
    <ProspectAccountDataProvider
      vendorId={vendorId}
      crmId={accountId}
      website={website}
      token={token}
    >
      <ScanLimitDataProvider vendorId={vendorId} token={token}>
        <ProspectComponentSelector />
      </ScanLimitDataProvider>
    </ProspectAccountDataProvider>
  );
};

export default ProspectTabContent;
