import { calculateDurationValue } from '../utils/utils';
import { FieldKey } from '../../formTypes';
import { FormValues } from '../../../formSchema';
import { FormikHelpers } from 'formik/dist/types';
import {
  BillingTerm,
  DurationType,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';

export const setDurationValue = (
  billingTerm: BillingTerm | null,
  billingTermCadence: DurationType | null,
  durationInCadence: number | null,
  serviceStartAt: string | null,
  serviceEndAt: string | null,
  setFieldValue: FormikHelpers<FormValues>['setFieldValue'],
) => {
  (async () => {
    const durationValue = calculateDurationValue({
      billingTerm,
      billingTermCadence,
      durationInCadence,
      serviceStartAt,
      serviceEndAt,
    });

    await setFieldValue(FieldKey.DurationValue, durationValue);
  })();
};
