import {
  FieldKey,
  FormValues,
} from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/EndUserLicenseAgreementForSection/formSchema';
import {
  EULAType,
  PrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';

export const initialFormValuesForNewOffer: FormValues = {
  [FieldKey.EulaType]: EULAType.Public,
  [FieldKey.EulaDocumentUrns]: [],
};

export const initialFormValuesForExistingOffer = (
  offer: PrivateOffer,
): FormValues => ({
  ...initialFormValuesForNewOffer,
  [FieldKey.EulaType]: offer.eula.type,
  [FieldKey.EulaDocumentUrns]: offer.eula.documentUrns,
});

export const initialFormValuesForCloneOffer = (
  offer: PrivateOffer,
): FormValues => initialFormValuesForExistingOffer(offer);

export const initialFormValuesForAmendOffer = (
  offer: PrivateOffer,
): FormValues => ({
  [FieldKey.EulaType]: EULAType.UseExisting,
  [FieldKey.EulaDocumentUrns]: offer.eula?.documentUrns || [],
});
