import { makeStyles } from 'vendor/material';

export const useSalesforceCoSellWidgetButtonGroupStyles = makeStyles(
  (theme) => ({
    editLockup: {
      display: 'flex',
      alignItems: 'stretch',
      gap: theme.spacing(1),
    },
    dashboardButtonElement: {
      flexGrow: 1,
      flexShrink: 0,
      color: theme.palette.NEUTRAL010,
      backgroundColor: theme.palette.BLUE400,
      '&:hover': {
        backgroundColor: theme.palette.BLUE500,
      },
      '&:disabled': {
        backgroundColor: theme.palette.BLUE200,
        color: theme.palette.BLUE050,
        pointer: 'none',
      },
      textTransform: 'none',
      padding: `7px ${theme.spacing(2)}`,
      fontSize: theme.typography.pxToRem(12),
      height: theme.typography.pxToRem(34),
      fontWeight: 700,
    },
    buttonElement: {
      '&:disabled': {
        backgroundColor: theme.palette.NEUTRAL020,
        pointer: 'none',
      },
      padding: `7px ${theme.spacing(2)}`,
      height: theme.typography.pxToRem(34),
      flexGrow: 1,
      flexShrink: 0,
      color: theme.palette.BLUE400,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 700,
      textTransform: 'none',
      borderColor: theme.palette.NEUTRAL040,
    },
  }),
);
