import * as yup from '../../../../../../../../../../utils/yup-extended';

export enum FieldKey {
  TotalContractValue = 'totalContractValue',
  DurationInMonths = 'durationInMonths',
  PaymentFrequency = 'paymentFrequency',
  DateOfFirstPayment = 'dateOfFirstPayment',
}

export enum PaymentFrequency {
  Monthly = 'monthly',
  Annually = 'annually',
  Quarterly = 'quarterly',
  SemiAnnually = 'semi-annually',
}

export interface FormValues {
  [FieldKey.TotalContractValue]: number | null;
  [FieldKey.DurationInMonths]: number | null;
  [FieldKey.PaymentFrequency]: string | null;
  [FieldKey.DateOfFirstPayment]: string | null;
}

const totalContractSchema = yup.number().min(0.01).nullable().required();
const durationInMonthsSchema = yup.number().min(1).nullable().required();

const paymentFrequencySchema = yup
  .string()
  .oneOf(Object.values(PaymentFrequency))
  .required();

export const formSchema = yup.object<FormValues>({
  [FieldKey.TotalContractValue]: totalContractSchema,
  [FieldKey.DurationInMonths]: durationInMonthsSchema,
  [FieldKey.PaymentFrequency]: paymentFrequencySchema,
  [FieldKey.DateOfFirstPayment]: yup.string().nullable().required(),
});
