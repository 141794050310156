import { Button } from '@tackle-io/platform-ui';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import { makeStyles } from 'vendor/material';
import useCoSellSfCanvasActions from 'packages/salesforce-canvas/cosell/src/hooks/useCoSellSfCanvasActions';

const useStyles = makeStyles((theme) => ({
  buttonElement: {
    padding: `7px ${theme.spacing(2)}`,
    height: theme.typography.pxToRem(34),
    flexGrow: 1,
    flexShrink: 0,
    color: theme.palette.BLUE400,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    textTransform: 'none',
    borderColor: '#C1C7D0',
  },
}));

type AvailableCoSellCloud = Extract<
  DisplayCloudType,
  DisplayCloudType.AWS | DisplayCloudType.MICROSOFT
>;

const labelMap = {
  [DisplayCloudType.AWS]: 'Add AWS co-sell',
  [DisplayCloudType.MICROSOFT]: 'Add Microsoft co-sell',
} as const;

// A button that will trigger a co-sell create action for the provided cloud
export const AddCoSellButton = ({ cloud }: { cloud: AvailableCoSellCloud }) => {
  const { buttonElement } = useStyles();
  const { handleCoSellAction } = useCoSellSfCanvasActions();

  return (
    <Button
      data-testid={`add-${cloud}-cosell-button`}
      className={buttonElement}
      fullWidth
      variant="outlined"
      onClick={() =>
        handleCoSellAction({
          id: 'create-cosell',
          cloud,
        })
      }
    >
      {labelMap[cloud]}
    </Button>
  );
};
