import { Box, Divider, makeStyles } from 'vendor/material';
import React from 'react';

interface SectionDividerProps {
  mt?: number;
  mb?: number;
}

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: theme.palette.NEUTRAL040,
  },
}));

const SectionDivider: React.FunctionComponent<SectionDividerProps> = ({
  mt = 0,
  mb = 2,
}) => {
  const classes = useStyles();

  return (
    <Box mt={mt} mb={mb}>
      <Divider className={classes.divider} />
    </Box>
  );
};

export default SectionDivider;
