const HandshakeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    style={{ marginRight: '8px' }}
  >
    <path
      d="M16.4801 10.4102C16.0901 10.8002 15.4401 10.8002 15.0501 10.4102L10.5801 5.95021L3.53005 12.9902L2.87005 12.3602C1.70005 11.1902 1.70005 9.29021 2.87005 8.12021L7.11005 3.88021C8.28005 2.71021 10.1801 2.71021 11.3501 3.88021L16.4801 9.00021C16.8701 9.39021 16.8701 10.0202 16.4801 10.4102ZM17.1801 8.29021C17.9601 9.07021 17.9601 10.3402 17.1801 11.1202C15.9101 12.3902 14.5701 11.3402 14.3501 11.1202L10.5901 7.36021L5.02005 12.9302C4.63005 13.3202 4.63005 13.9502 5.02005 14.3402C5.41005 14.7302 6.04005 14.7302 6.44005 14.3402L11.0601 9.72021L11.7701 10.4302L7.15005 15.0502C6.76005 15.4402 6.76005 16.0702 7.15005 16.4602C7.54005 16.8502 8.17005 16.8502 8.57005 16.4602L13.1901 11.8402L13.9001 12.5502L9.28005 17.1702C8.89005 17.5602 8.89005 18.1902 9.28005 18.5802C9.67005 18.9702 10.3001 18.9702 10.6901 18.5802L15.3101 13.9602L16.0201 14.6702L11.4001 19.2902C11.0101 19.6802 11.0101 20.3102 11.4001 20.7002C11.7901 21.0902 12.4201 21.0902 12.8101 20.7002L21.1301 12.3602C22.3001 11.1902 22.3001 9.29021 21.1301 8.12021L16.8901 3.88021C15.7401 2.73021 13.8801 2.71021 12.7101 3.82021L17.1801 8.29021Z"
      fill="#253858"
    />
  </svg>
);

export default HandshakeIcon;
