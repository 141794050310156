export enum FieldKeys {
  InvoiceDateType = 'invoiceDateType',
  InvoiceDate = 'invoiceDate',
  InvoiceAmount = 'invoiceAmount',
}

export interface FormSchedule {
  [FieldKeys.InvoiceDateType]: string | null;
  [FieldKeys.InvoiceDate]: string | null;
  [FieldKeys.InvoiceAmount]: number | null;
}

export const newSchedule: FormSchedule = {
  [FieldKeys.InvoiceDateType]: null,
  [FieldKeys.InvoiceDate]: null,
  [FieldKeys.InvoiceAmount]: 0,
};
