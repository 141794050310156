import {
  getMonthsDifferenceFromDateTimes,
  getUtcDateTimeFromSerializedIsoDateTime,
} from '../../../../../../generic/utils/date/dateUtils';
import { Optional } from 'utils/optional/optional';
import {
  BillingTerm,
  DurationType,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';

interface DurationValueParams {
  billingTerm: BillingTerm | null;
  billingTermCadence: DurationType | null;
  durationInCadence: number | null;
  serviceStartAt: string | null;
  serviceEndAt: string | null;
}

const shouldCalculateCustomDurationValue = ({
  billingTermCadence,
  durationInCadence,
}) => !!billingTermCadence && !!durationInCadence;

const shouldCalculateFutureDatedDurationValue = ({
  serviceStartAt,
  serviceEndAt,
}) => !!serviceStartAt && !!serviceEndAt;

const termToPredicate: {
  [bt: string]: (v: DurationValueParams) => boolean;
} = {
  [BillingTerm.Custom]: shouldCalculateCustomDurationValue,
  [BillingTerm.FutureDated]: shouldCalculateFutureDatedDurationValue,
};

const cadenceToMonthFactor = {
  [DurationType.Months]: 1,
  [DurationType.Quarterly]: 3,
  [DurationType.Annually]: 12,
};

const customTermDurationValueProducer = ({
  billingTermCadence,
  durationInCadence,
}): number => {
  const monthsFactor = cadenceToMonthFactor[billingTermCadence];

  return durationInCadence * monthsFactor;
};

const futureDatedTermDurationValueProducer = ({
  serviceStartAt,
  serviceEndAt,
}): number =>
  getMonthsDifferenceFromDateTimes(
    getUtcDateTimeFromSerializedIsoDateTime(serviceStartAt),
    getUtcDateTimeFromSerializedIsoDateTime(serviceEndAt),
  );

const termToProducer: {
  [bt: string]: (v: DurationValueParams) => number;
} = {
  [BillingTerm.Custom]: customTermDurationValueProducer,
  [BillingTerm.FutureDated]: futureDatedTermDurationValueProducer,
};

export const calculateDurationValue = (values: DurationValueParams) => {
  const { billingTerm } = values;

  return Optional.ofNullable(billingTerm)
    .filter((bt) => termToPredicate[bt](values))
    .map((bt) => termToProducer[bt](values))
    .orElse(0);
};
