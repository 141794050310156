import { FieldKey } from '../../formTypes';
import { FormUsageDimension } from '../UsageDimensionRow/formSchema';
import { toFinite } from 'lodash';
import { FormikHelpers } from 'formik';
import { FormValues } from '../../../formSchema';
import { AWSProductPricingUsageFee } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';

const toFormUsageDimensions = (
  usageFees: AWSProductPricingUsageFee[],
): FormUsageDimension[] =>
  usageFees.map((uf) => ({
    sku: uf.sku,
    description: uf.description,
    price: toFinite(uf.price),
  }));

export const setUsageDimensionsFromProductUsageFees = (
  usageFees: AWSProductPricingUsageFee[],
  setFieldValue: FormikHelpers<FormValues>['setFieldValue'],
) => {
  (async () => {
    const usageDimensions = toFormUsageDimensions(usageFees);

    await setFieldValue(FieldKey.UsageDimensions, usageDimensions);
  })();
};
