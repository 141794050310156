import { useQuery } from '@tanstack/react-query';
import { getFormFieldByAwsCoSellFieldName } from './getFormFieldByAwsCoSellFieldName';
import { getMappedFields } from 'packages/salesforce-canvas/api/requests/getMappedFields';
import {
  Cloud,
  Domain,
  Operation,
} from 'pages/Settings/Account/components/FieldMapper/utils/constants';
import { transformToAceInitialValues } from './transformToAceInitialValues';

/**
 * Query for fetching mapped fields for a given crmId (salesforce record id) and mappingId
 * or a default mapping for a given cloud, domain & operation. Each of them is required.
 * TODO: update the types to reflect the returned result based on cloud and domain.
 * the mappingId should be specific to the crmId.
 * if the mappingId is not provided, cloud, domain and operation parameters should be required
 */
export function useMappedFieldsQuery<
  D extends Domain,
  C extends Cloud,
  O extends Operation,
>({
  crmId,
  cloud,
  domain,
  operation,
  enabled = true,
  mappingId,
}: {
  crmId: string;
  cloud: C;
  domain: D;
  operation: O;
  mappingId?: string;
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: ['mapped-fields', crmId, mappingId, cloud, domain, operation],
    queryFn: () =>
      getMappedFields({
        crmId,
        mappingId,
        cloud,
        domain,
        operation,
      }),
    select: (data) => {
      if (cloud === Cloud.AWS && domain === Domain.CO_SELL) {
        return {
          fieldsWithErrors: data.errors
            ? Object.keys(data.errors)?.map((x) =>
                getFormFieldByAwsCoSellFieldName({ name: x, key: 'label' }),
              )
            : null,
          initialValues: transformToAceInitialValues(data.mappedFields),
        };
      }

      return {
        initialValues: null,
        fieldsWithErrors: null,
      };
    },
    staleTime: Infinity,
    enabled,
  });
}
