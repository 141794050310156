import React, { useState } from 'react';
import { Box, Typography, Card, Divider, Button } from 'vendor/material';
import { ChevronDown, ChevronRight } from 'mdi-material-ui';
import useStyles from './DetailsSectionCard.styles';

interface DetailsSectionCardProps {
  title: string;
  subTitle?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  showTurner?: boolean;
  defaultShowSteps?: boolean;
  dataId?: string;
}

const DetailsSectionCard: React.FC<DetailsSectionCardProps> = ({
  title,
  subTitle,
  icon,
  children,
  showTurner,
  defaultShowSteps = true,
  dataId,
}) => {
  const [showSteps, setShowSteps] = useState(defaultShowSteps);
  const classes = useStyles();

  const handleShowSteps = () => {
    setShowSteps(!showSteps);
  };

  return (
    <Card data-testid={title} data-id={dataId}>
      <Box className={classes.cardTitle} onClick={handleShowSteps}>
        <Box className={classes.sectionTitleWrapper}>
          <Box className={classes.mainTitleWrapper}>
            {icon && <Box mr={1}>{icon}</Box>}
            <Typography className={classes.mainTitleText}>{title}</Typography>
          </Box>
          {subTitle && (
            <Typography className={classes.subTitle}>{subTitle}</Typography>
          )}
        </Box>
        {showTurner && (
          <Button className={classes.chevronIcon}>
            {showSteps ? <ChevronDown /> : <ChevronRight />}
          </Button>
        )}
      </Box>

      <Divider />
      {showTurner ? (
        showSteps && <Box p={4}>{children} </Box>
      ) : (
        <Box p={4} pt={2}>
          {children}
        </Box>
      )}
    </Card>
  );
};

export default DetailsSectionCard;
