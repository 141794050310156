import React, { useEffect, useState, useCallback } from 'react';
import { Button } from 'vendor/material';
import integrationIconUrl from './../assets/integration_icon.png';
import { useCanvasTackleIntegrationStyles } from './CanvasTackleIntegration.styles';
import { Card } from '@tackle-io/platform-ui';
import { Box } from 'vendor/material';
import { TransitConnectionVariant } from 'mdi-material-ui';

export interface SalesforceCrmConnectorResponse {
  vendorId: string;
  orgId: string;
  instanceUrl: string;
  namespace: string;
  createdAt: string;
}

const PACKAGE_NAMESPACE_PARAM = 'namespace';

export default function CanvasTackleIntegrationStatus() {
  const [isPendingConnection, setIsPendingConnection] = useState(false);
  const [salesforceLoginPopup, setSalesforceLoginPopup] =
    useState<Window | null>(null);
  const [popupMessageResponse, setPopupMessageResponse] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  const packageNamespace = urlParams.get(PACKAGE_NAMESPACE_PARAM).toString();

  const classes = useCanvasTackleIntegrationStyles();

  const getOAuthLink = document.referrer + '/services/oauth2/authorize';

  // Open a pop up window kicking off Salesforce OAuth flow
  const openSalesforceOAuthPopUp = () => {
    const queryParams = new URLSearchParams({
      client_id: process.env.REACT_APP_CRM_CONNECTOR_SALESFORCE_CLIENT_ID,
      redirect_uri:
        process.env.REACT_APP_CRM_CONNECTOR_SALESFORCE_REGISTER_REDIRECT_URL,
      response_type: 'code',
      state: JSON.stringify({ packageNamespace, loginUrl: document.referrer }),
    });

    const width = 500;
    const height = 500;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 1.5;
    const windowFeatures = `left=${left},top=${top},width=${width},height=${height}`;

    const popup = window.open(
      getOAuthLink + '?' + queryParams.toString(),
      'popup',
      windowFeatures,
    );

    setSalesforceLoginPopup(popup);
    setIsPendingConnection(true);
  };

  // Check if window is closed every second. If closed, reset the state of the component. Without this, if user closes pop up, the page will still show a loading indicator
  useEffect(() => {
    if (!salesforceLoginPopup) return;

    const timer = setInterval(() => {
      if (salesforceLoginPopup.closed) {
        clearInterval(timer);
        setIsPendingConnection(false);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [salesforceLoginPopup]);

  const sendMessage = useCallback(
    (message: string | undefined) => {
      if (message) {
        const jsonMessage: SalesforceCrmConnectorResponse = JSON.parse(message);
        setPopupMessageResponse(jsonMessage);
      }
      if (salesforceLoginPopup) {
        salesforceLoginPopup.close();
      }
    },
    [salesforceLoginPopup],
  );

  // Assign the sendMessage function to window object for use in the popup this component creates
  useEffect(() => {
    if (window) {
      // @ts-ignore
      window.sendMessage = sendMessage;
    }
  }, [sendMessage]);

  return (
    <div className={classes.integrationWindow}>
      <Card
        icon={<TransitConnectionVariant />}
        title="Setup your integration"
        subtitle="Set up the Tackle app and sync your Marketplace, Co-Sell, and Prospect data. Creating an integration user is also recommended, but it is not required."
      >
        <Box py={2.5} px={3}>
          <img
            src={integrationIconUrl}
            alt="Integration icon"
            className={classes.integrationIcon}
          />
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            disabled={isPendingConnection}
            onClick={openSalesforceOAuthPopUp}
          >
            {isPendingConnection
              ? 'Pending connection to Tackle...'
              : 'Connect to Tackle'}
          </Button>
          <br />
          {popupMessageResponse &&
            `Popup Message Response: ${popupMessageResponse}`}
        </Box>
      </Card>
    </div>
  );
}
