import { authApi } from '../tackleApiClient';
import { reportError } from 'utils/monitor';

interface WhoamiData {
  $schema?: string;
  type?: string;
  auth_provider?: string;
  otkuser?: {
    id?: string;
    email?: string;
    given_name?: string;
    family_name?: string;
  };
  context?: {
    access?: {
      allowedVendors?: string[];
      permissions?: string[];
    };
    request_id?: string;
    user_agent?: string;
    vendorid?: string;
    vendor_type?: string;
    organization_id?: string;
    app_label?: string;
    custom_claims?: Record<string, unknown>;
  };
}

/** fetches data for current user via whoami endpoint of auth service */
export const getUserData = async (accessToken?: string) => {
  try {
    const result: Promise<WhoamiData> = authApi(accessToken)
      .get('whoami')
      .json();
    return result;
  } catch (error) {
    reportError(error);
    throw error;
  }
};
