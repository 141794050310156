import { Loader } from '@tackle-io/platform-ui';
import { useCanvasSession } from './useCanvasSession';
import { useCanvasAppStyles } from './CanvasApp.styles';
import { AwsOpportunityActionsProvider } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/AwsOpportunityActionsProvider';
import CanvasTackleIntegrationStatus from './components/CanvasTackleIntegrationStatus';
import TackleSidebarWidgetForSalesforce from './components/TackleSidebarWidgetForSalesforce';
import ErrorBoundary from 'components/ErrorBoundary';
import CoSellCanvasApp from 'packages/salesforce-canvas/cosell/src/components/CoSellCanvasApp';
import OffersCanvasApp from 'packages/salesforce-canvas/offers/OffersCanvasApp';
import Error from 'pages/Error/Error';

const AuthorizedCanvasApp = () => {
  const { canvasSession, payload } = useCanvasSession();
  const componentId = canvasSession?.context?.parameters?.componentId;
  const domain = payload?.domain;

  if (componentId === 'Setup') {
    return <CanvasTackleIntegrationStatus />;
  }
  if (componentId === 'TackleWidget') {
    return <TackleSidebarWidgetForSalesforce />;
  }
  if (componentId === 'CanvasApp' && domain === 'cosell') {
    return <CoSellCanvasApp />;
  }
  if (componentId === 'CanvasApp' && domain === 'offers') {
    return <OffersCanvasApp />;
  }
  return (
    <div>
      No component found for {componentId} with domain of {domain}.
    </div>
  );
};

const CanvasApp = () => {
  const { canvasSession, isAuthorized, isLoadingCanvasSession } =
    useCanvasSession();
  const classes = useCanvasAppStyles();

  if (isLoadingCanvasSession) {
    return <Loader />;
  }

  if (!isAuthorized) {
    return <CanvasTackleIntegrationStatus />;
  }

  const componentId = canvasSession?.context?.parameters.componentId;

  if (!componentId) {
    return <div>No context provided.</div>;
  }

  return (
    <ErrorBoundary renderError={Error}>
      <AwsOpportunityActionsProvider token={canvasSession.access_token}>
        <div className={classes.canvasAppContainer}>
          <AuthorizedCanvasApp />
        </div>
      </AwsOpportunityActionsProvider>
    </ErrorBoundary>
  );
};

export default CanvasApp;
