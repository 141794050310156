import { FormSections, formSectionToTitle } from '../formSections';
import { Card, Checkbox, TextField } from '@tackle-io/platform-ui';
import { Link } from 'mdi-material-ui';
import { Grid } from 'vendor/material';
import { Field, useFormikContext } from 'formik';
import { DataId } from '../../../../generic/analytics';
import { FieldKey } from './formSchema';
import { FormValues } from '../formSchema';
import { useState } from 'react';

const fieldKeyToLabel: { [fk: string]: string } = {
  [FieldKey.OfferId]: 'Offer ID',
  [FieldKey.SourceOfferId]: 'Source Offer ID',
  [FieldKey.isAmended]: 'Are you amending a previous offer?',
};

const sourceOfferFieldHelperText =
  'If this is an amendment offer, enter the ID of the original offer here. This value must be unique between other offers';

const DirectOfferLinkOfferFormSection = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const [isAmededCheckbockChecked, setIsAmendedCheckboxChecked] =
    useState<boolean>(false);

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (isAmededCheckbockChecked) {
      setFieldValue(FieldKey.SourceOfferId, '');
      setFieldValue(FieldKey.isAmended, false);
      setIsAmendedCheckboxChecked(false);
    }
    if (!isAmededCheckbockChecked) {
      setFieldValue(FieldKey.isAmended, true);
      setIsAmendedCheckboxChecked(true);
    }
  };

  return (
    <div id={FormSections.LinkOffer}>
      <Card
        title={formSectionToTitle[FormSections.LinkOffer]}
        subtitle="Enter the Offer ID to track the progress here in Tackle."
        icon={<Link />}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <Field name={FieldKey.OfferId}>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  label={`${fieldKeyToLabel[FieldKey.OfferId]} *`}
                  error={meta.touched && !!meta.error}
                  helperText="Unique identifier assigned by the marketplace for each offer"
                  data-id={DataId.OfferIdField}
                  required
                />
              )}
            </Field>
          </Grid>
          <Grid item xs>
            <Field name={FieldKey.isAmended}>
              {({ field, meta }) => (
                <Checkbox
                  {...field}
                  checked={!!values.isAmended}
                  label={fieldKeyToLabel[FieldKey.isAmended]}
                  error={meta.touched && !!meta.error}
                  data-id={DataId.IsAmendedCheckBox}
                  onChange={(e): void => {
                    handleCheckboxChange(e);
                  }}
                />
              )}
            </Field>
          </Grid>
          {!!values.isAmended && (
            <Grid item xs>
              <Field name={FieldKey.SourceOfferId}>
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    label={fieldKeyToLabel[FieldKey.SourceOfferId]}
                    error={meta.touched && !!meta.error}
                    helperText={sourceOfferFieldHelperText}
                    data-id={DataId.SourceOfferIdField}
                  />
                )}
              </Field>
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
};

export default DirectOfferLinkOfferFormSection;
