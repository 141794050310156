import React from 'react';
import { Divider, Grid, useMediaQuery, useTheme } from 'vendor/material';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import InfoItem, {
  FULL_GRID_SIZE,
  HALF_GRID_SIZE,
} from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
import { CustomerAccount } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { coSellAceOpportunityFormFieldsDataId } from 'packages/cosell/src/utilities/intercomEnums';
import { OpportunityIndustryEnum } from 'packages/cosell/src/types/enums';

interface AceCustomerDetailsProps {
  account: CustomerAccount;
}
const AceCustomerDetails: React.FC<AceCustomerDetailsProps> = ({ account }) => {
  const isIndustryOther = account?.industry === OpportunityIndustryEnum.OTHER;
  const theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down(960));

  return (
    <DetailsSectionCard title="Customer details" icon={<OfficeBuilding />}>
      <Grid container spacing={2}>
        <InfoItem
          itemTitle="Customer DUNS"
          value={account?.duns}
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_DUNS_NUMBER}
        />
        {screenMobile && <Divider style={{ width: '100%' }} />}
        <InfoItem
          itemTitle="Customer company name"
          value={account?.companyName}
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_COMPANY_NAME}
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="Industry vertical"
          value={account?.industry}
          dataId={coSellAceOpportunityFormFieldsDataId.CITY}
        />
        {screenMobile && <Divider style={{ width: '100%' }} />}
        {isIndustryOther && (
          <InfoItem
            itemTitle="Industry other"
            value={account?.otherIndustry}
            dataId={coSellAceOpportunityFormFieldsDataId.INDUSTRY_OTHER}
          />
        )}
        {isIndustryOther && <Divider style={{ width: '100%' }} />}
        <InfoItem
          itemTitle="Customer website"
          value={account?.websiteUrl}
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_WEBSITE}
        />
        {!isIndustryOther && <Divider style={{ width: '100%' }} />}

        <InfoItem
          itemTitle="Country"
          value={account?.address?.countryCode}
          dataId={coSellAceOpportunityFormFieldsDataId.COUNTRY_CODE}
        />
        {screenMobile && <Divider style={{ width: '100%' }} />}

        <InfoItem
          itemTitle="Address"
          value={account?.address?.streetAddress}
          dataId={coSellAceOpportunityFormFieldsDataId.ADDRESS}
        />
        <Divider style={{ width: '100%' }} />

        <InfoItem
          itemTitle="City"
          value={account?.address?.city}
          dataId={coSellAceOpportunityFormFieldsDataId.CITY}
        />
        {screenMobile && <Divider style={{ width: '100%' }} />}

        <InfoItem
          itemTitle="State"
          value={account?.address?.stateOrRegion}
          dataId={coSellAceOpportunityFormFieldsDataId.STATE_PROVINCE}
        />
        <Divider style={{ width: '100%' }} />

        <InfoItem
          itemTitle="Postal code"
          value={account?.address?.postalCode}
          dataId={coSellAceOpportunityFormFieldsDataId.POSTAL_CODE}
          gridSize={isIndustryOther ? HALF_GRID_SIZE : FULL_GRID_SIZE}
        />
        <Divider style={{ width: '100%' }} />
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceCustomerDetails;
