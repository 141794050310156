import NotAvailableState, {
  NotAvailableReasonEnum,
} from 'components/NotAvailableState/NotAvailableState';
import { useRbac, Permission } from 'utils/rbac';
import { Loader } from '@tackle-io/platform-ui';
interface RoleRestrictedPageProps {
  permission: Permission;
  pageFeatureName?: string;
  children: React.ReactElement;
}

export const PermissionProtectedPage = ({
  permission,
  pageFeatureName,
  children,
}: RoleRestrictedPageProps) => {
  const { hasPermission, loading } = useRbac();
  if (loading) return <Loader />;
  if (!hasPermission(permission)) {
    return (
      <NotAvailableState
        feature={pageFeatureName}
        reason={NotAvailableReasonEnum.PERMISSION}
      />
    );
  }
  return <>{children}</>;
};
