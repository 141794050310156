import { Banner, Link } from '@tackle-io/platform-ui';
import React, { useContext, useEffect } from 'react';
import { Box } from 'vendor/material';
import OfferFormSection from '../../../../../generic/OfferFormSection/OfferFormSection';
import CurrencySelector from './CurrencySelector';
import { useFormikContext } from 'formik';
import { FormValues } from '../../formSchema';
import { setCurrencyCodeToUSDIfProductHasUsageFees } from './effects/effects';
import { AWSProduct } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';

const CurrencyConfig: React.FunctionComponent = () => {
  const {
    values: { productRef },
    setFieldValue,
  } = useFormikContext<FormValues>();
  const { productsByProductId } = useContext(OfferPageContext);

  useEffect(() => {
    (async () => {
      await setCurrencyCodeToUSDIfProductHasUsageFees(
        productRef,
        productsByProductId as { [pId: string]: AWSProduct },
        setFieldValue,
      );
    })();
  }, [productRef, productsByProductId, setFieldValue]);

  return (
    <Box mb={3}>
      <OfferFormSection
        title="Currency"
        subtitle="Select the currency negotiated with the buyer. You will receive disbursements in the selected currency."
      >
        <Box>
          <Box mb={2}>
            <Banner
              type="info"
              borderPosition="top"
              title={
                <>
                  AWS supports foreign currency only for contract-based pricing
                  models. To enable,{' '}
                  <Link
                    to="https://docs.tackle.io/articles/#!tackle-docs/aws-private-offers-in-foreign-currency"
                    external
                  >
                    set up bank disbursement preferences in the AWS marketplace.
                  </Link>
                </>
              }
            />
          </Box>
          <Box>
            <CurrencySelector />
          </Box>
        </Box>
      </OfferFormSection>
    </Box>
  );
};

export default CurrencyConfig;
