import { Box, Grid } from 'vendor/material';
import OfferFormSection from 'pages/PrivateOffers/pages/Next/generic/OfferFormSection/OfferFormSection';
import React, { useContext, useEffect, useMemo } from 'react';
import { FieldKey } from '../formTypes';
import { FieldArray, useFormikContext } from 'formik';
import UsageDimensionRow from './UsageDimensionRow/UsageDimensionRow';
import { FormValues } from '../../formSchema';
import { findMatchingAwsProduct } from '../utils/productUtils';
import { setUsageDimensionsFromProductUsageFees } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/ProductAndPricingFormSection/UsageDimensionsConfig/effects/effects';
import { AWSProduct } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';

const UsageDimensionsConfig: React.FunctionComponent = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { productRef, usageDimensions } = values;
  const { productsByProductId } = useContext(OfferPageContext);

  const matchingProduct = findMatchingAwsProduct(
    productRef,
    productsByProductId as { [pId: string]: AWSProduct },
  );

  const productUsageFees = useMemo(
    () => matchingProduct?.pricing?.usageFees || [],
    [matchingProduct],
  );

  const usageDimensionSkus = useMemo(
    () => new Set<string>(usageDimensions.map((ud) => ud.sku)),
    [usageDimensions],
  );

  useEffect(() => {
    const usageDimensionSkusMatchProduct = productUsageFees.every((uf) =>
      usageDimensionSkus.has(uf.sku),
    );

    if (usageDimensionSkusMatchProduct) {
      return;
    }

    setUsageDimensionsFromProductUsageFees(productUsageFees, setFieldValue);
  }, [productUsageFees, usageDimensionSkus, setFieldValue]);

  return productUsageFees.length === 0 ? null : (
    <OfferFormSection title="Usage dimensions">
      <Box>
        <FieldArray name={FieldKey.UsageDimensions}>
          {() => (
            <Grid container spacing={2}>
              {values.usageDimensions.map((_, i) => (
                <Grid item md={12} key={`usage-dimension-${i}`}>
                  <UsageDimensionRow index={i} />
                </Grid>
              ))}
            </Grid>
          )}
        </FieldArray>
      </Box>
    </OfferFormSection>
  );
};

export default UsageDimensionsConfig;
