import { DateTime } from 'luxon';
import { startOfTodayAsUtcDatetime } from '../../../../generic/utils/date/dateUtils';

export const maxFutureDatedStartDate = (): DateTime => {
  const today = startOfTodayAsUtcDatetime();

  return today.plus({ years: 5 });
};

export const minFutureDatedEndDate = (): DateTime => {
  const today = startOfTodayAsUtcDatetime();

  return today.plus({ days: 1 });
};
