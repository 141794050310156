import { Banner } from '@tackle-io/platform-ui';
import { Box, makeStyles, Typography } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    display: 'flex',
    width: '100%',
    padding: '16px',
  },
}));

const ScanLimitReachedBanner: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.bannerContainer}>
      <Banner
        borderPosition="top"
        title={'Scan limit reached'}
        isCollapsible={true}
        type="warning"
        defaultOpen={true}
        body={
          <Typography>
            The maximum number of accounts that can be scored for your Tackle
            account has been reached. Contact your Tackle account admin, or
            check your account subscription in Tackle for details.{' '}
            <a
              href="https://docs.tackle.io/articles/#!tackle-docs/your-tackle-subscription"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </Typography>
        }
      />
    </Box>
  );
};

export default ScanLimitReachedBanner;
