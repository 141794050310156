import { Alert, Button, ProviderIcon, Tag } from '@tackle-io/platform-ui';
import { DateTime } from 'luxon';
import { Product } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import { findMatchingProduct } from 'pages/PrivateOffers/pages/Next/generic/utils/product/productUtils';
import { getOfferCollectionItemStatus } from 'pages/PrivateOffers/utils';
import {
  ActivitySlug,
  PaymentModel,
  PrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { Typography } from 'ui';
import {
  makeStyles,
  Grid,
  Box,
  Menu,
  MenuItem,
  Snackbar,
  Slide,
} from 'vendor/material';
import { DotsVertical } from 'mdi-material-ui';
import { useCallback, useContext, useEffect, useState } from 'react';
import { OfferType } from 'stores/privateOffers/typings';
import useOffersCanvasActions from './useOffersCanvasActions';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContextProvider/apiContext';
import { getLatestOfferActivitySlug } from 'pages/PrivateOffers/pages/Next/generic/utils/offer/activityUtils';
import { getUsageDuration } from 'pages/PrivateOffers/pages/Next/generic/utils/duration/durationUtil';

const startPollingSlugs = new Set<ActivitySlug>([
  ActivitySlug.VendorCreatedMarketplaceOfferPending,
  ActivitySlug.VendorChangedMarketplaceOfferExpirationPending,
  ActivitySlug.VendorCancelledMarketplaceOfferPending,
]);

const stopPollingSlugs = new Set<ActivitySlug>([
  ActivitySlug.VendorCreatedMarketplaceOfferSuccess,
  ActivitySlug.VendorCreatedMarketplaceOfferFailed,
  ActivitySlug.VendorSentPurchaseInstructions,
  ActivitySlug.VendorChangedMarketplaceOfferExpirationSuccess,
  ActivitySlug.VendorChangedMarketplaceOfferExpirationFailed,
  ActivitySlug.VendorCancelledMarketplaceOfferSuccess,
  ActivitySlug.VendorCancelledMarketplaceOfferFailed,
]);

const MarketplaceActionPendingPollingInterval = 7500;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  createdBanner: {
    backgroundColor: theme.palette.NEUTRAL020,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
  },
  value: {
    textTransform: 'capitalize',
  },
  menuPaper: {
    width: 160,
    maxWidth: 160,
  },
}));

const detemrineTerms = (offer: PrivateOffer): string => {
  if (offer?.pricing?.paymentModel === PaymentModel.PayGo) {
    return `${getUsageDuration(offer.pricing, offer.acceptedAt)} days`;
  }

  return `${offer?.pricing?.duration} ${offer?.pricing?.durationType}`;
};

const LabelValue = ({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode | undefined;
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography variant="body1" style={{ fontFamily: 'Open Sans' }}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          className={classes.value}
          component="div"
          style={{ fontFamily: 'Open Sans' }}
        >
          {value ?? '--'}
        </Typography>
      </Grid>
    </Grid>
  );
};

const formatDate = (iso: string): string | undefined => {
  if (!iso) return undefined;
  const date = DateTime.fromISO(iso);
  if (!date.isValid) return undefined;
  return date.toLocaleString(DateTime.DATE_SHORT);
};

function TransitionRight(props) {
  return <Slide {...props} direction="left" />;
}
const SendEmailButton = ({ awsOffer }: { awsOffer: PrivateOffer }) => {
  const { api } = useContext(ApiContext);
  const [loading, setLoading] = useState(false);
  const [didSendEmail, setDidSendEmail] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  let text = 'Send email';
  if (error) {
    text = 'Failed';
  } else if (awsOffer.sentAt) {
    text = 'Resend';
  } else if (didSendEmail) {
    text = 'Resend'; // since we are not currently refetching the offer
  }
  return (
    <>
      <Button
        fullWidth
        appearance="primary"
        onClick={async () => {
          try {
            setLoading(true);
            await api.sendBuyerInstructions(awsOffer.poId);
            setDidSendEmail(true);
            setShowAlert(true);
          } catch (error) {
            setError(error);
          } finally {
            setLoading(false);
          }
        }}
        loading={loading}
        disabled={
          awsOffer.offerType === OfferType.PartnerResale || !!error || loading
        }
      >
        {text}
      </Button>
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        TransitionComponent={TransitionRight}
      >
        <div
        // we unfortunately need something here that can hold a ref for slide to work, and Alert does not cut it
        >
          <Alert
            appearance="success"
            title="Email invite successfully was sent to your buyer"
          />
        </div>
      </Snackbar>
    </>
  );
};

const AWSOfferListViewItem = ({
  awsOffer,
  products,
}: {
  awsOffer: PrivateOffer;
  products: Product[];
}) => {
  const classes = useStyles();
  const { navigate } = useOffersCanvasActions();

  const currencyFormatter = new Intl.NumberFormat(
    undefined, // this should use the default locale
    {
      style: 'currency',
      currency: awsOffer?.pricing?.currencyCode || 'USD',
    },
  );

  const productsByProductId = products.reduce(
    (acc, p) => ({
      ...acc,
      [p.productid]: p,
    }),
    {},
  );

  const product = findMatchingProduct(awsOffer.productId, productsByProductId);

  const offerCollectionItemStatus = getOfferCollectionItemStatus(
    // @ts-ignore TODO fix in MKTPLC-687
    awsOffer.activities,
    awsOffer.offerExpirationAt,
    true,
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleEdit = () =>
    navigate({
      action: 'edit',
      domain: 'offers',
      cloud: 'aws',
      poId: awsOffer.poId,
    });
  const handleView = () =>
    navigate({
      action: 'view',
      domain: 'offers',
      cloud: 'aws',
      poId: awsOffer.poId,
    });
  const handleClone = () =>
    navigate({
      action: 'clone',
      domain: 'offers',
      cloud: 'aws',
      poId: awsOffer.poId,
    });
  // TODO
  const handleArchive = () => console.log('archive not yet implemented');
  // const handleViewContract = () =>
  //   console.log('View contract not yet implemented');
  // const handleDownloadContract = () =>
  //   console.log('Download contract not yet implemented');

  const handleCancel = () => console.log('Cancel not yet implemented');

  const {
    api: { getOfferSilently, updateOffer },
    offerSubmissionError,
  } = useContext(ApiContext);

  const latestActivitySlug = getLatestOfferActivitySlug(awsOffer);

  const [isPolling, setIsPolling] = useState<boolean>(
    startPollingSlugs.has(latestActivitySlug),
  );
  const [showSubmit, setShowSubmit] = useState<boolean>(true);
  const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(false);

  useEffect(() => {
    if (!isPolling) return;

    if (offerSubmissionError) {
      setIsPolling(false);
      return;
    }

    const pollingInterval = setInterval(async () => {
      const updatedOffer = await getOfferSilently(awsOffer.poId);
      const latestActivity = getLatestOfferActivitySlug(updatedOffer);
      if (stopPollingSlugs.has(latestActivity)) {
        setIsPolling(false);
        setShowSubmit(false);
        setShowSuccessBanner(true);
        clearInterval(pollingInterval);
      }
    }, MarketplaceActionPendingPollingInterval);

    return () => clearInterval(pollingInterval);
  }, [isPolling, awsOffer, getOfferSilently, offerSubmissionError]);

  const handleSubmitToCloud = useCallback(async () => {
    setIsPolling(true);
    await updateOffer(awsOffer.poId, awsOffer, true);

    const updatedOffer = await getOfferSilently(awsOffer.poId);
    const latestActivity = getLatestOfferActivitySlug(updatedOffer);
    if (stopPollingSlugs.has(latestActivity)) {
      setIsPolling(false);
    }
  }, [updateOffer, getOfferSilently, awsOffer]);

  return (
    <div>
      <Box className={classes.createdBanner}>
        <Typography variant="body2" style={{ fontFamily: 'Open Sans' }}>
          <strong>Created {formatDate(awsOffer.createdAt)}</strong>
        </Typography>
      </Box>
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12}>
          <LabelValue
            label="Offer name"
            value={
              <Grid container spacing={1}>
                <Grid item>
                  <ProviderIcon provider="aws" fontSize="small" />
                </Grid>
                <Grid item style={{ fontFamily: 'Open Sans' }}>
                  {awsOffer.offerName}
                </Grid>
              </Grid>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <LabelValue label="Product" value={product?.name} />
        </Grid>
        <Grid item xs={6} sm={4}>
          <LabelValue
            label="Status"
            value={
              <Tag color={offerCollectionItemStatus.statusColorName}>
                {offerCollectionItemStatus.text}
              </Tag>
            }
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <LabelValue label="Type" value={awsOffer.offerType} />
        </Grid>
        <Grid item xs={6} sm={4}>
          <LabelValue
            label="Last updated"
            value={formatDate(awsOffer.lastModifiedAt)}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <LabelValue
            label="Expires on"
            value={formatDate(awsOffer.offerExpirationAt)}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <LabelValue label="Terms" value={detemrineTerms(awsOffer)} />
        </Grid>
        <Grid item xs={6} sm={4}>
          <LabelValue
            label="Gross"
            value={
              awsOffer?.pricing?.totalContractValue &&
              currencyFormatter.format(
                parseFloat(awsOffer.pricing.totalContractValue),
              )
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {awsOffer.cancelledAt ? (
              <>
                {/* canceled */}
                <Grid item xs>
                  <Button
                    fullWidth
                    variant="outlined"
                    appearance="primary"
                    onClick={handleView}
                  >
                    View offer
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    fullWidth
                    appearance="primary"
                    onClick={handleArchive}
                  >
                    Archive offer
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    variant="outlined"
                    appearance="primary"
                    startIcon={<DotsVertical />}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    classes={{
                      paper: classes.menuPaper,
                    }}
                  >
                    <MenuItem onClick={handleClone}>Clone</MenuItem>
                  </Menu>
                </Grid>
              </>
            ) : awsOffer.acceptedAt ? (
              <>
                {/* accepted */}
                <Grid item xs>
                  <Button
                    fullWidth
                    variant="outlined"
                    appearance="primary"
                    onClick={handleView}
                  >
                    View offer
                  </Button>
                </Grid>
                {/* <Grid item xs>
                  <Button
                    fullWidth
                    appearance="primary"
                    onClick={handleViewContract}
                    disabled
                  >
                    View contract
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    variant="outlined"
                    appearance="primary"
                    startIcon={<Download />}
                    onClick={handleDownloadContract}
                    disabled
                  />
                </Grid> */}
              </>
            ) : awsOffer.createdInMarketplaceAt || !showSubmit ? (
              <>
                {/* created */}
                <Grid item xs>
                  <SendEmailButton awsOffer={awsOffer} />
                </Grid>
                <Grid item xs>
                  <Button
                    fullWidth
                    appearance="destructive"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    variant="outlined"
                    appearance="primary"
                    startIcon={<DotsVertical />}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    classes={{
                      paper: classes.menuPaper,
                    }}
                  >
                    <MenuItem onClick={handleArchive}>Archive</MenuItem>
                    <MenuItem onClick={handleClone}>Clone</MenuItem>
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                    <MenuItem onClick={handleView}>View</MenuItem>
                  </Menu>
                </Grid>
              </>
            ) : (
              <>
                {/* draft, error */}
                <Grid item>
                  <Button
                    fullWidth
                    appearance="primary"
                    variant="outlined"
                    disabled={isPolling}
                    onClick={handleEdit}
                  >
                    Edit offer
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    fullWidth
                    appearance="primary"
                    disabled={isPolling || !!offerSubmissionError}
                    onClick={handleSubmitToCloud}
                    loading={isPolling}
                  >
                    {offerSubmissionError
                      ? 'Submission failed'
                      : 'Submit to cloud'}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Snackbar
          open={showSuccessBanner}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
          onClose={() => setShowSuccessBanner(false)}
          TransitionComponent={TransitionRight}
        >
          <div
          // we unfortunately need something here that can hold a ref for slide to work, and Alert does not cut it
          >
            <Alert
              appearance="success"
              title="Offer was created in the marketplace. Send email invite to your buyer."
            />
          </div>
        </Snackbar>
      </Grid>
    </div>
  );
};

export default AWSOfferListViewItem;
