import { createContext } from 'react';
import { Agreement } from '../../../generic/api/types/Agreement';

export interface BuyerAgreementsContextValues {
  buyerAccountNumber: string | null;
  setBuyerAccountNumber: (buyerAccountNumber: string | null) => void;
  buyerAccountInvalid: boolean;
  agreementsForBuyer: Agreement[];
  loadingAgreementsForBuyer: boolean;
  agreementsSidePanelOpen: boolean;
  setAgreementsSidePanelOpen: (v: boolean) => void;
}

const BuyerAgreementsContext = createContext<BuyerAgreementsContextValues>({
  buyerAccountNumber: null,
  setBuyerAccountNumber: (_: string | null) => {},
  buyerAccountInvalid: false,
  agreementsForBuyer: [],
  loadingAgreementsForBuyer: false,
  agreementsSidePanelOpen: false,
  setAgreementsSidePanelOpen: (_: boolean) => {},
});

export default BuyerAgreementsContext;
