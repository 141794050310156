import * as yup from '../../../../../../../../utils/yup-extended';

export enum FieldKey {
  RegistrationPageFields = 'registrationPageFields',
  Title = 'title',
  DisplayTitle = 'displayTitle',
  DataType = 'dataType',
  Enabled = 'enabled',
  TackleRequired = 'tackleRequired',
  FieldValue = 'fieldValue',
}

export type RegistrationPageField = {
  [FieldKey.Title]: string;
  [FieldKey.DisplayTitle]: string;
  [FieldKey.DataType]:
    | 'text'
    | 'dropdown'
    | 'number'
    | 'radio'
    | 'checkbox'
    | 'boolean'
    | 'true/false'
    | 'aws-region';
  [FieldKey.TackleRequired]: boolean;
  [FieldKey.Enabled]: boolean;
  [FieldKey.FieldValue]: string;
};

export type FormValues = {
  [FieldKey.RegistrationPageFields]: RegistrationPageField[];
};

export const formSchema: yup.ObjectSchema<FormValues> = yup.object({
  [FieldKey.RegistrationPageFields]: yup.array().of(
    yup.object({
      [FieldKey.Title]: yup.string(),
      [FieldKey.DisplayTitle]: yup.string(),
      [FieldKey.DataType]: yup.string(),
      [FieldKey.Enabled]: yup.boolean(),
      [FieldKey.TackleRequired]: yup.boolean(),
      [FieldKey.FieldValue]: yup.string().progress(true),
    }),
  ),
});
