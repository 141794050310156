import { Banner, Button } from '@tackle-io/platform-ui';
import { Domain } from 'pages/Settings/Account/components/FieldMapper/utils/constants';

const contentMap = {
  [Domain.CO_SELL]: {
    message:
      'Co-sell fields could not be populated from the opportunity, try loading the data again.',
  },
  [Domain.OFFER]: {
    message:
      'Offer fields could not be populated from the opportunity, try loading the data again.',
  },
} as const;

const title = 'An error occurred loading the mapped fields';

/** Banner to display if the data mappings failed to load
 *  informing user of error and providing a button to refetch
 */
export const DataMapperLoadingErrorBanner = ({
  domain,
  isRefetching = false,
  refetch,
}: {
  /** domain determines the message displayed */
  domain: keyof typeof contentMap;
  isRefetching?: boolean;
  refetch: () => void;
}) => {
  return (
    <Banner
      body={
        <>
          {contentMap[domain].message}
          <Button
            disabled={isRefetching}
            onClick={refetch}
            variant="text"
            appearance="primary"
          >
            {`Retry${isRefetching ? 'ing' : ''}`}
          </Button>
        </>
      }
      borderPosition="top"
      isCollapsible
      title={title}
      type="warning"
    />
  );
};
