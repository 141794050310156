export const OffersProductArea = 'offers-new';

export enum PageLocation {
  OffersListPage = 'offers-list-page',
  CreateOfferModal = 'create-offer-modal',
  OfferEditPage = 'offer-edit-page',
  OfferReviewPage = 'offer-review-page',
}

export enum DataId {
  CreateOfferModal = 'create-offer-modal',
  CreateOfferButton = 'create-offer-button',
  CloseCreateOfferModalButton = 'close-create-offer-modal-button',
  ContinueCreateOfferButton = 'continue-create-offer-button',
  DirectOfferRadioButton = 'direct-offer-radio-button',
  PartnerOfferRadioButton = 'partner-offer-radio-button',
  NewOfferRadioButton = 'new-offer-radio-button',
  AssociateOfferRadioButton = 'associate-offer-radio-button',
  BuyerBillingAccountField = 'buyer-billing-account-field',
  CompanyNameField = 'company-name-field',
  BuyerNameField = 'buyer-name-field',
  BuyerEmailField = 'buyer-email-field',
  BuyerTitleField = 'buyer-title-field',
  OfferNameField = 'offer-name-field',
  OfferDescriptionField = 'offer-description-field',
  RenewalField = 'renewal-field',
  RenewalTypeField = 'renewal-type-field',
  CancelEditButton = 'cancel-edit-button',
  SaveDraftButton = 'save-draft-button',
  SubmitToMarketplaceButton = 'submit-to-marketplace-button',
  KeepEditingButton = 'keep-editing-button',
  DiscardChangesButton = 'discard-changes-button',
  OfferAcceptanceDeadlineField = 'offer-acceptance-deadline-field',
  ProductSelectorSearchField = 'product-selector-search-field',
  ProductSelectorChangeProductButton = 'product-selector-change-product-button',
  ProductSelectorProduct = 'product-selector-product',
  PaymentModelField = 'payment-model-field',
  BillingTermField = 'billing-term-field',
  BillingTermCadenceField = 'billing-term-cadence-field',
  OfferDurationField = 'offer-duration-field',
  CurrencyField = 'currency-field',
  DimensionNameField = 'dimension-name-field',
  DimensionApiNameField = 'dimension-api-name-field',
  DimensionPriceField = 'dimension-price-field',
  DimensionQuantityField = 'dimension-quantity-field',
  DeleteDimensionButton = 'delete-dimension-button',
  DimensionsCounter = 'dimensions-counter',
  RetryLoadDimensionsButton = 'retry-load-dimensions-button',
  AddDimensionButton = 'add-dimension-button',
  UsageDimensionSkuField = 'usage-dimension-sku-field',
  UsageDimensionFeeField = 'usage-dimension-fee-field',
  UsageDimensionDescriptionField = 'usage-dimension-description-field',
  ScheduleInvoiceDateField = 'schedule-invoice-date-field',
  ScheduleInvoiceAmountField = 'schedule-invoice-amount-field',
  DeleteScheduleButton = 'delete-schedule-button',
  AddScheduleButton = 'add-schedule-button',
  CreatePaymentScheduleButton = 'create-payment-schedule-button',
  PaymentScheduleTotalContractValueField = 'payment-schedule-total-contract-value-field',
  PaymentScheduleDurationInMonthsField = 'payment-schedule-duration-in-months-field',
  PaymentSchedulePaymentFrequencyField = 'payment-schedule-payment-frequency-field',
  PaymentScheduleFirstPaymentDateField = 'payment-schedule-first-payment-date-field',
  PaymentScheduleBackButton = 'payment-schedule-back-button',
  PaymentSchedulePreviewButton = 'payment-schedule-preview-button',
  PaymentScheduleCreateButton = 'payment-schedule-create-button',
  MarketplaceFeeField = 'marketplace-fee-field',
  OfferMetaDataLabelField = 'offer-metadata-label-field',
  OfferMetaDataValueField = 'offer-metadata-value-field',
  RegistrationPageField = 'registration-page-field',
  DocumentTypeField = 'document-type-field',
  PartnerNameField = 'partner-name-field',
  PartnerAccountNumberField = 'partner-account-number-field',
  ResellerAgreementTypeField = 'reseller-agreement-type-field',
  OfferIdField = 'offer-id-field',
  SourceOfferIdField = 'source-offer-id-field',
  IsAmendedCheckBox = 'is-amended-checkbox',
  SellingAuthorizationIdField = 'selling-authorization-id-field',
  PartnerOfferIdField = 'partner-offer-id-field',
  ActiveAgreementsSubText = 'active-agreements-sub-text',
}
