import React, { createContext, useContext, useEffect, useState } from 'react';
import { OfferPageContextProvider } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/OfferPageContextProvider';
import {
  Marketplace,
  PrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { CurrencyCode } from 'utils/currency';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContextProvider/apiContext';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import { OfferRunFrom } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerRunFrom';

export interface AwsEditOfferVendorContextProps {
  marketplaceCurrencies: CurrencyCode[];
}

export const AwsEditOfferVendorContext =
  createContext<AwsEditOfferVendorContextProps>({ marketplaceCurrencies: [] });

const AwsEditOfferVendorContextProvider: React.FunctionComponent<{
  children: React.ReactElement;
}> = ({ children }) => {
  const {
    api: { getVendorCurrencies },
  } = useContext(ApiContext);
  const [values, setValues] = useState<AwsEditOfferVendorContextProps>({
    marketplaceCurrencies: [],
  });

  useEffect(() => {
    (async () => {
      const currencies = await getVendorCurrencies();

      setValues((previousValues) => ({
        ...previousValues,
        marketplaceCurrencies: (currencies as CurrencyCode[]) || [],
      }));
    })();

    return () => {
      setValues((previousValues) => ({
        ...previousValues,
        marketplaceCurrencies: [],
      }));
    };
  }, [getVendorCurrencies]);

  return (
    <AwsEditOfferVendorContext.Provider value={values}>
      {children}
    </AwsEditOfferVendorContext.Provider>
  );
};

interface AwsEditOfferContextProviderProps {
  includeVendorContext?: boolean;
  mode: OfferPageMode;
  runFrom: OfferRunFrom;
  accessTokenProvider?: () => Promise<string>;
  afterSubmit?: (offer: PrivateOffer) => void;
  onEdit?: (offer: PrivateOffer) => void;
  onAmend?: (offerId: string, offer: PrivateOffer | null) => void;
  onCancel?: (offer: PrivateOffer | null) => void;
  onOpenOffer?: (Offer: PrivateOffer) => void;
  children: React.ReactElement;
}

const AwsEditOfferContextProvider: React.FunctionComponent<
  AwsEditOfferContextProviderProps
> = ({
  includeVendorContext = true,
  accessTokenProvider,
  mode,
  runFrom,
  afterSubmit,
  onEdit,
  onAmend,
  onCancel,
  onOpenOffer,
  children,
}) => (
  <OfferPageContextProvider
    accessTokenProvider={accessTokenProvider}
    marketplace={Marketplace.Aws}
    mode={mode}
    runFrom={runFrom}
    afterSubmit={afterSubmit}
    onEdit={onEdit}
    onAmend={onAmend}
    onCancel={onCancel}
    onOpenOffer={onOpenOffer}
  >
    {includeVendorContext ? (
      <AwsEditOfferVendorContextProvider>
        {children}
      </AwsEditOfferVendorContextProvider>
    ) : (
      children
    )}
  </OfferPageContextProvider>
);

export default AwsEditOfferContextProvider;
