import { Box, Typography } from 'vendor/material';
import { Formik, Form, FormikValues } from 'formik';
import { ObjectSchema } from 'yup';
import { useState, useEffect } from 'react';
import { Page } from 'components';
import { ConfirmModal, DELETE_DRAFT, TOASTS } from '../../components';
import { Alert, Provider, ProviderIcon } from '@tackle-io/platform-ui';
import useToast from 'hooks/useToast/useToast';
import { useOpportunityFormStyles } from './UnifiedOpportunityForm.styles';
import { UnifiedOpportunityFormValues } from './UnifiedOpportunityFormValues';
import { UnifiedOpportunityFormButtonSection } from './UnifiedOpportunityFormButtonSection';
import { AceOpportunityResponse } from '../../types/responses/AceOpportunityResponse';
import { isEmpty } from 'lodash-es';
import AceOpportunityDetailsHeader from '../../pages/UnifiedOpportunityDetails/AceOpportunityDetails/detailSections/AceOpportunityDetailsHeader';
import { AceOpportunityFormBanner } from './AceOpportunityForm/AceOpportunityFormBanner';
import { AceOpportunityReviewStatusEnum } from '../../types/enums';
import {
  ActionRequiredBannerBodyContent,
  ApprovedBannerBodyContent,
} from './AceOpportunityForm/helpers';
import { coSellCommonDataId } from '../../utilities/intercomEnums';

export enum ActiveOpportunityFormActions {
  DELETING = 'deleting',
  SUBMITTING = 'submitting',
  SAVING_DRAFT = 'saving_draft',
}

interface UnifiedOpportunityFormProps {
  // TODO: Update any to the correct type
  onSubmit: (values: UnifiedOpportunityFormValues) => any;
  onSaveAsDraft: (
    values: UnifiedOpportunityFormValues,
  ) => Promise<{ id: string }>;
  validationSchema?: ObjectSchema<any>;
  initialValues: FormikValues;
  children: React.ReactNode;
  opportunityId?: string;
  opportunity?: AceOpportunityResponse;
  provider?: Provider;
  isAceV14?: boolean;
  onCancel: () => void;
  /** banners passed from parent component to render alongside other banners */
  banners?: React.ReactNode;
}

export const UnifiedOpportunityFormShell = ({
  banners,
  children: formFields,
  onSubmit,
  onSaveAsDraft,
  validationSchema,
  initialValues,
  opportunityId,
  opportunity,
  provider,
  onCancel,
}: UnifiedOpportunityFormProps) => {
  const classes = useOpportunityFormStyles();
  const { toaster } = useToast();
  const [cancelConfirmModalOpen, setCancelConfirmModalOpen] = useState(false);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [errors, setErrors] = useState<string[]>(undefined);
  const [activeOpportunityAction, setActiveOpportunityAction] =
    useState<ActiveOpportunityFormActions>();
  // Scroll to top of page when errors are present
  const companyName = opportunity?.customer?.account?.companyName;
  useEffect(() => {
    if (errors) {
      const containerShell = document.querySelector('#root > div > main');
      if (containerShell && containerShell.scrollTop > 0) {
        containerShell.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }, [errors]);

  const handleCancel = (isFormDirty?: boolean) => {
    if (isFormDirty) {
      /** have user confirm if they've made any changes */
      setCancelConfirmModalOpen(true);
      return;
    }
    onCancel();
  };

  const handleError = (error) => {
    if (Array.isArray(error?.cause?.message?.detail)) {
      error?.cause?.message?.detail.map((err) => {
        return setErrors([`${error?.message}: ${err?.msg}`]);
      });
    } else {
      setErrors([`${error?.message}`]);
    }
  };

  const handleSubmitOpportunity = async (
    values: UnifiedOpportunityFormValues,
  ) => {
    setActiveOpportunityAction(ActiveOpportunityFormActions.SUBMITTING);
    try {
      await onSubmit(values);
    } catch (error) {
      handleError(error);
    } finally {
      setActiveOpportunityAction(undefined);
    }
  };

  const handleSaveOpportunityAsDraft = async (
    values: UnifiedOpportunityFormValues,
  ) => {
    setActiveOpportunityAction(ActiveOpportunityFormActions.SAVING_DRAFT);

    try {
      await onSaveAsDraft(values);
    } catch (error) {
      handleError(error);
    } finally {
      setActiveOpportunityAction(undefined);
    }
  };

  const handleDeleteDraftOpportunity = async () => {
    setActiveOpportunityAction(ActiveOpportunityFormActions.DELETING);
    try {
      // await onDeleteDraft();
      toaster(TOASTS[DELETE_DRAFT]);
      handleCancel();
    } catch (error) {
      handleError(error);
    } finally {
      setActiveOpportunityAction(undefined);
    }
  };

  return (
    <Page className={classes.page}>
      {opportunity?.identifier ? (
        <Box mt={2}>
          <AceOpportunityDetailsHeader
            title={opportunity?.customer?.account?.companyName}
            source={opportunity?.source}
            lifeCycle={opportunity?.lifeCycle}
          />
        </Box>
      ) : (
        <>
          <Box mt={4} display={'flex'}>
            <ProviderIcon fontSize={'large'} provider={provider} />
            <Typography className={classes.title}>
              {companyName ? companyName : 'Create a Co-Sell opportunity'}
            </Typography>
          </Box>
        </>
      )}

      {!!banners && (
        <div className={classes.externalBannerWrapper}>{banners}</div>
      )}
      {/* {opportunity?.submissionErrors && (
        <ErrorFeedbackAlert submissionErrors={opportunity.submissionErrors} />
      )} */}
      {errors?.map((error) => (
        <Box mb={2} key={error}>
          <Alert title={error} appearance="danger" />
        </Box>
      ))}
      {opportunity?.lifeCycle?.reviewStatus ===
        AceOpportunityReviewStatusEnum.ACTION_REQUIRED && (
        <AceOpportunityFormBanner
          title="When your cloud partner sends a Co-Sell back with action required status, AWS limits you to changing only the following customer and project fields:"
          content={ActionRequiredBannerBodyContent}
        />
      )}
      {opportunity?.lifeCycle?.reviewStatus ===
        AceOpportunityReviewStatusEnum.APPROVED && (
        <AceOpportunityFormBanner
          title="Before you launch or close a Co-Sell, AWS enables you to change any field EXCEPT the following:"
          content={ApprovedBannerBodyContent}
        />
      )}
      {((opportunityId && !isEmpty(initialValues)) || !opportunityId) && (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => {
            // noop
          }}
        >
          <Form>
            {formFields}
            <UnifiedOpportunityFormButtonSection
              onSubmit={handleSubmitOpportunity}
              onSaveAsDraft={handleSaveOpportunityAsDraft}
              onCancel={handleCancel}
              onDelete={() => setDeleteConfirmModalOpen(true)}
              opportunity={opportunity}
              activeOpportunityFormAction={activeOpportunityAction}
            />
          </Form>
        </Formik>
      )}
      <ConfirmModal
        open={deleteConfirmModalOpen}
        onClose={() => setDeleteConfirmModalOpen(false)}
        onSubmit={handleDeleteDraftOpportunity}
        title={'Are you sure you want to delete?'}
        body={'If you delete this draft, you will be unable to revisit it.'}
        cancelButtonId={coSellCommonDataId.CANCEL_DELETE_OPPORTUNITY}
        confirmButtonId={coSellCommonDataId.CONFIRM_DELETE_OPPORTUNITY}
        cancelButtonLabel={'Cancel'}
        submitButtonLabel={'Delete draft'}
        submitButtonLoading={activeOpportunityAction === 'deleting'}
      />
      <ConfirmModal
        open={cancelConfirmModalOpen}
        onClose={() => setCancelConfirmModalOpen(false)}
        onSubmit={() => handleCancel()}
        title={'Are you sure you want to cancel?'}
        body={
          'If you leave this page without saving your changes, they will be lost.'
        }
        cancelButtonId={coSellCommonDataId.CANCEL_DELETE_OPPORTUNITY}
        confirmButtonId={coSellCommonDataId.CONFIRM_DELETE_OPPORTUNITY}
        cancelButtonLabel={'Cancel'}
        submitButtonLabel={'Confirm'}
      />
    </Page>
  );
};
