import { isEmpty } from 'lodash';
import { UpdateAceOpportunityFormValues } from '../../components/UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import {
  LifeCycle,
  UpdateAceOpportunityRequest,
} from '../../types/requests/AceOpportunityRequest';
import { SoftwareRevenue } from '../../types/AceOpportunity.type';
import {
  getCustomer,
  getMarketing,
  getPartnerOpportunityTeam,
  getProject,
} from './convertAceFormValuesToCreateRequest';
import { AceSoftwareRevenueDeliveryModelEnum } from '../../types/enums';
import { getAwsSubmission } from './getAwsSubmission';

export const getSoftwareRevenue = (
  values: UpdateAceOpportunityFormValues,
): SoftwareRevenue | null => {
  const softwareRevenue: SoftwareRevenue = {
    deliveryModel: values?.deliveryModel as AceSoftwareRevenueDeliveryModelEnum,
    value: {
      amount: values?.customerSoftwareValue
        ? String(values?.customerSoftwareValue)
        : null,
      currencyCode: values?.currencyCode,
    },
    effectiveDate: values?.effectiveDate
      ? new Date(values?.effectiveDate).toISOString()
      : null,
    expirationDate: values?.expirationDate
      ? new Date(values?.expirationDate).toISOString()
      : null,
  };

  return isEmpty(softwareRevenue?.value) ? null : softwareRevenue;
};

export const getAwsMarketplaceOffers = (
  values: UpdateAceOpportunityFormValues,
) => {
  return values?.marketplaceOfferId ? [values.marketplaceOfferId] : null;
};

export const getUpdatedLifecycle = (
  values: UpdateAceOpportunityFormValues,
): LifeCycle => {
  const targetCloseDate = values.targetCloseDate
    ? new Date(values.targetCloseDate)?.toISOString()
    : null;
  const lifeCycle: LifeCycle = {
    nextSteps: values.nextStep,
    targetCloseDate,
    closedLostReason: values.closedLostReason,
  };
  return lifeCycle;
};

export const convertAceFormValuesToUpdateRequest = (
  values: UpdateAceOpportunityFormValues,
): UpdateAceOpportunityRequest => {
  return {
    customer: getCustomer(values),
    lifeCycle: getUpdatedLifecycle(values),
    marketing: getMarketing(values),
    opportunityType: values.opportunityType,
    opportunityTeam: getPartnerOpportunityTeam(values),
    primaryNeedsFromAws: values.primaryNeedsFromAws,
    project: getProject(values),
    solutions: values.solutions,
    awsProducts: values.awsProducts,
    awsMarketplaceOffers: getAwsMarketplaceOffers(values),
    awsSubmission: getAwsSubmission(values.primaryNeedsFromAws),
  };
};
