import { AWSProduct } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';

export const findMatchingAwsProduct = (
  productRef: string,
  productsByProductId: { [pId: string]: AWSProduct },
): AWSProduct => productsByProductId[productRef];

export const toMatchingAwsProduct =
  (productsByProductId: { [pId: string]: AWSProduct }) =>
  (productRef: string): AWSProduct =>
    findMatchingAwsProduct(productRef, productsByProductId);
