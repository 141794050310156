import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Loader } from '@tackle-io/platform-ui';
import ErrorComponent from 'pages/Error/Error';
import { findMatchingProduct } from 'pages/PrivateOffers/pages/Next/generic/utils/product/productUtils';
import { Box } from 'vendor/material';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContextProvider/apiContext';
import { PrivateOffer } from '../api/types/PrivateOffer';
import { Product } from '../api/types/Product';
import OfferContext, { OfferContextValues } from './offerContext';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import OfferPageContext from '../OfferPageContext/offerPageContext';

interface OfferContextProviderProps {
  poId: string;
  children: React.ReactElement;
}

const OfferContextProvider: React.FunctionComponent<
  OfferContextProviderProps
> = ({ poId, children }) => {
  const [currentPoId, setCurrentPoId] = useState<string | null>(null);
  const [offer, setOffer] = useState<PrivateOffer | null>(null);
  const [offerForMode, setOfferForMode] = useState<PrivateOffer | null>(null);
  const [product, setProduct] = useState<Product | null>(null);

  const loadingOffer = useMemo(
    () => !!poId && poId !== currentPoId,
    [poId, currentPoId],
  );

  const {
    api: { getOffer },
  } = useContext(ApiContext);

  const { mode, productsByProductId, onCancel } = useContext(OfferPageContext);

  const offerIsAmendment = useMemo(
    () => !!offerForMode?.sourceOfferId,
    [offerForMode],
  );

  useEffect(() => {
    if (!poId) {
      return;
    }

    (async () => {
      const fetchedOffer = await getOffer(poId);
      const product = findMatchingProduct(
        fetchedOffer?.productId,
        productsByProductId,
      );

      const ofm =
        mode === OfferPageMode.Amend || mode === OfferPageMode.Clone
          ? null
          : fetchedOffer;

      setProduct(product);
      setOfferForMode(ofm);
      setOffer(fetchedOffer);
      setCurrentPoId(poId);
    })();
  }, [getOffer, poId, productsByProductId, mode, setOffer, setProduct]);

  const contextValues: OfferContextValues = {
    offer,
    setOffer,
    product,
    offerForMode,
    offerIsAmendment,
  };

  return loadingOffer ? (
    <Loader />
  ) : poId && !offer ? (
    <ErrorComponent>
      <Box mb={2}>Private offer not found</Box>
      <Button
        appearance="primary"
        onClick={() => {
          onCancel(null);
        }}
      >
        Back
      </Button>
    </ErrorComponent>
  ) : (
    <OfferContext.Provider value={contextValues}>
      {children}
    </OfferContext.Provider>
  );
};

export default OfferContextProvider;
