import {
  AbsolutePrice,
  Activity,
  Buyer,
  Dimension,
  EULA,
  ExtraData,
  PartnerOffer,
  Pricing,
  PrivateOffer,
  Salesforce,
  Schedule,
  Unit,
  UsageDimension,
  UserToNotify,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import {
  AbsolutePriceJSON,
  ActivityJSON,
  BuyerJSON,
  DimensionJSON,
  EULAJSON,
  ExtraDataJSON,
  PartnerOfferJSON,
  PricingJSON,
  PrivateOfferJSON,
  SalesforceJSON,
  ScheduleJSON,
  UnitJSON,
  UsageDimensionJSON,
  UserToNotifyJSON,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOfferJSON';
import { Optional } from 'utils/optional/optional';

const activityJSONsToActivities = (activities: ActivityJSON[]): Activity[] =>
  activities.map((a) => ({
    activityType: a.activity_type,
    createdAt: a.created_at,
    metadata: a.metadata,
    slug: a.slug,
    userId: a.user_id,
  }));

const buyersJSONToBuyers = (buyers: BuyerJSON[]): Buyer[] =>
  buyers.map((b) => ({
    emailAddress: b.email_address,
    fullName: b.full_name,
    title: b.title,
  }));

const toEula = (eula: EULAJSON | null): EULA | null => {
  return Optional.ofNullable(eula)
    .map((e) => ({
      documentUrns: e.document_urns,
      type: e.type,
    }))
    .orElse(null);
};

const usersToNotifyJSONsToUsersToNotify = (
  usersToNotify: UserToNotifyJSON[],
): UserToNotify[] =>
  usersToNotify.map((u) => ({
    email: u.email,
  }));

const toExtraData = (extraData: ExtraDataJSON | null): ExtraData | null => {
  return Optional.ofNullable(extraData)
    .map((ed) => ({
      users: usersToNotifyJSONsToUsersToNotify(ed.users || []),
    }))
    .orElse(null);
};

const toPartnerOffer = (
  partnerOffer: PartnerOfferJSON | null,
): PartnerOffer | null => {
  return Optional.ofNullable(partnerOffer)
    .map((po) => ({
      partnerName: po.partner_name,
      partnerOfferRef: po.partner_offer_ref,
      partnerRef: po.partner_ref,
      resellerAgreement: Optional.ofNullable(partnerOffer.reseller_agreement)
        .map((ra) => ({
          documentUrns: ra.document_urns,
          type: ra.type,
        }))
        .orElse(null),
    }))
    .orElse(null);
};

const absolutePriceJSONsToAbsolutePrices = (
  absolutePrices: AbsolutePriceJSON[],
): AbsolutePrice[] =>
  absolutePrices.map((ap) => ({
    billingTerm: {
      type: ap.billing_term.type,
      value: ap.billing_term.value,
    },
    paymentOption: {
      type: ap.payment_option.type,
      value: ap.payment_option.value,
    },
  }));

const dimensionJSONsToDimensions = (dimensions: DimensionJSON[]): Dimension[] =>
  dimensions.map((d) => ({
    absolutePrices: absolutePriceJSONsToAbsolutePrices(d.absolute_prices || []),
    apiName: d.api_name,
    description: d.description,
    dimensionValue: d.dimension_value,
    discountPercentage: d.discount_percentage,
    name: d.name,
    price: d.price,
    quantity: d.quantity,
  }));

const scheduleJSONsToSchedules = (schedules: ScheduleJSON[]): Schedule[] =>
  schedules.map((s) => ({
    invoiceAmount: s.invoice_amount,
    invoiceDate: s.invoice_date,
    invoiceDateType: s.invoice_date_type,
  }));

const unitJSONsToUnits = (units: UnitJSON[]): Unit[] =>
  units.map((u) => ({
    durationPrice: u.duration_price,
    hourlyPrice: u.hourly_price,
    unit: u.unit,
  }));

const usageDimensionJSONsToUsageDimensions = (
  usageDimensions: UsageDimensionJSON[],
): UsageDimension[] =>
  usageDimensions.map((u) => ({
    description: u.description,
    price: u.price,
    sku: u.sku,
  }));

export const toPricing = (pricing: PricingJSON | null): Pricing | null =>
  Optional.ofNullable(pricing)
    .map((p) => ({
      allowAutoRenew: p.allow_auto_renew,
      billingTerm: p.billing_term,
      currencyCode: p.currency_code,
      dimensions: dimensionJSONsToDimensions(p.dimensions || []),
      duration: p.duration,
      durationType: p.duration_type,
      durationValue: p.duration_value,
      marketplaceFee: p.marketplace_fee,
      monthlyContractValue: p.monthly_contract_value,
      netContractValue: p.net_contract_value,
      paymentModel: p.payment_model,
      schedule: scheduleJSONsToSchedules(p.schedule || []),
      serviceEndAt: p.service_end_at,
      serviceStartAt: p.service_start_at,
      showOnEmail: p.show_on_email,
      totalContractValue: p.total_contract_value,
      units: unitJSONsToUnits(p.units || []),
      usageDimensions: usageDimensionJSONsToUsageDimensions(
        p.usage_dimensions || [],
      ),
      version: p.version,
    }))
    .orElse(null);

const toSalesforce = (eula: SalesforceJSON | null): Salesforce | null =>
  Optional.ofNullable(eula)
    .map((s) => ({
      customObjectId: s.custom_object_id,
      opportunityId: s.opportunity_id,
    }))
    .orElse(null);

export const privateOfferJSONToPrivateOffer = (
  offer: PrivateOfferJSON,
): PrivateOffer => ({
  acceptedAt: offer.accepted_at,
  activities: activityJSONsToActivities(offer.activities || []),
  archivedAt: offer.archived_at,
  awsRenewalMovingToMarketplace: offer.aws_renewal_moving_to_marketplace,
  buyerBillingAccountRef: offer.buyer_billing_account_ref,
  buyerCompanyName: offer.buyer_company_name,
  buyers: buyersJSONToBuyers(offer.buyers || []),
  cancelledAt: offer.cancelled_at,
  cloudContractId: offer.cloud_contract_id,
  createdAt: offer.created_at,
  createdInMarketplaceAt: offer.created_in_marketplace_at,
  enableZeroDollarPrices: offer.enable_zero_dollar_prices,
  eula: toEula(offer.eula),
  extraData: toExtraData(offer.extra_data),
  lastModifiedAt: offer.last_modified_at,
  marketplace: offer.marketplace,
  offerDescription: offer.offer_description,
  offerEndAt: offer.offer_end_at,
  offerExpirationAt: offer.offer_expiration_at,
  offerMetadata: offer.offer_metadata,
  offerName: offer.offer_name,
  offerPointOfContact: offer.offer_point_of_contact,
  offerPointOfContactName: offer.offer_point_of_contact_name,
  offerStartAt: offer.offer_start_at,
  offerType: offer.offer_type,
  offerId: offer.offerid,
  openedInstructionsAt: offer.opened_instructions_at,
  partnerOffer: toPartnerOffer(offer.partner_offer),
  poId: offer.po_id,
  preRegistrationDetails: offer.pre_registration_details,
  pricing: toPricing(offer.pricing),
  productId: offer.productid,
  renewal: offer.renewal,
  salesforce: toSalesforce(offer.salesforce),
  salesforceId: offer.salesforce_id,
  sentAt: offer.sent_at,
  sourceOfferId: offer.source_offer_id,
  stage: offer.stage,
  status: offer.status,
  vendorId: offer.vendor_id,
  viewedOfferAt: offer.viewed_offer_at,
});
