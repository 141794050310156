import {
  Marketplace,
  PrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { History } from 'history';

export const toPrivateOfferOnAmend =
  (history: History) => (offerId: string, offer: PrivateOffer | null) => {
    history.push({
      pathname: `/private-offers/${Marketplace.Aws}/amend/${offerId}`,
      search: offer ? 'poId=' + offer.poId : '',
    });
  };
