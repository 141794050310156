import { Tag } from '@tackle-io/platform-ui';
import React from 'react';
import { makeStyles } from 'vendor/material';

interface ScoreTagProps {
  score?: string;
}

const useStyles = makeStyles((theme) => ({
  highScore: {
    background: '#E2FFEE',
    '& span': {
      color: '#006644',
    },
  },
  mediumScore: {
    background: '#FFF0B3',
    '& span': {
      color: '#FF5630',
    },
  },
  lowScore: {
    background: '#EBECF0',
    '& span': {
      color: '#505F79',
    },
  },
}));

export const ScoreTag: React.FC<ScoreTagProps> = ({ score }) => {
  const classes = useStyles();
  switch (score) {
    case 'High':
      return <Tag className={classes.highScore}>{score}</Tag>;
    case 'Medium':
      return <Tag className={classes.mediumScore}>{score}</Tag>;
    case 'Low':
      return <Tag className={classes.lowScore}>{score}</Tag>;
    default:
      return null;
  }
};
