import React from 'react';

import AWSLogo from 'images/aws-logo';
import useStyles from './CoSellActivityFeedItem.styles';
import { toDateTime } from 'utils/dates';
import TackleLogoSymbol from 'images/tackle-logo-symbol';
import { Avatar } from 'vendor/material';

/**
 * TODO: Look into where SOURCE_ENUM values come from, bc they arenot defined outside this file.
 *  but adding here bc they are used in the switch cases below
 */

interface CoSellActivityFeedItemProps {
  source: string;
  displayName?: string;
  message: string;
  timestamp: string;
  pictureUrl?: string;
}

enum SOURCE_ENUM {
  TACKLE = 'tackle',
  CLOUD_PARTNER = 'cloud_partner',
  USER = 'user',
}

const getLogo = (source: string, displayName?: string, pictureUrl?: string) => {
  const letter = displayName ? displayName[0] : '';
  switch (source) {
    case SOURCE_ENUM.TACKLE:
    case 'internalUpdateEvent':
      return <TackleLogoSymbol width="2.5em" height="2.5em" />;
    case SOURCE_ENUM.CLOUD_PARTNER:
    case 'cloudUpdateEvent':
      return <AWSLogo width={40} />;
    default:
      // Should return Auth0 user profile picture here if relevant, placeholder for now
      return pictureUrl ? (
        <img src={pictureUrl} alt="Person Icon" />
      ) : (
        <Avatar>{letter}</Avatar>
      );
  }
};

const getTitle = (source: string, displayName?: string) => {
  switch (source) {
    case SOURCE_ENUM.TACKLE:
    case 'internalUpdateEvent':
      return 'Tackle';
    case 'cloud_partner':
    case 'cloudUpdateEvent':
      return 'Cloud Partner';
    default:
      return displayName;
  }
};

export const CoSellActivityFeedItem = (props: CoSellActivityFeedItemProps) => {
  const classes = useStyles();
  return (
    <div className={classes.activityContainer}>
      <div className={classes.logoContainer}>
        {getLogo(props.source, props.displayName, props.pictureUrl)}
      </div>
      <div>
        <span className={classes.title}>
          {getTitle(props.source, props.displayName)}
        </span>
        <span className={classes.subTitle}>{props.message}</span>
        <span className={classes.dateTimestamp}>
          {toDateTime(props.timestamp)
            .toLocal()
            .toFormat('HH:mm | LLL dd, yyyy')}
        </span>
      </div>
    </div>
  );
};
