import {
  CurrencyFormField,
  SingleSelectFormField,
  // TextFormField,
} from 'packages/cosell/src/components';
import { Grid, makeStyles } from 'vendor/material';
import { Link } from '@tackle-io/platform-ui';
import { coSellAceOpportunityFormFieldsDataId as DATA_ID } from 'packages/cosell/src/utilities/intercomEnums';
import { useAcePickListQuery } from 'packages/cosell/api/hooks/useAcePickList';
import {
  fontWeightBold,
  fontWeightMediumLarge,
} from 'utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    borderBottom: '1px solid #EBECF0',
    marginBottom: theme.spacing(1),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    color: '#5E6C84',
    lineHeight: theme.typography.pxToRem(24),
  },
  helperText: {
    marginBlock: `${theme.spacing(1)}px`,
    color: '#5E6C84',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    '&>a': {
      color: '#0052CC',
      textDecoration: 'none',
    },
  },
  targetCompanyLabel: {
    color: '#253858',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: fontWeightMediumLarge,
    lineHeight: theme.typography.pxToRem(24),
  },
}));

export const ExpectedCustomerSpendFieldSet = () => {
  const classes = useStyles();
  const { data: awsEnumListMap } = useAcePickListQuery();

  /** document link is not ready yet, when added, link will render */
  const learnMoreLink = null;

  const sectionContent = {
    description: (
      <>
        The amount of revenue you expect each partner involved in the deal to
        make for their services with each payment, including AWS. AWS will
        calculate the total estimated revenue across all partners that you add.
        {!!learnMoreLink && (
          <>
            {' '}
            <Link external to={learnMoreLink}>
              Learn more
            </Link>
          </>
        )}
      </>
    ),
    title: 'Estimated revenue by partner',
  };

  const fields = {
    amount: {
      dataId: DATA_ID.EXPECTED_CUSTOMER_SPEND_AMOUNT,
      defaultValue: null,
      disabled: false,
      fieldName: 'expectedCustomerSpendAmount',
      label: 'Amount',
      helperText:
        "The partner's estimated revenue based on standard pricing and payment frequency. For example, to estimate the amount for a partner with a monthly payment frequency, divide the estimated ARR (annual recurring revenue) for the partner by the number of months in a year (12).",
      required: true,
    },
    currencyCode: {
      dataId: DATA_ID.EXPECTED_CUSTOMER_SPEND_CURRENCY_CODE,
      defaultValue: 'USD',
      disabled: false,
      fieldName: 'expectedCustomerSpendCurrencyCode',
      label: 'Currency',
      helperText: '',
      required: true,
      options: awsEnumListMap?.['project.expectedCustomerSpend[].currencyCode'],
    },
    frequency: {
      /** For now the only valid value is Monthy, and this cannot be changed by the vendor */
      dataId: DATA_ID.EXPECTED_CUSTOMER_SPEND_FREQUENCY,
      defaultValue: 'Monthly',
      disabled: true,
      fieldName: 'expectedCustomerSpendFrequency',
      label: 'Frequency',
      helperText: 'The frequency of payments to the partner.',
      required: true,
      options: awsEnumListMap?.['project.expectedCustomerSpend[].frequency'],
    },
    targetCompany: {
      /** For the moment the only acceptable value is AWS, and this cannot be changed by the vendor */
      defaultValue: 'AWS',
      disabled: true,
      fieldName: 'expectedCustomerSpendTargetCompany',
      label: 'Target company',
      dataId: DATA_ID.EXPECTED_CUSTOMER_SPEND_TARGET_COMPANY,
      helperText:
        'The company or organization that is expected to make the expected spend.',
      required: true,
    },
  };

  const { amount, currencyCode, frequency, targetCompany } = fields;

  return (
    <Grid container spacing={2}>
      <Grid className={classes.sectionTitle} item xs={12}>
        <span className={classes.title}>{sectionContent.title}</span>
        <span className={classes.helperText}>{sectionContent.description}</span>
      </Grid>
      <Grid item xs={12}>
        <span className={classes.targetCompanyLabel}>
          {targetCompany.defaultValue}
        </span>
        {/** targetCompany field is hidden until options are available for user to select. */}
        {/* <TextFormField
          name={targetCompany.fieldName}
          label={targetCompany.label}
          dataId={targetCompany.dataId}
          disabled={targetCompany.disabled}
          defaultValue={targetCompany.defaultValue}
          required={targetCompany.required}
          helperText={targetCompany.helperText}
        /> */}
      </Grid>
      <Grid item xs={12}>
        <SingleSelectFormField
          data-testid={frequency.fieldName}
          disabled={frequency.disabled}
          fieldId={frequency.fieldName}
          label={frequency.label}
          dataId={frequency.dataId}
          options={frequency.options}
          helperText={frequency.helperText}
          required={frequency.required}
        />
      </Grid>
      <Grid item xs={12}>
        <SingleSelectFormField
          data-testid={currencyCode.fieldName}
          fieldId={currencyCode.fieldName}
          label={currencyCode.label}
          dataId={currencyCode.dataId}
          options={currencyCode.options}
          required={currencyCode.required}
        />
      </Grid>

      <Grid item xs={12}>
        <CurrencyFormField
          name={amount.fieldName}
          label={amount.label}
          data-id={amount.dataId}
          required={amount.required}
        />
        <p className={classes.helperText}>{amount.helperText}</p>
      </Grid>
    </Grid>
  );
};
