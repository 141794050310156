import React, { useContext } from 'react';
import { Banner, Card, Link } from '@tackle-io/platform-ui';
import { FilePdfBox, Text, TrophyAward } from 'mdi-material-ui';
import { Box, Grid, makeStyles, Typography } from 'vendor/material';
import LabelAndValue from 'pages/PrivateOffers/pages/Next/aws/view/OfferView/LabelAndValue';
import { EULAType } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { standardContractUrl } from 'pages/PrivateOffers/pages/Next/aws/shared/urls';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import { fontWeightMedium } from 'utils/fontWeightConstants';

const createFileNameGridItem = (
  classes: Record<string, string>,
  uniqueFileName: string,
  index: number,
): JSX.Element => {
  const isPdfFile = uniqueFileName.endsWith('.pdf');
  return (
    <div className={classes.item}>
      <Typography component="h5" className={classes.fileNameLabel}>
        {`Document Name ${index + 1}`}
      </Typography>
      <div className={classes.fileContainer}>
        {isPdfFile ? (
          <FilePdfBox className={classes.pdfIcon} />
        ) : (
          <Text className={classes.pdfIcon} />
        )}
        <Typography noWrap component="p" className={classes.content}>
          {uniqueFileName}
        </Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  item: {
    flex: 1,
    paddingTop: theme.typography.pxToRem(10),
    paddingRight: theme.typography.pxToRem(10),
  },
  fileContainer: {
    backgroundColor: theme.palette.NEUTRAL000,
    boxShadow: `0 0 0 1px ${theme.palette.NEUTRAL030} inset`,
    borderRadius: 4,
    display: 'inline-flex',
    alignItems: 'center',
    paddingTop: theme.typography.pxToRem(4),
    paddingBottom: theme.typography.pxToRem(4),
    paddingLeft: theme.typography.pxToRem(4),
    paddingRight: theme.typography.pxToRem(8),
    marginTop: theme.typography.pxToRem(4),
    maxWidth: theme.typography.pxToRem(270),
  },
  pdfIcon: {
    marginRight: theme.typography.pxToRem(4),
  },
  fileNameLabel: {
    color: theme.palette.NEUTRAL300,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: fontWeightMedium,
  },
  content: {
    color: theme.palette.NEUTRAL500,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: fontWeightMedium,
  },
}));

const EulaValue: React.FC<{
  type: EULAType;
  docUrns: string[];
}> = ({ type, docUrns }) => {
  const classes = useStyles();

  const mainLabel =
    type === EULAType.Public ? (
      'Public EULA'
    ) : type === EULAType.AwsStandard ? (
      <Link to={standardContractUrl} external showExternalIcon>
        Standard contract for AWS Marketplace
      </Link>
    ) : (
      'Custom'
    );

  const formattedUrns = docUrns.map((urn) =>
    urn.substring(urn.lastIndexOf(':') + 1, urn.length),
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {mainLabel}
      </Grid>
      {formattedUrns.map((urn, index) => (
        <Grid item xs={6} key={index}>
          {createFileNameGridItem(classes, urn, index)}
        </Grid>
      ))}
    </Grid>
  );
};

const EndUserLicenseAgreementSection: React.FunctionComponent = () => {
  const { offer } = useContext(OfferContext);

  const eulaType = offer.eula?.type;

  return (
    <Card
      title="End user licensing agreement"
      icon={<TrophyAward />}
      bodyStyle={{ backgroundColor: '#FAFBFC' }}
    >
      {!!offer.createdInMarketplaceAt && (
        <Box mb={4}>
          <Banner
            title="This offer has been created in the Cloud marketplace and this section cannot be edited."
            borderPosition="top"
          />
        </Box>
      )}
      <Box mb={2}>
        <Box>
          <LabelAndValue
            label="EULA containing all uploaded documents"
            value={
              <EulaValue type={eulaType} docUrns={offer?.eula?.documentUrns} />
            }
          />
        </Box>
      </Box>
    </Card>
  );
};

export default EndUserLicenseAgreementSection;
