import React, { useContext } from 'react';
import { Box, Grid, makeStyles, Skeleton, Typography } from 'vendor/material';
import ActivitiesList from '../../../generic/ActivitiesList/ActivitiesList';
import { fontWeightMedium } from 'utils/fontWeightConstants';
import OfferActions from 'pages/PrivateOffers/pages/Next/generic/ViewOfferPage/OfferActions/OfferActions';
import SectionDivider from './SectionDivider';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import OfferPageContext from '../../../generic/OfferPageContext/offerPageContext';
import { Sale } from 'mdi-material-ui';
import { Link } from '@tackle-io/platform-ui';
import AmendmentContext from 'pages/PrivateOffers/pages/Next/aws/edit/AmendmentContext/AmendmentContext';

const useStyles = makeStyles((theme) => ({
  widgetTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: fontWeightMedium,
    color: theme.palette.NEUTRAL500,
    marginTop: theme.spacing(4),
  },
  amendedOfferHeader: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '12px',
  },
  sourceOfferIdText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
  },
  offerActivitiesList: {
    maxHeight: 400,
    overflowY: 'auto',
    scrollbarColor: `${theme.palette.NEUTRAL100} ${theme.palette.NEUTRAL020}`,
  },
}));

const SidePanel: React.FunctionComponent = () => {
  const classes = useStyles();
  const { onEdit, onOpenOffer } = useContext(OfferPageContext);
  const { offerForMode: offer, offerIsAmendment } = useContext(OfferContext);
  const { loadingAgreementOffer, sourceOffer } = useContext(AmendmentContext);
  const { activities = [] } = offer || {};

  return (
    <Box>
      <Box mb={4}>
        <OfferActions onEdit={onEdit} />
      </Box>
      <SectionDivider />
      {offerIsAmendment && (
        <>
          <Box mb={3}>
            {loadingAgreementOffer ? (
              <Skeleton />
            ) : (
              <>
                <Box mb={2}>
                  <Typography className={classes.amendedOfferHeader}>
                    Amended offer
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Sale />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.sourceOfferIdText}>
                        Source offer ID:
                      </Typography>
                      <Link
                        onClick={(e) => {
                          e.preventDefault();

                          onOpenOffer(sourceOffer);
                        }}
                      >
                        {offer.sourceOfferId}
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </Box>
          <SectionDivider />
        </>
      )}
      <Box mb={3}>
        <Typography component="h4" className={classes.widgetTitle}>
          Activity
        </Typography>
      </Box>
      <div className={classes.offerActivitiesList}>
        <ActivitiesList activities={activities} />
      </div>
    </Box>
  );
};

export default SidePanel;
