import { FormValues, PaymentFrequency } from '../formSchema';
import { FormSchedule } from '../../ScheduleRow/formTypes';
import { getUtcDateTimeFromSerializedIsoDateTime } from '../../../../../../../generic/utils/date/dateUtils';
import { floatToDinero } from 'utils/money';
import { Dinero } from 'dinero.js';
import { DateTime } from 'luxon';

const paymentFrequencyToMonthFactor = {
  [PaymentFrequency.Monthly]: 1,
  [PaymentFrequency.Annually]: 12,
  [PaymentFrequency.Quarterly]: 4,
  [PaymentFrequency.SemiAnnually]: 6,
};

const numberRange = (start: number, end: number): Array<number> =>
  Array.from({ length: end - start }, (_, k) => k + start);

const toFormSchedule =
  (
    firstPaymentDate: DateTime,
    monthFactor: number,
    numberOfPayments: number,
    payment: Dinero,
    lastPayment: Dinero,
  ) =>
  (i: number): FormSchedule => {
    const invoiceAmount = i === numberOfPayments - 1 ? lastPayment : payment;
    const date =
      i === 0
        ? firstPaymentDate
        : firstPaymentDate.plus({ months: i * monthFactor });

    return {
      invoiceDateType: null,
      invoiceDate: date.toISO(),
      invoiceAmount: invoiceAmount.toUnit(),
    };
  };

export const generatePaymentSchedule = (v: FormValues): FormSchedule[] => {
  const firstPaymentDate = getUtcDateTimeFromSerializedIsoDateTime(
    v.dateOfFirstPayment,
  );
  const monthFactor = paymentFrequencyToMonthFactor[v.paymentFrequency];
  const contractValue = floatToDinero(v.totalContractValue, 2);
  const numberOfPayments = Math.ceil(v.durationInMonths / monthFactor);
  const payment = contractValue.divide(numberOfPayments, 'DOWN');
  const lastPaymentDifference = payment.multiply(numberOfPayments - 1);
  const lastPayment = contractValue.subtract(lastPaymentDifference);

  return numberRange(0, numberOfPayments).map(
    toFormSchedule(
      firstPaymentDate,
      monthFactor,
      numberOfPayments,
      payment,
      lastPayment,
    ),
  );
};
