import { Banner } from '@tackle-io/platform-ui';
import { Box, makeStyles, Typography } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    display: 'flex',
    width: '100%',
    padding: '16px',
  },
}));

const WebsiteRequiredBanner: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.bannerContainer}>
      <Banner
        borderPosition="top"
        title={'Website required to score'}
        isCollapsible={true}
        type="warning"
        defaultOpen={true}
        body={
          <Typography>
            Tackle needs a website to score this account. Please update the
            related Salesforce account with an accurate website.
          </Typography>
        }
      />
    </Box>
  );
};

export default WebsiteRequiredBanner;
