import * as yup from '../../../../../../../../utils/yup-extended';
import {
  formSchema as buyersFormSchema,
  FormValues as BuyerFormValues,
} from '../../../../generic/OfferBuyers/formSchema';
import { OfferType } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { isWithProgress } from 'pages/PrivateOffers/pages/Next/generic/utils/schema/schemaTestsUtils';
import { FieldKeys as TopLevelFieldKeys } from '../formTypes';

export enum FieldKey {
  PartnerName = 'partnerName',
  PartnerAccountNumber = 'partnerAccountNumber',
  BuyerAccountNumber = 'buyerAccountNumber',
  CompanyName = 'companyName',
  OfferName = 'offerName',
  OfferDescription = 'offerDescription',
  Renewal = 'renewal',
  RenewalType = 'renewalType',
}

export enum RenewalType {
  NewAwsMarketplaceCustomer = 'new_aws_marketplace_customer',
  ExistingAwsMarketplaceCustomer = 'existing_aws_marketplace_customer',
}

export type FormValues = {
  [FieldKey.PartnerName]: string | null;
  [FieldKey.PartnerAccountNumber]: string;
  [FieldKey.BuyerAccountNumber]: string;
  [FieldKey.CompanyName]: string;
  [FieldKey.OfferName]: string | null;
  [FieldKey.OfferDescription]: string | null;
  [FieldKey.Renewal]: boolean;
  [FieldKey.RenewalType]: string | null;
} & BuyerFormValues;

const partnerAccountNumberSchema = yup.string().when('$', {
  is: isWithProgress(
    TopLevelFieldKeys.OfferType,
    (ot: OfferType) => ot === OfferType.PartnerResale,
  ),
  then: (schema: yup.StringSchema) => schema.required().progress(true),
  otherwise: (schema: yup.StringSchema) => schema.nullable().progress(false),
});

const renewalTypeSchema = yup
  .string()
  .nullable()
  .when(FieldKey.Renewal, {
    is: true,
    then: (schema: yup.StringSchema) => schema.required().progress(true),
    otherwise: (schema: yup.StringSchema) => schema.nullable().progress(false),
  });

export const formSchema: yup.ObjectSchema<FormValues> = yup
  .object({
    [FieldKey.PartnerName]: yup.string().nullable(),
    [FieldKey.PartnerAccountNumber]: partnerAccountNumberSchema,
    [FieldKey.BuyerAccountNumber]: yup.string().required().progress(true),
    [FieldKey.CompanyName]: yup.string().required().progress(true),
    [FieldKey.OfferName]: yup.string().max(150).nullable(),
    [FieldKey.OfferDescription]: yup.string().max(255).nullable(),
    [FieldKey.Renewal]: yup.boolean().required(),
    [FieldKey.RenewalType]: renewalTypeSchema,
  })
  .concat(buyersFormSchema);
