export enum FieldKey {
  PaymentModel = 'paymentModel',
  BillingTerm = 'billingTerm',
  DurationValue = 'durationValue',
  BillingTermCadence = 'billingTermCadence',
  DurationInCadence = 'durationInCadence',
  ServiceStartAt = 'serviceStartAt',
  ServiceEndAt = 'serviceEndAt',
  CurrencyCode = 'currencyCode',
  Dimensions = 'dimensions',
  UsageDimensions = 'usageDimensions',
  Schedules = 'schedules',
  UsageEndDate = 'usageEndDate',
}
