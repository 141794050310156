import { Optional } from 'utils/optional/optional';
import { FieldKey } from '../../formTypes';
import { CurrencyCode } from 'utils/currency';
import { FormikHelpers } from 'formik/dist/types';
import { FormValues } from '../../../formSchema';
import { toMatchingAwsProduct } from '../../utils/productUtils';
import { AWSProduct } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';

export const setCurrencyCodeToUSDIfProductHasUsageFees = async (
  productRef: string | null,
  productsByProductId: { [pId: string]: AWSProduct },
  setFieldValue: FormikHelpers<FormValues>['setFieldValue'],
) => {
  await Optional.ofNullable(productRef)
    .map(toMatchingAwsProduct(productsByProductId))
    .filter((p) => p?.pricing?.usageFees?.length > 0)
    .ifPresentAsync(async () => {
      await setFieldValue(
        FieldKey.CurrencyCode,
        CurrencyCode.UnitedStatesDollar,
      );
    });
};
