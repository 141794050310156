import { isEqual } from 'lodash';

export type Predicate<T> = (v: T) => boolean;
export type Consumer<T> = (v: T) => void;
export type AsyncConsumer<T> = (v: T) => Promise<void>;
export type Function<T, U> = (v: T) => U;
export type Supplier<T> = () => T;
export type AsyncSupplier<T> = () => Promise<T>;
export type Runnable = () => void;
export type AsyncRunnable = () => Promise<void>;

class NoSuchElementException extends Error {
  constructor() {
    super('Optional.get() called with no value present.');

    Object.setPrototypeOf(this, NoSuchElementException.prototype);
  }
}

class NotImplementedException extends Error {
  constructor() {
    super('Method is not implemented.');

    Object.setPrototypeOf(this, NotImplementedException.prototype);
  }
}

export class Optional<T> {
  private static readonly EmptyToStringValue: string = 'empty';

  private readonly value: T | null;

  private constructor(value?: T | null) {
    this.value = value;
  }

  public static empty<T>(): Optional<T> {
    return new Optional<T>();
  }

  public static of<T>(value: NonNullable<T>): Optional<T> {
    return new Optional<T>(value);
  }

  public static ofNullable<T>(value: T): Optional<T> {
    return value ? Optional.of<T>(value) : Optional.empty<T>();
  }

  public equals(obj: Object): boolean {
    return obj instanceof Optional && isEqual(this.value, obj.value);
  }

  public filter(predicate: Predicate<T>): Optional<T> {
    const predicatePassed = this.isPresent() && predicate(this.get());

    return predicatePassed ? this : Optional.empty();
  }

  public flatMap<U>(mapper: Function<T, Optional<U>>): Optional<U> {
    return this.isPresent() ? mapper(this.get()) : Optional.empty();
  }

  private throwNoElementError(): void {
    throw new NoSuchElementException();
  }

  public get(): T {
    if (this.isEmpty()) {
      this.throwNoElementError();
    }

    return this.value;
  }

  public hashCode() {
    throw new NotImplementedException();
  }

  public ifPresent(consumer: Consumer<T>): void {
    if (this.isEmpty()) {
      return;
    }

    consumer(this.get());
  }

  public async ifPresentAsync(consumer: AsyncConsumer<T>): Promise<void> {
    if (this.isEmpty()) {
      return;
    }

    await consumer(this.get());
  }

  public ifPresentOrElse(consumer: Consumer<T>, emptyAction: Runnable): void {
    const actionSupplier = this.isPresent()
      ? () => consumer(this.get())
      : emptyAction;

    actionSupplier();
  }

  public async ifPresentOrElseAsync(
    consumer: AsyncConsumer<T>,
    emptyAction: AsyncRunnable,
  ): Promise<void> {
    const actionSupplier = this.isPresent()
      ? () => consumer(this.get())
      : emptyAction;

    await actionSupplier();
  }

  public isEmpty(): boolean {
    return !this.value;
  }

  public isPresent(): boolean {
    return !!this.value;
  }

  public map<U>(mapper: Function<T, U>): Optional<U> {
    return this.isPresent()
      ? Optional.ofNullable(mapper(this.get()))
      : Optional.empty();
  }

  public or(supplier: Supplier<Optional<T>>): Optional<T> {
    return this.isPresent() ? this : supplier();
  }

  public async orAsync(
    supplier: AsyncSupplier<Optional<T>>,
  ): Promise<Optional<T>> {
    return this.isPresent() ? Promise.resolve(this) : supplier();
  }

  public orElse(otherwise: T): T {
    return this.isPresent() ? this.get() : otherwise;
  }

  public orElseGet(supplier: Supplier<T>): T {
    return this.isPresent() ? this.get() : supplier();
  }

  public async orElseGetAsync(supplier: AsyncSupplier<T>): Promise<T> {
    return this.isPresent() ? Promise.resolve(this.get()) : supplier();
  }

  public orElseThrow<X extends Error>(exceptionSupplier?: Supplier<X>): T {
    if (this.isEmpty()) {
      const errorSupplier = exceptionSupplier ?? this.throwNoElementError;

      errorSupplier();
    }

    return this.get();
  }

  public async orElseThrowAsync<X extends Error>(
    exceptionSupplier?: AsyncSupplier<X>,
  ): Promise<T> {
    if (this.isEmpty()) {
      const errorSupplier =
        exceptionSupplier ??
        (async () => {
          this.throwNoElementError();
        });

      await errorSupplier();
    }

    return Promise.resolve(this.get());
  }

  public toString(): string {
    return this.isPresent()
      ? JSON.stringify(this.get())
      : Optional.EmptyToStringValue;
  }
}
