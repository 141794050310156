import React, { useContext, useEffect, useMemo, useState } from 'react';
import { PrivateOffer } from '../../../generic/api/types/PrivateOffer';
import ApiContext from '../../../generic/ApiContextProvider/apiContext';
import AmendmentContext, {
  AgreementContextValues,
} from 'pages/PrivateOffers/pages/Next/aws/edit/AmendmentContext/AmendmentContext';
import { Loader } from '@tackle-io/platform-ui';
import OfferContext from '../../../generic/OfferContext/offerContext';
import { mergeAgreementAndSourceOffers } from './utils/mergeAgreementAndSourceOfferUtils';
import { Agreement } from '../../../generic/api/types/Agreement';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';

interface AmendmentContextProviderProps {
  offerId: string;
  children: React.ReactElement;
}

const AmendmentContextProvider: React.FunctionComponent<
  AmendmentContextProviderProps
> = ({ offerId, children }) => {
  const {
    api: {
      getAgreementMappedAsPrivateOffer,
      getAgreementsForBuyer,
      getOfferForAgreement,
    },
  } = useContext(ApiContext);

  const [currentOfferId, setCurrentOfferId] = useState<string | null>(null);
  const { mode } = useContext(OfferPageContext);
  const { offer } = useContext(OfferContext);
  const [sourceOffer, setSourceOffer] = useState<PrivateOffer | null>(null);
  const [agreement, setAgreement] = useState<Agreement | null>(null);

  const [agreementOffer, setAgreementOffer] = useState<PrivateOffer | null>(
    null,
  );

  const [mergedAgreementAndSourceOffer, setMergedAgreementAndSourceOffer] =
    useState<PrivateOffer | null>(null);

  const loadingAgreementOffer = useMemo(
    () => !!offerId && offerId !== currentOfferId,
    [offerId, currentOfferId],
  );

  useEffect(() => {
    if (!offerId) {
      return;
    }

    (async () => {
      const mappedOffer = await getAgreementMappedAsPrivateOffer(offerId);

      const sourceOffer =
        mode === OfferPageMode.Amend
          ? offer
          : await getOfferForAgreement(offerId);

      const agreements =
        (await getAgreementsForBuyer(
          mappedOffer.buyerBillingAccountRef,
          offerId,
        )) || [];

      const matchingAgreement = agreements.find((a) => a.offerId === offerId);

      const mappedOfferWithProductId = {
        ...mappedOffer,
        productId: matchingAgreement?.productId,
        pricing: {
          ...(mappedOffer.pricing || {}),
          serviceStartAt: matchingAgreement?.startDate,
          serviceEndAt: matchingAgreement?.endDate,
        },
      };

      const mergedOffer = mergeAgreementAndSourceOffers(
        mappedOfferWithProductId,
        offer,
      );

      setSourceOffer(sourceOffer);
      setAgreementOffer(mappedOffer);
      setMergedAgreementAndSourceOffer(mergedOffer);
      setAgreement(matchingAgreement);
      setCurrentOfferId(offerId);
    })();

    return () => {
      setSourceOffer(null);
      setAgreementOffer(null);
      setMergedAgreementAndSourceOffer(null);
      setAgreement(null);
      setCurrentOfferId(null);
    };
  }, [
    getAgreementMappedAsPrivateOffer,
    mode,
    getOfferForAgreement,
    getAgreementsForBuyer,
    offerId,
    offer,
  ]);

  const contextValues: AgreementContextValues = {
    offerId,
    sourceOffer,
    agreementOffer,
    mergedAgreementAndSourceOffer,
    agreement,
    loadingAgreementOffer,
  };

  return loadingAgreementOffer && mode !== OfferPageMode.View ? (
    <Loader />
  ) : (
    <AmendmentContext.Provider value={contextValues}>
      {children}
    </AmendmentContext.Provider>
  );
};

export default AmendmentContextProvider;
