import { useCallback } from 'react';
import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import type { OffersCanvasAppContentPayload } from 'packages/salesforce-canvas/src/lib/salesforceCanvasSession';

const domain = 'offers';

const useOffersCanvasActions = () => {
  const { canvasClient, namespace, record } = useCanvasSession();

  const navigate = useCallback(
    (
      navAction:
        | OffersCanvasAppContentPayload
        | {
            action: 'go-back-to-opportunity-root';
          },
    ) => {
      Sfdc.canvas.client.publish(canvasClient, {
        name: `${namespace}.${domain}`,
        payload: {
          navigate:
            navAction.action === 'go-back-to-opportunity-root'
              ? 'TackleWidget'
              : 'CanvasApp',
          domain: 'offers',
          record: record,
          ...navAction,
        },
      });
    },
    [canvasClient, namespace, record],
  );

  return {
    navigate,
  };
};

export default useOffersCanvasActions;
