import React, { useState } from 'react';

import PageBannerDismissable from '../../../../../components/PageBannerDismissable/PageBannerDismissable';
import { TEST_ENVIRONMENT_BANNER_TEXT } from '../../../../../AppLayout';
import { AlertBox } from 'mdi-material-ui';
import useStyles from './CoSellTestEnvironmentBanner.styles';
import { useTheme } from '../../../../../vendor/material';
import { useIsDemoEnvironment } from '../../../../../hooks';

export const CoSellTestEnvironmentBanner = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [isTestEnvBannerOpen, setIsTestEnvBannerOpen] = useState(true);
  const isDemoEnvironment = useIsDemoEnvironment();
  const showBanner = isTestEnvBannerOpen && isDemoEnvironment;

  return (
    <div className={classes.root}>
      {showBanner && (
        <PageBannerDismissable
          text={TEST_ENVIRONMENT_BANNER_TEXT}
          icon={
            <AlertBox
              role={'img'}
              aria-hidden={false}
              htmlColor={theme.palette.NEUTRAL700}
            />
          }
          className={classes.pageBanner}
          bannerColor={theme.palette.YELLOW300}
          textColor={theme.palette.NEUTRAL700}
          onClose={() => setIsTestEnvBannerOpen(false)}
        />
      )}
    </div>
  );
};
