import { useMutation, useQuery } from '@tanstack/react-query';
import coSellClient from '../coSellClient';
import { TackleOperationId } from '../utils';

export interface HandleUpdateAcceptInvitationArgs {
  invitationId: string;
}

export interface HandleUpdateRejectInvitationArgs
  extends HandleUpdateAcceptInvitationArgs {
  rejectionReason: string;
}
export const POLLING_MS = 3000;

export const useAceInvitation = ({
  invitationId,
  enablePolling,
}: {
  invitationId?: string;
  enablePolling?: boolean;
}) => {
  const aceInvitationQuery = useQuery({
    queryKey: ['co-sell-aws-invitation', invitationId],
    queryFn: () => coSellClient.getInvitationById(invitationId!),
    enabled: !!invitationId,
    refetchInterval: enablePolling ? POLLING_MS : 0,
  });

  const acceptInvitation = useMutation({
    mutationFn: async ({ invitationId }: HandleUpdateAcceptInvitationArgs) =>
      await coSellClient.acceptOrRejectInvitation({
        invitationId,
        tackleOperationId: TackleOperationId.ACCEPT_INVITATION,
        body: {},
      }),
  });
  const rejectInvitation = useMutation({
    mutationFn: async ({
      rejectionReason,
      invitationId,
    }: HandleUpdateRejectInvitationArgs) =>
      await coSellClient.acceptOrRejectInvitation({
        invitationId,
        tackleOperationId: TackleOperationId.REJECT_INVITATION,
        body: { rejectionReason },
      }),
  });

  return {
    aceInvitationQuery,
    acceptInvitation,
    rejectInvitation,
  };
};
