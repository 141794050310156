export const additionalFieldKeysToOmit = new Set<string>([
  'AWS Account Number',
  'CPPO Opportunity ID',
  'Partner AWS Account Number',
]);

export const registrationFieldKeysToOmit = new Set<string>([
  'Company',
  'Full Name',
  'Email Address',
  'AWS Account ID',
]);
