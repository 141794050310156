import * as yup from 'utils/yup-extended';
import { ResellerAgreementType } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { isWithProgress } from 'pages/PrivateOffers/pages/Next/generic/utils/schema/schemaTestsUtils';

export enum FieldKey {
  ResellerAgreementType = 'resellerAgreementType',
  ResellerAgreementDocumentUrns = 'resellerAgreementDocumentUrns',
}

export type FormValues = {
  [FieldKey.ResellerAgreementType]: string | null;
  [FieldKey.ResellerAgreementDocumentUrns]: string[];
};

const documentLengthErrorMessage =
  'A single document is required with a mx of 5.';

const resellerAgreementTypeSchema = yup
  .string()
  .oneOf(Object.values(ResellerAgreementType));

const resellerDocumentUrnsSchema = yup.array<string>().when('$', {
  is: isWithProgress(
    FieldKey.ResellerAgreementType,
    (rat: ResellerAgreementType) => rat === ResellerAgreementType.Custom,
  ),
  then: (schema: yup.ArraySchema<string>) =>
    schema
      .min(1, documentLengthErrorMessage)
      .max(5, documentLengthErrorMessage)
      .required()
      .progress(true),
  otherwise: (schema: yup.ArraySchema<string>) =>
    schema.max(5, 'Maximum of 5 documents allowed.'),
});

export const formSchema: yup.ObjectSchema<FormValues> = yup.object({
  [FieldKey.ResellerAgreementType]: resellerAgreementTypeSchema,
  [FieldKey.ResellerAgreementDocumentUrns]: resellerDocumentUrnsSchema,
});
