import Lottie, { LottieComponentProps } from 'lottie-react';
import animationData from './tackle-prospect-off-screen-animation.json';

const TackleCoSellAnimatedHandshake = (
  props: Omit<LottieComponentProps, 'animationData'>,
) => (
  <Lottie
    animationData={animationData}
    loop={false}
    style={{ width: 'auto', height: '100%', maxHeight: '400px' }}
    {...props}
  />
);

export default TackleCoSellAnimatedHandshake;
