import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  offerTypeContainer: {
    border: '1px dotted',
    borderColor: theme.palette.NEUTRAL400,
    borderRadius: 5,
    width: '100%',
    height: '100%',
  },
  blueGray: {
    color: theme.palette.NEUTRAL700,
  },
  lightGray: {
    color: theme.palette.NEUTRAL300,
  },
  offerTypeContainerSelected: {
    border: '1px solid',
    borderColor: theme.palette.BLUE400,
  },
  optionTitle: {
    fontWeight: 600,
    color: theme.palette.NEUTRAL700,
  },
  optionSubtitle: {
    fontWeight: 300,
    color: theme.palette.NEUTRAL300,
  },
  optionLabel: {
    alignItems: 'flex-start',
    marginLeft: 0,
  },
}));

export default useStyles;
