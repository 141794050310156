const PartnerOfferSvg = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_842_543)">
      <path
        d="M26.3196 12.6549L21.6598 7.99506L6.71155 22.9433L11.3714 27.6031L26.3196 12.6549Z"
        fill="#FF8C24"
      />
      <path
        d="M42.0872 7.99806L37.4274 12.6579L52.3756 27.6061L57.0354 22.9463L42.0872 7.99806Z"
        fill="#19C8B3"
      />
      <path
        d="M48.28 47.04L51.08 49.84"
        stroke="#FF8C24"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M52.51 51.27L53.91 52.67"
        stroke="#FF8C24"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M50.39 43.46L54.19 44.57"
        stroke="#FF8C24"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M56.13 45.14L58.03 45.69"
        stroke="#FF8C24"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M44.6901 49.13L45.8001 52.94"
        stroke="#FF8C24"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M46.3601 54.88L46.9201 56.78"
        stroke="#FF8C24"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M15.03 47.04L12.23 49.84"
        stroke="#1AC9B5"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M10.8 51.27L9.40002 52.67"
        stroke="#1AC9B5"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M12.92 43.46L9.12 44.57"
        stroke="#1AC9B5"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M7.18003 45.14L5.28003 45.69"
        stroke="#1AC9B5"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M18.62 49.13L17.51 52.94"
        stroke="#1AC9B5"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M16.94 54.88L16.39 56.78"
        stroke="#1AC9B5"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M51.79 23.51C52.3423 23.51 52.79 23.0623 52.79 22.51C52.79 21.9577 52.3423 21.51 51.79 21.51C51.2378 21.51 50.79 21.9577 50.79 22.51C50.79 23.0623 51.2378 23.51 51.79 23.51Z"
        fill="#063649"
      />
      <path
        d="M16.18 39.18C15.57 39.18 14.95 38.95 14.48 38.48C13.54 37.54 13.54 36.02 14.48 35.09L20.47 29.1C21.41 28.16 22.93 28.16 23.87 29.1C24.81 30.04 24.81 31.56 23.87 32.49L17.88 38.48C17.41 38.95 16.8 39.18 16.18 39.18Z"
        stroke="#063649"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M32.71 18.86L26.87 24.7C26.38 25.19 25.74 25.43 25.1 25.43C24.46 25.43 23.82 25.19 23.33 24.7C22.35 23.72 22.35 22.14 23.33 21.16L29.57 14.92C30.55 13.94 32.13 13.94 33.11 14.92C33.11 14.92 34.41 16.8 35.53 16.83C37.74 16.9 39.09 14.31 39.09 14.31"
        stroke="#063649"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M19.48 42.68C18.87 42.68 18.25 42.45 17.78 41.98C16.84 41.04 16.84 39.52 17.78 38.59L23.77 32.6C24.71 31.66 26.23 31.66 27.16 32.6C28.1 33.54 28.1 35.06 27.16 35.99L21.17 41.98C20.7 42.45 20.09 42.68 19.47 42.68H19.48Z"
        stroke="#063649"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M22.81 46.13C22.2 46.13 21.58 45.9 21.11 45.43C20.17 44.49 20.17 42.97 21.11 42.04L26 37.15C26.94 36.21 28.46 36.21 29.39 37.15C30.33 38.09 30.33 39.61 29.39 40.54L24.5 45.43C24.03 45.9 23.42 46.13 22.8 46.13H22.81Z"
        stroke="#063649"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M26.17 49.55C25.56 49.55 24.94 49.32 24.47 48.85C23.53 47.91 23.53 46.39 24.47 45.46L27.94 41.99C28.88 41.05 30.4 41.05 31.34 41.99C32.28 42.93 32.28 44.45 31.34 45.38L27.87 48.85C27.4 49.32 26.79 49.55 26.17 49.55Z"
        stroke="#063649"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M34.3299 42.8L37.0799 45.55C38.0599 46.53 38.0599 48.11 37.0799 49.09C36.5899 49.58 35.9499 49.82 35.3099 49.82C34.6699 49.82 34.0299 49.58 33.5399 49.09L30.6899 46.24"
        stroke="#063649"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M40.59 41.96C41.57 42.94 41.57 44.52 40.59 45.5C40.1 45.99 39.46 46.23 38.82 46.23C38.18 46.23 37.54 45.99 37.05 45.5"
        stroke="#063649"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M44.17 38.43C45.15 39.41 45.15 40.99 44.17 41.97C43.68 42.46 43.04 42.7 42.4 42.7C41.76 42.7 41.12 42.46 40.63 41.97L37.74 39.08"
        stroke="#063649"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M32.99 20.21L47.7 34.92C48.68 35.9 48.68 37.48 47.7 38.46C47.21 38.95 46.57 39.19 45.93 39.19C45.29 39.19 44.65 38.95 44.16 38.46L40.95 35.25"
        stroke="#063649"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.0601 27.02L17.5001 32.46"
        stroke="#063649"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M28.39 16.11L25.77 13.49"
        stroke="#063649"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M26.3196 12.6549L21.6598 7.99506L6.71155 22.9433L11.3714 27.6031L26.3196 12.6549Z"
        stroke="#063649"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0699 14.1C21.6222 14.1 22.0699 13.6523 22.0699 13.1C22.0699 12.5477 21.6222 12.1 21.0699 12.1C20.5177 12.1 20.0699 12.5477 20.0699 13.1C20.0699 13.6523 20.5177 14.1 21.0699 14.1Z"
        fill="#063649"
      />
      <path
        d="M42.0872 7.99806L37.4274 12.6579L52.3756 27.6061L57.0354 22.9463L42.0872 7.99806Z"
        stroke="#063649"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.9 32.12L50.79 26.23"
        stroke="#063649"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_842_543">
        <rect
          width="53.31"
          height="50.06"
          fill="white"
          transform="translate(5 7)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PartnerOfferSvg;
