import { ValidationError } from 'yup';
import { FieldKey as ProductAndPricingFieldKey } from '../../formTypes';
import { getUtcDateTimeFromSerializedIsoDateTime } from '../../../../../../generic/utils/date/dateUtils';
import { FieldKeys, FormSchedule } from './formTypes';
import { Optional } from 'utils/optional/optional';
import {
  combineValidationErrors,
  hasValidationError,
} from '../../../../../../generic/utils/schema/schemaTestsUtils';

const toDuplicateInvoiceDateError = (
  invoiceDateInSeconds: number | null,
  index: number,
  array: (number | null)[],
): ValidationError | null => {
  const duplicateInvoiceDatesExist = array.some(
    (s, i) =>
      index !== i && invoiceDateInSeconds && s && invoiceDateInSeconds === s,
  );

  return duplicateInvoiceDatesExist
    ? new ValidationError(
        `Invoice dates must be unique`,
        null,
        `${ProductAndPricingFieldKey.Schedules}.${index}.${FieldKeys.InvoiceDate}`,
      )
    : null;
};

const toInvoiceDateSeconds = (s: FormSchedule): number | null => {
  const dateTime = getUtcDateTimeFromSerializedIsoDateTime(s.invoiceDate);

  return dateTime?.toSeconds();
};

export const duplicateInvoiceDatesTest = (
  schedules: FormSchedule[],
): boolean | ValidationError => {
  const errors = Optional.ofNullable(schedules)
    .map((s) =>
      s
        .map(toInvoiceDateSeconds)
        .map(toDuplicateInvoiceDateError)
        .filter(hasValidationError),
    )
    .orElse([]);

  return (
    errors.length === 0 ||
    combineValidationErrors(errors, ProductAndPricingFieldKey.Schedules)
  );
};
