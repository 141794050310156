import {
  AceOpportunityResponse,
  IncompleteAceOpportunityResponse,
} from 'packages/cosell/src/types/responses/AceOpportunityResponse';

/**
 * Utility to check if an Ace opportunity is in the process of being created.
 */
export default function isOpportunityBeingCreatedCheck({
  data,
}: {
  data: AceOpportunityResponse | IncompleteAceOpportunityResponse | undefined;
}) {
  if (!data) return false;

  const keys = Object.keys(data);
  /** if it is being created:
   *  - it will only contain metadata key (will be missing opportunity data)
   *  - there wont be errors
   *  - pendingRequestIds will be present if still processing,
   *    but not necessarily complete if pendingRequestIds is empty or undefined.
   *  - if data.isCreatedInCloud, it is created
   */

  return (
    keys.length === 2 &&
    keys.includes('metadata') &&
    !data.metadata?.hasCloudErrors &&
    (!!data.metadata?.pendingRequestIds?.length ||
      data.isCreatedInCloud === false)
  );
}
