import { FormSections, formSectionToTitle } from '../formSections';
import { ClipboardText, Delete } from 'mdi-material-ui';
import { useContext, useEffect } from 'react';
import { Button, Card, Divider, TextField } from '@tackle-io/platform-ui';
import ModifiedTextField from '../../../../../../../../components/FieldsPricing/TextField/TextField';
import { Grid, IconButton } from 'vendor/material';
import { Field, FieldArray, useFormikContext } from 'formik';
import { FieldKey } from './formSchema';
import { FormValues as FullFormValues } from '../formSchema';
import { DataId } from '../../../../generic/analytics';
import { getFormattedError } from '../../../../generic/utils/field/fieldUtils';
import { findMatchingProduct } from 'pages/PrivateOffers/pages/Next/generic/utils/product/productUtils';
import { Optional } from 'utils/optional/optional';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';

const fieldKeyToLabel: { [fk: string]: string } = {
  [FieldKey.MarketplaceFee]: 'Marketplace fee',
};

const AdditionalFieldsFormSection = () => {
  const { values, setFieldValue } = useFormikContext<FullFormValues>();
  const { productRef } = values;
  const { productsByProductId } = useContext(OfferPageContext);
  const { offerForMode: offer } = useContext(OfferContext);

  const createdInMarketplaceAt = offer?.createdInMarketplaceAt;

  useEffect(() => {
    (async () => {
      const matchingProduct = findMatchingProduct(
        productRef,
        productsByProductId,
      );

      await Optional.ofNullable(matchingProduct?.contractStandardFields)
        .map((contractStandardFields) => {
          const existingAdditionalFields = values.offerMetadataFields;
          const existingAdditionalFieldTitles = new Set<string>(
            existingAdditionalFields.map((f) => f.key),
          );
          const fieldTitlesFromProduct = new Set<string>(
            contractStandardFields.map((f) => f.title),
          );
          const existing = existingAdditionalFields.map((f) => ({
            [FieldKey.OfferMetadataFieldLabel]: f.key,
            [FieldKey.OfferMetadataFieldValue]: f.value,
            [FieldKey.IsDisabled]: fieldTitlesFromProduct.has(f.key),
          }));
          const newAdditionalFieldsFromProduct = contractStandardFields
            .filter((f) => !existingAdditionalFieldTitles.has(f.title))
            .map((f) => ({
              [FieldKey.OfferMetadataFieldLabel]: f.title,
              [FieldKey.OfferMetadataFieldValue]: '',
              [FieldKey.IsDisabled]: true,
            }));

          return [...newAdditionalFieldsFromProduct, ...existing];
        })
        .ifPresentAsync(async (v) => {
          await setFieldValue(FieldKey.OfferMetadataFields, v);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRef, productsByProductId]);

  return (
    <div id={FormSections.AdditionalFields}>
      <Card
        title={`${
          formSectionToTitle[FormSections.AdditionalFields]
        } (optional)`}
        subtitle="Enter custom fields and values for a complete bookable artifact. Marketplace fee is optional."
        icon={<ClipboardText />}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Field name={FieldKey.MarketplaceFee}>
              {({ field, meta }) => (
                <ModifiedTextField
                  {...field}
                  mode="numberformat"
                  fixedDecimalScale
                  suffix="%"
                  numberPrefix=""
                  type="number"
                  data-id={DataId.MarketplaceFeeField}
                  label={`${fieldKeyToLabel[FieldKey.MarketplaceFee]}`}
                  error={getFormattedError(
                    FieldKey.MarketplaceFee,
                    fieldKeyToLabel,
                    meta,
                  )}
                  helperText="Optional: Used in order notifications and reporting for accurate gross and net total contract value amounts."
                  disabled={!!createdInMarketplaceAt}
                />
              )}
            </Field>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>

          <FieldArray name={FieldKey.OfferMetadataFields}>
            {({ form, push, remove }) => (
              <>
                {form.values[FieldKey.OfferMetadataFields].map(
                  (val, index: number) => (
                    <Grid
                      item
                      container
                      spacing={2}
                      alignItems="flex-end"
                      key={`additional-field-${index}`}
                    >
                      <Grid item xs>
                        <Field
                          name={`${FieldKey.OfferMetadataFields}[${index}].${FieldKey.OfferMetadataFieldLabel}`}
                        >
                          {({ field, meta }) => (
                            <TextField
                              {...field}
                              disabled={val?.[FieldKey.IsDisabled]}
                              data-id={DataId.OfferMetaDataLabelField}
                              label="Label"
                              error={getFormattedError(
                                FieldKey.OfferMetadataFieldLabel,
                                fieldKeyToLabel,
                                meta,
                              )}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs>
                        <Field
                          name={`${FieldKey.OfferMetadataFields}[${index}].${FieldKey.OfferMetadataFieldValue}`}
                        >
                          {({ field, meta }) => (
                            <TextField
                              {...field}
                              data-id={DataId.OfferMetaDataValueField}
                              label="Value"
                              error={getFormattedError(
                                FieldKey.OfferMetadataFieldValue,
                                fieldKeyToLabel,
                                meta,
                              )}
                            />
                          )}
                        </Field>
                      </Grid>
                      {!val?.[FieldKey.IsDisabled] && (
                        <Grid item>
                          <IconButton onClick={() => remove(index)}>
                            <Delete />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  ),
                )}
                <Grid item>
                  <Button
                    appearance="primary"
                    variant="text"
                    onClick={() => {
                      const newAdditionalField = {
                        [FieldKey.OfferMetadataFieldLabel]: '',
                        [FieldKey.OfferMetadataFieldValue]: '',
                        [FieldKey.IsDisabled]: false,
                      };
                      push(newAdditionalField);
                    }}
                  >
                    Add field
                  </Button>
                </Grid>
              </>
            )}
          </FieldArray>
        </Grid>
      </Card>
    </div>
  );
};

export default AdditionalFieldsFormSection;
