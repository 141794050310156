import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from 'vendor/material/index';
import { AlertCircle } from 'mdi-material-ui';
import { Banner } from '@tackle-io/platform-ui';
import React from 'react';

interface MarketplaceErrorsBannerProps {
  marketplaceErrors: string[];
}

const useStyles = makeStyles((theme) => ({
  errorListItem: {
    alignItems: 'flex-start',
    paddingTop: 0,
    paddingBottom: 0,
  },
  errorListItemIcon: {
    marginTop: theme.spacing(0.5),
    minWidth: 0,
    paddingRight: theme.spacing(1),
  },
}));

const MarketplaceErrorsBanner: React.FunctionComponent<
  MarketplaceErrorsBannerProps
> = ({ marketplaceErrors }) => {
  const classes = useStyles();

  return (
    <Banner
      type="danger"
      borderPosition="top"
      title="Marketplace error"
      body={
        <List disablePadding>
          {marketplaceErrors.map((error: string, i: number) => (
            <ListItem
              key={`marketplace-error-${i}`}
              className={classes.errorListItem}
            >
              <ListItemIcon className={classes.errorListItemIcon}>
                <AlertCircle />
              </ListItemIcon>
              <ListItemText primary={error} />
            </ListItem>
          ))}
        </List>
      }
      isCollapsible
      defaultOpen
    />
  );
};

export default MarketplaceErrorsBanner;
