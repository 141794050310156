import { makeStyles } from 'vendor/material';
import { ThemeTackle } from 'Theme';

const useStyles = makeStyles((theme: ThemeTackle) => {
  return {
    root: {
      display: 'flex',
    },
    pageBanner: {
      position: 'sticky',
      top: 0,
      zIndex: theme.zIndex.appBar,
    },
  };
});

export default useStyles;
