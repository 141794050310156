const getFilenameWithNumber = (filename: string, index: number): string => {
  const fileTypeSeparatorIndex = filename.lastIndexOf('.');

  const filenameUpToTypeSeparator = filename.substring(
    0,
    fileTypeSeparatorIndex,
  );

  const fileType = filename.substring(
    fileTypeSeparatorIndex + 1,
    filename.length,
  );

  return `${filenameUpToTypeSeparator}${index}.${fileType}`;
};

export const findNextUniqueFilenameIndex = (
  filename: string,
  existingFilenames: string[],
  searchingIndex: number = 0,
): number => {
  const potentialUniqueFilename =
    searchingIndex === 0
      ? filename
      : getFilenameWithNumber(filename, searchingIndex);

  const filenameMatchesExistingFilename = existingFilenames.some(
    (fn) => fn === potentialUniqueFilename,
  );

  return filenameMatchesExistingFilename
    ? findNextUniqueFilenameIndex(
        filename,
        existingFilenames,
        searchingIndex + 1,
      )
    : searchingIndex;
};

export const getFileWithUniqueFilename = (
  file: File,
  uniqueFilenameIndex: number,
): File => {
  const { name, type } = file;
  const uniqueFilename = getFilenameWithNumber(name, uniqueFilenameIndex);

  return new File([file], uniqueFilename, { type });
};
