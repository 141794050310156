import { useState } from 'react';
import { Banner } from '@tackle-io/platform-ui';
import { theme } from 'ui';

/**
 * displays a list of fields that were not mapped due to errors
 * with default title and message
 */
export const DataMapperFieldErrorsBanner = ({
  defaultCollapsed = false,
  errorFields,
  title = 'Some fields could not be populated from the opportunity.',
  message = 'The following fields do not have data from the opportunity due to a field mapping error. You will need to complete required fields before submitting to the cloud.',
}: {
  defaultCollapsed?: boolean;
  errorFields: string[];
  message?: string;
  title?: string;
}) => {
  const [isOpen, setIsOpen] = useState(!defaultCollapsed);

  const bodyContent = (
    <>
      <p style={{ marginTop: 0 }}>{message}</p>
      <ul
        style={{
          marginTop: 0,
          paddingInline: theme.spacing(3),
        }}
      >
        {errorFields.map((field) => (
          <li key={field}>{field}</li>
        ))}
      </ul>
    </>
  );

  return (
    <Banner
      body={bodyContent}
      borderPosition="top"
      isCollapsible
      onChange={() => setIsOpen((current) => !current)}
      open={isOpen}
      title={title}
      type="warning"
    />
  );
};
