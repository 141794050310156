import TackleProspectCallToActionAnimatedSvg from 'components/AnimatedSvgs/TackleProspectCallToActionAnimatedSvg';
import { Grid, makeStyles, Typography } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    background: 'linear-gradient(to bottom, #FFFFFF99, #DEF0F399)',
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    textWrap: 'balance',
  },
  svgContent: {
    flex: 1,
    alignSelf: 'center',
  },
  title: {
    fontSize: '32px',
    marginBlockEnd: '1rem',
  },
}));

const ProspectComingSoon = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item xs container direction="column" justifyContent="center">
        <Grid item>
          <Typography className={classes.title}>Coming soon!</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.subTitle}>
            Scoring for all your Salesforce accounts with Tackle Prospect.
          </Typography>
        </Grid>
        <Grid className={classes.svgContent} item xs={9}>
          <TackleProspectCallToActionAnimatedSvg />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProspectComingSoon;
