import {
  getUtcDateTimeFromSerializedIsoDateTime,
  startOfTodayAsUtcDatetime,
} from 'pages/PrivateOffers/pages/Next/generic/utils/date/dateUtils';
import {
  PaymentModel,
  PrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';

const expirationDateIsBeforeToday = (offerExpirationAt: string): boolean => {
  const today = startOfTodayAsUtcDatetime().startOf('day');
  const expiresAt =
    getUtcDateTimeFromSerializedIsoDateTime(offerExpirationAt).startOf('day');

  return expiresAt < today;
};

export const isOfferExpired = (offer: PrivateOffer): boolean =>
  !offer.acceptedAt && expirationDateIsBeforeToday(offer.offerExpirationAt);

export const paymentModelResourceText: { [pm: string]: string } = {
  [PaymentModel.PayGo]: 'Pay as you go',
  [PaymentModel.PaymentSchedule]: 'Payment schedule',
  [PaymentModel.PerProduct]: 'Upfront pricing',
};
