import React from 'react';
import { Box, Typography } from 'vendor/material';

interface OfferFormSectionProps {
  title: string;
  subtitle?: string;
  children: React.ReactNode | React.ReactNode[];
}

export const sectionHeaderStyle = { fontWeight: 700 };
export const sectionHeaderSubtitleStyle = { fontWeight: 300 };

const OfferFormSection: React.FunctionComponent<OfferFormSectionProps> = ({
  title,
  subtitle,
  children,
}) => (
  <Box mb={4}>
    <Box mb={2}>
      <Box mb={1}>
        <Typography style={sectionHeaderStyle}>{title}</Typography>
      </Box>
      <Box>
        <Typography style={sectionHeaderSubtitleStyle}>{subtitle}</Typography>
      </Box>
    </Box>
    {children}
  </Box>
);

export default OfferFormSection;
