const NewsFeedSvg = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2.19995V16.2H2V2.19995H16ZM18 0.199951H0V18.2H18V0.199951ZM14 14.2H4V13.2H14V14.2ZM14 12.2H4V11.2H14V12.2ZM14 9.19995H4V4.19995H14V9.19995Z"
      fill="#253858"
    />
  </svg>
);

export default NewsFeedSvg;
