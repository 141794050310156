import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  content: {
    color: theme.palette.NEUTRAL700,
    height: '100%',
    overflowY: 'auto',
    overflowX: 'clip',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    lineHeight: theme.typography.pxToRem(20),
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(2),
    scrollbarColor: `${theme.palette.NEUTRAL100} ${theme.palette.NEUTRAL020}`,
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
      borderRadius: 0,
      backgroundColor: theme.palette.NEUTRAL020,
    },
    '&::-webkit-scrollbar': {
      width: 3,
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
      backgroundColor: theme.palette.NEUTRAL100,
    },
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  lastListItem: {
    borderBottom: 'none',
  },
}));

export default useStyles;
