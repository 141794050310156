import { Product } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';

export const findMatchingProduct = (
  productRef: string,
  productsByProductId: { [pId: string]: Product },
): Product => productsByProductId[productRef];

export const toMatchingProduct =
  (productsByProductId: { [pId: string]: Product }) =>
  (productRef: string): Product =>
    findMatchingProduct(productRef, productsByProductId);
