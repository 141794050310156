import { AceInvolvementTypeEnum } from '../../types/enums/AceInvolvementTypeEnum';
import { AceInvolvementVisibilityEnum } from '../../types/enums/AceInvolvementVisibilityEnum';
import type { PrimaryNeedsFromAws } from 'packages/cosell/src/types/requests/AceOpportunityRequest';

/**
 *  awsSubmission values are based on the primaryNeedsFromAws values.
 */
export const getAwsSubmission = (
  primaryNeedsFromAws: PrimaryNeedsFromAws | null,
) => {
  if (Array.isArray(primaryNeedsFromAws) && !!primaryNeedsFromAws.length) {
    return {
      involvementType: AceInvolvementTypeEnum.CO_SELL,
      visibility: AceInvolvementVisibilityEnum.FULL,
    };
  }
  return {
    involvementType: AceInvolvementTypeEnum.FOR_VISIBILITY_ONLY,
    visibility: AceInvolvementVisibilityEnum.FULL,
  };
};
