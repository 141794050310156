import { Auth0UserJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/Auth0UserJSON';
import { Auth0User } from 'pages/PrivateOffers/pages/Next/generic/api/types/Auth0User';

export const auth0UserJSONsToAuth0Users = (
  users: Auth0UserJSON[],
): Auth0User[] =>
  users.map((u) => ({
    userId: u.user_id,
    email: u.email,
    picture: u.picture,
    name: u.name,
  }));
