import {
  Dimension,
  PrivateOffer,
  UsageDimension,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';

export const mergeAgreementAndSourceOffers = (
  agreementOffer: PrivateOffer,
  sourceOffer: PrivateOffer | null,
): PrivateOffer => {
  const sourceOfferPricing = sourceOffer?.pricing || {};
  const agreementOfferPricing = agreementOffer?.pricing || {};
  const sourceOfferDimensions = sourceOfferPricing.dimensions || [];
  const agreementOfferDimensions = agreementOfferPricing.dimensions || [];
  const sourceOfferUsageDimensions = sourceOfferPricing.usageDimensions || [];
  const agreementOfferSchedules = agreementOfferPricing.schedule || [];
  const sourceOfferSchedules = sourceOfferPricing.schedule || [];

  const agreementOfferUsageDimensions =
    agreementOfferPricing?.usageDimensions || [];

  const sourceOfferDimensionsByApiName = sourceOfferDimensions.reduce(
    (acc, d) => {
      acc[d.apiName] = d;
      return acc;
    },
    {},
  );

  const mergedDimensions = agreementOfferDimensions.map((d): Dimension => {
    const matchingSourceOfferDimension =
      sourceOfferDimensionsByApiName[d.apiName];

    return {
      ...(matchingSourceOfferDimension || {}),
      quantity: d.quantity || matchingSourceOfferDimension?.quantity,
      price: d.price || matchingSourceOfferDimension?.price,
      apiName: d.apiName || matchingSourceOfferDimension?.apiName,
    };
  });

  const sourceOfferUsageDimensionsBySku = sourceOfferUsageDimensions.reduce(
    (acc, d) => {
      acc[d.sku] = d;
      return acc;
    },
    {},
  );

  const mergedUsageDimensions = agreementOfferUsageDimensions.map(
    (d): UsageDimension => {
      const matchingSourceOfferUsageDimension =
        sourceOfferUsageDimensionsBySku[d.sku];

      return {
        ...(matchingSourceOfferUsageDimension || {}),
        sku: d.sku || matchingSourceOfferUsageDimension?.sku,
        price: d.price || matchingSourceOfferUsageDimension?.price,
      };
    },
  );

  return {
    ...(sourceOffer || {}),
    offerType: agreementOffer.offerType || sourceOffer?.offerType,
    offerId: agreementOffer.offerId || sourceOffer?.offerId,
    productId: agreementOffer.productId || sourceOffer?.productId,
    buyerBillingAccountRef:
      agreementOffer.buyerBillingAccountRef ||
      sourceOffer?.buyerBillingAccountRef,
    offerExpirationAt:
      agreementOffer.offerExpirationAt || sourceOffer?.offerExpirationAt,
    pricing: {
      ...sourceOfferPricing,
      dimensions: mergedDimensions,
      usageDimensions: mergedUsageDimensions,
      schedule: agreementOfferSchedules || sourceOfferSchedules || [],
      serviceStartAt:
        agreementOfferPricing.serviceStartAt ||
        sourceOfferPricing.serviceStartAt,
      serviceEndAt:
        agreementOfferPricing.serviceEndAt || sourceOfferPricing.serviceEndAt,
    },
    offerMetadata: {
      ...(sourceOffer?.offerMetadata || {}),
      ...agreementOffer.offerMetadata,
    },
    extraData: {
      ...(sourceOffer?.extraData || { users: [] }),
    },
  };
};
