import { ContractsQuery, ContractUsageDimensions } from 'generated/graphql';
import { DetailItem } from './components/Summary/components/SummaryCard/SummaryCard';
import { DateTime } from 'luxon';
import LinkItem from './components/Summary/components/LinkItem/LinkItem';
import TackleManaged from './components/Summary/components/TackleManaged/TackleManaged';
import { CurrencyCode, formatValueWithCurrencyPrefix } from 'utils/currency';
import { formatCurrency } from 'pages/Contracts/helpers/currencyFormat';
import {
  convertISODuration,
  formatDate,
} from 'pages/Contracts/helpers/dateFormat';

type Contract = ContractsQuery['contracts']['hits']['hits'][number]['_source'];
type ContractDimensions = Contract['productAndPricing']['dimensions'];

const buildRenewByHeader = (contract: Contract): string => {
  switch (contract?.contractStatus) {
    case 'Cancelled':
      return 'Cancelled on';
    case 'Amended':
      return 'Amended on';
    default:
      return 'Renew by';
  }
};

const buildTimeRemainingFooter = (contract: Contract): string | undefined => {
  const now = DateTime.now();
  const schedule = contract?.paymentSchedule?.schedule || [];

  const futureDates = schedule
    .map((item) => DateTime.fromISO(item.chargeDate))
    .filter((date) => date > now);

  if (futureDates.length === 0) {
    return undefined;
  }

  const nextChargeDate = futureDates.reduce((closest, date) => {
    return date < closest ? date : closest;
  });

  const formattedDate = nextChargeDate.toFormat('MMM dd yyyy');
  return `Next invoice on ${formattedDate}`;
};

const buildSummaryItems = (contract: Contract): DetailItem[] => {
  const dateAccepted = formatDate(contract?.acceptedDate);
  const term = convertISODuration(contract?.contractDuration);
  const renewBy = formatDate(contract?.endDate);
  const startDate = formatTermDate(contract?.startDate);
  const endDate = formatTermDate(contract?.endDate);
  const termFooter =
    contract?.endDate || contract?.startDate
      ? `${startDate} - ${endDate}`
      : undefined;
  const timeRemaining =
    contract?.endDate &&
    ['Active', 'Pending'].includes(contract?.contractStatus)
      ? computeTimeRemaining(contract.endDate)
      : '--';

  return [
    {
      header: 'Date accepted',
      content: dateAccepted,
      footer: `Offer ID ${contract?.offerId || '--'}`,
    },
    {
      header: 'Term',
      content: term,
      footer: termFooter,
    },
    {
      header: 'Time remaining',
      content: timeRemaining,
      footer: buildTimeRemainingFooter(contract),
    },
    {
      header: buildRenewByHeader(contract),
      content: renewBy,
    },
  ];
};

const buildTotalContractValueMetric = (contract: Contract): DetailItem[] => {
  const totalContractValue = formatTotalContractValue(contract);
  const netAfterFees = formatCurrency(contract?.totalContractNetValue);
  const feeAmount = formatFeeAmount(contract);
  const footer =
    feeAmount === undefined
      ? undefined
      : `${netAfterFees} net after ${feeAmount} fee`;
  return [
    {
      header: 'Total contract value',
      content: totalContractValue,
      footer,
    },
  ];
};

const formatTotalContractValue = (contract: Contract): string => {
  if (
    contract?.totalContractValue?.amount !== undefined &&
    contract?.totalContractValue?.amount !== null
  ) {
    return formatValueWithCurrencyPrefix(
      contract?.totalContractValue?.amount,
      contract?.totalContractValue?.currencyCode as CurrencyCode,
    );
  }
  return '--';
};

const formatFeeAmount = (contract: Contract): string | undefined => {
  return contract?.totalContractValue?.amount &&
    contract?.totalContractNetValue?.amount
    ? formatCurrency({
        amount:
          contract.totalContractValue.amount -
          contract.totalContractNetValue.amount,
        currencyCode: contract.totalContractNetValue.currencyCode,
      })
    : undefined;
};

const buildTotalDisbursedMetric = (contract: Contract): DetailItem[] => {
  const totalDisbursed = formatCurrency(
    contract?.originalCurrencyGrossDisbursed,
  );
  const netDisbursed = formatCurrency(contract?.originalCurrencyNetDisbursed);

  return [
    {
      header: 'Total disbursed',
      content: totalDisbursed,
      footer: `${netDisbursed} net`,
    },
  ];
};

const buildTotalUninvoicedPaymentsMetric = (
  contract: Contract,
): DetailItem[] => {
  if (!contract?.totalGrossUninvoicedPayment?.amount)
    return [{ header: 'Total uninvoiced payments', content: '--' }];

  const netUninvoicedPayments =
    contract?.totalNetUninvoicePayment?.amount !== undefined
      ? formatCurrency(contract?.totalNetUninvoicePayment)
      : '--';

  return [
    {
      header: 'Total uninvoiced payments',
      content: formatCurrency(contract.totalGrossUninvoicedPayment),
      footer: `${netUninvoicedPayments} net`,
    },
  ];
};

const formatTermDate = (dateString?: string): string => {
  if (!dateString) return '--';
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options).replace(/,/g, '');
};

const computeTimeRemaining = (endDate?: string): string => {
  if (!endDate) return '--';
  const today: Date = new Date();
  const end: Date = new Date(endDate);

  const diff: number = end.getTime() - today.getTime();
  if (diff < 0) return '--';
  const daysRemaining: number = Math.floor(diff / (1000 * 60 * 60 * 24));
  const years: number = Math.floor(daysRemaining / 365);
  const months: number = Math.floor((daysRemaining % 365) / 30);

  const yearText: string =
    years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '';
  const monthText: string =
    months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '';

  return [yearText, monthText].filter(Boolean).join(' and ') || '--';
};

export const buildSummaryDetails = (contract: Contract) => {
  return {
    summaryItems: buildSummaryItems(contract),
    totalContractValueMetric: buildTotalContractValueMetric(contract),
    totalDisbursedMetric: buildTotalDisbursedMetric(contract),
    totalUninvoicedPaymentsMetric: buildTotalUninvoicedPaymentsMetric(contract),
  };
};

const mapDimensionRows = (
  dimensions: ContractDimensions,
): { label: string; value: string }[] => {
  const rows = [];
  for (const dimension of dimensions) {
    rows.push({
      label: 'Dimension',
      value: dimension.dimension || '--',
    });
    rows.push({
      label: 'Api',
      value: dimension.api || '--',
    });
    rows.push({
      label: dimension.quantity ? 'Quantity' : 'Value',
      value: String(dimension.quantity || dimension.value || '--'),
    });
  }
  return rows;
};

const mapUsageDimensionRows = (
  dimensions: ContractUsageDimensions[],
): { label: string; value: string }[] => {
  const rows = [];
  for (const dimension of dimensions) {
    rows.push({
      label: 'Sku',
      value: dimension.api || '--',
    });
    rows.push({
      label: 'Fee amount',
      value: dimension.price ? formatCurrency(dimension.price) : '--',
    });
    rows.push({
      label: 'Description',
      value: dimension.description || '--',
    });
  }
  return rows;
};

const buildOfferId = (contract: Contract) => {
  if (contract?.tackleOfferId) {
    return LinkItem({
      label: contract?.offerId || '--',
      to: `/private-offers/${contract?.tackleOfferId}`,
    });
  }
  return contract?.offerId || '--';
};

const snakeToHumanReadable = (str: string): string => {
  return str
    .split('_')
    .map((word, index) =>
      index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word,
    )
    .join(' ');
};

const mapRegistration = (contract: Contract) => {
  if (!contract?.productAndPricing?.isListingManagedByTackle) return undefined;
  const registration = [
    {
      sectionHeader: 'Registration details',
      rows: [
        { label: 'Company', value: contract?.companyName || '--' },
        { label: 'Buyer', value: contract?.registration?.fullName || '--' },
        { label: 'Buyer email', value: contract?.registration?.email || '--' },
        ...Object.entries(
          contract?.registration?.registrationMetadata || {},
        ).map(([label, value]) => ({
          label: snakeToHumanReadable(label),
          value: String(value) || '--',
        })),
      ],
    },
  ];
  if (contract?.tackleOfferId) {
    registration.push({
      sectionHeader: 'Additional contract details',
      rows: [
        ...Object.entries(
          contract?.registration?.offerMetadata?.offer_metadata || {},
        ).map(([label, value]) => ({ label, value: String(value) || '--' })),
      ],
    });
  }
  return registration;
};

const formatMarketplace = (marketplace?: string): string => {
  switch (marketplace) {
    case 'aws':
      return 'AWS';
    case 'azure':
      return 'Microsoft';
    case 'gcp':
      return 'GCP';
    default:
      return marketplace || '--';
  }
};

const buildAmendedAgreement = (
  contract: Contract,
): { label: string; value: string | JSX.Element } | undefined => {
  if (!contract?.amendedAgreementId) return undefined;

  const value =
    contract?.amendedAgreementExistsInTackle === true
      ? LinkItem({
          label: contract.amendedAgreementId,
          to: `/cloud-contracts/${contract.amendedAgreementId}`,
        })
      : contract.amendedAgreementId;

  return { label: 'Source agreement', value };
};

export const buildContractDetails = (contract: Contract) => {
  const contractBasicInfoRows: {
    label: string;
    value: string | JSX.Element;
  }[] = [
    {
      label: 'Marketplace',
      value: formatMarketplace(contract?.cloudMarketplace),
    },
    { label: 'Agreement ID', value: contract?.id || '--' },
    { label: 'Offer ID', value: buildOfferId(contract) },
  ];
  const amendedAgreement = buildAmendedAgreement(contract);
  if (amendedAgreement) {
    contractBasicInfoRows.push(amendedAgreement);
  }
  contractBasicInfoRows.push(
    ...[
      {
        label: 'Offer accepted date',
        value: formatDate(contract?.acceptedDate),
      },
      { label: 'Offer accepted by', value: contract?.buyerId || '--' },
      { label: 'Contract start', value: contract?.contractStart || '--' },
      {
        label: 'Duration',
        value: convertISODuration(contract?.contractDuration) || '--',
      },
      {
        label: 'Start date',
        value: formatDate(contract?.startDate),
      },
      {
        label: 'End date',
        value: formatDate(contract?.endDate),
      },
      { label: 'Auto renew', value: contract?.autoRenew ? 'Yes' : 'No' },
    ],
  );

  const basicInformation = [
    {
      sectionHeader: 'Contract',
      rows: contractBasicInfoRows,
    },
    {
      sectionHeader: 'Billing Account',
      rows: [
        {
          label: 'Customer Id',
          value: contract?.offerTargetAccount || contract?.buyerId || '--',
        },
      ],
    },
  ];

  const productAndPricing = [
    {
      sectionHeader: 'Product',
      rows: [
        {
          label: 'Product name',
          value: contract?.listingName || '--',
          footer: contract?.productAndPricing?.isListingManagedByTackle
            ? TackleManaged()
            : undefined,
        },
        {
          label: 'Product ID',
          value: contract?.productId || '--',
        },
        { label: 'Product type', value: contract?.listingType || '--' },
      ],
    },
    {
      sectionHeader: 'Pricing',
      rows: [
        { label: 'Pricing model', value: contract?.pricingModel || '--' },
        { label: 'Payment model', value: 'Payment schedule' },
        {
          label: 'Gross',
          value: formatCurrency(contract?.totalContractValue) || '--',
        },
      ],
    },
  ];

  if (
    contract?.productAndPricing?.dimensions &&
    contract?.productAndPricing?.dimensions.length > 0
  ) {
    productAndPricing.push({
      sectionHeader: 'Dimensions',
      rows: mapDimensionRows(contract.productAndPricing.dimensions),
    });
  }

  if (
    contract?.productAndPricing?.usageDimensions &&
    contract?.productAndPricing?.usageDimensions.length > 0
  ) {
    productAndPricing.push({
      sectionHeader: 'Usage Dimensions',
      rows: mapUsageDimensionRows(contract.productAndPricing.usageDimensions),
    });
  }

  return {
    basicInformation,
    productAndPricing,
    registration: mapRegistration(contract),
  };
};
