import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import useCoSellSfCanvasActions from '../hooks/useCoSellSfCanvasActions'; // type CanvasAppContentPayload,
import CoSellCanvasAppRouter from 'packages/cosell/src/CoSellCanvasAppRouter/CoSellCanvasAppRouter';
import { CoSellContextProvider } from 'packages/cosell/src/CoSellContextProvider';
import { CoSellCanvasAppContentPayload } from 'packages/salesforce-canvas/src/lib/salesforceCanvasSession';

/** main entry point for Co-sell CanvasApp */
const CoSellCanvasApp = () => {
  const { payload, vendorId } =
    useCanvasSession<CoSellCanvasAppContentPayload>();
  const { handleCoSellAction } = useCoSellSfCanvasActions();

  const opportunityId =
    payload && 'tackleOpportunityId' in payload
      ? payload.tackleOpportunityId
      : null;

  return (
    <CoSellContextProvider
      opportunityId={opportunityId}
      renderEnv="sf_canvas"
      vendorId={vendorId}
    >
      <CoSellCanvasAppRouter
        {...payload}
        onClose={() =>
          handleCoSellAction({ id: 'go-back-to-opportunity-root' })
        }
      />
    </CoSellContextProvider>
  );
};

export default CoSellCanvasApp;
