import type {
  AceOpportunityEventResponse,
  AceOpportunityEventOperation,
  RawRequest,
} from 'packages/cosell/src/types/responses/AceOpportunityEventResponse';

export type RawRequestWithOperation = {
  rawRequest: RawRequest;
  operation: AceOpportunityEventOperation;
};

export const extractLatestRawRequestFromEvents = ({
  events,
}: {
  events: AceOpportunityEventResponse[];
}): RawRequestWithOperation | undefined => {
  const eventsWithRawRequest = events.filter((event) => 'rawRequest' in event);

  const sortedEventsWithRawRequest = eventsWithRawRequest.sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
  );

  // Return the rawRequest and operation of the most recent event
  if (sortedEventsWithRawRequest.length > 0) {
    const mostRecentEvent = sortedEventsWithRawRequest[0];
    return {
      rawRequest: mostRecentEvent.rawRequest!,
      operation: mostRecentEvent.operation,
    };
  }

  // Return undefined if no events with rawRequest are found
  return undefined;
};
