import { useCallback } from 'react';
import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import { DisplayCloudType } from 'pages/CoSell/types/enums';

import type {
  CoSellCanvasAppContentPayload,
  ParameterPayload,
  TackleWidgetContentPayload,
} from 'packages/salesforce-canvas/src/lib/salesforceCanvasSession';

/** @deprecated will be removing this */
export const COSELL_ACTION_MAP = {
  'go-back-to-opportunity': {
    label: 'cancel',
  },
  'edit-cosell': {
    label: 'Edit co-sell',
  },
  'view-cosell': {
    label: 'View co-sell',
  },
  'submit-cosell-to-cloud': {
    label: 'Submit to cloud',
  },
  'create-cosell-aws': {
    label: 'Add AWS co-sell',
  },
  'create-cosell-microsoft': {
    label: 'Add Microsoft co-sell',
  },
} as const;

/** available co-sell actions and their payloads
 */
type CoSellAction =
  | {
      id: 'go-back-to-opportunity-root';
      cloud?: DisplayCloudType;
    }
  | {
      id: 'create-cosell';
      cloud: DisplayCloudType;
    }
  | {
      id: 'view-cosell';
      cloud: DisplayCloudType;
      tackleOpportunityId: string;
    }
  | {
      id: 'edit-cosell';
      cloud: DisplayCloudType;
      tackleOpportunityId: string;
    }
  | {
      id: 'submit-cosell-to-cloud';
      cloud: DisplayCloudType;
      tackleOpportunityId: string;
    }
  | {
      id: 'view-invitation';
      cloud: DisplayCloudType;
      tackleInvitationId: string;
    };

/** Allows specific set of payloads per component */
type MessagePayload =
  | ({
      navigate: 'TackleWidget';
    } & ParameterPayload<TackleWidgetContentPayload>)
  | ({
      navigate: 'CanvasApp';
    } & ParameterPayload<CoSellCanvasAppContentPayload>);

const domain = 'cosell';

/** All actions and their requirements
 *  TODO: clean up types to be more dynamic
 */
const useCoSellSfCanvasActions = () => {
  const { canvasClient, namespace, record } = useCanvasSession();

  /** just pass a payload to publish a message */
  const publishCanvasMessage = useCallback(
    (data: MessagePayload) => {
      /** TODO: remove before merge
       *  - add dynamic domain
       */
      console.info('publishing canvas message 2.0', {
        name: `${namespace}.${domain}`,
        payload: data,
      });
      Sfdc.canvas.client.publish(canvasClient, {
        name: `${namespace}.${domain}`,
        payload: data,
      });
    },
    [canvasClient, namespace],
  );

  /**
   * note: will be adding payload options to view and edit opportunity
   */
  const handleCoSellAction = (action: CoSellAction) => {
    switch (action.id) {
      case 'go-back-to-opportunity-root':
        publishCanvasMessage({
          navigate: 'TackleWidget',
          cloud: action.cloud,
          record,
          domain,
        });
        break;
      case 'create-cosell':
        publishCanvasMessage({
          navigate: 'CanvasApp',
          object: 'opportunity',
          cloud: action.cloud,
          action: 'create',
          record,
          domain,
          crmId: record.Id,
        });
        break;
      case 'view-cosell':
        publishCanvasMessage({
          navigate: 'CanvasApp',
          object: 'opportunity',
          cloud: action.cloud,
          action: 'view',
          tackleOpportunityId: action.tackleOpportunityId,
          record,
          domain,
        });
        break;
      case 'edit-cosell':
        publishCanvasMessage({
          navigate: 'CanvasApp',
          object: 'opportunity',
          cloud: action.cloud,
          action: 'edit',
          tackleOpportunityId: action.tackleOpportunityId,
          record,
          domain,
        });
        break;
      case 'view-invitation':
        publishCanvasMessage({
          navigate: 'CanvasApp',
          object: 'invitation',
          cloud: action.cloud,
          action: 'view',
          tackleInvitationId: action.tackleInvitationId,
          domain,
          record,
        });
        break;
      default:
        alert(`TODO: handle action: ${action.id}`);
        break;
    }
  };

  return {
    handleCoSellAction,
  };
};

export default useCoSellSfCanvasActions;
