import { useParams } from 'react-router';
import invariant from 'tiny-invariant';
import CreateAceOpportunityForm from 'packages/cosell/src/components/UnifiedOpportunityForm/AceOpportunityForm/CreateAceOpportunityForm';
import { useMappedFieldsQuery } from 'packages/salesforce-canvas/cosell/src/hooks/useMappedFields/useMappedFieldsQuery';
import { Loader } from '@tackle-io/platform-ui';
import { DataMapperFieldErrorsBanner } from 'packages/cosell/src/components/DataMapperBanners/DataMapperFieldErrorsBanner';
import { DataMapperLoadingErrorBanner } from 'packages/cosell/src/components/DataMapperBanners/DataMapperLoadingErrorBanner';
import { DataMapperNotConfiguredBanner } from 'packages/cosell/src/components/DataMapperBanners/DataMapperNotConfiguredBanner';
import {
  Cloud,
  Domain,
  Operation,
} from 'pages/Settings/Account/components/FieldMapper/utils/constants';
import {
  useListMappingsQuery,
  LIST_MAPPINGS_QUERY_LIMIT,
} from 'pages/Settings/Account/components/FieldMapper/api/hooks/useListMappingsQuery';

const useAwsCoSellMappedFields = ({
  crmId,
  enabled = false,
}: {
  crmId: string;
  enabled: boolean;
}) => {
  /** first check if the vendor has any mappings,
   * then only fetch mappings if a mappingId is available.
   */
  const listMappingQuery = useListMappingsQuery({
    cloud: Cloud.AWS,
    domain: Domain.CO_SELL,
    operation: Operation.CREATE_OPPORTUNITY,
    isDefault: true,
    isPublished: true,
    limit: LIST_MAPPINGS_QUERY_LIMIT,
    enabled,
  });

  const mappingId = listMappingQuery.data?.mappings?.[0]?.mappingId;

  const mappedFieldsQuery = useMappedFieldsQuery({
    cloud: Cloud.AWS,
    domain: Domain.CO_SELL,
    operation: Operation.CREATE_OPPORTUNITY,
    crmId,
    mappingId,
    enabled: !!mappingId,
  });

  return {
    isInitialLoading:
      listMappingQuery.isInitialLoading || mappedFieldsQuery.isInitialLoading,
    mappedData: mappedFieldsQuery.data,
    isError: mappedFieldsQuery.isError || listMappingQuery.isError,
    refetch: mappedFieldsQuery.isError
      ? mappedFieldsQuery.refetch
      : listMappingQuery.refetch,
    isRefetching:
      mappedFieldsQuery.isRefetching || listMappingQuery.isRefetching,
  };
};

/** TODO: merge the domain & cloud enums from fieldMapper and co-sell so a single version for each is used */
export const CreateOpportunityPage = ({ onClose }: { onClose: () => void }) => {
  const params = useParams<{ cloud: Cloud; crmId: string }>();

  invariant(
    params?.crmId,
    'crmId is undefined: The Salesforce opportunity id (crmId) is required to create a Co-sell opportunity in Salesforce Canvas',
  );

  const awsMappedFieldsQuery = useAwsCoSellMappedFields({
    crmId: params.crmId,
    enabled: params.cloud === Cloud.AWS,
  });

  if (awsMappedFieldsQuery.isInitialLoading) {
    return <Loader />;
  }

  const mappedData = awsMappedFieldsQuery.mappedData;

  const banners = (
    <>
      {!!mappedData?.fieldsWithErrors && (
        <DataMapperFieldErrorsBanner
          errorFields={mappedData.fieldsWithErrors}
        />
      )}

      {awsMappedFieldsQuery.isError && (
        <DataMapperLoadingErrorBanner
          domain={Domain.CO_SELL}
          isRefetching={awsMappedFieldsQuery.isRefetching}
          refetch={awsMappedFieldsQuery.refetch}
        />
      )}
      {!mappedData && !awsMappedFieldsQuery.isError && (
        <DataMapperNotConfiguredBanner domain={Domain.CO_SELL} />
      )}
    </>
  );

  return (
    <CreateAceOpportunityForm
      banners={banners}
      crmId={params.crmId}
      initialValues={mappedData?.initialValues}
      onCancel={onClose}
      onCreated={onClose}
    />
  );
};
