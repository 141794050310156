import { FormSections, formSectionToTitle } from '../formSections';
import { Bell } from 'mdi-material-ui';
import React from 'react';
import { Card } from '@tackle-io/platform-ui';
import NotifyUsersSelector from 'pages/PrivateOffers/pages/Next/generic/NotifyUsersSelector/NotifyUsersSelector';

const NotifyUsersFormSection = () => (
  <div id={FormSections.NotifyUsers}>
    <Card
      title={formSectionToTitle[FormSections.NotifyUsers]}
      subtitle="Keep stakeholders informed about this offer."
      icon={<Bell />}
    >
      <NotifyUsersSelector />
    </Card>
  </div>
);

export default NotifyUsersFormSection;
