import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import CoSellSfTackleWidget from 'packages/cosell/src/components/CoSellSfTackleWidget/CoSellSfTackleWidget';
import { CoSellContextProvider } from 'packages/cosell/src/CoSellContextProvider';

/** Co-sell view for TackleWidget Salesforce canvas app*/
export default function TackleWidgetCoSellView() {
  const { vendorId, payload, record } = useCanvasSession();

  if (!record?.Id) {
    return (
      <p>
        Unable to get Salesforce Opportunity ID. Please verify with your admin
        this component is on the Salesforce Opportunity page.
      </p>
    );
  }

  return (
    <CoSellContextProvider vendorId={vendorId} renderEnv="sf_canvas">
      <CoSellSfTackleWidget crmId={record.Id} {...payload} />
    </CoSellContextProvider>
  );
}
