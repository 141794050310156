import { FieldKey, FormValues, RenewalType } from './formSchema';
import {
  FieldKey as BuyersFieldKeys,
  FormBuyer,
} from '../../../../generic/OfferBuyers/formSchema';
import {
  Buyer,
  OfferType,
  PrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';

const emptyInitialBuyer = [
  {
    name: '',
    email: '',
    title: '',
  },
];

export const initialFormValuesForNewOffer = (
  offerType: OfferType,
): FormValues => {
  const buyers: FormBuyer[] =
    offerType === OfferType.PartnerResale ? [] : emptyInitialBuyer;

  return {
    [FieldKey.PartnerName]: '',
    [FieldKey.PartnerAccountNumber]: '',
    [FieldKey.BuyerAccountNumber]: '',
    [FieldKey.CompanyName]: '',
    [BuyersFieldKeys.Buyers]: buyers,
    [FieldKey.OfferName]: '',
    [FieldKey.OfferDescription]: '',
    [FieldKey.Renewal]: false,
    [FieldKey.RenewalType]: null,
  };
};

const toFormBuyer = (b: Buyer): FormBuyer => ({
  name: b.fullName,
  email: b.emailAddress,
  title: b.title,
});

const mapBuyersToFormBuyers = (buyers: Buyer[]): FormBuyer[] =>
  buyers.map(toFormBuyer);

export const initialFormValuesForExistingOffer = (
  offer: PrivateOffer,
): FormValues => {
  const partnerOffer = offer.partnerOffer;
  const renewalType = offer.awsRenewalMovingToMarketplace
    ? RenewalType.NewAwsMarketplaceCustomer
    : RenewalType.ExistingAwsMarketplaceCustomer;

  return {
    partnerName: partnerOffer?.partnerName,
    partnerAccountNumber: partnerOffer?.partnerRef,
    buyerAccountNumber: offer.buyerBillingAccountRef,
    companyName: offer.buyerCompanyName,
    buyers: mapBuyersToFormBuyers(offer.buyers || []),
    offerName: offer.offerName,
    offerDescription: offer.offerDescription,
    renewal: offer.renewal,
    renewalType,
  };
};

export const initialFormValuesForCloneOffer = (
  offer: PrivateOffer,
): FormValues => initialFormValuesForExistingOffer(offer);

export const initialFormValuesForAmendOffer = (
  offer: PrivateOffer,
): FormValues => {
  const offerName = `Amended - ${offer?.offerName || ''}`;

  const renewalType = offer?.renewal
    ? RenewalType.ExistingAwsMarketplaceCustomer
    : null;

  return {
    ...initialFormValuesForExistingOffer(offer),
    offerName,
    renewalType,
  };
};
