import React, { useEffect } from 'react';
import { Box, Divider, Grid } from 'vendor/material';
import { Page } from 'components';
import useStyles from './AceInvitationDetails.styles';
import { Banner } from '@tackle-io/platform-ui';
import { AceInvitationCloudStatusEnum } from 'packages/cosell/src/types/enums';
import InvitationDetailsHeader from './components/InvitationDetailsHeader';
import ReceiverResponsibilities from './components/ReceiverResponsibilities';
import AceCustomerDetails from './components/AceCustomerDetails';
import AceProjectDetails from './components/AceProjectDetails';
import RightRailCard from './components/RightRail/RightRailCard';
import { SOURCE_TAG } from 'packages/cosell/src/utilities/constants';
import { isEmpty } from 'lodash';
import {
  AceInvitationEventResponse,
  AceInvitationResponse,
} from 'packages/cosell/src/types/AceInvitation.type';
import { CoSellActivityFeed } from 'packages/cosell/src/components/CoSellActivityFeed/CoSellActivityFeed';
interface AceSingleInvitationProps {
  invitation: AceInvitationResponse;
  invitationEvents: AceInvitationEventResponse[];
  invitationEventsError: Error | {} | null;
}

const AceInvitationDetails: React.FC<AceSingleInvitationProps> = ({
  invitation,
  invitationEvents,
  invitationEventsError,
}) => {
  const classes = useStyles();

  useEffect(() => {
    const containerShell = document.querySelector('#root > div > main');
    if (containerShell && containerShell.scrollTop > 0) {
      containerShell.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  return (
    <Page className={classes.detailsPage}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Box mt={2}>
            <InvitationDetailsHeader
              title={invitation.engagementTitle}
              status={invitation.status}
            />
          </Box>
        </Grid>
        {invitation.status === AceInvitationCloudStatusEnum.PENDING && (
          <Grid item xs={12}>
            <Banner
              borderPosition="top"
              defaultOpen={false}
              isCollapsible={false}
              title="Invitations must be accepted to view all opportunity details."
            />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          <ReceiverResponsibilities />

          <Box mt={4}>
            <AceCustomerDetails {...invitation?.opportunityInvitationPayload} />
          </Box>
          <Box mt={4}>
            <AceProjectDetails {...invitation?.opportunityInvitationPayload} />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          {/* TODO: Revisit to support any valid errors that can arise */}
          {!isEmpty(invitationEventsError) ? (
            <div>Error loading invitation events</div>
          ) : (
            <RightRailCard
              invitation={invitation}
              origin={SOURCE_TAG.INBOUND}
              invitationEvents={invitationEvents}
            />
          )}
          <Box mt={4} mb={4}>
            <Divider />
          </Box>
          <CoSellActivityFeed activities={invitationEvents} />
        </Grid>
      </Grid>
    </Page>
  );
};

export default AceInvitationDetails;
