import React, { useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FormValues } from '../../formSchema';
import { Agreement } from 'pages/PrivateOffers/pages/Next/generic/api/types/Agreement';
import { Box, CircularProgress, makeStyles, Typography } from 'vendor/material';
import NewsFeedSvg from 'pages/PrivateOffers/pages/Next/assets/NewsFeed';
import { Button } from '@tackle-io/platform-ui';
import { DataId } from 'pages/PrivateOffers/pages/Next/generic/analytics';
import BuyerAgreementsContext from 'pages/PrivateOffers/pages/Next/aws/edit/BuyerAgreementsContext/BuyerAgreementsContext';
import buyerAgreementsContext from 'pages/PrivateOffers/pages/Next/aws/edit/BuyerAgreementsContext/BuyerAgreementsContext';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import OfferPageContext from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageContext';

const useStyles = makeStyles((theme) => ({
  searchingActiveContractsText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
  },
  noActiveContractsText: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '20px',
  },
  buyerAgreementsContainer: {
    backgroundColor: '#FFFAE6',
    padding: theme.spacing(2),
    borderRadius: '4px',
  },
  agreementsIcon: {
    marginTop: theme.spacing(0.5),
  },
  contractsTitle: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
  },
  contractDescription: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
  },
  reviewActiveContractsButton: {
    marginLeft: -theme.spacing(2),
  },
}));

const SearchingForAgreements: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box display="span" className={classes.agreementsIcon}>
        <CircularProgress size={16} />
      </Box>
      <Box display="span" marginLeft={1}>
        <Typography className={classes.searchingActiveContractsText}>
          Searching for active contracts
        </Typography>
      </Box>
    </Box>
  );
};

const NoAgreements: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box display="span" className={classes.agreementsIcon}>
        <NewsFeedSvg />
      </Box>
      <Box display="span" marginLeft={1}>
        <Typography className={classes.noActiveContractsText}>
          You have no active contracts with this buyer in the AWS marketplace
        </Typography>
      </Box>
    </Box>
  );
};

interface ActiveAgreementsProps {
  agreementsForBuyer: Agreement[];
}

const ActiveAgreementsBanner: React.FunctionComponent<
  ActiveAgreementsProps
> = ({ agreementsForBuyer }) => {
  const classes = useStyles();
  const { setAgreementsSidePanelOpen } = useContext(buyerAgreementsContext);

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      className={classes.buyerAgreementsContainer}
    >
      <Box display="span" className={classes.agreementsIcon}>
        <NewsFeedSvg />
      </Box>
      <Box display="span" marginLeft={2}>
        <Box>
          <Typography className={classes.contractsTitle}>
            {agreementsForBuyer.length} active contracts with this buyer
          </Typography>
          <Typography
            data-id={DataId.ActiveAgreementsSubText}
            className={classes.contractDescription}
          >
            Amend an existing contract to avoid potential buyer errors when the
            offer is accepted, or you can continue creating a new direct offer.
          </Typography>
        </Box>
        <Box className={classes.reviewActiveContractsButton}>
          <Button
            variant="text"
            appearance="primary"
            onClick={() => {
              setAgreementsSidePanelOpen(true);
            }}
          >
            Review active contracts
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const BuyerAgreementsLookup: React.FunctionComponent = () => {
  const { mode } = useContext(OfferPageContext);

  const {
    agreementsForBuyer,
    loadingAgreementsForBuyer,
    setBuyerAccountNumber,
    buyerAccountInvalid,
  } = useContext(BuyerAgreementsContext);

  const {
    values: { buyerAccountNumber },
  } = useFormikContext<FormValues>();

  useEffect(() => {
    setBuyerAccountNumber(buyerAccountNumber);
  }, [setBuyerAccountNumber, buyerAccountNumber]);

  return buyerAccountInvalid ||
    mode !== OfferPageMode.New ? null : loadingAgreementsForBuyer ? (
    <SearchingForAgreements />
  ) : agreementsForBuyer.length === 0 ? (
    <NoAgreements />
  ) : (
    <ActiveAgreementsBanner agreementsForBuyer={agreementsForBuyer} />
  );
};

export default BuyerAgreementsLookup;
