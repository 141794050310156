import { Agreement } from '../types/Agreement';
import { AgreementJSON } from '../types/AgreementsResponseJSON';

export const agreementJSONToAgreements = (
  agreements: AgreementJSON[],
): Agreement[] =>
  agreements.map((a) => ({
    id: a.id,
    status: a.status,
    productId: a.productid,
    publicProductId: a.public_productid,
    productType: a.product_type,
    offerId: a.offerid,
    buyerAccountId: a.buyer_account_id,
    startDate: a.start_date,
    endDate: a.end_date,
  }));
