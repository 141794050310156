import React from 'react';
import { Banner } from '@tackle-io/platform-ui';
import { Box, makeStyles, Typography } from 'vendor/material';

const useStyles = makeStyles(() => ({
  heavyText: {
    fontWeight: 500,
  },
}));

const CreatedInMarketplaceBanner: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Banner
      type="info"
      borderPosition="top"
      title="When you are editing an offer that was created in the marketplace, AWS limits you to changing only the following fields:"
      body={
        <Box>
          <Box mb={2}>
            <ul>
              <li>
                <Typography display="inline" className={classes.heavyText}>
                  Buyer details
                </Typography>
                : Update your buyer's information
              </li>
              <li>
                <Typography display="inline" className={classes.heavyText}>
                  Offer acceptance deadline
                </Typography>
                : Extend offer expiration date
              </li>
              <li>
                <Typography display="inline" className={classes.heavyText}>
                  Registration
                </Typography>
                : Update your buyer's registration
              </li>
              <li>
                <Typography display="inline" className={classes.heavyText}>
                  Additional fields
                </Typography>
                : Update custom fields that you need to complete Tackle bookable
                artifact
              </li>
            </ul>
          </Box>
          <Box>
            <Typography display="inline" className={classes.heavyText}>
              Note:{' '}
            </Typography>
            Editing the offer acceptance deadline will update the offer in the
            marketplace.
          </Box>
        </Box>
      }
      isCollapsible
      defaultOpen
    />
  );
};

export default CreatedInMarketplaceBanner;
