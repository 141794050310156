import { PrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { FieldKey, FormValues } from './formSchema';

export const initialFormValuesForNewOffer: FormValues = {
  [FieldKey.OfferId]: '',
  [FieldKey.isAmended]: false,
  [FieldKey.SourceOfferId]: '',
  [FieldKey.SellingAuthorizationId]: '',
};

export const initialFormValuesForExistingOffer = (
  offer: PrivateOffer,
): FormValues => {
  return {
    [FieldKey.OfferId]: offer?.offerId || '',
    [FieldKey.isAmended]: !!offer?.sourceOfferId,
    [FieldKey.SourceOfferId]: offer?.sourceOfferId || '',
    [FieldKey.SellingAuthorizationId]:
      offer?.partnerOffer?.partnerOfferRef || '',
  };
};

export const initialFormValuesForCloneOffer = (
  offer: PrivateOffer,
): FormValues => {
  return { ...initialFormValuesForExistingOffer(offer) };
};

export const initialFormValuesForAmendOffer = (
  _: PrivateOffer,
): Omit<FormValues, 'sourceOfferId'> => {
  return {
    [FieldKey.OfferId]: '',
    [FieldKey.isAmended]: false,
    [FieldKey.SellingAuthorizationId]: '',
  };
};
