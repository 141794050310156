import * as yup from '../../../../../../../../utils/yup-extended';
import {
  FieldKey as ProductFieldKey,
  formSchema as productFormSchema,
  FormValues as ProductFormValues,
} from '../../../../generic/ProductSelector/formSchema';
import { dimensionsSchema } from './DimensionsConfig/DimensionRow/formSchema';
import { FormDimension } from './DimensionsConfig/DimensionRow/formTypes';
import { schedulesSchema } from './ScheduleConfig/ScheduleRow/formSchema';
import {
  FormUsageDimension,
  usageDimensionsSchema,
} from './UsageDimensionsConfig/UsageDimensionRow/formSchema';
import { CurrencyCode } from 'utils/currency';
import { FormSchedule } from './ScheduleConfig/ScheduleRow/formTypes';
import { FieldKey } from './formTypes';
import {
  BillingTerm,
  DurationType,
  PaymentModel,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';

interface NonUserInputFormValues {
  version: string;
  durationValue: number | null;
}

export type FormValues = {
  [FieldKey.PaymentModel]: string | null;
  [FieldKey.BillingTerm]: string | null;
  [FieldKey.BillingTermCadence]: string | null;
  [FieldKey.DurationInCadence]: number | null;
  [FieldKey.ServiceStartAt]: string | null;
  [FieldKey.ServiceEndAt]: string | null;
  [FieldKey.CurrencyCode]: string | null;
  [FieldKey.Dimensions]: FormDimension[];
  [FieldKey.UsageDimensions]: FormUsageDimension[];
  [FieldKey.Schedules]: FormSchedule[];
} & NonUserInputFormValues &
  ProductFormValues;

const nonUserInputFormSchema: yup.ObjectSchema<NonUserInputFormValues> =
  yup.object({
    version: yup.string().required(),
    durationValue: yup.number().nullable(),
  });

const ifExistsRequireValue = {
  is: true,
  then: (schema: yup.StringSchema) => schema.required().progress(true),
  otherwise: (schema: yup.StringSchema) => schema.nullable().progress(false),
};

const paymentModelSchema = yup
  .string()
  .oneOf([
    PaymentModel.PaymentSchedule,
    PaymentModel.PerProduct,
    PaymentModel.PayGo,
  ])
  .when(ProductFieldKey.ProductRef, ifExistsRequireValue);

const billingTermSchema = yup
  .string()
  .oneOf([BillingTerm.Custom, BillingTerm.FutureDated, null])
  .when(ProductFieldKey.ProductRef, ifExistsRequireValue);

const billingTermCadenceSchema = yup
  .string()
  .nullable()
  .when(FieldKey.BillingTerm, {
    is: BillingTerm.Custom,
    then: (schema: yup.MixedSchema) =>
      schema.oneOf(Object.values(DurationType)).required().progress(true),
    otherwise: (schema: yup.MixedSchema) => schema.nullable().progress(false),
  });

const durationInCadenceSchema = yup
  .number()
  .min(1)
  .max(60)
  .nullable()
  .when(FieldKey.BillingTerm, {
    is: BillingTerm.Custom,
    then: (schema: yup.MixedSchema) => schema.required().progress(true),
    otherwise: (schema: yup.MixedSchema) => schema.nullable().progress(false),
  });

const stringRequiredWhenFutureDatedTermSchema = yup
  .string()
  .when([FieldKey.BillingTerm, FieldKey.PaymentModel], {
    is: (billingTerm: string, paymentModel: string) =>
      billingTerm === BillingTerm.FutureDated ||
      paymentModel === PaymentModel.PayGo,
    then: (schema: yup.StringSchema) =>
      schema.nullable().required().progress(true),
    otherwise: (schema: yup.StringSchema) => schema.nullable().progress(false),
  });

const currencyCodeSchema = yup
  .string()
  .oneOf(Object.values(CurrencyCode))
  .required()
  .progress(true);

const stringRequiredFutureDatedStartDateSchema = yup
  .string()
  .when(FieldKey.BillingTerm, {
    is: BillingTerm.FutureDated,
    then: (schema: yup.StringSchema) =>
      schema.nullable().required().progress(true),
    otherwise: (schema: yup.StringSchema) => schema.nullable().progress(false),
  });

export const formSchema: yup.ObjectSchema<FormValues> = yup
  .object({
    [FieldKey.PaymentModel]: paymentModelSchema,
    [FieldKey.BillingTerm]: billingTermSchema,
    [FieldKey.BillingTermCadence]: billingTermCadenceSchema,
    [FieldKey.DurationInCadence]: durationInCadenceSchema,
    [FieldKey.ServiceStartAt]: stringRequiredFutureDatedStartDateSchema,
    [FieldKey.ServiceEndAt]: stringRequiredWhenFutureDatedTermSchema,
    [FieldKey.CurrencyCode]: currencyCodeSchema,
    [FieldKey.Dimensions]: dimensionsSchema,
    [FieldKey.UsageDimensions]: usageDimensionsSchema,
    [FieldKey.Schedules]: schedulesSchema,
  })
  .concat(nonUserInputFormSchema)
  .concat(productFormSchema);
