import React from 'react';
import { Grid, IconButton, makeStyles } from 'vendor/material';
import { TextField } from '@tackle-io/platform-ui';
import { FastField } from 'formik';
import { Delete } from 'mdi-material-ui';
import { FieldKey } from './formSchema';
import { DataId } from '../analytics';
import classNames from 'classnames';

interface BuyerFieldsRowProps {
  index: number;
  onRemove: (i: number) => void;
}

const useStyles = makeStyles((theme) => ({
  fieldAction: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
    display: 'flex',
    alignItems: 'flex-end',
  },
  buttonIcon: {
    color: theme.palette.NEUTRAL500,
  },
  deleteButton: {
    alignItems: 'flex-start',
    marginTop: theme.spacing(1.75),
  },
}));

const OfferBuyer: React.FunctionComponent<BuyerFieldsRowProps> = ({
  index,
  onRemove,
}) => {
  const classes = useStyles();
  const nameFieldKey = `${FieldKey.Buyers}.${index}.name`;
  const emailFieldKey = `${FieldKey.Buyers}.${index}.email`;
  const titleFieldKey = `${FieldKey.Buyers}.${index}.title`;

  const removeBuyer = () => {
    onRemove(index);
  };

  return (
    <Grid container item md={12} spacing={2} alignItems="flex-end">
      <Grid item md={4} sm={12} xs={12}>
        <FastField name={nameFieldKey}>
          {({ field }) => (
            <TextField
              {...field}
              data-id={`${DataId.BuyerNameField}-${index}`}
              label="Full name"
            />
          )}
        </FastField>
      </Grid>
      <Grid item md={4} sm={12} xs={12}>
        <FastField name={emailFieldKey}>
          {({ field }) => (
            <TextField
              {...field}
              data-id={`${DataId.BuyerEmailField}-${index}`}
              label="Email"
            />
          )}
        </FastField>
      </Grid>
      <Grid item md={index === 0 ? 4 : 3} sm={12} xs={12}>
        <FastField name={titleFieldKey}>
          {({ field }) => (
            <TextField
              {...field}
              data-id={`${DataId.BuyerTitleField}-${index}`}
              label="Title"
            />
          )}
        </FastField>
      </Grid>
      {index !== 0 && (
        <Grid
          item
          md={1}
          sm={1}
          xs={1}
          className={classNames(classes.fieldAction, classes.deleteButton)}
        >
          <IconButton onClick={removeBuyer}>
            <Delete className={classes.buttonIcon} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default OfferBuyer;
