import React, { useContext, useEffect, useMemo, useState } from 'react';
import ApiContext from '../../../generic/ApiContextProvider/apiContext';
import BuyerAgreementsContext, {
  BuyerAgreementsContextValues,
} from 'pages/PrivateOffers/pages/Next/aws/edit/BuyerAgreementsContext/BuyerAgreementsContext';
import { Agreement } from '../../../generic/api/types/Agreement';

interface BuyerAgreementsContextProviderProps {
  children: React.ReactElement;
}

const BuyerAgreementsContextProvider: React.FunctionComponent<
  BuyerAgreementsContextProviderProps
> = ({ children }) => {
  const {
    api: { getAgreementsForBuyer },
  } = useContext(ApiContext);

  const [buyerAccountNumber, setBuyerAccountNumber] = useState<string>('');

  const buyerAccountInvalid = useMemo(
    () => !buyerAccountNumber || buyerAccountNumber.length !== 12,
    [buyerAccountNumber],
  );

  const [agreementsForBuyer, setAgreementsForBuyer] = useState<Agreement[]>([]);
  const [agreementsSidePanelOpen, setAgreementsSidePanelOpen] = useState(false);

  const [loadingAgreementsForBuyer, setLoadingAgreementsForBuyer] =
    useState(false);

  useEffect(() => {
    if (!buyerAccountInvalid) {
      return;
    }

    setAgreementsForBuyer([]);
  }, [buyerAccountInvalid]);

  useEffect(() => {
    (async () => {
      if (buyerAccountInvalid) {
        return;
      }

      setLoadingAgreementsForBuyer(true);

      const agreements = await getAgreementsForBuyer(
        buyerAccountNumber,
        null,
        true,
      );

      setAgreementsForBuyer(agreements || []);
      setLoadingAgreementsForBuyer(false);
    })();
  }, [buyerAccountInvalid, getAgreementsForBuyer, buyerAccountNumber]);

  const contextValues: BuyerAgreementsContextValues = {
    buyerAccountNumber,
    setBuyerAccountNumber,
    buyerAccountInvalid,
    agreementsForBuyer,
    loadingAgreementsForBuyer,
    agreementsSidePanelOpen,
    setAgreementsSidePanelOpen,
  };

  return (
    <BuyerAgreementsContext.Provider value={contextValues}>
      {children}
    </BuyerAgreementsContext.Provider>
  );
};

export default BuyerAgreementsContextProvider;
