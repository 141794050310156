import { FormValues } from './formSchema';
import { startOfLastDayOfMonthAsUtcDateTime } from '../../../../generic/utils/date/dateUtils';
import { PrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';

export const initialFormValuesForNewOffer = (): FormValues => ({
  offerAcceptanceDeadline: startOfLastDayOfMonthAsUtcDateTime().toISO(),
});

export const initialFormValuesForExistingOffer = (
  offer: PrivateOffer,
): FormValues => {
  const { offerExpirationAt } = offer;

  return {
    offerAcceptanceDeadline: offerExpirationAt,
  };
};

export const initialFormValuesForCloneOffer = (
  offer: PrivateOffer,
): FormValues => {
  return { ...initialFormValuesForExistingOffer(offer) };
};

export const initialFormValuesForAmendOffer = (
  offer: PrivateOffer,
): FormValues => {
  return {
    ...initialFormValuesForExistingOffer(offer),
    offerAcceptanceDeadline: startOfLastDayOfMonthAsUtcDateTime().toISO(),
  };
};
