import { FormSections, formSectionToTitle } from '../formSections';
import { FormValues } from '../formSchema';
import { getFormProgress, percentComplete, Schema } from 'utils/yup-extended';
import React from 'react';
import { Box, Grid } from 'vendor/material';
import { Donut } from '@tackle-io/platform-ui';
import { formSchema as basicInformationFormSchema } from '../BasicInformationFormSection/formSchema';
import { formSchema as productAndPricingFormSchema } from '../ProductAndPricingFormSection/formSchema';
import { formSchema as endUserLicenseAgreementFormSchema } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/EndUserLicenseAgreementForSection/formSchema';
import { formSchema as resellerAgreementFormSchema } from '../ResllerAgreementForSection/formSchema';
import { formSchema as registrationDetailsFormSchema } from '../RegistrationDetailsFormSection/formSchema';
import { formSchema as additionalFieldsFormSchema } from '../AdditionalFieldsFormSection/formSchema';
import { formSchema as offerValidityPeriodFormSchema } from '../OfferValidityPeriodFormSection/formSchema';
import { formSchema as notifyUsersFormSchema } from '../../../../generic/NotifyUsersSelector/formSchema';
import { formSchema as linkOfferFormSchema } from '../LinkOfferFormSection/formSchema';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';

interface QuickLinkProps {
  formSection: FormSections;
  formValues: FormValues;
  mode: OfferPageMode;
}

const formSectionToFormSchema = (
  mode: OfferPageMode,
): {
  [fs: string]: Schema<Partial<FormValues>> | null;
} => ({
  [FormSections.BasicInformation]: basicInformationFormSchema,
  [FormSections.ProductAndPricing]: productAndPricingFormSchema,
  [FormSections.EndUserLicenseAgreement]: endUserLicenseAgreementFormSchema,
  [FormSections.ResellerAgreement]: resellerAgreementFormSchema,
  [FormSections.RegistrationDetails]: registrationDetailsFormSchema,
  [FormSections.AdditionalFields]: additionalFieldsFormSchema,
  [FormSections.OfferValidityPeriod]: offerValidityPeriodFormSchema,
  [FormSections.NotifyUsers]: notifyUsersFormSchema,
  [FormSections.LinkOffer]: linkOfferFormSchema(mode),
});

const getFormSectionCompletePercentage = (
  formSection: FormSections,
  formValues: FormValues,
  mode: OfferPageMode,
): number => {
  const schemaForFormSection = formSectionToFormSchema(mode)[formSection];

  return schemaForFormSection
    ? percentComplete(getFormProgress(schemaForFormSection, formValues))
    : 0;
};

const QuickLink: React.FunctionComponent<QuickLinkProps> = ({
  formSection,
  formValues,
  mode,
}) => {
  const linkTextForFormSection = formSectionToTitle[formSection] || '';

  const percent = getFormSectionCompletePercentage(
    formSection,
    formValues,
    mode,
  );

  return (
    <Box mb={2}>
      <Grid container spacing={1} alignContent="center">
        <Grid
          item
          onClick={(e) => {
            e.preventDefault();
            if (e.metaKey) {
              console.log('formValues', formValues);
            } else {
              const progress = getFormProgress(
                formSectionToFormSchema[formSection],
                formValues,
              );
              console.log(
                `Progress for ${formSection}: ${percentComplete(progress)}% (${
                  progress.complete
                }/${progress.total})`,
              );
              if (progress.statusByPath) {
                Object.entries(progress.statusByPath).forEach(
                  ([path, status]) => {
                    console.log(`[${status}] ${path}`);
                  },
                );
              }
            }
          }}
        >
          <Donut percentage={percent} activeColor="#0052CC" />
        </Grid>
        <Grid item xs>
          <a href={`#${formSection}`}>{linkTextForFormSection}</a>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuickLink;
