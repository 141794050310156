import { createContext } from 'react';
import { Agreement } from '../../../generic/api/types/Agreement';
import { PrivateOffer } from '../../../generic/api/types/PrivateOffer';

export interface AgreementContextValues {
  offerId: string | null;
  sourceOffer: PrivateOffer | null;
  agreementOffer: PrivateOffer | null;
  mergedAgreementAndSourceOffer: PrivateOffer;
  agreement: Agreement | null;
  loadingAgreementOffer: boolean;
}

const AmendmentContext = createContext<AgreementContextValues>({
  offerId: null,
  sourceOffer: null,
  agreementOffer: null,
  mergedAgreementAndSourceOffer: null,
  agreement: null,
  loadingAgreementOffer: false,
});

export default AmendmentContext;
