import React, { useContext, useEffect, useRef } from 'react';
import {
  ActivitySlug,
  OfferType,
  PrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { Box, Grid, makeStyles, Typography } from 'vendor/material/index';
import {
  getLatestOfferActivitySlug,
  getMarketplaceErrors,
} from 'pages/PrivateOffers/pages/Next/generic/utils/offer/activityUtils';
import { ErrorBoundary, Page } from 'components/index';
import OhNo from 'pages/Error/Error';
import { logTags } from 'pages/PrivateOffers/pages/Next/generic/logTags';
import OfferProgressBar from 'pages/PrivateOffers/pages/Next/generic/OfferProgressBar/OfferProgressBar';
import { Alert, ProviderIcon } from '@tackle-io/platform-ui';
import MarketplaceErrorsBanner from 'pages/PrivateOffers/pages/Next/generic/MarketplaceErrorsBanner/MarketplaceErrorsBanner';
import ApiContext from '../ApiContextProvider/apiContext';
import OfferSubmissionErrorsBanner from 'pages/PrivateOffers/pages/Next/generic/OfferSubmissionErrorsBanner/OfferSubmissionErrorsBanner';

interface ViewOfferPageProps {
  offer: PrivateOffer;
  children: React.ReactElement;
  sidePanel: React.ReactElement;
}

const useStyles = makeStyles((theme) => ({
  titleGrid: { paddingLeft: theme.spacing(1) },
}));

const offerApiContainerStyle = { scrollMarginTop: 100 };

const ViewOfferPage: React.FunctionComponent<ViewOfferPageProps> = ({
  offer,
  children,
  sidePanel,
}) => {
  const classes = useStyles();
  const latestActivitySlug = getLatestOfferActivitySlug(offer);
  const marketplaceErrors = getMarketplaceErrors(offer?.activities);

  const { offerSubmissionError, setOfferSubmissionError } =
    useContext(ApiContext);

  const offerApiErrorRef = useRef(null);

  useEffect(() => {
    if (!offerSubmissionError) {
      offerApiErrorRef.current = null;

      return;
    }

    if (offerApiErrorRef.current) {
      offerApiErrorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [offerSubmissionError, offerApiErrorRef]);

  return (
    <ErrorBoundary renderError={OhNo} logTags={logTags}>
      <Page>
        {latestActivitySlug ===
          ActivitySlug.VendorCreatedMarketplaceOfferSuccess && (
          <Box mt={2}>
            <Alert
              appearance="success"
              title={
                offer.offerType === OfferType.PartnerResale
                  ? 'Offer was created in the marketplace.'
                  : 'Offer was created in the marketplace. Send email invite to your buyer.'
              }
            />
          </Box>
        )}
        {(latestActivitySlug === ActivitySlug.VendorSentPurchaseInstructions ||
          latestActivitySlug ===
            ActivitySlug.VendorReSentPurchaseInstructions) && (
          <Box mt={2}>
            <Alert
              appearance="success"
              title="Email invite successfully was sent to your buyer"
            />
          </Box>
        )}
        <Box mt={3}>
          <OfferProgressBar offerType={offer.offerType} offer={offer} />
        </Box>
        <Box pt={2} pb={2} mt={2} mb={2}>
          <Grid
            container
            spacing={2}
            className={classes.titleGrid}
            alignItems="center"
          >
            <Grid item>
              <ProviderIcon provider={offer.marketplace} />
            </Grid>
            <Grid item>
              <Typography variant="h5">{offer.buyerCompanyName}</Typography>
            </Grid>
          </Grid>
          {offer && (
            <Box>
              <Typography variant="subtitle1">
                {offer.offerName || '-'}
              </Typography>
            </Box>
          )}
        </Box>
        {marketplaceErrors.length > 0 && !offer?.cancelledAt && (
          <Box mb={4}>
            <MarketplaceErrorsBanner marketplaceErrors={marketplaceErrors} />
          </Box>
        )}
        {offerSubmissionError && (
          <div ref={offerApiErrorRef} style={offerApiContainerStyle}>
            <Box mb={4}>
              <OfferSubmissionErrorsBanner
                offerSubmissionError={offerSubmissionError}
                setOfferSubmissionError={setOfferSubmissionError}
              />
            </Box>
          </div>
        )}
        <Grid container spacing={4}>
          <Grid item md={9} xs={12}>
            {children}
          </Grid>
          <Grid item md={3} xs={12}>
            <Box position="sticky" top={32} display={{ md: 'block' }}>
              {sidePanel}
            </Box>
          </Grid>
        </Grid>
      </Page>
    </ErrorBoundary>
  );
};

export default ViewOfferPage;
