import { useCallback } from 'react';
import {
  authApi,
  offersV2Api,
  salesforceDataMapperApi,
} from 'pages/PrivateOffers/pages/Next/generic/api/offersV2API';
import {
  Marketplace,
  Pricing,
  PrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { User } from 'pages/PrivateOffers/pages/Next/generic/api/types/User';
import { Auth0User } from 'pages/PrivateOffers/pages/Next/generic/api/types/Auth0User';
import { Product } from 'pages/PrivateOffers/pages/Next/generic/api/types/Product';
import { camelCase, convertKeysTo } from 'stores/utils';
import {
  privateOfferJSONToPrivateOffer,
  toPricing,
} from 'pages/PrivateOffers/pages/Next/generic/api/transformers/privateOfferJSONToPrivateOffer';
import { privateOfferToPrivateOfferJSON } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/privateOfferToPrivateOfferJSON';
import { PrivateOfferRequestJSON } from '../api/types/PrivateOfferRequestJSON';
import { PrivateOfferResponseJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOfferResponseJSON';
import { PrivateOffersResponseJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffersResponseJSON';
import { Auth0UsersResponseJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/Auth0UsersResponseJSON';
import { auth0UserJSONsToAuth0Users } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/auth0UserJSONToAuth0User';
import { userJSONToUser } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/userJSONToUser';
import { OfferAPIKey } from '../ApiContextProvider/offerAPIKey';
import { ContextValueSetters } from 'pages/PrivateOffers/pages/Next/generic/ApiContextProvider/ApiContextProvider';
import { SchemaValidationError } from 'pages/PrivateOffers/pages/Next/generic/ApiContextProvider/apiContext';
import { Document } from 'pages/PrivateOffers/pages/Next/generic/api/types/Document';
import { documentJSONToDocument } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/documentJSONToDocument';
import { DocumentUploadResponseJSON } from '../api/types/DocumentJSON';
import { PricingJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOfferJSON';
import { UserResponseJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/UserResponseJSON';
import { SalesforceDataMappingResponseJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/SalesforceDataMappingResponseJSON';
import { salesforceDataMappingJSONToSalesforceDataMapping } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/salesforceDataMappingJSONToSalesforceDataMapping';
import { SalesforceDataMapping } from 'pages/PrivateOffers/pages/Next/generic/api/types/SalesforceDataMapping';
import { Agreement } from '../api/types/Agreement';
import { AgreementsResponseJSON } from 'pages/PrivateOffers/pages/Next/generic/api/types/AgreementsResponseJSON';
import { agreementJSONToAgreements } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/agreementJSONToAgreements';

interface UsePrivateOfferApi {
  getUser: () => Promise<User>;
  getAuth0Users: () => Promise<Auth0User[]>;
  getProducts: (marketplace: Marketplace) => Promise<Product[]>;
  getOffer: (poId: string) => Promise<PrivateOffer | null>;
  getOfferForAgreement: (offerId: string) => Promise<PrivateOffer | null>;
  getAgreementMappedAsPrivateOffer: (offerId: string) => Promise<any>;
  getOfferSilently: (poId: string) => Promise<PrivateOffer | null>;
  getOffersForOpportunity: (
    opportunityId: string,
  ) => Promise<PrivateOffer[] | null>;
  createOffer: (
    offer: Partial<PrivateOffer>,
    createInMarketplace: boolean,
  ) => Promise<PrivateOffer | null>;
  updateOffer: (
    poId: string,
    updatedOffer: Partial<PrivateOffer>,
    createInMarketplace: boolean,
  ) => Promise<PrivateOffer | null>;
  cancelOffer: (poId: string) => Promise<void>;
  sendBuyerInstructions: (poId: string) => Promise<void>;
  archiveOffer: (poId: string) => Promise<void>;
  getVendorCurrencies: () => Promise<string[]>;
  getAwsMarketplacePricing: (encryptedProductId: string) => Promise<Pricing>;
  postDocument: (document: File) => Promise<Document>;
  getSalesforceDataMapping: (
    marketplace: Marketplace,
    opportunityId: string,
    mappingId?: string,
  ) => Promise<SalesforceDataMapping | null>;
  getAgreementsForBuyer: (
    buyerAccountNumber: string,
    offerId?: string,
    activeOnly?: boolean,
  ) => Promise<Agreement[]>;
}

const getResponseJson = async <T = any,>(
  response: Response | null,
): Promise<T> => (response ? response.json() : null);

const convertJsonToType = <T,>(json: any) =>
  convertKeysTo(camelCase)(json) as T;

const convertJsonToArrayType = <T,>(json: any): T[] =>
  json.map((i: any) => convertJsonToType<T>(i));

const convertToUser = async (responseJson: UserResponseJSON | null) =>
  userJSONToUser(responseJson?.user);

const convertToUsers = async (responseJson: Auth0UsersResponseJSON | null) =>
  auth0UserJSONsToAuth0Users(responseJson?.users || []);

const convertToProducts = async (responseJson: any) =>
  convertJsonToArrayType<Product>(responseJson);

const convertPrivateOfferResponseToPrivateOffer = async (
  response: PrivateOfferResponseJSON | null,
) => (response ? privateOfferJSONToPrivateOffer(response.private_offer) : null);

const convertPrivateOffersResponseToPrivateOffers = async (
  response: PrivateOffersResponseJSON | null,
) =>
  response
    ? response.private_offers?.map(privateOfferJSONToPrivateOffer)
    : null;

const convertToPricing = async (responseJson: PricingJSON | null) =>
  toPricing(responseJson);

const convertToDocument = async (responseJson: DocumentUploadResponseJSON) =>
  documentJSONToDocument(responseJson?.data);

const convertToSalesforceDataMapping = async (
  responseJson: SalesforceDataMappingResponseJSON,
) => salesforceDataMappingJSONToSalesforceDataMapping(responseJson);

const convertToAgreements = async (responseJson: AgreementsResponseJSON) =>
  agreementJSONToAgreements(responseJson.entitlements);

const noOpTransformer = <T,>(responseJson: T): T => responseJson;

const usePrivateOfferApi = (
  accessTokenProvider: () => Promise<string>,
  contextValueSetters: ContextValueSetters,
): UsePrivateOfferApi => {
  const { setOfferSubmissionError, setLoading, setSynced, setSubmitting } =
    contextValueSetters;

  const handleError = async (): Promise<null> => null;

  const handleOfferSubmissionError = useCallback(
    async (response): Promise<null> => {
      const body = await response.response.text();
      const schemaValidationError = JSON.parse(body) as SchemaValidationError;

      setOfferSubmissionError(schemaValidationError);

      return null;
    },
    [setOfferSubmissionError],
  );

  const afterGet = useCallback(
    (apiKey: OfferAPIKey) => async () => {
      setLoading((previousLoading) => ({
        ...previousLoading,
        [apiKey]: false,
      }));
      setSynced((previousSynced) => ({ ...previousSynced, [apiKey]: true }));
    },
    [setLoading, setSynced],
  );

  const afterPostOrPut = useCallback(
    (apiKey: OfferAPIKey) => async () => {
      setSubmitting((previousSubmitting) => ({
        ...previousSubmitting,
        [apiKey]: false,
      }));
    },
    [setSubmitting],
  );

  const stateTrackingGet = useCallback(
    async <T,>(
      apiCallKey: OfferAPIKey,
      getSupplier: () => Promise<Response>,
      jsonTransformer: (responseJson: any) => Promise<T> = noOpTransformer,
    ): Promise<T> => {
      setLoading((previousLoading) => ({
        ...previousLoading,
        [apiCallKey]: true,
      }));

      return getSupplier()
        .catch(handleError)
        .then(getResponseJson)
        .then((responseJson) =>
          responseJson ? jsonTransformer(responseJson) : null,
        )
        .finally(afterGet(apiCallKey));
    },
    [setLoading, afterGet],
  );

  const stateTrackingSubmit = useCallback(
    async <T,>(
      apiKey: OfferAPIKey,
      postOrPutSupplier: () => Promise<Response>,
      jsonTransformer: (responseJson: any) => Promise<T> = noOpTransformer,
      errorHandler: (reason: any) => Promise<null> = handleError,
      responseTransformer: (response: Response | null) => any = getResponseJson,
    ): Promise<T> => {
      setSubmitting((previousSubmitting) => ({
        ...previousSubmitting,
        [apiKey]: true,
      }));

      return postOrPutSupplier()
        .catch(errorHandler)
        .then(responseTransformer)
        .then((responseJson) =>
          responseJson ? jsonTransformer(responseJson) : null,
        )
        .finally(afterPostOrPut(apiKey));
    },
    [setSubmitting, afterPostOrPut],
  );

  const stateTrackingSubmitWithEmptyResponse = useCallback(
    async <T,>(
      apiKey: OfferAPIKey,
      postOrPutSupplier: () => Promise<Response>,
      errorHandler: (reason: any) => Promise<null> = handleError,
    ): Promise<T> =>
      stateTrackingSubmit(
        apiKey,
        postOrPutSupplier,
        null,
        errorHandler,
        () => {},
      ),
    [stateTrackingSubmit],
  );

  const getUser = useCallback(
    async (): Promise<User> =>
      stateTrackingGet(
        OfferAPIKey.User,
        () => authApi(accessTokenProvider).get('whoami'),
        convertToUser,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getAuth0Users = useCallback(
    async (): Promise<Auth0User[]> =>
      stateTrackingGet(
        OfferAPIKey.Users,
        () => offersV2Api(accessTokenProvider).get('public/v2/users'),
        convertToUsers,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getProducts = useCallback(
    async (marketplace: Marketplace): Promise<Product[]> =>
      stateTrackingGet(
        OfferAPIKey.Products,
        () =>
          offersV2Api(accessTokenProvider).get(
            `public/v2/products?cloud=${marketplace}`,
          ),
        convertToProducts,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const fetchOffer = useCallback(
    (poId: string, silent: boolean = false) =>
      stateTrackingGet(
        silent ? OfferAPIKey.Silent : OfferAPIKey.Offer,
        () =>
          offersV2Api(accessTokenProvider).get(
            `public/v2/private-offers/${poId}`,
          ),
        convertPrivateOfferResponseToPrivateOffer,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getOffer = useCallback(
    async (poId: string): Promise<PrivateOffer | null> => fetchOffer(poId),
    [fetchOffer],
  );

  const getOfferSilently = useCallback(
    async (poId: string): Promise<PrivateOffer | null> =>
      fetchOffer(poId, true),
    [fetchOffer],
  );

  const getOfferForAgreement = useCallback(
    (offerId: string): Promise<PrivateOffer | null> =>
      stateTrackingGet(
        OfferAPIKey.AgreementOffer,
        () =>
          offersV2Api(accessTokenProvider).get(
            `public/v2/private-offers?offer_ref=${offerId}&limit=1`,
          ),
        async (r: PrivateOffersResponseJSON | null) => {
          const privateOffers =
            await convertPrivateOffersResponseToPrivateOffers(r);
          return privateOffers?.at(0);
        },
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getAgreementMappedAsPrivateOffer = useCallback(
    (offerId: string): Promise<any> =>
      stateTrackingGet(
        OfferAPIKey.Silent,
        () =>
          offersV2Api(accessTokenProvider).get(
            `public/v2/marketplace/aws/offers/${offerId}`,
          ),
        convertPrivateOfferResponseToPrivateOffer,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getOffersForOpportunity = useCallback(
    async (opportunityId: string): Promise<PrivateOffer[] | null> =>
      stateTrackingGet(
        OfferAPIKey.OffersForOpportunity,
        () =>
          offersV2Api(accessTokenProvider).get(
            `public/v2/private-offers?salesforce_opportunity_id=${opportunityId}`,
          ),
        convertPrivateOffersResponseToPrivateOffers,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const createOffer = useCallback(
    async (
      offer: Partial<PrivateOffer>,
      createInMarketplace: boolean,
    ): Promise<PrivateOffer | null> => {
      const json: PrivateOfferRequestJSON = {
        create_in_marketplace: createInMarketplace,
        send_buyer_instructions: false,
        dry_run: false,
        private_offer: privateOfferToPrivateOfferJSON(offer),
      };

      return stateTrackingSubmit(
        OfferAPIKey.Offer,
        () =>
          offersV2Api(accessTokenProvider).post('public/v2/private-offers', {
            json,
          }),
        convertPrivateOfferResponseToPrivateOffer,
        handleOfferSubmissionError,
      );
    },
    [stateTrackingSubmit, accessTokenProvider, handleOfferSubmissionError],
  );

  const updateOffer = useCallback(
    async (
      poId: string,
      updatedOffer: Partial<PrivateOffer>,
      createInMarketplace: boolean,
    ): Promise<PrivateOffer | null> => {
      const json: PrivateOfferRequestJSON = {
        create_in_marketplace: createInMarketplace,
        send_buyer_instructions: false,
        dry_run: false,
        private_offer: privateOfferToPrivateOfferJSON(updatedOffer),
      };

      return stateTrackingSubmit(
        OfferAPIKey.Offer,
        () =>
          offersV2Api(accessTokenProvider).put(
            `public/v2/private-offers/${poId}`,
            { json },
          ),
        convertPrivateOfferResponseToPrivateOffer,
        handleOfferSubmissionError,
      );
    },
    [stateTrackingSubmit, accessTokenProvider, handleOfferSubmissionError],
  );

  const sendBuyerInstructions = useCallback(
    async (poId: string): Promise<void> =>
      stateTrackingSubmitWithEmptyResponse(
        OfferAPIKey.SendBuyerInstructions,
        () =>
          offersV2Api(accessTokenProvider).post(
            `public/v2/private-offers/${poId}/send-buyer-instructions`,
          ),
      ),
    [stateTrackingSubmitWithEmptyResponse, accessTokenProvider],
  );

  const cancelOffer = useCallback(
    async (poId: string): Promise<void> =>
      stateTrackingSubmitWithEmptyResponse(OfferAPIKey.CancelOffer, () =>
        offersV2Api(accessTokenProvider).post(
          `public/v2/private-offers/${poId}/cancellation`,
        ),
      ),
    [stateTrackingSubmitWithEmptyResponse, accessTokenProvider],
  );

  const archiveOffer = useCallback(
    async (poId: string): Promise<void> =>
      stateTrackingSubmitWithEmptyResponse(OfferAPIKey.ArchiveOffer, () =>
        offersV2Api(accessTokenProvider).delete(
          `public/v2/private-offers/${poId}`,
        ),
      ),
    [stateTrackingSubmitWithEmptyResponse, accessTokenProvider],
  );

  const getVendorCurrencies = useCallback(
    async (): Promise<string[]> =>
      stateTrackingGet(OfferAPIKey.VendorCurrencies, () =>
        offersV2Api(accessTokenProvider).get(
          `public/v2/marketplace/aws/seller/allowed-currencies`,
        ),
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const getAwsMarketplacePricing = useCallback(
    async (encryptedProductId: string): Promise<Pricing> =>
      stateTrackingGet(
        OfferAPIKey.MarketplacePricing,
        () =>
          offersV2Api(accessTokenProvider).get(
            `public/v2/marketplace/aws/products/${encryptedProductId}/pricing`,
          ),
        convertToPricing,
      ),
    [stateTrackingGet, accessTokenProvider],
  );

  const postDocument = useCallback(
    async (document: File): Promise<Document> => {
      const body = new FormData();
      body.append('file', document);

      return stateTrackingSubmit(
        OfferAPIKey.Document,
        () =>
          offersV2Api(accessTokenProvider).post(
            `public/v2/documents?filename=${document.name}`,
            { body },
          ),
        convertToDocument,
      );
    },
    [stateTrackingSubmit, accessTokenProvider],
  );

  const getSalesforceDataMapping = useCallback(
    async (
      marketplace: Marketplace,
      opportunityId: string,
      mappingId?: string,
    ): Promise<SalesforceDataMapping | null> => {
      const url =
        opportunityId && mappingId
          ? `api/mapped-fields?crmId=${opportunityId}&mappingId=${mappingId}`
          : `api/mapped-fields?crmId=${opportunityId}&cloud=${marketplace}&domain=offer`;

      return stateTrackingGet(
        OfferAPIKey.SalesforceDataMapping,
        () => salesforceDataMapperApi(accessTokenProvider).get(url),
        convertToSalesforceDataMapping,
      );
    },
    [stateTrackingGet, accessTokenProvider],
  );

  const getAgreementsForBuyer = useCallback(
    (
      buyerAccountNumber: string = '',
      offerId: string = '',
      activeOnly: boolean = false,
    ): Promise<Agreement[]> => {
      const activeSearchParam = activeOnly ? '&status=ACTIVE' : '';
      const buyerAccountSearchParam = buyerAccountNumber
        ? `&buyer_billing_account_ref=${buyerAccountNumber}`
        : '';

      const offerIdSearchParam = offerId ? `&offerid=${offerId}` : '';
      const url = `public/v2/marketplace/aws/entitlements?limit=20${activeSearchParam}${buyerAccountSearchParam}${offerIdSearchParam}`;

      return stateTrackingGet(
        OfferAPIKey.ActiveAgreements,
        () => offersV2Api(accessTokenProvider).get(url),
        convertToAgreements,
      );
    },
    [stateTrackingGet, accessTokenProvider],
  );

  return {
    getUser,
    getAuth0Users,
    getProducts,
    getOffer,
    getOfferSilently,
    getOfferForAgreement,
    getAgreementMappedAsPrivateOffer,
    getOffersForOpportunity,
    createOffer,
    updateOffer,
    cancelOffer,
    sendBuyerInstructions,
    archiveOffer,
    getVendorCurrencies,
    getAwsMarketplacePricing,
    postDocument,
    getSalesforceDataMapping,
    getAgreementsForBuyer,
  };
};

export default usePrivateOfferApi;
