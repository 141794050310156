const DirectOfferSvg = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.3822 29.4576C38.5341 29.4576 41.0891 26.9161 41.0891 23.781C41.0891 20.646 38.5341 18.1045 35.3822 18.1045C32.2304 18.1045 29.6753 20.646 29.6753 23.781C29.6753 26.9161 32.2304 29.4576 35.3822 29.4576Z"
      fill="#209DB2"
    />
    <path d="M60 7H51V12H60V7Z" fill="#C1ECDE" />
    <path d="M31 7H22V12H31V7Z" fill="#C1ECDE" />
    <path
      d="M26.9227 48.745C26.9561 47.0085 27.3633 45.2993 28.1169 43.7326C28.8704 42.1659 29.9528 40.778 31.2911 39.6625C30.9847 38.2391 30.1599 36.9786 28.9752 36.1238C27.7906 35.269 26.33 34.8802 24.8745 35.0323C23.419 35.1844 22.0715 35.8665 21.0913 36.9475C20.1111 38.0285 19.5676 39.432 19.5652 40.8877C19.5652 40.9693 19.5652 41.051 19.5652 41.1327C19.0112 40.9674 18.4356 40.8849 17.8573 40.8877C16.2863 40.8877 14.7796 41.5078 13.668 42.612C12.5564 43.7161 11.9308 45.214 11.9286 46.7766C10.3563 46.7766 8.84829 47.3979 7.73645 48.5038C6.62462 49.6097 6 51.1096 6 52.6736C6 54.2376 6.62462 55.7375 7.73645 56.8435C8.84829 57.9494 10.3563 58.5707 11.9286 58.5707H32.2272C30.6334 57.4583 29.3235 55.9903 28.4024 54.2841C27.4813 52.5779 26.9745 50.6809 26.9227 48.745Z"
      fill="#CFEAF3"
    />
    <path
      d="M32.5328 24.2303L34.2326 25.823L37.6403 21.7473"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M22 32V7H60V57H46.983"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M51.3349 12H56V53H47"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M26.0001 32V12.0143L31 12.0143"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M51 7V13.0519C50.9978 13.5693 50.7817 14.0648 50.3991 14.4299C50.0164 14.795 49.4984 15 48.9583 15H33.0333C32.4941 15 31.9769 14.7948 31.5956 14.4294C31.2142 14.0641 31 13.5686 31 13.0519V10.0259V7"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M35.3001 29.5719C38.2933 29.5719 40.7197 27.1584 40.7197 24.1812C40.7197 21.204 38.2933 18.7905 35.3001 18.7905C32.307 18.7905 29.8806 21.204 29.8806 24.1812C29.8806 27.1584 32.307 29.5719 35.3001 29.5719Z"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M47 48H49"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M34 11H36"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M40 11H42"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M46 11H48"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M50 48H53"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M43 25H46"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M48 25L53 25"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M53 20H51"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M53 30H43"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M53 35L34 35"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M53 40H41"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M49 20H43"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M39.0856 46.3074C39.0856 44.7326 38.4567 43.2222 37.3371 42.1087C36.2176 40.9951 34.6992 40.3695 33.1159 40.3695C31.5327 40.3695 30.0143 40.9951 28.8947 42.1087C27.7752 43.2222 27.1462 44.7326 27.1462 46.3074"
      stroke="#063649"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.6467 58H39.2005C39.9844 58 40.6458 58.0296 41.37 57.7312C42.0943 57.4328 42.7524 56.9954 43.3068 56.444C43.8611 55.8927 44.3008 55.2381 44.6008 54.5176C44.9008 53.7972 45.0552 53.0251 45.0552 52.2453C45.0552 51.4655 44.9008 50.6934 44.6008 49.973C44.3008 49.2526 43.8611 48.598 43.3068 48.0466C42.7524 47.4952 42.0943 47.0578 41.37 46.7594C40.6458 46.461 39.8695 46.3074 39.0855 46.3074C39.0866 45.527 38.933 44.7539 38.6335 44.0326C38.334 43.3112 37.8944 42.6557 37.34 42.1034C36.7855 41.5512 36.1271 41.113 35.4023 40.8141C34.6774 40.5152 33.9005 40.3614 33.1158 40.3614C32.5344 40.3612 31.9561 40.4466 31.3997 40.6146C31.3997 40.5329 31.3997 40.4512 31.3997 40.3614C31.3997 38.7865 30.7707 37.2762 29.6512 36.1626C28.5317 35.0491 27.0132 34.4235 25.43 34.4235C23.8467 34.4235 22.3283 35.0491 21.2088 36.1626C20.0892 37.2762 19.4603 38.7865 19.4603 40.3614C19.4559 40.4457 19.4559 40.5302 19.4603 40.6146C18.5659 40.3461 17.6207 40.2902 16.7006 40.4514C15.7806 40.6125 14.9114 40.9862 14.163 41.5423C13.4145 42.0984 12.8078 42.8215 12.3915 43.6534C11.9752 44.4853 11.7611 45.4027 11.7662 46.3319C10.1829 46.3319 8.66453 46.9575 7.54499 48.0711C6.42546 49.1847 5.79651 50.695 5.79651 52.2698C5.79651 53.8446 6.42546 55.355 7.54499 56.4685C8.66453 57.5821 10.1829 58 11.7662 58H36.6467Z"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M11.7908 46.3075H15.2067"
      stroke="#063649"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M11.7909 49.697C11.1376 49.7339 10.5232 50.018 10.0737 50.491C9.6243 50.9641 9.3739 51.5904 9.3739 52.2412C9.3739 52.8921 9.6243 53.5184 10.0737 53.9915C10.5232 54.4645 11.1376 54.7486 11.7909 54.7855"
      stroke="#063649"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.0856 54.8099C39.4337 54.8296 39.7821 54.7783 40.1096 54.6593C40.4371 54.5403 40.7367 54.3561 40.9901 54.1179C41.2435 53.8798 41.4454 53.5926 41.5834 53.2741C41.7214 52.9557 41.7925 52.6125 41.7925 52.2657C41.7925 51.9189 41.7214 51.5758 41.5834 51.2573C41.4454 50.9388 41.2435 50.6517 40.9901 50.4135C40.7367 50.1753 40.4371 49.9911 40.1096 49.8721C39.7821 49.7531 39.4337 49.7019 39.0856 49.7215"
      stroke="#063649"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DirectOfferSvg;
