import * as yup from '../../../../../../../../../../utils/yup-extended';
import { duplicateInvoiceDatesTest } from './schemaTests';
import { FieldKey as ProductAndPricingFieldKey } from '../../formTypes';
import { FieldKeys, FormSchedule } from './formTypes';
import { PaymentModel } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';

const requiredWhenPaymentSchedule = {
  is: PaymentModel.PaymentSchedule,
  then: (schema: yup.MixedSchema) => schema.required(),
  otherwise: (schema: yup.MixedSchema) => schema.nullable(),
};

export const schedulesSchema = yup
  .array<FormSchedule>()
  .of(
    yup.object({
      [FieldKeys.InvoiceDateType]: yup.string().oneOf([null]).nullable(),
      [FieldKeys.InvoiceDate]: yup
        .string()
        .when(
          ProductAndPricingFieldKey.PaymentModel,
          requiredWhenPaymentSchedule,
        ),
      [FieldKeys.InvoiceAmount]: yup
        .number()
        .min(0)
        .when(
          ProductAndPricingFieldKey.PaymentModel,
          requiredWhenPaymentSchedule,
        ),
    }),
  )
  .when(ProductAndPricingFieldKey.PaymentModel, {
    is: PaymentModel.PaymentSchedule,
    then: (schema: yup.ArraySchema<FormSchedule>) =>
      schema.test('Duplicate invoice dates', '', duplicateInvoiceDatesTest),
    otherwise: (schema: yup.ArraySchema<FormSchedule>) => schema,
  });
