export interface Product {
  cloud: string;
  productid: string;
  productidInternal: string;
  encryptedProductid: string;
  vendorid: string;
  listingType: ProductListingType;
  marketplaceUrl: string;
  solutionId: string;
  name: string;
  status: ProductStatus;
  registrationHosting: RegistrationHostingType;
  contractStandardFields: ContractStandardFields[];
  registrationPageFields: RegistrationPageField[];
}

export interface AWSProduct extends Product {
  pricing: AWSProductPricing;
}

export enum ProductListingType {
  Saas = 'saas',
  Ccp = 'ccp',
  PayGo = 'saas-subscription',
}

export enum ProductStatus {
  InProgress = 'in-progress',
  WaitingForTackle = 'waiting-for-tackle',
  Approved = 'approved',
  WaitingForCloudProvider = 'waiting-for-cloud-provider',
  ReadyForPreview = 'ready-to-preview',
  Published = 'published',
  Deprecated = 'deprecated',
}

export interface AWSProductPricing {
  contractDimensions: AWSProductPricingContractDimension[];
  usageFees: AWSProductPricingUsageFee[];
}

export interface AWSProductPricingContractDimension {
  position: number;
  sku: string;
  name: string;
  description: string;
}

export interface AWSProductPricingUsageFee {
  position: number;
  sku: string;
  description: string;
  price: string;
}

export enum RegistrationHostingType {
  SelfHosted = 'self-hosted',
  TackleHosted = 'tackle-hosted',
}

export interface ContractStandardFields {
  cloudField: boolean;
  title: string;
}

type Option = {
  label?: string;
  value?: string;
};

type RegistrationPageField = {
  _id: string;
  dataType:
    | 'text'
    | 'dropdown'
    | 'number'
    | 'radio'
    | 'checkbox'
    | 'boolean'
    | 'true/false'
    | 'aws-region';
  default: boolean;
  displayTitle: string;
  enabled: boolean;
  options: Option[];
  required: boolean;
  tackleRequired: boolean;
  title: string;
  validationType: string;
};
