import { useCallback, useState } from 'react';
import { TextField } from '@tackle-io/platform-ui';
import { Magnify } from 'mdi-material-ui';
import {
  Operation,
  SEARCH_KEY,
  getUrlParamsPath,
} from '../../helpers/urlParamsHelper';
import { useHistory, useLocation } from 'react-router-dom';
import useStyles from './CoSellSearch.styles';
import { useDebounce } from 'react-use';
import { coSellLandingPageDataId } from 'packages/cosell/src/utilities/intercomEnums';

export const CoSellSearch = ({ placeholder = 'Search opportunities' }) => {
  const classes = useStyles();
  // TODO: Implement disabled if EmptyTable is shown & active filters are present
  const isDisabled = false;
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const searchString = searchParams.get(SEARCH_KEY) || '';
  // Get search from URL
  const [searchValue, setSearchValue] = useState(
    searchString ? decodeURIComponent(searchString) : searchString,
  );

  const handleSearchFilter = useCallback(
    (value) => {
      const correctedPath = getUrlParamsPath({
        filter: {
          key: SEARCH_KEY,
          operation: Operation.SEARCH,
          value: value,
        },
        location,
      });

      history.push(correctedPath);

      // TODO: Track search in Amplitude
    },
    [history, location],
  );

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchValue(event.target.value);
  };

  // Debounce onChange to reduce render lag between keystrokes,
  useDebounce(() => handleSearchFilter(searchValue), 500, [searchValue]);

  return (
    <div
      className={classes.container}
      data-id={coSellLandingPageDataId.OPPORTUNITIES_SEARCH}
    >
      <TextField
        key="opportunitiesSearch"
        iconLeft={<Magnify />}
        disabled={isDisabled}
        placeholder={placeholder}
        id="opportunitiesSearch"
        name="opportunitiesSearch"
        value={searchValue}
        onChange={handleSearchValueChange}
      />
    </div>
  );
};
