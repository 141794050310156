export const vendorsWithException = [
  'P9QLC7G', // CloudCheckr
  'EL5IUKE', // NetApp
  'ACYC5NY', // POC NetApp
  'S8APOKW', // Commvault
  'Q3EXNHO', // ThousandEyes
  'DS4RPPC', // WP Engine
  '8ARDXWZ', // Crownpeak
  '34S1PJ5', // Unqork
];

export function shouldEnforceSDDSConfiguration(data) {
  const currentVendorId = data?.currentUser?.vendor?.id;
  const isVendorException = vendorsWithException.includes(currentVendorId);

  // If the vendor is in the exception list, do not enforce SDDS configuration
  if (isVendorException) {
    return false;
  }

  const vendorType = data?.currentUser?.vendor?.vendor_type;

  // If vendor_type is not 'prod', do not enforce SDDS configuration
  if (vendorType !== 'prod') {
    return false;
  }

  const vendorCreatedAt = data?.currentUser?.vendor?.created_at
    ? new Date(data?.currentUser?.vendor?.created_at)
    : null;
  const cutoffDate = new Date('2024-07-31T23:59:59Z');

  // If the vendor was created after the cutoff date, do not enforce SDDS configuration
  const newerCustomer = vendorCreatedAt > cutoffDate;
  if (newerCustomer) {
    return false;
  }

  // Check if at least one of the AWS listings has a 'published' status
  const awsListings = data?.currentUser?.vendor?.listingsByChannel?.aws || [];
  const hasAwsListings = awsListings.some(
    (listing) => listing.status === 'published',
  );

  const isUnconfigured =
    data?.currentUser?.vendor?.configuration?.status?.sdds.value ===
    'unconfigured';

  return hasAwsListings && isUnconfigured;
}
