import { Button } from '@tackle-io/platform-ui';
import { makeStyles } from 'vendor/material';

/** Breadcrumb looking button that can be used at top of
 *  CanvasApp page to navigate back
 */
const ButtonLink = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => {
  const classes = useStyles();
  return (
    <Button onClick={onClick} className={classes.buttonLink}>
      <>{children}</>
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonLink: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
    fontSize: `${theme.typography.pxToRem(12)} !important `,
  },
}));

export default ButtonLink;
