import * as yup from '../../../../../../../../../../utils/yup-extended';
import { FieldKey, FormDimension } from './formTypes';
import { duplicateApiNamesTest } from './schemaTests';
import { isWithProgress } from 'pages/PrivateOffers/pages/Next/generic/utils/schema/schemaTestsUtils';
import { PaymentModel } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { FieldKey as ProductAndPricingFieldKey } from '../../formTypes';

const nameSchema = yup.string().when('$', {
  is: isWithProgress(
    ProductAndPricingFieldKey.PaymentModel,
    (pm: PaymentModel) => pm === PaymentModel.PayGo,
  ),
  then: (schema: yup.StringSchema) => schema.nullable().progress(false),
  otherwise: (schema: yup.StringSchema) =>
    schema.max(80).nullable().required().progress(true),
});

const invalidApiNameMessage =
  'Api name must start with a letter and may contain up to 36 letters, numbers and underscores.';

const apiNameSchema = yup
  .string()
  .matches(/^[a-zA-Z][_a-zA-Z\d]{0,35}$/, {
    message: invalidApiNameMessage,
    excludeEmptyString: true,
  })
  .max(36)
  .nullable();

const priceSchema = yup.number().min(0).nullable();
const quantitySchema = yup.number().min(0).nullable();

export const dimensionsSchema = yup
  .array<FormDimension>()
  .of(
    yup.object({
      [FieldKey.Name]: nameSchema,
      [FieldKey.APIName]: apiNameSchema,
      [FieldKey.Price]: priceSchema,
      [FieldKey.Quantity]: quantitySchema,
    }),
  )
  .test('Duplicate api names', '', duplicateApiNamesTest);
