import { createContext } from 'react';
import usePrivateOfferApi from 'pages/PrivateOffers/pages/Next/generic/hooks/usePrivateOfferApi';

export interface SchemaValidationError {
  message: string;
  schema_validation_errors: string[];
}

export interface LogicalValidationError {
  code: string;
  message: string;
}

export type OfferSubmissionError =
  | SchemaValidationError
  | LogicalValidationError[]
  | null;

export interface ApiContextValues {
  offerSubmissionError: OfferSubmissionError;
  setOfferSubmissionError: (ose: OfferSubmissionError) => void;
  isSubmitting: (k: string) => boolean;
  isLoading: (k: string) => boolean;
  hasSynced: (k: string) => boolean;
  api: ReturnType<typeof usePrivateOfferApi>;
}

const ApiContext = createContext<ApiContextValues>({
  offerSubmissionError: null,
  setOfferSubmissionError: async (_: SchemaValidationError | null) => {},
  isSubmitting: (_: string) => false,
  isLoading: (_: string) => false,
  hasSynced: (_: string) => false,
  api: null,
});

export default ApiContext;
