import React from 'react';
import { Divider, Grid, useMediaQuery, useTheme } from 'vendor/material';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import InfoItem, {
  FULL_GRID_SIZE,
} from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
import { coSellAceOpportunityFormFieldsDataId } from 'packages/cosell/src/utilities/intercomEnums';
import { SoftwareRevenue } from 'packages/cosell/src/types/AceOpportunity.type';

// import { DetailsSectionCard, InfoItem } from 'packages/cosell/src/components';

interface AceAdditionalDetailsProps {
  isSRRPAndLaunched?: boolean;
  awsAccountId?: string;
  additionalComments?: string;
  partnerOpportunityIdentifier?: string;
  competitiveTracking?: string;
  softwareRevenue?: SoftwareRevenue;
  awsMarketplaceOffers?: string[];
}

const AceAdditionalDetails: React.FC<AceAdditionalDetailsProps> = ({
  isSRRPAndLaunched,
  awsAccountId,
  additionalComments,
  partnerOpportunityIdentifier,
  competitiveTracking,
  softwareRevenue,
  awsMarketplaceOffers,
}) => {
  const theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down(960));

  return (
    <DetailsSectionCard title="Additional details" icon={<OfficeBuilding />}>
      <Grid container spacing={2}>
        <InfoItem
          itemTitle="Partner CRM unique identifier"
          value={partnerOpportunityIdentifier}
          gridSize={FULL_GRID_SIZE}
          dataId={
            coSellAceOpportunityFormFieldsDataId.PARTNER_CRM_UNIQUE_IDENTIFIER
          }
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="Competitive tracking"
          value={competitiveTracking}
          dataId={coSellAceOpportunityFormFieldsDataId.COMPETITIVE_TRACKING}
        />
        {screenMobile && <Divider style={{ width: '100%' }} />}
        <InfoItem
          itemTitle="Additional comments"
          value={additionalComments}
          dataId={coSellAceOpportunityFormFieldsDataId.ADDITIONAL_COMMENTS}
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="AWS account ID"
          value={awsAccountId}
          dataId={coSellAceOpportunityFormFieldsDataId.AWS_ACCOUNT_ID}
          gridSize={FULL_GRID_SIZE}
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="Marketplace offer ID"
          value={awsMarketplaceOffers?.[0]}
          dataId={coSellAceOpportunityFormFieldsDataId.MARKETPLACE_OFFER_ID}
        />
        {screenMobile && <Divider style={{ width: '100%' }} />}
        <InfoItem
          itemTitle="Procurement type"
          value={softwareRevenue?.deliveryModel}
          dataId={coSellAceOpportunityFormFieldsDataId.PROCUREMENT_TYPE}
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="Customer software value"
          value={softwareRevenue?.value?.amount}
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_SOFTWARE_VALUE}
        />
        {screenMobile && <Divider style={{ width: '100%' }} />}
        <InfoItem
          itemTitle="Currency"
          value={softwareRevenue?.value?.currencyCode}
          dataId={coSellAceOpportunityFormFieldsDataId.CURRENCY_CODE}
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="Contract start date"
          value={softwareRevenue?.effectiveDate}
          dataId={coSellAceOpportunityFormFieldsDataId.CONTRACT_START_DATE}
        />
        {screenMobile && <Divider style={{ width: '100%' }} />}
        <InfoItem
          itemTitle="Contract end date"
          value={softwareRevenue?.expirationDate}
          dataId={coSellAceOpportunityFormFieldsDataId.CONTRACT_END_DATE}
        />
        <Divider style={{ width: '100%' }} />
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceAdditionalDetails;
