import EditForm from './EditForm/EditForm';
import { initialFormValuesForCloneOffer } from './EditForm/initialFormValues';
import React, { useContext } from 'react';
import { FormValues } from './EditForm/formSchema';
import { formValuesToPrivateOffer } from './EditForm/formValuesToPrivateOffer';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContextProvider/apiContext';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import LoadAwsPrivateOfferPage, {
  AwsPrivateOfferCanvasPage,
  AwsPrivateOfferCanvasPageProps,
} from 'pages/PrivateOffers/pages/Next/aws/shared/AwsPrivateOfferPage';
import OfferContext from '../../generic/OfferContext/offerContext';
import OfferPageContext from '../../generic/OfferPageContext/offerPageContext';

const CloneAwsOfferPrivateOfferPage: React.FunctionComponent = () => {
  const {
    api: { createOffer },
  } = useContext(ApiContext);

  const { user, users, afterSubmit } = useContext(OfferPageContext);
  const { offer, product } = useContext(OfferContext);

  const initialValues = initialFormValuesForCloneOffer(
    offer,
    product,
    user,
    users,
  );

  const onSubmit = async (
    formValues: FormValues,
    createInMarketplace: boolean,
  ) => {
    const clonedOffer = formValuesToPrivateOffer(formValues);

    const createdOffer = await createOffer(clonedOffer, createInMarketplace);

    if (createdOffer) {
      afterSubmit(createdOffer);
    }
  };

  return <EditForm initialValues={initialValues} onSubmit={onSubmit} />;
};

export const CloneAwsPrivateOfferCanvasPage: React.FunctionComponent<
  Omit<AwsPrivateOfferCanvasPageProps, 'mode' | 'children'>
> = (props) => (
  <AwsPrivateOfferCanvasPage {...props} mode={OfferPageMode.Clone}>
    <CloneAwsOfferPrivateOfferPage />
  </AwsPrivateOfferCanvasPage>
);

const CloneAwsPrivateOfferPlatformPage: React.FunctionComponent = () => (
  <LoadAwsPrivateOfferPage mode={OfferPageMode.Clone}>
    <CloneAwsOfferPrivateOfferPage />
  </LoadAwsPrivateOfferPage>
);

export default CloneAwsPrivateOfferPlatformPage;
