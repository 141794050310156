import React, { useContext } from 'react';
import { ClipboardText } from 'mdi-material-ui';
import { Box, Grid } from 'vendor/material';
import { Banner, Card } from '@tackle-io/platform-ui';
import LabelAndValue from './LabelAndValue';
import OfferSection from './OfferSection';
import SectionDivider from './SectionDivider';
import { OfferType } from '../../../generic/api/types/PrivateOffer';
import OfferContext from 'pages/PrivateOffers/pages/Next/generic/OfferContext/offerContext';

const BasicInformationSection: React.FunctionComponent = () => {
  const { offer } = useContext(OfferContext);
  const partnerOffer = offer.partnerOffer;

  return (
    <Card
      title="Basic information"
      icon={<ClipboardText />}
      bodyStyle={{ backgroundColor: '#FAFBFC' }}
    >
      {!!offer.createdInMarketplaceAt && (
        <Box mb={4}>
          <Banner
            borderPosition="top"
            title="Buyer details can be edited. The rest cannot be due to the offer being created in the marketplace."
          />
        </Box>
      )}
      {offer.offerType === OfferType.Direct && (
        <OfferSection title="Billing account">
          <LabelAndValue
            label="Buyer AWS account number"
            value={offer.buyerBillingAccountRef}
          />
        </OfferSection>
      )}
      {offer.offerType === OfferType.PartnerResale && (
        <OfferSection title="Partner details">
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <LabelAndValue
                label="Partner name"
                value={partnerOffer?.partnerName}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <LabelAndValue
                label="Partner AWS account number"
                value={partnerOffer?.partnerRef}
              />
            </Grid>
          </Grid>
        </OfferSection>
      )}
      <SectionDivider />
      <OfferSection title="Buyer details">
        <Grid container spacing={2}>
          {offer.offerType === OfferType.PartnerResale && (
            <Grid item md={4} xs={12}>
              <LabelAndValue
                label="Buyer AWS account number"
                value={offer.buyerBillingAccountRef}
              />
            </Grid>
          )}
          <Grid
            item
            md={offer.offerType === OfferType.PartnerResale ? 4 : 12}
            xs={12}
          >
            <LabelAndValue
              label="Company name"
              value={offer.buyerCompanyName}
            />
          </Grid>
        </Grid>
      </OfferSection>
      <SectionDivider />
      <Box mb={2}>
        {offer.buyers?.map((u) => (
          <Box key={u.emailAddress}>
            <Box mb={2}>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <LabelAndValue label="Full name" value={u.fullName} />
                </Grid>
                <Grid item md={4} xs={12}>
                  <LabelAndValue label="Email" value={u.emailAddress} />
                </Grid>
                <Grid item md={4} xs={12}>
                  <LabelAndValue label="Title" value={u.title} />
                </Grid>
              </Grid>
            </Box>
            <SectionDivider />
          </Box>
        ))}
      </Box>
      <OfferSection title="Offer details">
        <Box mb={2}>
          <LabelAndValue label="Offer name" value={offer.offerName} />
        </Box>
        <SectionDivider />
        <Box mb={2}>
          <LabelAndValue
            label="Offer description"
            value={offer.offerDescription}
          />
        </Box>
        <SectionDivider />
        <Box mb={2}>
          <LabelAndValue
            label={
              offer.offerType === OfferType.PartnerResale
                ? 'Partner offer ID'
                : 'Offer ID'
            }
            value={offer.offerId}
          />
        </Box>
        <SectionDivider />
      </OfferSection>
      <OfferSection title="Renewal">
        <Box mb={2}>
          <LabelAndValue
            label="Is this a renewal?"
            value={offer.renewal ? 'Yes' : 'No'}
          />
        </Box>
        {offer.renewal && (
          <LabelAndValue
            label="Is existing customer moving to AWS marketplace"
            value={offer.awsRenewalMovingToMarketplace ? 'Yes' : 'No'}
          />
        )}
      </OfferSection>
    </Card>
  );
};

export default BasicInformationSection;
