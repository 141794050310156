import {
  MemoryRouter,
  Switch,
  Route,
  useParams,
  useHistory,
  useRouteMatch,
} from 'react-router';
import UnifiedOpportunityDetails from '../pages/UnifiedOpportunityDetails';
import { useMemo } from 'react';
import ButtonLink from 'packages/salesforce-canvas/src/components/ButtonLink';
import useCoSellSfCanvasActions from 'packages/salesforce-canvas/cosell/src/hooks/useCoSellSfCanvasActions';
import { type CoSellCanvasAppContentPayload } from 'packages/salesforce-canvas/src/lib/salesforceCanvasSession';
import invariant from 'tiny-invariant';
import Error from 'pages/Error/Error';
import EditAceOpportunityForm from 'packages/cosell/src/components/UnifiedOpportunityForm/AceOpportunityForm/EditAceOpportunityForm';
import { CreateOpportunityPage } from './CreateOpportunityPage';

/** Router for Cosell when rendered in the Salesforce  CanvasApp
 *  TODO: handle object in route dynamically to handle opportunities and invitations
 *  TODO: update the pageData.domain to be `co-sell`instead of cosell.
 */
const CoSellCanvasRouter = ({
  cloud,
  onClose,
  ...pageData
}: CoSellCanvasAppContentPayload & {
  onClose: () => void;
}) => {
  const domain = 'co-sell';
  const rootPath = `/${domain}/${pageData.object}`;

  /** set initial route to set the route context */
  const initialRoute = useMemo(() => {
    if (pageData.object === 'invitation') {
      return `${rootPath}/${cloud}/${pageData.tackleInvitationId}`;
    }
    switch (pageData.action) {
      case 'create':
        return `${rootPath}/${cloud}/create/${pageData.crmId}`;
      case 'edit':
        return `${rootPath}/${cloud}/${pageData.tackleOpportunityId}/edit`;
      case 'view':
        return `${rootPath}/${cloud}/${pageData.tackleOpportunityId}`;
    }
  }, [cloud, pageData, rootPath]);

  return (
    <MemoryRouter initialEntries={[initialRoute]}>
      <BackLink />
      <Switch>
        <Route path={`/:domain/opportunity/:cloud/create/:crmId`} exact>
          <CreateOpportunityPage onClose={onClose} />
        </Route>
        <Route
          path={`/:domain/opportunity/:cloud/:tackleOpportunityId`}
          component={OpportunityDetailsPage}
          exact
        />
        <Route
          path={`/:domain/opportunity/:cloud/:tackleOpportunityId/edit`}
          component={EditOpportunityPage}
          exact
        />
        <Route
          path={`/:domain/invitation/:cloud/:tackleInvitationId`}
          component={InvitationDetailsPage}
          exact
        />
        <Route component={Error} />
      </Switch>
    </MemoryRouter>
  );
};

const OpportunityDetailsPage = () => {
  const params = useParams<{ tackleOpportunityId: string }>();
  invariant(
    params.tackleOpportunityId,
    `The tackleOpportunityId is not defined, but required to view a Co-sell opportunity`,
  );

  return (
    <UnifiedOpportunityDetails
      tackleOpportunityId={params.tackleOpportunityId}
    />
  );
};

const EditOpportunityPage = () => {
  const params = useParams<{ tackleOpportunityId: string }>();
  const { handleCoSellAction } = useCoSellSfCanvasActions();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const opportunityLink = routeMatch.url.replace('/edit', '');

  invariant(
    params.tackleOpportunityId,
    `The tackleOpportunityId is not defined, but required to edit a Co-sell opportunity`,
  );

  const redirectToOpportunity = () => history.push(opportunityLink);

  const redirectToRoot = () =>
    handleCoSellAction({ id: 'go-back-to-opportunity-root' });

  const goBackLink = () => {
    // if the use navigate to the edit page from the root page, for some reason the history length is 1
    // if the user navigates to the edit page from the details page, the history length is 2
    if (history.length > 1) {
      history.goBack();
    } else {
      redirectToRoot();
    }
  };

  return (
    <EditAceOpportunityForm
      onCancel={goBackLink}
      onUpdateSuccess={redirectToOpportunity}
      opportunityId={params.tackleOpportunityId}
    />
  );
};
const InvitationDetailsPage = () => {
  const params = useParams<{ tackleInvitationId: string }>();

  return (
    <>
      <h1>Invitiation detail page</h1>
      <pre>invitationId: {params.tackleInvitationId}</pre>
    </>
  );
};

const BackLink = ({ label = `< back to opportunity` }: { label?: string }) => {
  const { handleCoSellAction } = useCoSellSfCanvasActions();
  return (
    <ButtonLink
      onClick={() => handleCoSellAction({ id: 'go-back-to-opportunity-root' })}
    >
      {label}
    </ButtonLink>
  );
};

export default CoSellCanvasRouter;
