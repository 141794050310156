import * as yup from '../../../../../../../../utils/yup-extended';

export enum FieldKey {
  MarketplaceFee = 'marketplaceFee',
  OfferMetadataFields = 'offerMetadataFields',
  OfferMetadataFieldLabel = 'key',
  OfferMetadataFieldValue = 'value',
  IsDisabled = 'isDisabled',
}

export type OfferMetadataField = {
  [FieldKey.OfferMetadataFieldLabel]: string;
  [FieldKey.OfferMetadataFieldValue]: string;
  [FieldKey.IsDisabled]: boolean;
};

export type FormValues = {
  [FieldKey.MarketplaceFee]: string;
  [FieldKey.OfferMetadataFields]: OfferMetadataField[];
};

export const formSchema: yup.ObjectSchema<FormValues> = yup.object({
  [FieldKey.MarketplaceFee]: yup.string().progress(true),
  [FieldKey.OfferMetadataFields]: yup.array().of(
    yup.object({
      [FieldKey.OfferMetadataFieldLabel]: yup.string().progress(true),
      [FieldKey.OfferMetadataFieldValue]: yup.string().progress(true),
      [FieldKey.IsDisabled]: yup.boolean(),
    }),
  ),
});
