import { FormValues } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/formSchema';
import {
  Buyer,
  PrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { Optional } from 'utils/optional/optional';
import { Product, ProductStatus } from '../../../generic/api/types/Product';
import { toMatchingProduct } from 'pages/PrivateOffers/pages/Next/generic/utils/product/productUtils';
import { FormBuyer } from 'pages/PrivateOffers/pages/Next/generic/OfferBuyers/formSchema';
import { OfferMetadataField } from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/AdditionalFieldsFormSection/formSchema';

const mappedBuyersToFormBuyers = (buyers: Buyer[]): FormBuyer[] =>
  buyers.map((b) => ({
    name: b.fullName,
    email: b.emailAddress,
    title: b.title,
  }));

const isValidProduct = (product: Product): boolean =>
  !!product.encryptedProductid &&
  (product.status === ProductStatus.Published ||
    product.status === ProductStatus.ReadyForPreview);

const toMergedOfferMetadataFields = (
  initialOfferMetadataFields: OfferMetadataField[],
) => {
  return (metadata: PrivateOffer['offerMetadata']): OfferMetadataField[] => {
    const initialOfferMetadataFieldByKey = initialOfferMetadataFields.reduce(
      (acc: { [k: string]: OfferMetadataField }, v: OfferMetadataField) => ({
        ...acc,
        [v.key]: v,
      }),
      {},
    );

    const mappedOfferMetadataEntries = Object.entries(metadata);

    const mappedOfferMetadataKeys = new Set<string>(
      mappedOfferMetadataEntries.map(([key]) => key),
    );

    const mappedOfferMetadataFields = mappedOfferMetadataEntries.map(
      ([key, value]) => {
        const isDisabled = Optional.ofNullable(
          initialOfferMetadataFieldByKey[key],
        )
          .map((omf) => omf.isDisabled)
          .orElse(false);

        return { key, value, isDisabled };
      },
    );

    const remainingInitialOfferMetadataFields =
      initialOfferMetadataFields.filter(
        (omf) => !mappedOfferMetadataKeys.has(omf.key),
      );

    return [
      ...remainingInitialOfferMetadataFields,
      ...mappedOfferMetadataFields,
    ];
  };
};

export const mergeInitialValuesWithSalesforceDataMapping = (
  initialValues: FormValues,
  dataMapperOffer: PrivateOffer | null,
  productsByProductId: { [pId: string]: Product },
): FormValues => {
  return Optional.ofNullable(dataMapperOffer)
    .map((offer) => {
      const {
        buyerAccountNumber,
        companyName,
        buyers: initialBuyers,
        cloud,
        offerName,
        offerAcceptanceDeadline,
        offerMetadataFields: initialOfferMetadataFields,
        offerType,
        productRef: initialProductRef,
        ...restInitialValues
      } = initialValues;

      const buyers = Optional.ofNullable(offer.buyers)
        .map(mappedBuyersToFormBuyers)
        .orElse(initialBuyers);

      const offerMetadataFields = Optional.ofNullable(offer.offerMetadata)
        .map(toMergedOfferMetadataFields(initialOfferMetadataFields))
        .orElse(initialOfferMetadataFields);

      const productRef = Optional.ofNullable(offer.productId)
        .map(toMatchingProduct(productsByProductId))
        .filter(isValidProduct)
        .map((p) => p.productid)
        .orElse(initialProductRef);

      return {
        buyerAccountNumber: offer.buyerBillingAccountRef ?? buyerAccountNumber,
        companyName: offer.buyerCompanyName ?? companyName,
        buyers,
        cloud: offer.marketplace ?? cloud,
        offerName: offer.offerName ?? offerName,
        offerAcceptanceDeadline:
          offer.offerExpirationAt ?? offerAcceptanceDeadline,
        offerMetadataFields,
        offerType: offer.offerType ?? offerType,
        productRef,
        ...restInitialValues,
      };
    })
    .orElse(initialValues);
};
