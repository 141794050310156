import React, { useContext, useState } from 'react';
import EditOfferPage from '../../../generic/EditOfferPage/EditOfferPage';
import { Form, Formik } from 'formik';
import BasicInformationFormSection from './BasicInformationFormSection/BasicInformationFormSection';
import { formSchema, FormValues } from './formSchema';
import { Box, Grid } from 'vendor/material';
import QuickLinks from './QuickLinks/QuickLinks';
import EndUserLicenseAgreementFormSection from './EndUserLicenseAgreementForSection/EndUserLicenseAgreementFormSection';
import ProductAndPricingFormSection from './ProductAndPricingFormSection/ProductAndPricingFormSection';
import OfferValidityFormSection from './OfferValidityPeriodFormSection/OfferValidityFormSection';
import NotifyUsersFormSection from './NotifyUsersFormSection/NotifyUsersFormSection';
import RegistrationDetailsFormSection from './RegistrationDetailsFormSection/RegistrationDetailsFormSection';
import AdditionalFieldsFormSection from './AdditionalFieldsFormSection/AdditionalFieldsFormSection';
import CreatedInMarketplaceBanner from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/CreatedInMarketplaceBanner/CreatedInMarketplaceBanner';
import ResellerAgreementFormSection from 'pages/PrivateOffers/pages/Next/aws/edit/EditForm/ResllerAgreementForSection/ResellerAgreementFormSection';
import { OfferType } from '../../../generic/api/types/PrivateOffer';
import OfferContext from '../../../generic/OfferContext/offerContext';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import LinkOfferFormSection from './LinkOfferFormSection/LinkOfferFormSection';
import OfferPageContext from '../../../generic/OfferPageContext/offerPageContext';

interface EditFormProps {
  initialValues: FormValues;
  onSubmit: (fv: FormValues, createInMarketplace: boolean) => void;
  dataMappingErrors?: { [k: string]: string };
}

type OnSubmit = (fv: FormValues) => Promise<void>;

const EditForm: React.FunctionComponent<EditFormProps> = ({
  initialValues,
  onSubmit,
  dataMappingErrors = {},
}) => {
  const [createInMarketplace, setCreateInMarketplace] = useState(false);
  const { mode } = useContext(OfferPageContext);
  const { offerForMode: offer } = useContext(OfferContext);
  const createdInMarketplaceAt = offer?.createdInMarketplaceAt;

  const createInMarketplaceProvidingOnSubmit =
    (cim: boolean): OnSubmit =>
    async (fv: FormValues): Promise<void> =>
      onSubmit(fv, cim);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema(mode)}
      onSubmit={createInMarketplaceProvidingOnSubmit(createInMarketplace)}
    >
      {({ values, errors, submitForm }) => {
        const onSaveDraft = async () => {
          setCreateInMarketplace(false);
          await submitForm();
        };

        const onSubmitToCloud = async () => {
          setCreateInMarketplace(true);
          await submitForm();
        };

        return (
          <Form>
            <EditOfferPage
              sidePanel={<QuickLinks formValues={values} formErrors={errors} />}
              dataMappingErrors={dataMappingErrors}
              initialFormValues={initialValues}
              formValues={values}
              hasFormErrors={Object.keys(errors).length > 0}
              onSaveDraft={onSaveDraft}
              onSubmitToCloud={onSubmitToCloud}
              createInMarketplace={createInMarketplace}
              createdInMarketplaceBanner={
                !!createdInMarketplaceAt ? <CreatedInMarketplaceBanner /> : null
              }
            >
              <Grid container>
                <Grid item md={12}>
                  {mode === OfferPageMode.Associate && (
                    <Box mb={2}>
                      <LinkOfferFormSection />
                    </Box>
                  )}
                  <Box mb={2}>
                    <BasicInformationFormSection />
                  </Box>
                  <Box mb={2}>
                    <ProductAndPricingFormSection />
                  </Box>
                  <Box mb={2}>
                    <EndUserLicenseAgreementFormSection />
                  </Box>
                  {values.offerType === OfferType.PartnerResale && (
                    <Box mb={2}>
                      <ResellerAgreementFormSection />
                    </Box>
                  )}
                  {values.productRef && (
                    <Box mb={2}>
                      <RegistrationDetailsFormSection />
                    </Box>
                  )}
                  {values.productRef && (
                    <Box mb={2}>
                      <AdditionalFieldsFormSection />
                    </Box>
                  )}
                  <Box mb={2}>
                    <OfferValidityFormSection />
                  </Box>
                  <Box mb={2}>
                    <NotifyUsersFormSection />
                  </Box>
                </Grid>
              </Grid>
            </EditOfferPage>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditForm;
