import { createContext } from 'react';
import { SalesforceDataMapping } from '../api/types/SalesforceDataMapping';

export interface SalesforceDataMappingContextValues {
  opportunityId?: string;
  mappingId?: string;
  salesforceDataMapping?: SalesforceDataMapping;
}

const SalesforceDataMappingContext =
  createContext<SalesforceDataMappingContextValues>({
    opportunityId: null,
    mappingId: null,
    salesforceDataMapping: null,
  });

export default SalesforceDataMappingContext;
