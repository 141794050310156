import { ValidationError } from 'yup';

export const runAssertions = (
  result: boolean | ValidationError,
  expected: boolean | ValidationError[],
) => {
  const expectedPass = typeof expected === 'boolean';
  const resultToAssert = expectedPass
    ? result
    : (result as ValidationError).inner;

  expect(resultToAssert).toEqual(expected);

  if (!expectedPass) {
    (expected as ValidationError[]).forEach((v: ValidationError, i: number) => {
      const { errors, path } = resultToAssert[i];

      expect(v.errors).toEqual(errors);
      expect(v.path).toEqual(path);
    });
  }
};

export const combineValidationErrors = (
  errors: ValidationError[],
  path: string,
): ValidationError => {
  const combinedValidationError = new ValidationError('', '', path);
  combinedValidationError.inner = errors;

  return combinedValidationError;
};

export const hasValidationError = (e: ValidationError | null) => !!e;

export const isWithProgress =
  (fieldKey: string, predicate: (v: any) => boolean) =>
  (v: any): any => {
    const value = v.progress ? v.values[fieldKey] : v[fieldKey];
    return predicate(value);
  };
