import { Box, Grid, makeStyles, Typography } from 'vendor/material';
import { formatDateFieldValueZeroPadded } from '../../../../../../generic/utils/date/dateUtils';
import React from 'react';
import { CurrencyCode, formatValueWithCurrencyPrefix } from 'utils/currency';
import { FormSchedule } from '../ScheduleRow/formTypes';
import { Divider } from '@tackle-io/platform-ui';

interface PaymentSchedulePreviewProps {
  schedules: FormSchedule[];
  currencyCode: CurrencyCode;
}

const useStyles = makeStyles((theme) => ({
  rowsContainer: {
    maxHeight: '70vh',
    overflow: 'auto',
    borderTop: `1px solid ${theme.palette.NEUTRAL100}`,
  },
  rows: {
    padding: theme.spacing(1),
    '&:nth-child(odd)': {
      background: theme.palette.NEUTRAL020,
    },
  },
}));

const PaymentSchedulePreview: React.FunctionComponent<
  PaymentSchedulePreviewProps
> = ({ schedules, currencyCode }) => {
  const classes = useStyles();

  const totalPayments = schedules
    .reduce((a: number, b: FormSchedule) => a + b.invoiceAmount, 0)
    .toFixed(2);

  return (
    <Box mb={2}>
      <Box mb={2}>
        <Grid container>
          <Grid item md={4}>
            <Typography>#</Typography>
          </Grid>
          <Grid item md={4}>
            <Typography>Invoice date</Typography>
          </Grid>
          <Grid item md={4}>
            <Typography>Invoice amount</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.rowsContainer}>
          {schedules.map((s, i) => (
            <Grid
              container
              className={classes.rows}
              key={`payment-schedule-preview-${i}`}
            >
              <Grid item md={4}>
                <Box display="flex" justifyContent="flex-start">
                  {i + 1}
                </Box>
              </Grid>
              <Grid item md={4}>
                {formatDateFieldValueZeroPadded(s.invoiceDate)}
              </Grid>
              <Grid item md={4}>
                <Box display="flex" justifyContent="flex-end">
                  {formatValueWithCurrencyPrefix(s.invoiceAmount, currencyCode)}
                </Box>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        Total payments:{' '}
        {formatValueWithCurrencyPrefix(totalPayments, currencyCode)}
      </Box>
    </Box>
  );
};

export default PaymentSchedulePreview;
