import React, { useCallback, useContext } from 'react';
import {
  ActivitySlug,
  OfferType,
  PrivateOffer,
} from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import usePolling from 'pages/PrivateOffers/pages/Next/generic/hooks/usePolling';
import { Box, Grid, makeStyles, Typography } from 'vendor/material';
import { Button, Link } from '@tackle-io/platform-ui';
import SectionDivider from 'pages/PrivateOffers/pages/Next/aws/view/OfferView/SectionDivider';
import ApiContext from 'pages/PrivateOffers/pages/Next/generic/ApiContextProvider/apiContext';
import { findMatchingProduct } from 'pages/PrivateOffers/pages/Next/generic/utils/product/productUtils';
import { getLatestOfferActivitySlug } from 'pages/PrivateOffers/pages/Next/generic/utils/offer/activityUtils';
import { OfferAPIKey } from 'pages/PrivateOffers/pages/Next/generic/ApiContextProvider/offerAPIKey';
import { isOfferExpired } from '../../utils/offer/offerUtils';
import OfferContext from '../../OfferContext/offerContext';
import OfferPageContext from '../../OfferPageContext/offerPageContext';

interface OfferActionsProps {
  onEdit: (offer: PrivateOffer) => void;
}

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.NEUTRAL000,
    border: `1px solid ${theme.palette.BLUE400}`,
  },
  actionTitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  actionDescription: {
    fontSize: 12,
    fontWeight: 400,
  },
  offerUrlContainer: {
    wordBreak: 'break-word',
  },
}));

const actionTitleByActivitySlug = {
  [ActivitySlug.VendorDraftedOffer]: 'Offer drafted',
  [ActivitySlug.VendorCreatedOffer]: 'Offer drafted',
  [ActivitySlug.VendorCreatedOfferInSalesforce]: 'Offer drafted in Salesforce',
  [ActivitySlug.VendorClonedOffer]: 'Offer drafted',
  [ActivitySlug.VendorSentPurchaseInstructions]: 'Purchase instructions sent',
  [ActivitySlug.VendorReSentPurchaseInstructions]:
    'Purchase instructions resent',
  [ActivitySlug.BuyerOpenedPurchaseInstructions]:
    'Purchase instructions opened by buyer',
  [ActivitySlug.BuyerViewedOffer]: 'Offer viewed by buyer',
  [ActivitySlug.BuyerAcceptedOffer]: 'Offer accepted by buyer',
  [ActivitySlug.VendorCreatedMarketplaceOfferPending]:
    'Submitting offer to the marketplace',
  [ActivitySlug.VendorCreatedMarketplaceOfferSuccess]:
    'Offer created in the marketplace',
  [ActivitySlug.VendorCreatedMarketplaceOfferFailed]:
    'Marketplace submission failed',
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationPending]:
    'Submitting offer expiration update',
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationSuccess]:
    'Offer expiration update succeeded',
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationFailed]:
    'Offer expiration update failed',
  [ActivitySlug.VendorCancelledMarketplaceOfferPending]:
    'Submitting offer cancellation',
  [ActivitySlug.VendorCancelledMarketplaceOfferSuccess]:
    'Offer canceled in the marketplace',
  [ActivitySlug.VendorCancelledMarketplaceOfferFailed]:
    'Offer cancellation failed',
};

const placeholder = 'Placeholder for offer step';

const actionDescriptionByActivitySlug = {
  [ActivitySlug.VendorDraftedOffer]: placeholder,
  [ActivitySlug.VendorCreatedOffer]: placeholder,
  [ActivitySlug.VendorCreatedOfferInSalesforce]: placeholder,
  [ActivitySlug.VendorClonedOffer]: placeholder,
  [ActivitySlug.VendorSentPurchaseInstructions]: placeholder,
  [ActivitySlug.VendorReSentPurchaseInstructions]: placeholder,
  [ActivitySlug.BuyerOpenedPurchaseInstructions]: placeholder,
  [ActivitySlug.BuyerViewedOffer]: placeholder,
  [ActivitySlug.BuyerAcceptedOffer]: placeholder,
  [ActivitySlug.VendorCreatedMarketplaceOfferPending]: placeholder,
  [ActivitySlug.VendorCreatedMarketplaceOfferSuccess]: placeholder,
  [ActivitySlug.VendorCreatedMarketplaceOfferFailed]: placeholder,
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationPending]: placeholder,
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationSuccess]: placeholder,
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationFailed]: placeholder,
  [ActivitySlug.VendorCancelledMarketplaceOfferPending]: placeholder,
  [ActivitySlug.VendorCancelledMarketplaceOfferSuccess]: placeholder,
  [ActivitySlug.VendorCancelledMarketplaceOfferFailed]: placeholder,
};

const startPollingSlugs = new Set<ActivitySlug>([
  ActivitySlug.VendorCreatedMarketplaceOfferPending,
  ActivitySlug.VendorChangedMarketplaceOfferExpirationPending,
  ActivitySlug.VendorCancelledMarketplaceOfferPending,
]);

const stopPollingSlugs = new Set<ActivitySlug>([
  ActivitySlug.VendorCreatedMarketplaceOfferSuccess,
  ActivitySlug.VendorCreatedMarketplaceOfferFailed,
  ActivitySlug.VendorSentPurchaseInstructions,
  ActivitySlug.VendorChangedMarketplaceOfferExpirationSuccess,
  ActivitySlug.VendorChangedMarketplaceOfferExpirationFailed,
  ActivitySlug.VendorCancelledMarketplaceOfferSuccess,
  ActivitySlug.VendorCancelledMarketplaceOfferFailed,
]);

const stopPollingPredicate = (o: PrivateOffer) => {
  const polledOfferLatestActivitySlug = getLatestOfferActivitySlug(o);
  return stopPollingSlugs.has(polledOfferLatestActivitySlug);
};

const MarketplaceActionPendingPollingInterval = 7500;

const OfferActions: React.FunctionComponent<OfferActionsProps> = ({
  onEdit,
}) => {
  const classes = useStyles();

  const {
    api: { getOfferSilently, updateOffer, sendBuyerInstructions, cancelOffer },
    isSubmitting,
  } = useContext(ApiContext);

  const { productsByProductId } = useContext(OfferPageContext);
  const { offer, setOffer } = useContext(OfferContext);
  const product = findMatchingProduct(offer.productId, productsByProductId);

  const updateEditOfferContextOffer = useCallback(
    (o: PrivateOffer) => {
      setOffer(o);
    },
    [setOffer],
  );

  const latestActivitySlug = getLatestOfferActivitySlug(offer);

  const { isPolling } = usePolling<PrivateOffer>({
    startPollingPredicate: () => startPollingSlugs.has(latestActivitySlug),
    pollingFn: async () => await getOfferSilently(offer.poId),
    stopPollingPredicate,
    afterPollingComplete: updateEditOfferContextOffer,
    pollingInterval: MarketplaceActionPendingPollingInterval,
  });

  const onEditClicked = () => {
    onEdit(offer);
  };

  const onSubmitToCloudClicked = useCallback(async () => {
    await updateOffer(offer.poId, offer, true);

    const updatedOffer = await getOfferSilently(offer.poId);

    updateEditOfferContextOffer(updatedOffer);
  }, [updateOffer, offer, getOfferSilently, updateEditOfferContextOffer]);

  const onSendEmailClicked = useCallback(async () => {
    await sendBuyerInstructions(offer.poId);

    const updatedOffer = await getOfferSilently(offer.poId);

    updateEditOfferContextOffer(updatedOffer);
  }, [
    sendBuyerInstructions,
    offer,
    getOfferSilently,
    updateEditOfferContextOffer,
  ]);

  const onCancelClicked = useCallback(async () => {
    await cancelOffer(offer.poId);

    const updatedOffer = await getOfferSilently(offer.poId);

    updateEditOfferContextOffer(updatedOffer);
  }, [cancelOffer, offer, getOfferSilently, updateEditOfferContextOffer]);

  const offerIsExpired = isOfferExpired(offer);

  const title = offerIsExpired
    ? 'Offer expired'
    : actionTitleByActivitySlug[latestActivitySlug] || '';

  const description = actionDescriptionByActivitySlug[latestActivitySlug] || '';
  const offerUrl = `https://aws.amazon.com/marketplace/saas/ordering?productId=${product.productid}&offerId=${offer.poId}`;

  const submittingToTheCloud =
    isSubmitting(OfferAPIKey.Offer) ||
    latestActivitySlug === ActivitySlug.VendorCreatedMarketplaceOfferPending;

  const cancellingOffer =
    isSubmitting(OfferAPIKey.CancelOffer) ||
    latestActivitySlug === ActivitySlug.VendorCancelledMarketplaceOfferPending;

  const offerCreatedInMarketplace = !!offer.createdInMarketplaceAt;

  const showActionsForInProgressOffer =
    offerCreatedInMarketplace &&
    !offerIsExpired &&
    !offer.acceptedAt &&
    !offer.cancelledAt;

  const offerEmailsHaveBeenSent =
    !!offer.sentAt ||
    !!offer.activities.find(
      (a) =>
        a.slug === ActivitySlug.VendorSentPurchaseInstructions ||
        a.slug === ActivitySlug.VendorReSentPurchaseInstructions,
    );

  return (
    <Box className={classes.actionContainer}>
      <Box mb={2}>
        <Box mb={2}>
          <Typography className={classes.actionTitle}>{title}</Typography>
        </Box>
        <Typography className={classes.actionDescription}>
          {description}
        </Typography>
      </Box>
      <Box mb={2}>
        <Button
          fullWidth
          variant="outlined"
          appearance="primary"
          onClick={onEditClicked}
          disabled={isPolling}
        >
          Edit offer
        </Button>
      </Box>
      {!offerCreatedInMarketplace && !offerIsExpired && (
        <Box mb={2}>
          <Button
            fullWidth
            appearance="primary"
            onClick={onSubmitToCloudClicked}
            disabled={isPolling}
            startIcon={<span />}
            loading={submittingToTheCloud}
          >
            Submit to cloud
          </Button>
        </Box>
      )}
      {showActionsForInProgressOffer && (
        <Box mb={2}>
          <Grid container spacing={1}>
            <Grid item xs>
              <Button
                fullWidth
                appearance="primary"
                onClick={onSendEmailClicked}
                disabled={isPolling}
                startIcon={<span />}
                loading={isSubmitting(OfferAPIKey.SendBuyerInstructions)}
              >
                {offerEmailsHaveBeenSent ? 'Resend' : 'Send email'}
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                fullWidth
                appearance="destructive"
                onClick={onCancelClicked}
                disabled={isPolling}
                startIcon={<span />}
                loading={cancellingOffer}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {offerCreatedInMarketplace && (
        <>
          <SectionDivider />
          <Box mb={2}>
            <Box mb={2}>
              <Typography className={classes.actionTitle}>
                {offer.sourceOfferId
                  ? 'Amended offer URL for buyer'
                  : offer.offerType === OfferType.PartnerResale
                  ? 'Partner offer URL for partner'
                  : 'Direct offer URL for buyer'}
              </Typography>
            </Box>
            <Box className={classes.offerUrlContainer}>
              <Link
                to={offerUrl}
                className={classes.actionDescription}
                external
              >
                {offerUrl}
              </Link>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default OfferActions;
