import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import { OfferType } from 'pages/PrivateOffers/pages/Next/generic/api/types/PrivateOffer';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';

export const getTackleSessionFromOtk = async <Payload>(otk: string) => {
  const otkRequest = await fetch(
    `${process.env.REACT_APP_TKL_AUTH_SERVICE_URL}/api/v1/auth/one-time-keys/exchange`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        key: otk,
      }),
    },
  );

  if (otkRequest.ok) {
    const otkRequestJson =
      (await otkRequest.json()) as IOneTimeKeyExchangeResponse<Payload>;
    return otkRequestJson;
  }
};

export interface IOneTimeKeyExchangeResponse<Payload> {
  /** auth token used as Bearer token for Tackle API requests */
  access_token: string;
  expires_in_seconds: number;
  context: SalesforceCanvasContext<Payload>;
}

/** available canvas components/actions. */
export type CanvasComponentId = 'CanvasApp' | 'Setup' | 'TackleWidget';

/** data for salesforce record id, etc */
type RecordType = {
  Id: string;
  /** this should be a union of known objects */
  objectName: 'Opportunity';
};

interface SalesforceCanvasContext<Payload> {
  client: Client;
  dimensions: Dimensions;
  /**
   * All data contained in Parameters is controlled by our tackle salesforce team.
   * if values are duplicated in the parent session object, use the values here.
   * Parameters vary depending on the Parameter.context value
   */
  parameters: Parameters<Payload>;
}

interface Client {
  instanceId: string;
  oauthToken: string;
  instanceUrl: string;
  /** currently not implemented, so always returns null */
  refreshToken: null;
  targetOrigin: string;
}

/** dimensions of Canvas App in pixels */
interface Dimensions {
  width: string;
  height: string;
  maxWidth: string;
  maxHeight: string;
  clientWidth: string;
  clientHeight: string;
}

type TacklePermission =
  | 'tackle_create_co_sells'
  | 'tackle_create_co_sells'
  | 'tackle_integration_connection'
  | 'tackle_view_co_sell_details';

interface Account {
  Id?: string | null;
  Website?: string | null;
}

type TackleDomain = 'cosell' | 'offers' | 'prospect';

/**
 *  TODO: update this to conditionally include fields based on the context
 */
interface Parameters<Payload> {
  account?: Account;
  componentId: CanvasComponentId;
  isConnected?: boolean;
  namespace: 'tackle';
  permissions: Record<TacklePermission, boolean>;
  redirectUri: string;
  /** returned for single record contexts */
  record?: RecordType;
  /** returned for bulk record contextx */
  records?: RecordType[];
  /** authenticated tackle vendor id */
  vendorId: string;
  environment: 'DEV' | 'STAGING' | 'PROD';
  /** payload context from previous step */
  payload?: {
    record: RecordType;
    action: string;
    domain: TackleDomain;
    cloud: string;
  } & Payload;
}

export type ParameterPayload<ContentPayload> = {
  record: RecordType;
  [key: string]: any;
} & ContentPayload;

type CanvasAppContentPayloads = CoSellCanvasAppContentPayloads &
  OffersCanvasAppContentPayloads;

type Cloud = 'aws' | 'azure' | 'gcp';

export type OffersCanvasAppContentPayloads = {
  create: {
    domain: 'offers';
    action: 'create';
    cloud: Cloud;
    offerType: OfferType;
    newOrAbo: OfferPageMode;
  };
  view: {
    domain: 'offers';
    action: 'view';
    cloud: Cloud;
    poId: string;
  };
  edit: {
    domain: 'offers';
    action: 'edit';
    cloud: Cloud;
    poId: string;
  };
  clone: {
    domain: 'offers';
    action: 'clone';
    cloud: Cloud;
    poId: string;
  };
};

export type OffersCanvasAppContentPayload =
  OffersCanvasAppContentPayloads[keyof OffersCanvasAppContentPayloads];

export type TackleWidgetContentPayload = {
  domain: TackleDomain;
  cloud?: DisplayCloudType;
};

export type CoSellCanvasAppContentPayloads = {
  'create-cosell': {
    domain: 'cosell';
    object: 'opportunity';
    cloud: DisplayCloudType;
    action: 'create';
    crmId: string;
  };
  'edit-cosell': {
    domain: 'cosell';
    object: 'opportunity';
    action: 'edit';
    cloud: DisplayCloudType;
    tackleOpportunityId: string;
  };
  'view-cosell': {
    domain: 'cosell';
    object: 'opportunity';
    action: 'view';
    cloud: DisplayCloudType;
    tackleOpportunityId: string;
  };
  'view-invitation': {
    domain: 'cosell';
    object: 'invitation';
    action: 'view';
    cloud: DisplayCloudType;
    tackleInvitationId: string;
  };
};

/** a union of co-sell CosellCanvas Payloads */
export type CoSellCanvasAppContentPayload =
  CoSellCanvasAppContentPayloads[keyof CoSellCanvasAppContentPayloads];

/** a union of all available Content paylods */
export type CanvasAppContentPayload =
  CanvasAppContentPayloads[keyof CanvasAppContentPayloads];
