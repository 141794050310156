import { useMemo } from 'react';
import { DisplayCloudType } from '../../types/enums';
import { ErrorBoundary } from 'components';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import { useCurrentUserVendorQuery } from 'generated/graphql';
import { UnifiedOpportunityView } from '../../utilities/constants';
import UnifiedOpportunityDetails from '../UnifiedOpportunityDetails';
import { useCoSellHistory } from '../../hooks';
import { COSELL_PATH } from '../../utilities/constants';
import { CoSellContextProvider } from 'packages/cosell/src/CoSellContextProvider';
import { useQueryClient } from '@tanstack/react-query';
import { ACE_OPPORTUNITY_QUERY_KEY } from 'packages/cosell/api/hooks/useAceOpportunity';
import CreateAceOpportunityForm from '../../components/UnifiedOpportunityForm/AceOpportunityForm/CreateAceOpportunityForm';
import EditAceOpportunityForm from '../../components/UnifiedOpportunityForm/AceOpportunityForm/EditAceOpportunityForm';

const useRedirectToOpportunityDetails = () => {
  const provider = DisplayCloudType.AWS;
  const queryClient = useQueryClient();
  const history = useCoSellHistory();
  return (opportunityId: string) => {
    // Invalidate the query to force a refetch
    queryClient.invalidateQueries([ACE_OPPORTUNITY_QUERY_KEY, opportunityId]);
    history.push(
      `${COSELL_PATH}/opportunity/${provider.toLowerCase()}/${opportunityId}`,
    );
  };
};

/**
 *
 * TODO:
 * - update this to handle the cloud and view type via routes & params
 */
const UnifiedOpportunityForm = ({
  view,
  match,
}: {
  view: UnifiedOpportunityView;
  match: { params: { [key: string]: string }; url: string };
  onCancel?: () => void;
}) => {
  const redirectToOpportunityDetails = useRedirectToOpportunityDetails();
  const history = useCoSellHistory();
  const cloud = useMemo(
    () => match.params.cloud.split('?')[0],
    [match.params.cloud],
  );
  const opportunityId = useMemo(
    () => match.params.opportunityId?.split('?')[0],
    [match.params.opportunityId],
  );

  const handleCreateCancel = () => {
    if (!opportunityId) {
      /**  Redirect to Co-sell page */
      history.push({
        pathname: COSELL_PATH,
        search: history.location.state?.coSellSearchParams,
      });
      return;
    }
  };

  return (
    <UnifiedOpportunityFormContainer opportunityId={opportunityId}>
      <>
        {cloud === DisplayCloudType.AWS &&
          view === UnifiedOpportunityView.CREATE_NEW_CO_SELL_FORM && (
            <CreateAceOpportunityForm
              onCancel={handleCreateCancel}
              onCreated={redirectToOpportunityDetails}
            />
          )}

        {cloud === DisplayCloudType.AWS &&
          view === UnifiedOpportunityView.EDIT_CO_SELL_FORM && (
            <EditAceOpportunityForm
              onCancel={() => redirectToOpportunityDetails(opportunityId)}
              onUpdateSuccess={redirectToOpportunityDetails}
              opportunityId={opportunityId}
            />
          )}

        {cloud === DisplayCloudType.AWS &&
          view === UnifiedOpportunityView.CO_SELL_DETAILS && (
            <UnifiedOpportunityDetails tackleOpportunityId={opportunityId} />
          )}
      </>
    </UnifiedOpportunityFormContainer>
  );
};

interface UnifiedOpportunityFormContainerProps {
  opportunityId?: string;
  children?: React.ReactElement;
}

/** Wrapper for Tackle-UI only. */
export const UnifiedOpportunityFormContainer = ({
  opportunityId,
  children,
}: UnifiedOpportunityFormContainerProps) => {
  const { data: currentUserData } = useCurrentUserVendorQuery({
    nextFetchPolicy: 'cache-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const vendorId = currentUserData?.currentUser?.vendor?.id;

  if (!vendorId) {
    return <div>Not Authenticated</div>;
  }

  return (
    <ErrorBoundary renderError={() => <ErrorPage title="Co-sell" />}>
      <CoSellContextProvider
        opportunityId={opportunityId}
        renderEnv="downstream"
        vendorId={vendorId}
      >
        {children}
      </CoSellContextProvider>
    </ErrorBoundary>
  );
};
export default UnifiedOpportunityForm;
